import styles from '../styles/LegalEntityTable.module.scss';
import { MyModal } from '../../../components/common/MyModal/MyModal';
import { Contact } from '../../../types/interfaces';
interface IContactTable {
  contact: Contact;
}

const ContactTable = ({ contact }: IContactTable) => {
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>ФИО</td>
          <td>{contact.name} {contact.surname} {contact.patronymic}</td>
        </tr>
        <tr>
          <td>Должность</td>
          <td>{contact.position}</td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>{contact.personal_phone}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ContactTable;
