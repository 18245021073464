const months = {
  '01': 'Января',
  '02': 'Февраля',
  '03': 'Марта',
  '04': 'Апреля',
  '05': 'Мая',
  '06': 'Июня',
  '07': 'Июля',
  '08': 'Августа',
  '09': 'Сентября',
  10: 'Октября',
  11: 'Ноября',
  12: 'Декабря',
};

const getTime = (
  time,
  hasDayTime = true,
  hasMonthName = true,
  forComment = false
) => {
  if (!time) return '';
  const year = time.slice(0, 4);
  const month = time.slice(5, 7);
  const day = time.slice(8, 10);
  const dayTime = time.slice(11, 16);
  if (forComment) {
    return `${day} ${months[month]}`;
  }
  if (hasDayTime && hasMonthName) {
    return `${day} ${months[month]} ${year}г., ${dayTime}`;
  }
  if (hasDayTime) {
    return `${day}.${month}.${year}г., ${dayTime}`;
  }
  if (hasMonthName) {
    return `${day} ${months[month]} ${year}г.`;
  }
  return `${day}.${month}.${year}г`;
};
export default getTime;
