import React, { lazy } from 'react';
import ProtectedRoute from '../ProtectedRoute';

const ManagerAddEdit = lazy(() =>
  import('../../components/modules/ManagerAddEdit/ManagerAddEdit')
);
const Managers = lazy(() => import('../../pages/Managers/Managers'));

export const managers_routes = [
  {
    path: 'managers/*',
    element:<ProtectedRoute
    action='view'
    subject={'username'}
    redirectTo={'/'}
    children={
      <Managers />
    }/>,
    loader: () => {
      return document.title = 'Менеджеры';
    },
  },
  {
    path: 'managers/:id',
    element: (
      <ProtectedRoute
        isOnlySuperManager={true}
        action='change'
        subject={'username'}
        redirectTo={'/managers'}
        children={<ManagerAddEdit />}
      />
    ),
    loader: () => {
      return document.title = 'Редактирование данных';
    },
  },
  {
    path: 'managers/add',
    element: (
      <ProtectedRoute
        isOnlySuperManager={true}
        action='add'
        subject={'username'}
        redirectTo={'/managers'}
        children={<ManagerAddEdit />}
      />
    ),
    loader: () => {
      return document.title = 'Добавление менеджера';
    },
  },
];
