import {
  Box,
  Flex,
  Center,
  useColorModeValue,
  useBreakpointValue,
  Image,
} from '@chakra-ui/react';
import bg from '../../../assets/images/building.jpeg';
import logo from '../../../assets/images/register-logo.png';

interface Props {
  leftContent?: React.ReactNode;
  children: React.ReactNode;
}
const AuthLayout = ({ leftContent, children }: Props) => {
  const flexDirection: any = useBreakpointValue({
    base: 'column-reverse',
    md: 'row',
  });
  const leftSideDisplay = useBreakpointValue({ base: 'none', md: 'flex' });

  return (
    <Flex direction={flexDirection} minH={'100vh'}>
      <Box
        display={leftSideDisplay}
        flex="1"
        bgImage={`linear-gradient(to top, rgba(227, 40, 42, 0.9), rgba(227, 40, 42, 0.9)), url(${bg})`}
        bgSize="cover"
        bgRepeat="no-repeat"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Center height="100%">
          {leftContent ? leftContent : <Image src={logo} alt="" />}
        </Center>
      </Box>
      <Box
        position={'relative'}
        flex="1"
        bg={useColorModeValue('white.100', 'white.800')}
      >
        <Flex direction="column" justifyContent="space-between" h="100%" p={8}>
          <Center p={10} flexDirection={'column'} flex="1">
            {children}
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AuthLayout;
