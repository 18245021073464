import { AbilityBuilder, Ability } from '@casl/ability';
import { User } from '../../types/interfaces';

export function defineAbilitiesFor(user: User) {
  const { can, build } = new AbilityBuilder(Ability);

  if (user.permissions) {
    Object.entries(user.permissions).forEach(([resource, actions]: any) => {
      actions.forEach((action: string) => {
        can(action, resource);
      });
    });
  }
  if (user.is_superuser) {
    can('manage', 'all');
  }

  return build();
}
