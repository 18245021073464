import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusType, LegalEntity } from '../../types/interfaces';
import {
  fetchAllLegalEntities,
  fetchLegalEntityById,
  createLegalEntity,
  updateLegalEntity,
  deleteLegalEntity,
} from './actions';

export interface LegalEntitiesState {
  legalEntities: LegalEntity[];
  currentLegalEntity: LegalEntity | {};
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: string | null;
}

const initialState: LegalEntitiesState = {
  legalEntities: [],
  listFetchStatus: 'idle',
  currentLegalEntity: {},
  status: 'idle',
  error: null,
};

const legalEntitiesSlice = createSlice({
  name: 'legalEntities',
  initialState,
  reducers: {
    resetLegalEntities: () => initialState,
    deleteAppLegalEntity: (state, action) => {
      state.legalEntities.filter((le) => le.id !== action.payload);
    },
    resetCurrentLegalEntity: (state) => {
      state.currentLegalEntity = {};
      state.status = 'idle';
    },
    updateAppLegalEntity: (state, action) => {
      state.currentLegalEntity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLegalEntities.pending, (state) => {
        //state.status = 'loading';
        state.listFetchStatus = 'loading';
      })
      .addCase(fetchAllLegalEntities.fulfilled, (state, action) => {
        //state.status = 'succeeded';
        state.listFetchStatus = 'succeeded';
        state.legalEntities = action.payload;
      })
      .addCase(fetchAllLegalEntities.rejected, (state, action) => {
        //state.status = 'failed';
        state.listFetchStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch legal entities';
      })
      .addCase(fetchLegalEntityById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLegalEntityById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentLegalEntity = action.payload;
      })
      .addCase(fetchLegalEntityById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch legal entity';
      })
      .addCase(createLegalEntity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createLegalEntity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.legalEntities = [...state.legalEntities, action.payload];
      })
      .addCase(createLegalEntity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create legal entity';
      })
      .addCase(updateLegalEntity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateLegalEntity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.legalEntities = state.legalEntities.map((legalEntity) => {
          if (legalEntity.id === action.payload.id) {
            legalEntity = action.payload;
          }
          return legalEntity;
        });
      })
      .addCase(updateLegalEntity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update legal entity';
      })
      .addCase(deleteLegalEntity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteLegalEntity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.legalEntities = state.legalEntities.filter(
          (legalEntity) => legalEntity.id !== action.payload
        );
      })
      .addCase(deleteLegalEntity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete legal entity';
      });
  },
});

export const selectLegalEntities = (state: any): LegalEntity[] =>
  state.legalEntities.legalEntities;
export const selectCurrentLegalEntity = (state: any): LegalEntity =>
  state.legalEntities.currentLegalEntity;
export const selectLegalEntityStatus = (state: any): FetchStatusType =>
  state.legalEntities.listFetchStatus;
export const selectLegalEntityError = (state: any): string =>
  state.legalEntities.error;
export const {
  resetLegalEntities,
  deleteAppLegalEntity,
  resetCurrentLegalEntity,
  updateAppLegalEntity,
} = legalEntitiesSlice.actions;
export default legalEntitiesSlice.reducer;
