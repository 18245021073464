import React from 'react';
import styles from './Button.module.scss';
import spinner from '../../../assets/sprites/spinner.svg';
interface ButtonProps {
  children?: React.ReactNode;
  isWhite?: boolean;
  status?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => null | void;
  type?: any;
  variant?: 'default' | 'danger' | 'light';
}
const Button: React.FC<ButtonProps> = ({
  children,
  isWhite,
  status,
  type = 'button',
  variant = 'default',
  ...props
}) => {
  //const buttonClassName = isWhite ? 'light' : 'default';
  return (
    <>
      <button
        {...props}
        type={type}
        disabled={status === 'loading' || props.disabled}
        className={[
          `${styles.button} ${isWhite ? styles['light'] : styles[variant]} ${
            props.className
          }`,
        ].join(' ')}
      >
        {status === 'loading' ? (
          <img className={styles.spinner} src={spinner} alt="Загрузка" />
        ) : (
          children
        )}
      </button>
    </>
  );
};

export default Button;
