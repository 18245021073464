import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
  Button,
  FormLabel,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  Text,
} from '@chakra-ui/react';
import Select from 'react-select';
import InputField from '../../common/InputField/InputField';
import packageJson from '../../../../package.json';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteIcon } from '@chakra-ui/icons';
import { itemsApi } from '../../../api/services/items.service';
import { Controller, useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './PdfAddViewer.scss';
import {
  setErrorNotification,
  setNotification,
} from '../../../store/notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import { priceAddEditSchema } from '../../../utils/validators/catalogAddEditValidators';
import { selectItemsStatus } from '../../../store/items/reducer';
import {
  selectCompanyList,
} from '../../../store/companies/reducer';
import { fetchAllCompanies } from '../../../store/companies/actions';
import { useMe } from '../../../pages/Area/Area';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  createPrice,
  fetchPriceById,
  updatePrice,
  deletePrice as deletePriceAction,
} from '../../../store/prices/actions';
import {
  selectCurrentPrice,
  selectPricesStatus,
} from '../../../store/prices/reducer';
import { Can } from '../../../utils/context/can';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace(/\^/g, '');

const PdfAddViewer = () => {
  const { id } = useParams();
  const status = useSelector(selectPricesStatus);
  const dispatch = useDispatch();
  const companies = useSelector(selectCompanyList);
  const currentPrice = useSelector(selectCurrentPrice);
  const [pdfFile, setPdfFile] = useState(
    currentPrice?.file ? currentPrice?.file : null
  );
  const [viewPdf, setViewPdf] = useState(
    id ? currentPrice?.file : null
  );

  const currentPriceCompanies = currentPrice
    ? companies.filter((company) => currentPrice.companies.includes(company.id)).map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    : [];
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(priceAddEditSchema),
    defaultValues: {
      name: '',
      companies: [],
      file: null
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchPriceById(id));
    }
    dispatch(fetchAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      reset({
        ...currentPrice,
        companies: currentPriceCompanies
      })
    }
  }, [dispatch]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('file', pdfFile);
    data.companies.forEach((company) => {
      formData.append('companies', company.value);
    });
    if (id) {
      dispatch(updatePrice({ id, data: formData }));
    } else {
      dispatch(createPrice(formData));
    }
  };

  const deletePrice = (id) => {
    dispatch(deletePriceAction(id));
  };

  return (
    <div className="prices-container">
      <Can I="change" a="pricelist">
        <Can I="add" a="pricelist">
          <div className="prices__upper-block-group">
            <FormLabel>Выберите файл</FormLabel>
            <CustomPdfInput onChange={setPdfFile} viewPdf={setViewPdf} />
          </div>
          <form as="form" onSubmit={handleSubmit(onSubmit)}>
            <InputField
              id="name"
              placeholder="Название прайса"
              label={'Название прайса'}
              name="name"
              inputName={'name'}
              register={register}
              errors={errors}
            />
            <FormLabel>Выберите интеграторов</FormLabel>
            {errors.selectedItems && (
              <div style={{ color: 'red' }} className="error">
                {errors.selectedItems.message}
              </div>
            )}
            <Controller
              name="companies"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Выберите интеграторов"
                  closeMenuOnSelect={false}
                  isMulti
                  options={companies.map((item) => {
                    return {
                      ...item,
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  className="group-select"
                />
              )}
            />
            <Button
              loadingText={id ? 'Сохранение...' : 'Добавление...'}
              isLoading={status === 'loading'}
              type="submit"
              colorScheme="red"
              mt={3}
            >
              {id ? 'Сохранить' : 'Добавить'}
            </Button>
          </form>
        </Can>
      </Can>
      <Can I="delete" a="pricelist">
        {id && (
          <Popover>
            <PopoverTrigger>
              <DeleteIcon
                position={'absolute'}
                top={4}
                right={4}
                color={'whiteAlpha'}
                cursor={'pointer'}
                _hover={{ color: 'red' }}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>Удалить прайс?</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                  <Button onClick={() => deletePrice(id)} colorScheme="red">
                    Удалить
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Can>
      <Can I="view" a="pricelist">
        {viewPdf && (
          <Worker
            workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}
          >
            <Viewer fileUrl={viewPdf} />
          </Worker>
        )}
      </Can>
    </div>
  );
};

export default PdfAddViewer;

const CustomPdfInput = ({ onChange, viewPdf }) => {
  const [fileName, setFileName] = useState(null);
  const handleFileChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFileName(selectedFile.name);
      onChange(selectedFile);
      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (e) => {
        viewPdf(e.target.result);
      };
    }
  };

  return (
    <VStack spacing={2} align="start">
      <input
        type="file"
        accept="application/pdf"
        style={{ display: 'none' }}
        id="pdf-input"
        onChange={handleFileChange}
      />
      <label htmlFor="pdf-input">
        <Button as="span" colorScheme="blue" size="md">
          Выберите PDF
        </Button>
      </label>
      {fileName && <Text fontSize="sm">{fileName}</Text>}
    </VStack>
  );
};
