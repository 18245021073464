import { useSelector } from 'react-redux';
import { RegistrationStepCredentials } from './Steps/RegistrationStepCredentials';
import { RegistrationStepCompanyData } from './Steps/RegistrationStepCompanyData';
import { RegistrationStepLegalAddress } from './Steps/RegistrationStepLegalAddress';
import { RegistrationStepDeliveryAddress } from './Steps/RegistrationStepDeliveryAddress';
import { RegistrationStepConfirmation } from './Steps/RegistrationStepConfirmation';
import { RegistrationStepContactPerson } from './Steps/RegistrationStepContactPerson';

import './Registration.scss';
import ErrorPage from './Steps/ErrorPage';
import AuthLayout from '../../components/layout/AccessLayout/AccessLayout';
import { selectCurrentStep } from '../../store/registration/reducer';
import { RegistrationStepShowAllData } from './Steps/RegistrationStepShowAllData';

const Registration = () => {
  const currentStep = useSelector(selectCurrentStep);
  const render = () => {
    if (currentStep === 0) {
      return <RegistrationStepCredentials />;
    } else if (currentStep === 1) {
      return <RegistrationStepCompanyData />;
    } else if (currentStep === 2) {
      return <RegistrationStepLegalAddress />;
    } else if (currentStep === 3) {
      return <RegistrationStepDeliveryAddress />;
    } else if (currentStep === 4) {
      return <RegistrationStepContactPerson />;
    } else if (currentStep === 5) {
      return <RegistrationStepShowAllData />;
    } else if (currentStep === 6) {
      return <RegistrationStepConfirmation/>;
    }
    return null;
  };
  return currentStep === 8 ? (
    <ErrorPage />
  ) : (
    <AuthLayout>{render()}</AuthLayout>
  );
};

export default Registration;
