import React from 'react';
import styles from './CardLayout.module.scss';

interface IProps {
  children: React.ReactNode;
  withLine?: boolean;
  className?: string;
  onClick?: () => void;
}
const Header = ({ children, withLine }: IProps) => {
  const className = withLine ? styles.headerWithLine : styles.header;
  return <div className={className}>{children}</div>;
};

const Main = ({ children }: IProps) => {
  return <div className={styles.main}>{children}</div>;
};

const Footer = ({ children }: IProps) => {
  return <div className={styles.footer}>{children}</div>;
};

const CardLayout = ({ children, className = '', onClick }: IProps) => {
  return (
    <div className={[styles.card, className].join(' ')} onClick={onClick}>
      {children}
    </div>
  );
};

CardLayout.Header = Header;
CardLayout.Main = Main;
CardLayout.Footer = Footer;

export default CardLayout;
