import React from 'react';
import phoneImg from '../../../assets/sprites/telephone.svg';
import emailImg from '../../../assets/sprites/envelope.svg';
import whatsappImg from '../../../assets/sprites/whatsapp.svg';
import telegramImg from '../../../assets/sprites/telegram.svg';
import inn from '../../../assets/sprites/file-text.svg';

import styles from './ContactInfo.module.scss';
import { Company, Contact, LegalEntity, User } from '../../../types/interfaces';

function handleStopPropagation(e: React.MouseEvent) {
  e.stopPropagation();
}

const ContactInfo = ({ company }: any) => (
  <div className={styles.contactCard__data}>
    {!company?.phones ? company?.mobile_phone ? (
      <a
        href={`tel:${(company?.mobile_phone)
          }`}
        onClick={handleStopPropagation}
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={phoneImg}
          alt="Телефон"
        />
        <span className={styles.contactCard__data_value}>
          {company?.mobile_phone}
        </span>
      </a>
    ) : (
      <div
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={phoneImg}
          alt="Телефон"
        />
        <span className={styles.contactCard__data_value}>
       не указано
        </span>
      </div>
    ) : null}
    {!company?.phones ? company?.whatsapp ? (
      <a
        href={`https://wa.me/${company?.whatsapp}`}
        onClick={handleStopPropagation}
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={whatsappImg}
          alt="WhatsApp"
        />
        <span className={styles.contactCard__data_value}>
          {company?.whatsapp}
        </span>
      </a>
    ) : (
      <div
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={whatsappImg}
          alt="WhatsApp"
        />
        <span className={styles.contactCard__data_value}>
        не указано

        </span>
      </div>
    ) : null}
    {!company?.phones ? company?.telegram ? (
      <a
        href={`https://t.me/${company?.telegram}`}
        onClick={handleStopPropagation}
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={telegramImg}
          alt="Telegram"
        />
        <span className={styles.contactCard__data_value}>
          {company?.telegram}
        </span>
      </a>
    ) :
      <div
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={telegramImg}
          alt="Telegram"
        />
        <span className={styles.contactCard__data_value}>
        не указано

        </span>
      </div>
      : null}
    {company?.email ? (
      <a
        href={`mailto:${company?.email}`}
        onClick={handleStopPropagation}
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={emailImg}
          alt="Email"
        />
        <span className={styles.contactCard__data_value}>
          {company?.email}
        </span>
      </a>
    ) : (
      <div
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={emailImg}
          alt="Email"
        />
        <span className={styles.contactCard__data_value}>
        не указано
        </span>
      </div>
    )}
    {company?.phones && (
      <a
        href={`tel:${(company?.phones[0])
          }`}
        onClick={handleStopPropagation}
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={phoneImg}
          alt="Email"
        />
        <span className={styles.contactCard__data_value}>
          {company?.phones[0]}
        </span>
      </a>
    )}
    {company?.inn &&
      (<div
        className={styles.contactCard__data_item}
      >
        <img
          className={styles.contactCard__data_icon}
          src={inn}
          alt="Email"
        />
        <span className={styles.contactCard__data_value}>
          ИНН:  {company?.inn}
        </span>
      </div>
      )}
  </div>
);

export default ContactInfo;
