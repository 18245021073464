import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

interface CustomModalProps {
  openButton: JSX.Element;
  title?: string;
  children?: JSX.Element;
  footer?: JSX.Element;
  size?: string;
  isClosed?: boolean;
  open?: () => void;
}
const CustomModal = ({
  openButton,
  title,
  children,
  footer,
  size = 'xl',
  isClosed,
  open,
}: CustomModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onOpen();
  };

  useEffect(() => {
    if (isClosed) {
      onClose();
    }
  }, [isClosed, onClose]);

  return (
    <>
      {React.cloneElement(openButton, {
        onClick: (e: React.MouseEvent) => {
          openModal(e);
          open && open();
        },
      })}
      <Modal size={size} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;
