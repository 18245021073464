import React from 'react';
import { Router } from './router/Router';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { MessageProvider } from './utils/hooks/useMessage';
import { MessageToast } from './components/common/MessageToast/MessageToast';

const App = () => {
  return (
    <div className="App">
      <MessageProvider>
        <RouterProvider router={Router} />
        <MessageToast />
      </MessageProvider>
    </div>
  );
};

export default App;
