import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import { Address } from '../../../types/interfaces';

const RegistrationTable = ({ legalEntities }: { legalEntities: any[] }) => {
  return (
    <Table variant="simple" colorScheme="blue" borderRadius="md" boxShadow="md">
      <Thead>
        <Tr>
          <Th>Название Юридического Лица</Th>
          <Th>Электронная почта</Th>
          <Th>Телефоны</Th>
          <Th>ИНН</Th>
          <Th>Адреса</Th>
        </Tr>
      </Thead>
      <Tbody>
        {legalEntities.map((legalEntity, index) => (
          <Tr key={index} _hover={{ bg: 'blue.100' }}>
            <Td>{legalEntity.name}</Td>
            <Td>{legalEntity.email}</Td>
            <Td>{legalEntity.phones.join(', ')}</Td>
            <Td>{legalEntity.inn}</Td>
            <Td>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Показать адреса
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {legalEntity.addresses.map(
                      (address: Address, addrIndex: number) => (
                        <Box key={addrIndex} mb={4}>
                          <p>{`Тип: ${
                            address.type === 'legal'
                              ? 'Юридический'
                              : 'Физический'
                          }`}</p>
                          <p>{`Страна: ${address.country}`}</p>
                          <p>{`Регион: ${address.region}`}</p>
                          <p>{`Город: ${address.city}`}</p>
                          <p>{`Улица: ${address.street}`}</p>
                          <p>{`Дом: ${address.house}`}</p>
                          <p>{`Квартира: ${address.apartment}`}</p>
                          <p>{`Почтовый индекс: ${address.postcode}`}</p>
                        </Box>
                      )
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default RegistrationTable;
