import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useAppDispatch } from '../../store/store';
import {
  addItemToCart,
  deleteItemFromCart,
  deleteVerificationFromCart,
} from '../../store/cart/actions';
import { Item } from '../../types/interfaces';

interface CartItemLogicProps {
  item: Item;
  CartItemUI: (props: {
    item: Item;
    quantity: number;
    verificationQuantity: number;
    onQuantityChange: (value: number) => void;
    onVerificationQuantityChange: (value: number) => void;
    removeFromCart: () => void;
    removeVerifyHandler: (id: number) => void;
    addingItemToCart: ({quantity, verificationQuantity}:{quantity: number, verificationQuantity: number}) => void;
  }) => JSX.Element;
}

export const CartItemLogic = ({
  item,
  CartItemUI,
}: CartItemLogicProps): JSX.Element => {

  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [verificationQuantity, setVerificationQuantity] = useState<number>(
    item?.verification_quantity
  );
  const dispatch = useAppDispatch();

  const addingItemToCart = ({quantity, verificationQuantity}: {quantity: number, verificationQuantity: number}) => {
    dispatch(
      addItemToCart({
        id: item.id,
        quantity,
        verification_item: item.verification_item
          ? item.verification_item.id.toString()
          : null,
        verification_quantity: item.verification_item
          ? verificationQuantity
          : 0,
        inCart: item?.inCart,
      })
    );
  };

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  useEffect(() => {
    setVerificationQuantity(item.verification_quantity);
  }, [item]);

  const handleInputChange = useRef(
    _.debounce(({itemQuatity, verificationQuantity}:{itemQuatity: number, verificationQuantity: number}) => {
      if (item.id != null) {
        if (itemQuatity < verificationQuantity) {
          addingItemToCart({quantity: itemQuatity, verificationQuantity: itemQuatity});
        } else {
          addingItemToCart({quantity: itemQuatity, verificationQuantity: verificationQuantity});
        }
      }
    }, 500)
  ).current;

  const handleVerificationInputChange = useRef(
    _.debounce(({itemQuatity, verificationQuantity}:{itemQuatity: number, verificationQuantity: number}) => {
      if (item.id != null) {
        if (itemQuatity < verificationQuantity) {
          addingItemToCart({quantity: verificationQuantity, verificationQuantity: verificationQuantity});
        } else {
          addingItemToCart({quantity: itemQuatity, verificationQuantity: verificationQuantity});
        }
      }
    }, 500)
  ).current;

  const handleInput = (value: number) => {
    value = parseInt(value ? value.toString() : '1', 10);
    setQuantity(value);
    handleInputChange({itemQuatity: value, verificationQuantity: verificationQuantity});
  };

  const handleVerificationInput = (value: number) => {
    value = parseInt(value ? value.toString() : '1', 10);
    setVerificationQuantity(value);
    handleVerificationInputChange({itemQuatity: quantity, verificationQuantity: value});
  };

  const removeFromCart = () => {
    dispatch(deleteItemFromCart(item.id));
  };

  const removeVerifyHandler = (id: number) => {
    dispatch(
      deleteVerificationFromCart({
        id,
        quantity,
        verification_item: item?.verification_item?.id.toString() || null,
      })
    );
  };


  return (
    <CartItemUI
      item={item}
      quantity={quantity}
      verificationQuantity={verificationQuantity}
      onQuantityChange={handleInput}
      onVerificationQuantityChange={handleVerificationInput}
      removeFromCart={removeFromCart}
      removeVerifyHandler={removeVerifyHandler}
      addingItemToCart={addingItemToCart}
    />
  );
};
