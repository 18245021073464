import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import './InfoTable.scss';
import { Order } from '../../../../types/interfaces';
export function ContactInfo({ order }: { order: Order }) {
  return (
    <Table variant="underline">
      <Thead className="info-table__header">
        <Tr>
          <Th width="15%">ФИО</Th>
          <Th width="15%">Почта</Th>
          <Th width="15%">Телефон</Th>
        </Tr>
      </Thead>
      <Tbody className="info-table__content">
        <Tr>
          <Td>{order.contact_full_name}</Td>
          <Td>{order.contact_email || 'Не указана'}</Td>
          <Td>{order.contact_phone || 'Не указан'}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}