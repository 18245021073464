import { useSelector } from 'react-redux';
import { RegisterHeader } from './RegistrationStepCompanyData';
import { selectResponsibleManager } from '../../../store/registration/reducer';
import { selectCurrentInvite } from '../../../store/invites/reducer';
import { Link } from 'react-router-dom';

export const RegistrationStepConfirmation = () => {
  const responsibleManager = useSelector(selectResponsibleManager);
  const invite = useSelector(selectCurrentInvite);

  if (invite?.type === 'employee') {
    return (
      <div className="auth__right">
        <RegisterHeader
          title="Регистрация завершена"
          description="Вы успешно зарегистрировались. Теперь вы можете войти в свою учетную запись, используя указанные при регистрации данные."
        />
        <Link style={{ color: 'blue' }} to="/login" className="login-button">
          Перейти на страницу входа
        </Link>
      </div>
    );
  }
  return (
    <div className="auth__right">
      <RegisterHeader
        title="Спасибо за заполнение всех полей формы"
        description={`Данную форму проверит Ваш персональный менеджер ${responsibleManager?.name}. 
  Обычно это занимает не больше одного рабочего дня. По завершении модерации Вы получите сообщение об активации Вашей учетной записи на указанный электронный адрес.`}
      />
    </div>
  );
};
