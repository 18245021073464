import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'; // Импортируем иконки стрелок влево и вправо

const QuantityInput = ({ value, onChange }) => {
  const handleIncrement = (e) => {
    e.stopPropagation();
    onChange(Math.min(value + 1, 10000));
  };

  const handleDecrement = (e) => {
    e.stopPropagation();
    onChange(Math.max(value - 1, 1));
  };

  const handleInputChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    onChange(Math.max(Math.min(inputValue, 10000), 1));
  };

  return (
    <InputGroup borderColor="transparent" size="sm" width="100px">
      <InputLeftElement>
        <Button
          onClick={handleDecrement}
          disabled={value <= 1}
          size="md"
          variant="ghost"
          _hover={{ bg: 'none', color: 'red.500' }}
          color={'gray.500'}
        >
          <Icon fontSize={'20px'} as={FiChevronLeft} />
        </Button>
      </InputLeftElement>
      <Input
        borderColor="transparent"
        borderRadius={'5px'}
        textAlign={'center'}
        bg={'white.100'}
        onClick={(e) => e.stopPropagation()}
        type="number"
        value={value}
        onChange={handleInputChange}
        min={1}
      />
      <InputRightElement>
        <Button
          onClick={handleIncrement}
          disabled={value >= 10000}
          size="md"
          variant="ghost"
          color={'gray.500'}
          _hover={{ bg: 'none', color: 'red.500' }}
        >
          <Icon fontSize={'20px'} as={FiChevronRight} />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default QuantityInput;
