import React, { Suspense } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { routes } from './router-data';
import Area from '../pages/Area/Area';
import Registration from '../pages/Registration/Registration';
import PasswordReset from '../pages/Login/PasswordReset';
import Login from '../pages/Login/Login';
import NotFound from '../pages/NotFound/NotFound';
import RequestAccess from '../pages/Login/RequestAccess';
export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Area />}>
        {routes.map((r, i) => (
          <Route
            loader={r.loader as any}
            key={i}
            path={r.path}
            element={<Suspense>{r.element}</Suspense>}
          />
        ))}
      </Route>
      <Route element={<Login />} path="/login" />
      <Route element={<Registration />} path="/registration/:id" />
      <Route element={<PasswordReset />} path="/password_reset/:key" />
      <Route element={<RequestAccess />} path="/request_access" />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
