import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import './InfoTable.scss';
import { Order, OrderItem } from '../../../../types/interfaces';
import NumberFormatter from '../../../common/NumberFormatter/NumberFormatter';
export function DeliveryItemsInfo({ order }: { order: Order }) {
  return (
    <Table variant="underline">
      <Thead className="info-table__header">
        <Tr>
          <Th width="20%">Наименование изделия</Th>
          <Th width="10%">Количество</Th>
          <Th width="15%">Цена за единицу</Th>
          <Th width="15%">Общая стоимость</Th>
        </Tr>
      </Thead>
      <Tbody className="info-table__content">
        <>
          {order.items.map((item: OrderItem, index: number) => (
            <Tr key={index}>
              <Td
                color={item.cancel ? 'gray' : 'black'}
                textDecoration={item.cancel ? 'line-through' : 'none'}
              >
                {item.item_name}
              </Td>
              <Td>
                <NumberFormatter number={item.quantity} />
              </Td>
              <Td>
                <NumberFormatter number={+item.price} />
              </Td>
              <Td>
                <NumberFormatter number={+item.price * +item.quantity} />
              </Td>
            </Tr>
          ))}
        </>
      </Tbody>
    </Table>
  );
}