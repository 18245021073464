import { CartItemUI } from './CartItemUI';
import { Item } from '../../../../types/interfaces';
import { CartItemLogic } from '../../../../utils/hooks/CartItemLogic';

interface CartItemProps {
  item: Item;
}

export const CartItem = ({ item }: CartItemProps): JSX.Element => {
  return <CartItemLogic item={item} CartItemUI={CartItemUI} />;
};
