import { useEffect, useMemo, useState } from 'react';
import { OrderActions } from './OrderActions';
import OrderHistoryBlock from '../../components/modules/OrderHistoryBlock/OrderHistoryBlock';
import { Order } from '../../types/interfaces';
import PaginationButtons from './PaginationButtons';
import EmptyState from '../../components/common/EmptyState/EmptyState';

interface RenderOrdersProps {
  orders: Order[];
  legalEntity: any | null;
  search: string;
}
export const OrderList = ({
  orders,
  legalEntity,
  search,
}: RenderOrdersProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const memoOrders = useMemo(() => {
    return orders?.filter((order) => {
      const matchesLegalEntity =
        legalEntity && legalEntity.value
          ? order.legal_entity_data?.id === legalEntity.value
          : true;
      const matchesSearch = order.name.includes(search);
      return matchesLegalEntity && matchesSearch;
    });
  }, [orders, legalEntity, search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [legalEntity, search]);

  const getCurrentPageOrders = () => {
    const ordersPerPage = 10;
    const startIndex = (currentPage - 1) * ordersPerPage;
    const endIndex = startIndex + ordersPerPage;

    return memoOrders.slice(startIndex, endIndex);
  };

  const currentPageOrders = getCurrentPageOrders();

  if (memoOrders?.length) {
    return (
      <>
        {currentPageOrders?.map((order) => {
          return (
            <OrderHistoryBlock
              key={order.id}
              order={order}
              orderActions={<OrderActions order={order} />}
            />
          );
        })}
        <PaginationButtons
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          ordersLength={memoOrders.length}
        />
      </>
    );
  } else {
    return <EmptyState message="Заказов не найдено" />;
  }
};
