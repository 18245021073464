import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import {
  ICreateOrder,
  Order,
} from '../../types/interfaces';

export const ordersApi = {
  getOrders: (params: {limit?: number } | undefined = undefined): Promise<AxiosResponse<Order[]>> => {
    const queryString = params?.limit ? `?ordering=-created_at&limit=${params?.limit}` : '?ordering=-created_at';
    return apiClient.get<Order[]>(`orders/${queryString}`);
  },
  getOrdersWithPagination: (params: {limit?: number } | undefined = undefined): Promise<AxiosResponse<{count: number, next: string, previous: string, results: Order[]}>> => {
    const queryString = params?.limit ? `?ordering=-created_at&limit=${params?.limit}` : '?ordering=-created_at';
    return apiClient.get<{count: number, next: string, previous: string, results: Order[]}>(`orders/${queryString}`);
  },

  createOrder: (order: ICreateOrder): Promise<AxiosResponse<Order>> =>
    apiClient.post<Order>('orders/', order),

  partialUpdateOrder: (
    order: Partial<ICreateOrder>,
    id: number
  ): Promise<AxiosResponse<Order>> => {
    return apiClient.patch<Order>(`orders/${id}/`, order);
  },

  cancelOrder: (id: number): Promise<AxiosResponse<Order>> =>
    apiClient.get(`orders/${id}/cancel`),
};
