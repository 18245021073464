import { Contact } from '../../../types/interfaces';
import { Avatar } from '@chakra-ui/react';
import { useAppDispatch } from '../../../store/store';
import { deleteContact } from '../../../store/contacts/actions';
import { deleteAppContact } from '../../../store/contacts/reducer';
import CardLayout from '../../../components/layout/CardLayout';
import { CardButton } from '../../../components/common/CardButton/CardButton';
import { useContext, useState } from 'react';
import ContactTable from './ContactTable';
import { MyModal } from '../../../components/common/MyModal/MyModal';
import styles from '../styles/ContactCard.module.scss';
import { AbilityContext, Can } from '../../../utils/context/can';

const ContactCard = ({ employee }: { employee: Contact }) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const ability = useContext(AbilityContext);
  return (
    <CardLayout>
      <CardLayout.Header withLine={true}>
        <Avatar
          name={employee.name}
          src={employee?.photo}
          className={styles.dashboard_manager__card_image}
        />
        <h2 className={styles['contact__title']}>
          {employee.name} {employee.surname}
        </h2>
      </CardLayout.Header>
      <CardLayout.Main>
        <p className={styles['contact__text']}>
          <span className={styles.label}>Должность:</span> {employee.position}
        </p>
        <p className={styles['contact__text']}>
          <span className={styles.label}>Телефон:</span>
          <a
            href={`tel:${employee.personal_phone}`}
            className={styles.phoneLink}
          >
            {employee.personal_phone}
          </a>
        </p>
      </CardLayout.Main>
      <CardLayout.Footer>
        <div className={styles['contact__footer']}>
          <Can I="change" a="contact">
            <CardButton
              link={`contact-person/${employee.id}`}
              title="Изменить"
            />
            <CardButton
              type="delete"
              onClick={() => {
                dispatch(deleteAppContact(employee.id));
                dispatch(deleteContact(employee.id));
              }}
              title="Удалить контакт?"
            />
          </Can>

          <CardButton
            type="eye"
            onClick={() => setShowModal(true)}
            title="Подробнее"
          />
          <MyModal isOpen={showModal} onClose={() => setShowModal(false)}>
            <ContactTable contact={employee} />
          </MyModal>
        </div>
      </CardLayout.Footer>
    </CardLayout>
  );
};

export default ContactCard;
