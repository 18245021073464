import { User } from "../../types/interfaces";

export interface RememberMeData {
  user: User;
  token: string;
  expiry?: number | null;
}

const userKey = '_rememberMeUser_';
const tokenKey = '_rememberMeToken_';

export const saveRememberMeData = (value: RememberMeData, rememberMe: boolean, expireTimeInSeconds?: number) => {
  const { user, token } = value;
  
  localStorage.setItem(userKey, JSON.stringify(user));
  
  const dataToStore = {
    token,
    expiry: expireTimeInSeconds ? new Date().getTime() + expireTimeInSeconds * 1000 : null,
  };
  const cookieString = `${tokenKey}=${JSON.stringify(dataToStore)}; path=/`;

  if (rememberMe && expireTimeInSeconds) {
    const expireDate = new Date(new Date().getTime() + expireTimeInSeconds * 1000);
    document.cookie = `${cookieString}; expires=${expireDate.toUTCString()}`;
  } else {
    document.cookie = cookieString;
  }
};

export const loadRememberMeData = () => {
  const user = localStorage.getItem(userKey);
  if (!user) {
    return null;
  }

  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${tokenKey}=`)) {
      const parsedData = JSON.parse(cookie.substring(tokenKey.length + 1));
      if (parsedData.expiry && new Date().getTime() > parsedData.expiry) {
        document.cookie = `${tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        localStorage.removeItem(userKey);
        return null;
      }
      return {
        user: JSON.parse(user),
        token: parsedData.token,
        expiry: parsedData.expiry,
      };
    }
  }
  return null;
};

export const clearRememberMeData = () => {
  document.cookie = `${tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  localStorage.removeItem(userKey);
  localStorage.removeItem('selectedManager')
};
