import ProtectedRoute from '../ProtectedRoute';
import Orders from '../../pages/Orders/Orders';
// const Orders = lazy(() => import('../../pages/Orders/Orders'));
export const orders_routes = [
  {
    path: 'orders',
    element: (
      <ProtectedRoute
        redirectTo={'/'}
        action={'view'}
        subject={'order'}
        children={<Orders />}
      />
    ),
    loader: () => {
      return (document.title = 'История заказов');
    },
  },
];
