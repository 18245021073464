import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllPrices,
  fetchPriceById,
  createPrice,
  updatePrice,
  patchPrice,
  deletePrice,
} from './actions';
import { FetchStatusType, PriceList } from '../../types/interfaces';
import { RootState } from '../store';

export interface PricesState {
  prices: PriceList[];
  status: FetchStatusType;
  error: string | null;
  currentPrice: PriceList;
}

const initialState: PricesState = {
  prices: [],
  status: 'idle',
  error: null,
  currentPrice: {
    id: 0,
    name: '',
    file: '',
    created_at: '',
    updated_at: '',
    companies: [],
  },
};

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    resetPrices: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPrices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllPrices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.prices = action.payload;
      })
      .addCase(fetchAllPrices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch prices';
      })
      .addCase(fetchPriceById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPriceById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.prices.findIndex(price => price.id === action.payload.id);
        if (index !== -1) {
          state.prices[index] = action.payload;
        } else {
          state.prices.push(action.payload);
        }
        state.currentPrice = action.payload;
      })
      .addCase(fetchPriceById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch price';
      })
      .addCase(createPrice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPrice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.prices.push(action.payload);
      })
      .addCase(createPrice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create price';
      })
      .addCase(updatePrice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePrice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.prices.findIndex(price => price.id === action.payload.id);
        if (index !== -1) {
          state.prices[index] = action.payload;
        }
      })
      .addCase(updatePrice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update price';
      })
      .addCase(patchPrice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchPrice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.prices.findIndex(price => price.id === action.payload.id);
        if (index !== -1) {
          state.prices[index] = { ...state.prices[index], ...action.payload };
        }
      })
      .addCase(patchPrice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to patch price';
      })
      .addCase(deletePrice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePrice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.prices = state.prices.filter(price => price.id !== action.meta.arg);
      })
      .addCase(deletePrice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete price';
      });
  },
});

export const selectPricesStatus = (state: RootState): FetchStatusType =>
  state.prices.status;
export const selectPricesError = (state: RootState): string | null =>
  state.prices.error;
export const selectPrices = (state: RootState): PriceList[] => state.prices.prices;

export const selectCurrentPrice = (state: RootState): PriceList =>
  state.prices.currentPrice;

export const { resetPrices } = pricesSlice.actions;
export default pricesSlice.reducer;
