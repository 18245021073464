import React from 'react';
import ButtonMy from '../../../../components/common/Button/Button';
import styles from '../CartConfirm.module.scss';
import NumberFormatter from '../../../common/NumberFormatter/NumberFormatter';
import { Can } from '../../../../utils/context/can';

export function CartTotal({ cartItems, setCartSteps, total }) {
  console.log(cartItems)
  const totalItemQuantity = cartItems.reduce(
    (sum, item) => sum + item.quantity,
    0 
  );
  const totalVerificationQuantity = cartItems.reduce(
    (sum, item) => sum + (item?.verification_quantity || 0),
    0
  );
  const totalItemsPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );
  const totalVerificationPrice = cartItems.reduce(
    (sum, item) => sum + (item.verificationTotalPrice || 0),
    0
  );

  return (
    <div className={styles.cart__total}>
      <div className={styles.cart__total_field}>
        <p className={styles.cart__total_field_title}>
          Кол-во товаров в корзине:
        </p>
        <p className={styles.cart__total_field_result}>{totalItemQuantity}</p>
      </div>
      <div className={styles.cart__total_field}>
        <p className={styles.cart__total_field_title}>
          Кол-во поверок в корзине:
        </p>
        <p className={styles.cart__total_field_result}>
          {totalVerificationQuantity}
        </p>
      </div>
      <div className={styles.cart__total_field}>
        <p className={styles.cart__total_field_title}>
          Сумма товаров ( {cartItems.length} ):
        </p>
        <p className={styles.cart__total_field_result}>
          <NumberFormatter number={totalItemsPrice} /> ₽
        </p>
      </div>
      <div className={styles.cart__total_field}>
        <p className={styles.cart__total_field_title}>Сумма поверок:</p>
        <p className={styles.cart__total_field_result}>
          <NumberFormatter number={totalVerificationPrice} /> ₽
        </p>
      </div>
      <div className={styles.cart__total_field}>
        <p className={styles.cart__total_field_title}>Всего к оплате:</p>
        <p className={styles.cart__total_field_result}>
          <NumberFormatter number={total} /> ₽
        </p>
      </div>
      <Can I="add" a="order">
        <ButtonMy onClick={() => setCartSteps('1')}>Оформить заказ</ButtonMy>
      </Can>
    </div>
  );
}
