import Contacts from '../pages/Contacts/Contacts';
import Dashboard from '../pages/Dashboard/Dashboard';
import Prices from '../pages/Prices/Prices';
import { catalog_routes } from './Catalogs/catalogs-routes';
import { companies_routes } from './Companies/companies-routes';
import { managers_routes } from './Managers/managers-routes';
import { profile_routes } from './Profile/profile-routes';
import { orders_routes } from './Orders/orders-routes';
import Cart from '../pages/Cart/Cart';
import PriceView from '../components/modules/PriceView/PriceView';
import PdfAddViewer from '../components/modules/PdfAddViewer/PdfAddViewer';
import Devices from '../pages/Devices/Devices';
import ProtectedRoute from './ProtectedRoute';
import Issue from '../pages/Devices/Issue/Issue';
import NotFound from '../pages/NotFound/NotFound';

export const routes = [
  {
    path: '/',
    element: <Dashboard />,
    loader: () => {
      return (document.title = 'Главная');
    },
  },
  {
    path: 'cart',
    element: (
      <ProtectedRoute
        redirectTo={'/'}
        action={'view'}
        subject={'cart'}
        children={<Cart />}
      />
    ),
    loader: () => {
      return (document.title = 'Корзина');
    },
  },
  {
    path: 'prices',
    element: (
      <ProtectedRoute
        redirectTo={'/'}
        action={'view'}
        subject={'pricelist'}
        children={<Prices />}
      />
    ),
    loader: () => {
      return (document.title = 'Прайсы');
    },
  },
  {
    path: 'prices/:id',
    element: (
      <ProtectedRoute
        redirectTo={'/'}
        action={'view'}
        subject={'pricelist'}
        children={<PriceView />}
      />
    ),
    loader: () => {
      return (document.title = 'Прайс');
    },
  },
  {
    path: 'prices/add',
    element: (
      <ProtectedRoute
        redirectTo={'/prices'}
        action={'add'}
        subject={'pricelist'}
        children={<PdfAddViewer />}
      />
    ),

    loader: () => {
      return (document.title = 'Добавление прайса');
    },
  },
  {
    path: 'prices/edit/:id',
    element: (
      <ProtectedRoute
        redirectTo={'/prices'}
        action={'view'}
        subject={'pricelist'}
        children={<PdfAddViewer />}
      />
    ),
    loader: () => {
      return (document.title = 'Изменение прайса');
    },
  },
  {
    path: 'contacts',
    element: <Contacts />,
    loader: () => {
      return (document.title = 'Контакты');
    },
  },
  {
    path: 'devices',
    element: <Devices />,
    loader: () => {
      return (document.title = 'Устройства');
    },
  },
  {
    path: 'issues/:id',
    element: <Issue />,
    loader: () => {
      return (document.title = 'Заявка ');
    },
  },
  ...profile_routes,
  ...managers_routes,
  ...catalog_routes,
  ...companies_routes,
  ...orders_routes,
];
