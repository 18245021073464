import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import {
  clearRememberMeData,
  loadRememberMeData,
  RememberMeData,
} from '../utils/hooks/rememberMeData';

const API_URL = process.env.REACT_APP_BASE_URL;

const reloadCondition = (
  config: InternalAxiosRequestConfig | undefined
): boolean => {
  if (!config) return false;
  return !!(
    config.url?.endsWith("auth/token/") ||
    config.url?.endsWith('/logout') ||
    config.url?.endsWith('/password_reset')
  );
};

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const okdeskClient = axios.create({
  baseURL: 'http://localhost:3001/api/',
});

apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const rememberMeData: RememberMeData | null = loadRememberMeData();
    if (rememberMeData) {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${rememberMeData.token}`,
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    console.log(error)
    if (error.response?.status === 401 && !reloadCondition(error.config)) {
      clearRememberMeData();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
