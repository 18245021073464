import React from 'react';
import {
  Heading,
  Text,
  Center,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaBoxOpen } from 'react-icons/fa';
interface EmptyStateProps {
  title?: string;
  message: string;
}
const EmptyState = ({ title, message }: EmptyStateProps) => {
  return (
    <Center
      textAlign="center"
      w={'100%'}
      minHeight={'100%'}
      py={10}
      px={6}
      flexDirection="column"
    >
      <Icon
        as={FaBoxOpen}
        w={20}
        h={20}
        color={useColorModeValue('gray.400', 'gray.600')}
        mb={4}
      />
      {title && (
        <Heading as="h2" size="lg" mt={6} mb={2}>
          {title}
        </Heading>
      )}
      {message && <Text color={'gray.600'}>{message}</Text>}
    </Center>
  );
};

export default EmptyState;
