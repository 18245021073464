import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import {
  CheckAvailabilityResponse,
  TokenResponse,
  User,
} from '../../types/interfaces';

export const authApi = {
  checkEmailAvailable: (
    email: string
  ): Promise<AxiosResponse<CheckAvailabilityResponse>> =>
    apiClient.get<CheckAvailabilityResponse>('auth/check_email_available/', {
      params: { email },
    }),
  getMe: (): Promise<AxiosResponse<User>> => apiClient.get<User>('users/me/'),

  updateMe: (data: Partial<User> | FormData): Promise<AxiosResponse<User>> =>
    apiClient.put<User>('users/me/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }),

  checkUsernameAvailable: (
    username: string
  ): Promise<AxiosResponse<CheckAvailabilityResponse>> =>
    apiClient.get<CheckAvailabilityResponse>('auth/check_username_available/', {
      params: { username },
    }),

  login: (
    username: string,
    password: string
  ): Promise<AxiosResponse<TokenResponse>> =>
    apiClient.get<TokenResponse>('auth/token/', {
      params: { username, password },
    }),

  logout: (): Promise<AxiosResponse<void>> => apiClient.get('auth/logout/'),

  passwordResetRequest: (data: { email: string }): Promise<AxiosResponse<void, any>> =>
    apiClient.put<void>('auth/reset_password/', data),

  resetPassword: (data: {code: string, new_password: string}): Promise<AxiosResponse<void>> =>
    apiClient.post('auth/reset_password/', data),

    updatePassword: (data: {old_password: string, new_password: string}): Promise<AxiosResponse<void>> =>
    apiClient.post('users/update_password/', data),

};
