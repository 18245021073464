import { useEffect } from 'react';
import { selectAnyRequestStatus } from '../../store/items/reducer';
import 'moment/locale/ru';
import { fetchAllPrices } from '../../store/prices/actions';
import { getCart } from '../../store/cart/actions';
import { getItems } from '../../store/items/actions';
import { selectOrdersStatus } from '../../store/orders/reducer';
import { selectCartStatus } from '../../store/cart/reducer';
import { fetchAllOrders } from '../../store/orders/actions';
import { selectUsersStatus } from '../../store/users/reducer';
import { fetchAllUsers } from '../../store/users/actions';
import { fetchAllLegalEntities } from '../../store/legalEntities/actions';
import { selectLegalEntityStatus } from '../../store/legalEntities/reducer';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useMe } from '../../pages/Area/Area';

export default function useDashboardEffects() {
  const catalogStatus = useAppSelector((state) =>
    selectAnyRequestStatus(state, 'getItems')
  );
  const ordersStatus = useAppSelector(selectOrdersStatus);
  const getPriceListStatus = useAppSelector((state) =>
    selectAnyRequestStatus(state, 'getPriceList')
  );
  const fetchManagersStatus = useAppSelector(selectUsersStatus);
  const companiesStatus = useAppSelector(selectLegalEntityStatus);
  const cartStatus = useAppSelector(selectCartStatus);
  const dispatch = useAppDispatch();
  const { me } = useMe();
  useEffect(() => {
    if (fetchManagersStatus !== 'succeeded') {
      dispatch(fetchAllUsers());
    }
  }, [dispatch]);

  useEffect(() => {
    if (companiesStatus !== 'succeeded') {
      dispatch(fetchAllLegalEntities());
    }
  }, [dispatch]);

  useEffect(() => {
    if (cartStatus !== 'succeeded') {
    }
    dispatch(getCart());
    // }
  }, [dispatch]);

  useEffect(() => {
    if (ordersStatus !== 'succeeded') {
      dispatch(fetchAllOrders());
    }
  }, [dispatch]);

  useEffect(() => {
    if (getPriceListStatus !== 'succeeded') {
      dispatch(fetchAllPrices());
    }
  }, [dispatch]);

  useEffect(() => {
    if (catalogStatus !== 'succeeded') {
      dispatch(getItems());
    }
  }, [dispatch, me.id]);
}
