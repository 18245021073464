import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  TokenResponse, User,
} from '../../types/interfaces';
import { authApi } from '../../api/services/auth.service';
import {
  clearRememberMeData,
  saveRememberMeData,
} from '../../utils/hooks/rememberMeData';
import { resetItems } from '../items/reducer';
import { setErrorNotification, setNotification } from '../notifications';
import { resetCompanies } from '../companies/reducer';
import { resetInvites } from '../invites/reducer';
import { resetLegalEntities } from '../legalEntities/reducer';
import { resetContacts } from '../contacts/reducer';
import { resetEmployees } from '../employees/reducer';
import { resetCart } from '../cart/reducer';
import { resetUsers } from '../users/reducer';
import { resetRegistration } from '../registration/reducer';
import { resetOrders } from '../orders/reducer';
import { resetPrices } from '../prices/reducer';

export const checkEmailAvailable = createAsyncThunk(
  'auth/checkEmailAvailable',
  async (email: string) => {
    const response = await authApi.checkEmailAvailable(email);
    return response.data;
  }
);

export const getMe = createAsyncThunk('auth/me', async () => {
  const response = await authApi.getMe();
  return response.data;
});



export const updateMe = createAsyncThunk(
  'auth/updateMe',
  async ({ data }: { data: Partial<User> | FormData }, { dispatch }) => {
    try {
      const response = await authApi.updateMe(data);
      dispatch(setNotification('Данные пользователя успешно изменены.'));
      return response.data;
    } catch (error) {
      dispatch(
        setErrorNotification('Ошибка при изменении данных пользователя.')
      );
      throw error;
    }
  }
);

export const checkUsernameAvailable = createAsyncThunk(
  'auth/checkUsernameAvailable',
  async (username: string) => {
    const response = await authApi.checkUsernameAvailable(username);
    return response.data;
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({
    username,
    password,
    rememberMe = false,
  }: {
    username: string;
    password: string;
    rememberMe?: boolean;
  }) => {
    const response: AxiosResponse<TokenResponse> = await authApi.login(
      username,
      password
    );
    localStorage.setItem('user', JSON.stringify(response.data.user));
    
    saveRememberMeData(
      response.data,
      rememberMe,
      3600 * 24 * 7
    );
    return response.data;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, dispatch } ) => {
    try {
      await authApi.logout();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    } finally {
      clearRememberMeData();
      localStorage.removeItem('user');
      dispatch(resetItems());
      dispatch(resetCompanies())
      dispatch(resetInvites())
      dispatch(resetLegalEntities())
      dispatch(resetContacts())
      dispatch(resetEmployees())
      dispatch(resetUsers())
      dispatch(resetCart())
      dispatch(resetOrders())
      dispatch(resetPrices())
      dispatch(resetRegistration())
    }
  }
);
export const requestResetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ email }: { email: string }) => {
    await authApi.passwordResetRequest({ email });
  }
);
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ code, new_password }: { code: string, new_password: string }) => {
    await authApi.resetPassword({ code, new_password });
  }
);

export const updatePassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ old_password, new_password }: { old_password: string, new_password: string }, { dispatch }) => {
    try {
      await authApi.updatePassword({ old_password, new_password });
      dispatch(setNotification('Пароль успешно изменен.'));
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при изменении пароля.'));
    }
  
  }
);

