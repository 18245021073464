import React from 'react';
import styles from '../CartConfirm.module.scss';

export function CartSelfDeliveryData() {
  return (
    <>
      <div className={styles['cart-confirm__column']}>
        <div className={styles['self-delivery-info']}>
          <p className={styles['self-delivery-info__name']}>Адрес офиса:</p>
          <div className={styles['self-delivery-info__indented-block']}>
            <p className={styles['self-delivery-info__text-bold']}>
              Республика Татарстан, г. Казань,
              <br />
              ул. Дементьева, 2Б корпус 4
            </p>
          </div>
          <p className={styles['self-delivery-info__text']}>
            Пн-Пт: 08:00 – 17:00 <br />
            Сб-Вс: Выходные
          </p>
        </div>
      </div>
      <div className={styles['cart-confirm__column']}>
        <div className={styles['self-delivery-info']}>
          <p className={styles['self-delivery-info__name']}>
            Контактная информация:
          </p>
          <div className={styles['self-delivery-info__indented-block']}>
            <p className={styles['self-delivery-info__text-bold']}>
              8 800 777 16 03
            </p>
            <p className={styles['self-delivery-info__text']}>
              Для звонков из России – бесплатно
            </p>
          </div>
          <div className={styles['self-delivery-info__indented-block']}>
            <p className={styles['self-delivery-info__text-bold']}>
              8 495 108 68 33
            </p>
            <p className={styles['self-delivery-info__text']}>
              Для звонков из СНГ и других стран
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
