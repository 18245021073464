import { createAsyncThunk } from '@reduxjs/toolkit';
import { itemsApi } from '../../api/services/items.service';
import { setNotification, setErrorNotification } from '../notifications';
import { cartApi } from '../../api/services/cart.service';

export const getItems = createAsyncThunk(
  'items/getItems',
  async (__, { dispatch }) => {
    try {
      const items = await Promise.all([itemsApi.getItems(), cartApi.getCart()]);
      const { data: allItems } = items[0];
      const { data: cartItemsData } = items[1];

      const newItems = allItems.map((item) => {
        return {
          ...item,
          items: item.items.map((i) => {
            const cartItem = cartItemsData.items.find(
              (cartI) => i.id === cartI.item.id
            );
            console.log(cartItemsData)
            return {
              ...i,
              verification_item: item.verification_item || null,
              verification_quantity: cartItem?.verification_quantity || 0,
              inCart: !!cartItem,
              quantity: cartItem?.quantity || 1,
            };
          }),
        };
      });

      console.log(newItems);

      return newItems;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при загрузке товаров.'));
      throw error;
    }
  }
);

export const getItem = createAsyncThunk(
  'items/getItem',
  async ({ id }: { id: number }, { dispatch }) => {
    try {
      const response = await Promise.all([itemsApi.getItem(id), cartApi.getCart()]);
      const { data: item } = response[0];
      const { data: cartItemsData } = response[1];
      const updatedItem = {
        ...item,
        items: item.items.map((i) => {
          const cartItem = cartItemsData.items.find(
            (cartI) => i.id === cartI.item.id
          );
          console.log(cartItemsData)
          return {
            ...i,
            verification_item: item.verification_item || null,
            verification_quantity: cartItem?.verification_quantity || 0,
            inCart: !!cartItem,
            quantity: cartItem?.quantity || 1,
          };
        }),
      };
      return updatedItem;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при загрузке товара.'));
      throw error;
    }
  }
);

export const updateItem = createAsyncThunk(
  'items/updateItem',
  async ({ itemId, body }: { itemId: number; body: any }, { dispatch }) => {
    try {
      const response = await itemsApi.updateItem(itemId, body);
      dispatch(setNotification('Товар успешно обновлен!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении товара.'));
      throw error;
    }
  }
);
