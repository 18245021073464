import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getItems, updateItem, getItem } from './actions';
import {
  Card,
  CardCategories,
  FetchStatusType,
  Item,
} from '../../types/interfaces';
import { RootState } from '../store';
import { selectCartWithCalculatedPrices } from '../cart/reducer';

interface IS {
  status: FetchStatusType;
  error: string | null;
  items: Card[];
  currentItem: Card;
  loadingId: string;
  loadingOrderId: string;
  [key: string]: any;
}
const initialState: IS = {
  status: 'idle',
  error: null,
  items: [],
  currentItem: {
    id: 0,
    items: [],
    photo: '',
    name: '',
    description: '',
    category: CardCategories.NO_CATEGORY,
    short_description: '',
    tech_specs: {},
    verification_item: null,
  },
  loadingId: '',
  loadingOrderId: '',
};

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    resetItems: () => initialState,
    clearItemCache(state) {
      state.currentItem = initialState.currentItem;
    },
    removeItemFromCartReducer(state, action) {
      state.items = state.items.map((item) => ({
        ...item,
        items: item.items.map((itemInItem) =>
          itemInItem.id === action.payload.id ? {...itemInItem, inCart:false, quantity: 1} : itemInItem
        ),
      }));
      state.currentItem.items = state.currentItem.items.map((itemInItem) =>
        itemInItem.id === action.payload.id ? {...itemInItem, inCart:false, quantity: 1} : itemInItem
      );
    },
    addItemToCartReducer(state, action) {
      state.items = state.items.map((item) => ({
        ...item,
        items: item.items.map((itemInItem) =>
          itemInItem.id === action.payload.id ? action.payload : itemInItem
        ),
      }));
      state.currentItem.items = state.currentItem.items.map((itemInItem) =>
        itemInItem.id === action.payload.id ? action.payload : itemInItem
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItem.pending, (state, action) => {
        state.status = 'loading';
        const name = action.type.split('/')[1];
        state[name] = 'loading';
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentItem = action.payload;
        const name = action.type.split('/')[1];
        state[name] = 'succeeded';
      })
      .addCase(getItem.rejected, (state, action) => {
        state.status = 'failed';
        state.error = 'Failed to fetch';
        const name = action.type.split('/')[1];
        state[name] = 'failed';
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        console.log(action.payload);
        const name = action.type.split('/')[1];
        state[name] = 'succeeded';
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentItem = action.payload;
        state.items.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
        const name = action.type.split('/')[1];
        state[name] = 'succeeded';
      })
      .addMatcher(
        isAnyOf(getItems.pending, updateItem.pending),
        (state, action) => {
          const name = action.type.split('/')[1];
          state[name] = 'loading';
          state.status = 'loading';
        }
      )
      .addMatcher(
        isAnyOf(getItems.rejected, updateItem.rejected),
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to fetch';
          const name = action.type.split('/')[1];
          state[name] = 'failed';
        }
      );
  },
});

export const selectItemsStatus = (state: RootState) => state.items.status;
export const selectItemsError = (state: RootState) => state.items.error;
export const selectAllItems = (state: RootState) => state.items.items;

export const selectAnyRequestStatus = (state: RootState, name: string) =>
  state.items[name];
export const selectLoadingOrderId = (state: RootState) =>
  state.items.loadingOrderId;

export const currentItemSelector = (state: RootState) =>
  state.items.currentItem;
const cartItemsSelector = (state: RootState) => state.cart.items;
const itemsSelector = (state: RootState) => state.items.items;

export const selectCurrentItemWithCartInfo = createSelector(
  [cartItemsSelector, currentItemSelector],
  (cartItems, currentItem) => {
    const currentItemWithCartInfo = { ...currentItem };

    if (currentItemWithCartInfo.items) {
      currentItemWithCartInfo.items = currentItemWithCartInfo.items.map(
        (product) => {
          const cartItem = cartItems.find((item) => item.id === product.id);
          return {
            ...product,
            ...cartItem,
            inCart: !!cartItem,
            quantity: cartItem ? cartItem.quantity : 1,
            verification_item: cartItem?.verification_item,
            verification_quantity: cartItem?.verification_quantity,
          };
        }
      );
    }

    return currentItemWithCartInfo;
  }
);

export const selectProductsWithCartInfo = createSelector(
  [cartItemsSelector, itemsSelector, selectCartWithCalculatedPrices],
  (cartItems, items, cartItemsWithPrice) => {
    return items.map((productGroup) => {
      const itemsWithCartInfo = productGroup.items.map((product) => {
        const cartItem = cartItems.find((item) => item.id === product.id);
        return {
          ...product,
          ...cartItem,
          inCart: !!cartItem,
          quantity: cartItem ? cartItem.quantity : 1,
          VI: productGroup.verification_item,
        };
      });
      return {
        ...productGroup,
        items: itemsWithCartInfo,
      };
    });
  }
);

export const {
  resetItems,
  addItemToCartReducer,
  removeItemFromCartReducer,
} = itemsSlice.actions;

export default itemsSlice.reducer;
