import React, { ForwardedRef } from 'react';
import { Box, Icon, Tag, Tooltip } from '@chakra-ui/react';
import {
  MdLocalGasStation,
  MdBuild,
  MdNaturePeople,
  MdAcUnit,
  MdLightbulbOutline,
  MdAirlineSeatFlat,
  MdInvertColors,
  MdWbSunny,
  MdAddBox,
  MdExtension,
} from 'react-icons/md';
import { CardCategories } from '../../types/interfaces';

interface DeliveryType {
  value: string;
  label: string;
}

export interface Contact {
  name: string;
  surname: string;
  patronymic: string;
  position: string;
  personal_phone: string;
}

export const CATEGORY_SELECT_ITEMS: { value: string; label: string }[] = [
  { value: 'fuel_level_sensor', label: 'Контроль уровня топлива' },
  {
    value: 'mechanism_deviation_sensor',
    label: 'Контроль отклонения механизмов спец.техники',
  },
  { value: 'agricultural_technology_sensor', label: 'Контроль с/х техники' },
  {
    value: 'temperature_and_humidity_sensor',
    label: 'Контроль температуры и влажности',
  },
  { value: 'indicator', label: 'Индикаторы' },
  { value: 'axle_load_sensor', label: 'Контроль нагрузки на ось' },
  { value: 'liquid_level_sensor', label: 'Контроль уровня жидкостей' },
  {
    value: 'meteorological_equipment',
    label: 'Метеорологическое оборудование',
  },
  {
    value: 'accessories',
    label: 'Аксессуары',
  },
  { value: 'optional_equipment', label: 'Дополнительное оборудование' },
];

export const CATEGORY_ICONS_AND_COLORS: Record<
  CardCategories,
  { icon: typeof MdAddBox; color: string; label: string }
> = {
  [CardCategories.FUEL_LEVEL_SENSOR]: {
    icon: MdLocalGasStation,
    color: '#FF4500',
    label: 'Контроль уровня топлива',
  },
  [CardCategories.MECHANISM_DEVIATION_SENSOR]: {
    icon: MdBuild,
    color: '#1E90FF',
    label: 'Контроль отклонения механизмов спец.техники',
  },
  [CardCategories.AGRICULTURAL_TECHNOLOGY_SENSOR]: {
    icon: MdNaturePeople,
    color: '#008000',
    label: 'Контроль с/х техники',
  },
  [CardCategories.TEMPERATURE_AND_HUMIDITY_SENSOR]: {
    icon: MdAcUnit,
    color: '#FF8C00',
    label: 'Контроль температуры и влажности',
  },
  [CardCategories.INDICATOR]: {
    icon: MdLightbulbOutline,
    color: '#FF8C00',
    label: 'Индикаторы',
  },
  [CardCategories.AXLE_LOAD_SENSOR]: {
    icon: MdAirlineSeatFlat,
    color: '#FF4500',
    label: 'Контроль нагрузки на ось',
  },
  [CardCategories.LIQUID_LEVEL_SENSOR]: {
    icon: MdInvertColors,
    color: '#FF4500',
    label: 'Контроль уровня жидкостей',
  },
  [CardCategories.METEOROLOGICAL_EQUIPMENT]: {
    icon: MdWbSunny,
    color: '#FF4500',
    label: 'Метеорологическое оборудование',
  },
  [CardCategories.ACCESSORIES]: {
    icon: MdExtension,
    color: '#696969',
    label: 'Аксессуары',
  },
  [CardCategories.OPTIONAL_EQUIPMENT]: {
    icon: MdAddBox,
    color: '#FF4500',
    label: 'Дополнительное оборудование',
  },
  [CardCategories.NO_CATEGORY]: {
    icon: MdAddBox,
    color: '#A9A9A9',
    label: 'Без категории',
  },
};

export const CustomCard = React.forwardRef(
  (
    { children, ...rest }: { children: React.ReactNode },
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Box p="1">
      <Tag ref={ref} {...rest}>
        {children}
      </Tag>
    </Box>
  )
);

interface CategoryItemProps {
  categoryValue: CardCategories;
  tooltip?: boolean;
}

export function CategoryItem({
  categoryValue,
  tooltip = false,
}: CategoryItemProps) {
  const IconComponent = CATEGORY_ICONS_AND_COLORS[categoryValue].icon;
  const color = CATEGORY_ICONS_AND_COLORS[categoryValue].color;

  return (
    <div>
      {tooltip ? (
        <Tooltip
          label={CATEGORY_ICONS_AND_COLORS?.[categoryValue]?.label}
          placement="top"
          fontSize={'small'}
          bg="gray.300"
          color={color}
        >
          <CustomCard>
            <Icon as={IconComponent} color={color} w={15} h={15} />
          </CustomCard>
        </Tooltip>
      ) : (
        <Icon as={IconComponent} color={color} w={15} h={15} />
      )}
    </div>
  );
}

export const deliveryTypes: DeliveryType[] = [
  { value: 'dellin', label: 'Деловые Линии' },
  { value: 'major', label: 'Major' },
  { value: 'ems', label: 'EMS' },
  { value: 'flippost', label: 'Flip Post' },

  //{ value: 'unspecified', label: 'Не указано' },
];

export const deliveryTypes2: DeliveryType[] = [
  ...deliveryTypes,
  { value: 'self-delivery', label: 'Самовывоз' },
  // { value: 'courier', label: 'Курьер' },
  { value: 'unspecified', label: 'Не указано' },
  { value: 'other', label: 'Информация скоро будет' },
];

export const payerTypes: DeliveryType[] = [
  { value: 'recipient', label: 'Получатель' },
  { value: 'sender', label: 'Отправитель' },
];
export const selfDeliveryTypes: DeliveryType[] = [
  { value: 'courier', label: 'Курьер' },
  { value: 'confidant', label: 'Доверенное лицо' },
];
export const selfDeliveryTypesOrderInfo: DeliveryType[] = [
  { value: 'courier', label: 'курьером' },
  { value: 'confidant', label: 'доверенным лицом' },
];
export const recipientTypes: DeliveryType[] = [
  { value: 'customer', label: 'Заказчик' },
  { value: 'third party', label: 'Третья сторона' },
];
