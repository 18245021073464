import React, { useEffect } from 'react';
import GoToLink from '../../components/common/GoToLink/GoToLink';
import styles from './Prices.module.scss';
import { GridItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import GridLayout from '../../components/layout/GridLayout';
import MainLayout from '../../components/layout/MainLayout/MainLayout';
import { deletePrice, fetchAllPrices } from '../../store/prices/actions';
import { selectPrices, selectPricesStatus } from '../../store/prices/reducer';
import EmptyState from '../../components/common/EmptyState/EmptyState';
import { Can } from '../../utils/context/can';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useMe } from '../Area/Area';
import CardLayout from '../../components/layout/CardLayout';
import { CardButton } from '../../components/common/CardButton/CardButton';
import LoadingWrapper from '../../components/common/Spinners/LoadingWrapper';

const Prices = () => {
  const { me } = useMe();
  const prices = useAppSelector(selectPrices);
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectPricesStatus)

  useEffect(() => {
    if(status !== 'succeeded') {
      dispatch(fetchAllPrices());
    }
  }, [dispatch]);

  const remove = (id: number) => {
    dispatch(deletePrice(id));
  };

  return (
    <LoadingWrapper status={status}>
      <MainLayout>
        <MainLayout.Title>Прайсы</MainLayout.Title>
        <Can I='add' a='pricelist'>
          <MainLayout.AddButton
            text={'прайс'}
            visible={true}
            path={'/prices/add'}
          />
        </Can>
        <MainLayout.Main>
          {prices.length ? (
            <GridLayout minColumnWidth={'300px'}>
              {prices.map((item) => (
                <GridItem w="100%" h={'100%'} key={item.id}>
                  <CardLayout className={styles.price__card}>
                    <CardLayout.Header>
                      <h5 className={styles.price__title}>{item.name}</h5>
                    </CardLayout.Header>
                    <CardLayout.Footer>
                      <div className={styles.price__footer}>
                        <Can I='change' a='pricelist'>
                        <CardButton link={`/prices/edit/${item.id}`} title="Изменить" />

                        </Can>
                        <Can I='delete' a='pricelist'>
                        <CardButton type='delete' onClick={() => {
                          remove(item.id)
                        }} />
                        </Can>
                        
                      </div>
                      <GoToLink to={`/prices/${item.id}`} />
                    </CardLayout.Footer>
                  </CardLayout>
                </GridItem>
              ))}
            </GridLayout>
          ) : (
            <>
              <EmptyState
                title={'Прайсов нет'}
                message={'Отсутствуют прайсы'}
              />
            </>
          )}
        </MainLayout.Main>
      </MainLayout>
    </LoadingWrapper>
  );
};

export default Prices;
