import React, { CSSProperties } from 'react';
import { default as ReactSelect } from 'react-select';

const customStyles = {
  control: (provided:any) => ({
    ...provided,
    paddingLeft: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
    borderRadius: '10px',
    border: '1px solid #DCDEE0',
    color: '#BCBCBC',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.14px',
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: {
    data: any;
    isDisabled: boolean;
    isFocused: boolean;
    isSelected: boolean;
  }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? '#ff6a6aa3' : isFocused ? '#eee' : undefined,
      color: isDisabled ? '#ccc' : isSelected ? 'black' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? '#ff6a6aa3' : '#ff6a6aa3') : undefined,
      },
    };
  },

};

interface ICartSelect {
  value: any
  placeholder: string
  options: any
  onChange: any
  defaultValue?: undefined | null| {label:string, value:string|number}
}
export function CartSelect({ value, placeholder, options, onChange,defaultValue }: ICartSelect) {
  return (
    <ReactSelect
    defaultValue={defaultValue}
      styles={customStyles}
      options={options}
      isMulti={false}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      isSearchable={false}
    />
  );
}
