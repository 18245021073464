import React from 'react';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

interface ICartDeliveryTypeRadios {
  deliveryMode: string;
  setDeliveryMode: (value: string) => void;
}
function CartDeliveryTypeRadios({ deliveryMode, setDeliveryMode }: ICartDeliveryTypeRadios) {
  return (
    <RadioGroup
      colorScheme="red"
      mt={4}
      onChange={setDeliveryMode}
      value={deliveryMode}
    >
      <Stack columnGap={'27px'} direction="row">
        <Radio value={'delivery'}>Доставка</Radio>
        {/* <Radio value={'courier'}>Курьером</Radio> */}
        <Radio value={'self-delivery'}>Самовывоз</Radio>
      </Stack>
    </RadioGroup>
  );
}

export default CartDeliveryTypeRadios;
