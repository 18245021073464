import { Box, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import deliveryTruck from './assets/sprites/delivery-truck.svg';
import deliveryBox from './assets/sprites/delivery-box.svg';
import deliveryPhone from './assets/sprites/delivery-phone.svg';
import deliveryComment from './assets/sprites/delivery-comment.svg';
import { DeliveryInfo } from './InformationTables/DeliveryInfo';
import { DeliveryItemsInfo } from './InformationTables/ItemsInfo';
import { ContactInfo } from './InformationTables/ContactInfo';
import { Order } from '../../../types/interfaces';
import { TabsWithIcons } from './components/TabsWithIcons';
import { TabsBox } from './components/TabsBox';
interface IProps {
  order: Order;
}
export const OrderCardExpanded = ({ order }: IProps) => {
  //const isTableLayout = useBreakpointValue({ base: false, md: true });

  const renderTableLayout = () => (
    <>
      <TabsWithIcons>
        <TabsBox title="Информация о доставке" icon={deliveryTruck}>
          <DeliveryInfo order={order} />
        </TabsBox>
        <TabsBox title="Информация о товаре" icon={deliveryBox}>
          <DeliveryItemsInfo order={order} />
        </TabsBox>
        <TabsBox title="Контактная информация" icon={deliveryPhone}>
          <ContactInfo order={order} />
        </TabsBox>
        <TabsBox title="Комментарий" icon={deliveryComment}>
          <Table variant="simple">
            <Tbody>
              <Tr key={order.id}>
                <Td wordBreak={'break-word'} width={'70%'}>
                  {order.comment || 'Комментарий отсутствует'}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TabsBox>
      </TabsWithIcons>
    </>
  );

  return <Box>{renderTableLayout()}</Box>;
};