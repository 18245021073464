export function translateType(type: string) {
  switch(type) {
      case "legal":
          return "юридический";
      case "physical":
          return "физический";
      default:
          return type;
  }
}
