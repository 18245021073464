import { useEffect, useState } from 'react';
import Select from '../../../common/Select/Select';
import {
  Alert,
  AlertIcon,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import styles from './CatalogItemSelectionFilter.module.scss';
import { useAppSelector } from '../../../../store/store';
import usePriceInRange from '../../../../utils/hooks/usePriceInRange';
import SelectedProductDetail from './SelectedProductDetail';
import { CartItemLogic } from '../../../../utils/hooks/CartItemLogic';
import { CartItemUI } from './CartItemUI';
import { selectTotalItemsCount } from '../../../../store/cart/reducer';

export const CatalogItemSelectionFilter = ({
  product,
  addToCart,
  removeFromCart,
  status,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [isWithVerification, setIsWithVerification] = useState(false);
  const totalItemsInCartCount = useAppSelector(selectTotalItemsCount);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
    if (product.items.length === 1) {
      const defaultProduct = product.items[0];
      handleProductChange({
        value: defaultProduct.id,
        label: defaultProduct.name,
      });
    } else if (firstLoad) {
      setSelectedProduct(null);
    }
  }, [product]);

  useEffect(() => {
    return () => {
      setSelectedProduct(null);
    };
  }, []);

  const priceInRange = usePriceInRange({
    product: selectedProduct,
    inputValue: quantity,
  });

  const handleProductChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedProduct(null);
      setIsWithVerification(false);
      setQuantity(1);
      return;
    }

    const selectedProductId = selectedOption.value;
    const selectedProduct = product.items.find(
      (product) => product.id === selectedProductId
    );

    let price = selectedProduct.prices.find(
      (price) =>
        Math.max(quantity, totalItemsInCartCount) >=
          price.price_type.range_start &&
        Math.max(quantity, totalItemsInCartCount) <= price.price_type.range_end
    );

    if (!price) {
      price = selectedProduct.prices.reduce((maxPrice, currentPrice) => {
        if (
          !maxPrice ||
          currentPrice.price_type.range_end > maxPrice.price_type.range_end
        ) {
          return currentPrice;
        }
        return maxPrice;
      }, null);
    }

    setSelectedProduct({
      ...selectedProduct,
      label: selectedProduct.name,
      value: selectedProductId,
      price: price?.price || selectedProduct?.prices[0]?.price,
    });

    setIsWithVerification(!!selectedProduct?.verification_quantity);
    setQuantity(selectedProduct.quantity);
  };

  const handleAttributeChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedAttribute((prev) => ({
        ...prev,
        [selectedOption.key]: selectedOption,
      }));
    }
  };

  const newAllAttributes = product.items.reduce((attributes, item) => {
    item.attributes.forEach((attr) => {
      if (!attributes[attr.attribute]) {
        attributes[attr.attribute] = [];
      }
      const isAttributeAlreadyAdded = attributes[attr.attribute].some(
        (existingAttr) => existingAttr.value === attr.id
      );
      if (!isAttributeAlreadyAdded) {
        attributes[attr.attribute].push({
          label: attr.value,
          value: attr.id,
          key: attr.attribute,
        });
      }
    });
    if (attributes['Длина']) {
      attributes['Длина'].sort(
        (a, b) => parseFloat(a.label) - parseFloat(b.label)
      );
    }
    return attributes;
  }, {});

  const filteredItems = product.items.filter((item) =>
    Object.entries(selectedAttribute).every(([key, selectedAttr]) =>
      item.attributes.some(
        (attr) => attr.attribute === key && attr.id === selectedAttr.value
      )
    )
  );

  useEffect(() => {
    if (filteredItems.length === 1) {
      handleProductChange({
        label: filteredItems[0].name,
        value: filteredItems[0].id,
      });
    }
  }, [selectedAttribute]);

  const clearAttributeSelect = (key) => {
    setSelectedAttribute((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleBlur = () => {
    if (quantity < 1) {
      setQuantity(1);
    } else if (!selectedProduct.price) {
      setQuantity(selectedProduct.quantity);
    }

    if (selectedProduct.inCart) {
      addToCart(
        selectedProduct?.id,
        quantity,
        product.verification_item.id,
        isWithVerification ? quantity : 0
      );
    }
  };

  const addingToCart = () => {
    setSelectedProduct((prev) => ({
      ...prev,
      inCart: true,
      quantity,
    }));

    addToCart(
      selectedProduct.id,
      quantity,
      product?.verification_item?.id,
      isWithVerification ? quantity : 0
    );
  };

  const removingFromCart = (id) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      inCart: false,
      quantity: 1,
    }));
    removeFromCart(id);
  };

  const verificationHandler = (checked) => {
    setIsWithVerification(checked);
    if (selectedProduct.inCart) {
      addToCart(
        selectedProduct.id,
        quantity,
        product.verification_item.id,
        checked ? quantity : 0
      );
    }
  };

  return (
    <div className={styles.filter}>
      {product.items.length > 1 &&
        Object.entries(newAllAttributes).map(([key, values]) => (
          <div key={key} className={styles.filter__category}>
            <Select
              label={key}
              options={values}
              onSelected={(opt) => {
                if (!opt) {
                  clearAttributeSelect(key);
                } else {
                  handleAttributeChange(opt);
                }
              }}
              optionSelected={selectedAttribute[key]}
              placeholder={key}
            />
          </div>
        ))}
      {product.items.length > 1 && (
        <div className={styles.filter__category}>
          <Select
            label="Выберите продукт"
            options={filteredItems.map((product) => ({
              value: product.id,
              label: product.name,
            }))}
            onSelected={handleProductChange}
            optionSelected={selectedProduct}
            placeholder="Выберите товар"
          />
        </div>
      )}
      {selectedProduct && (
        <SelectedProductDetail
          status={status}
          product={selectedProduct}
          pricesInRange={priceInRange}
          inputValue={quantity}
          setInputValue={setQuantity}
          handler={handleBlur}
          addingItemToCart={addingToCart}
          removeFromCart={removingFromCart}
          isWithVerification={isWithVerification}
          verifitaonQuantity={'1'}
          verificationItem={product.verification_item}
          inCart={selectedProduct?.inCart}
          verificationHandler={verificationHandler}
        />
      )}
      <Accordion
        allowMultiple
        border={'1px solid rgba(218, 223, 230, 1)'}
        borderRadius={10}
        mt={3}
      >
        <AccordionItem border={'none'}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Корзина
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={0} border={'none'}>
            <div className={styles.filter__products_inCart}>
              {product.items
                .filter((item) => item.inCart)
                .map((pr) =>
                  status === 'loading' ? (
                    'loading'
                  ) : (
                    <CartItemLogic
                      item={{
                        ...pr,
                        totalPrice:
                          pr.prices.find(
                            (p) =>
                              p?.price_type?.range_end >= pr?.quantity &&
                              p?.price_type?.range_start <= pr?.quantity
                          )?.price * pr.quantity,
                        verificationTotalPrice:
                          pr?.verification_item?.prices.find(
                            (p) =>
                              p?.price_type?.range_end >=
                                pr?.verification_quantity &&
                              p?.price_type?.range_start <=
                                pr?.verification_quantity
                          )?.price * pr.verification_quantity,
                      }}
                      CartItemUI={CartItemUI}
                    />
                  )
                )}
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Alert
        status="warning"
        visibility={
          selectedProduct && !selectedProduct.price ? 'visible' : 'hidden'
        }
      >
        <AlertIcon />
        Цена не указана, свяжитесь со своим менеджером для дополнительной
        информации
      </Alert>
    </div>
  );
};
