import mainIcon from '../../assets/sprites/main.svg';
import profileIcon from '../../assets/sprites/profile.svg';
import catalogIcon from '../../assets/sprites/catalog.svg';
import pricesIcon from '../../assets/sprites/prices.svg';
import managersIcon from '../../assets/sprites/managers.svg';
import companiesIcon from '../../assets/sprites/companies.svg';
import ordersIcon from '../../assets/sprites/orders.svg';
import contactsIcon from '../../assets/sprites/contacts.svg';
import cartIcon from '../../assets/sprites/cart.svg';
import devicesIcon from '../../assets/sprites/devices2.svg';

export const NAV_ITEMS = [
  {
    id: 1,
    icon: mainIcon,
    alt: 'Главная',
    link: '/',
    text: 'Главная',
    perm_name: 'all',
  },
  {
    id: 2,
    icon: profileIcon,
    alt: 'Профиль',
    link: '/profile',
    text: 'Профиль',
    perm_name: 'all',
  },
  {
    id: 3,
    icon: catalogIcon,
    alt: 'Каталог',
    link: '/catalog',
    text: 'Каталог',
    perm_name: 'card',
  },
  {
    id: 4,
    icon: cartIcon,
    alt: 'Корзина',
    link: '/cart',
    text: 'Корзина',
    perm_name: 'cart',
  },
  {
    id: 5,
    icon: pricesIcon,
    alt: 'Прайсы',
    link: '/prices',
    text: 'Прайсы',
    perm_name: 'pricelist',
  },
  {
    id: 6,
    icon: managersIcon,
    alt: 'Менеджеры',
    link: '/managers',
    text: 'Менеджеры',
    perm_name: 'username',
  },
  {
    id: 7,
    icon: companiesIcon,
    alt: 'Компании',
    link: '/companies',
    text: 'Компании',
    perm_name: 'company',
  },
  {
    id: 8,
    icon: ordersIcon,
    alt: 'История заказов',
    link: '/orders',
    text: 'История заказов',
    perm_name: 'order',
  },
  {
    id: 10,
    icon: devicesIcon,
    alt: 'Устройства',
    link: '/devices',
    text: 'Устройства',
    perm_name: 'all',
  },
  {
    id: 11,
    icon: contactsIcon,
    alt: 'Контакты',
    link: '/contacts',
    text: 'Контакты',
    perm_name: 'all',
  },
];

export const NAV_ITEMS_USER = [
  {
    id: 1,
    icon: mainIcon,
    alt: 'Главная',
    link: '/',
    text: 'Главная',
    perm_name: 'all',
  },
  {
    id: 2,
    icon: profileIcon,
    alt: 'Профиль',
    link: '/profile',
    text: 'Профиль',
    perm_name: 'all',
  },
  {
    id: 3,
    icon: catalogIcon,
    alt: 'Каталог',
    link: '/catalog',
    text: 'Каталог',
    perm_name: 'card',
  },
  {
    id: 4,
    icon: cartIcon,
    alt: 'Корзина',
    link: '/cart',
    text: 'Корзина',
    perm_name: 'cart',
  },
  {
    id: 5,
    icon: pricesIcon,
    alt: 'Прайсы',
    link: '/prices',
    text: 'Прайсы',
    perm_name: 'pricelist',
  },
  {
    id: 6,
    icon: managersIcon,
    alt: 'Менеджеры',
    link: '/managers',
    text: 'Менеджеры',
    perm_name: 'username',
  },
  {
    id: 7,
    icon: companiesIcon,
    alt: 'Компания',
    link: '/company',
    text: 'Компания',
    perm_name: 'company',
  },
  {
    id: 8,
    icon: ordersIcon,
    alt: 'История заказов',
    link: '/orders',
    text: 'История заказов',
    perm_name: 'order',
  },
  {
    id: 10,
    icon: devicesIcon,
    alt: 'Устройства',
    link: '/devices',
    text: 'Устройства',
    perm_name: 'all',
  },
  {
    id: 11,
    icon: contactsIcon,
    alt: 'Контакты',
    link: '/contacts',
    text: 'Контакты',
    perm_name: 'all',
  },
];
