import React from 'react';
import { Link } from 'react-router-dom';
import red_arrow from '../../../assets/sprites/red_arrow.svg';

const styles = {
  display: 'flex',
  justifyContent: 'end',
  color: 'red',
  textDecoration: 'none',
  columnGap: '2px',
  zIndex: 2,
  fontSize: '12px',
  fontFamily: 'Montserrat',
  fontWeight: '600',
  lineHeight: '28px',
};

interface GoToLinkProps {
  to: string;
  state?: any;
}

const GoToLink = ({ to, state }: GoToLinkProps) => {
  return (
    <Link to={to} state={state} style={styles}>
      <span>Перейти</span>
      <img src={red_arrow} alt="Перейти" />
    </Link>
  );
};

export default GoToLink;
