import styles from '../styles/LegalEntityTable.module.scss';
import { Company, User } from '../../../types/interfaces';
import LegalEntityTable from './LegalEntityTable';
import ContactTable from './ContactTable';
interface IEmployee {
  employee: User;
}

const EmployeeTable = ({ employee }: IEmployee) => {
  return (
    <table className={styles.table}>
      <tbody>
        <tr >
          <td>Имя</td>
          <td>{employee?.first_name} {employee?.last_name}</td>
        </tr>
        <tr >
          <td>Почта</td>
          <td>{employee.email}</td>
        </tr>
        <tr >
          <td>Телефон</td>
          <td>{employee?.mobile_phone}</td>
        </tr>
        <tr >
          <td>Telegram</td>
          <td>{employee?.telegram}</td>
        </tr>
        <tr >
          <td>Whatsapp</td>
          <td>{employee?.whatsapp}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmployeeTable;
