import {
    FaTruckMoving,
    FaMoneyCheckAlt,
    FaCheckDouble,
    FaTimesCircle,
    FaTruckLoading,
  } from 'react-icons/fa';
  import { IconType } from 'react-icons/lib';
  interface orderStatus {
    text: string;
    icon: IconType;
    color: string;
  }
  interface IOrderStatusData {
    [key: string]: orderStatus;
  }
  export const orderStatusData: IOrderStatusData = {
    no_print_form: {
      text: 'Оформляется',
      icon: FaTruckLoading,
      color: 'orange',
    },
    canceled: {
      text: 'Отменен',
      icon: FaTimesCircle,
      color: '#E3282A',
    },
    paid_and_shipped: {
      text: 'Оплачен\nи отгружен',
      icon: FaCheckDouble,
      color: 'green',
    },
    paid: {
      text: 'Оплачен,\nждет отгрузки',
      icon: FaMoneyCheckAlt,
      color: 'orange',
    },
    shipped: {
      text: 'Отгружен,\nждет оплаты',
      icon: FaTruckMoving,
      color: 'orange',
    },
    no_paid_no_shipped: {
      text: 'Не оплачен\nи не отгружен',
      icon: FaTimesCircle,
      color: '#E3282A',
    },
  };