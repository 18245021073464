import moment from 'moment';
import React, { ReactNode } from 'react';
import {
  Collapse,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from '@chakra-ui/react';
import { DateDisplay } from './DateDisplay';
import { OrderCardExpanded } from './OrderCardExpanded';
import spinner from '../../../assets/sprites/spinner.svg';
import { orderStatusData } from './lib/orderStatusData';
import './OrderHistoryBlock.scss';
import { Order } from '../../../types/interfaces';
import { useAppSelector } from '../../../store/store';
import { selectLoadingOrderId } from '../../../store/orders/reducer';
import NumberFormatter from '../../common/NumberFormatter/NumberFormatter';

type OrderCardProps = {
  order: Order;
  orderActions: ReactNode;
};

const OrderHistoryBlock = ({ order, orderActions }: OrderCardProps) => {
  const totalCost = order.items.reduce(
    (accumulator: number, { price, quantity, cancel }: any) => {
      if (!cancel) {
        return accumulator + price * quantity;
      }
      return accumulator;
    },
    0
  );
  const { status, detail: status_extensive } = order.order_status;
  const orderDetails = orderStatusData[status_extensive];
  const loadingOrderId = useAppSelector(selectLoadingOrderId);
  const { isOpen, onToggle } = useDisclosure();

  return (
    <div
      className={`order-card ${status === 'canceled' ? status : ''} ${
        isOpen ? 'order-card--expanded' : ''
      }`}
    >
      <Table
        variant="simple"
        position={'relative'}
        onClick={onToggle}
        cursor="pointer"
        colorScheme="red"
      >
        {loadingOrderId.toString() === order?.id?.toString() && (
          <img className="order-card-loader" src={spinner} alt="" />
        )}
        <Thead className="order-card__header">
          <Tr>
            <Th>Номер</Th>
            <Th>Юр. лицо</Th>
            <Th>Общая стоимость</Th>
            <Th>Трек-номер</Th>
            <Th>Статус</Th>
            <Th>Дата отгрузки</Th>
            <Th>{status !== 'canceled' && 'Действия'}</Th>
          </Tr>
        </Thead>
        <Tbody className="order-card__content">
          <Tr key={order.id}>
            <Td>
              {order.name} от{' '}
              {moment(order.created_at).local().format('DD.MM.YYYY, HH:mm')}
            </Td>
            <Td>{order?.legal_entity_data?.name}</Td>
            <Td>
              {status === 'canceled' ? (
                '0 ₽'
              ) : (
                <>
                  <NumberFormatter number={totalCost} /> ₽
                </>
              )}
            </Td>
            <Td>{order.delivery_track_number || 'Не указан'}</Td>
            <Td>
              <div className={`order-card__status`}>
                <Icon as={orderDetails?.icon} color={orderDetails?.color} />
                <p style={{ color: orderDetails?.color }}>
                  {orderDetails?.text
                    .split('\n')
                    .map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              </div>
            </Td>
            <Td>
              <DateDisplay order={order} canceled={status === 'canceled'} />
            </Td>

            <Td>
              <div className="order-card__actions">{orderActions}</div>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <div>
        <Collapse in={isOpen} animateOpacity>
          <OrderCardExpanded order={order} />
        </Collapse>
      </div>
    </div>
  );
};

export default OrderHistoryBlock;
