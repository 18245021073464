import React, { useRef } from 'react';
import avatar from '../../../assets/images/no_avatar.svg';
import fileIcon from '../../../assets/images/file.svg';
import clipIcon from '../../../assets/images/clip.svg';
import arrowIcon from '../../../assets/images/arrowUp.svg';
import getTime from '../utils/getTime';
import getFileSize from '../utils/getFileSize';
import LoadingWrapper from '../../../components/common/Spinners/LoadingWrapper';
import compare from '../utils/comparer';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { Link, useParams } from 'react-router-dom';
import styles from './Issue.module.scss';
import { useEffect } from 'react';
import {
  getIssueAttachment,
  getIssueById,
  postCommentOnIssue,
} from '../../../store/issues/actions';
import { useForm } from 'react-hook-form';
import { Button, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { renderTextWithLinks } from '../utils/renderTextWithLinks';
import { IssueAttachement, IssueComment } from '../../../types/issueInterfaces';
import { Nullable } from '../../../types/interfaces';
import { parseHtmlTable } from '../utils/parseHtmlTable';
import { openLinkInNewWindow } from '../utils/openLinkNewWindow';
const statusesTranslate = {
  'Work in progress': 'В работе',
  Postponed: 'Отложено',
  Resolved: 'Решено',
  Closed: 'Закрыто',
};

export default function Issue() {
  const dispatch = useAppDispatch();
  const { issueDetails: issue, comments } =
    useAppSelector((state) => state.issues.currentIssue) ?? {};
  const status = useAppSelector((state) => state.issues.status);

  const scrollRef = useRef<Nullable<HTMLDivElement>>(null);
  const { id: issueId } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    console.log({
      id: Number(issueId),
      author_id: issue?.contact?.id || 1,
      ...data,
    });

    dispatch(
      postCommentOnIssue({
        id: Number(issueId),
        author_id: issue?.contact?.id || 1,
        ...data,
      })
    );
    reset();
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getIssueById(Number(issueId)));
    }
  }, [status, dispatch, issueId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef.current, comments]);

  const regExp = /<[^>]*>/g;
  const devicesOnDiagnostics = parseHtmlTable(issue?.description);
  let firstDay = getTime(issue?.created_at, false, false, true);
  const isAnotherDay = (published: string) => {
    const publishedDay = getTime(published, false, false, true);
    if (publishedDay > firstDay) {
      firstDay = publishedDay;
      return true;
    }
    return false;
  };

  return (
    <div className={styles['diagnostics']}>
      <LoadingWrapper status={status}>
        <Link className={styles['diagnostics__back']} to="/devices">
          Обращения на диагностику
        </Link>
        <div className={styles['diagnostics__info']}>
          <p
            className={styles['diagnostics__title']}
            style={{ marginBottom: '15px' }}
          >
            {`Оборудование на диагностике от ${getTime(
              issue?.created_at,
              false,
              false
            )}`}
          </p>
          <div className={styles['diagnostics__items']}>
            <div className={styles['item']}>
              <p className={styles['item__title']}>Список оборудования</p>
              {devicesOnDiagnostics.map((item) => (
                <p
                  key={item.serialNumber}
                  className={styles['item__list-item']}
                >
                  {item.model} №{item.serialNumber}{' '}
                  {item.isWarrantyExpired && '- срок гарантии истёк'}
                </p>
              ))}
            </div>
            <div className={styles['item']}>
              <p className={styles['item__title']}>
                Дата регистрации обращения
              </p>

              <p className={styles['item__list-item']}>
                {getTime(issue?.created_at)}
              </p>
            </div>
            <div className={styles['item']}>
              <p className={styles['item__title']}>
                Планируемая дата решения обращения
              </p>
              <p className={styles['item__list-item']}>
                {getTime(issue?.planned_reaction_at)}
              </p>
            </div>
            <div className={styles['item']}>
              <p className={styles['item__title']}>Статус</p>
              <p style={{}} className={styles['item__list-item']}>
                {/* @ts-ignore */}
                {issue?.status && statusesTranslate[issue.status.name]}
              </p>
            </div>
            <div className={styles['item']}>
              <p className={styles['item__title']}>Ответственный сотрудник</p>
              <p className={styles['item__list-item']}>
                <img src={avatar} alt="" />
                <span>
                  {issue?.assignee?.name ? issue.assignee.name : 'Не назначен'}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles['diagnostics__chat']}>
          <p
            className={styles['diagnostics__title']}
            style={{ marginLeft: '16px' }}
          >
            Комментарии
          </p>
          <div className={styles['plug']}></div>
          <div className={styles['window']} ref={scrollRef}>
            {comments &&
              comments.toSorted(compare).map((comment: IssueComment, index) => (
                <React.Fragment key={comment.id}>
                  <p className={styles['window__date']}>
                    {isAnotherDay(comment.published_at)
                      ? getTime(comment.published_at, false, false, true)
                      : ''}
                  </p>
                  <div
                    className={`${styles['comment']} ${
                      comment.author.type !== 'contact'
                        ? styles['comment--received']
                        : styles['comment--sent']
                    }`}
                    key={comment.id}
                  >
                    <div className={styles['comment__header']}>
                      <span>{comment.author.name}</span>
                      <span className={styles['comment__time']}>
                        {getTime(comment.published_at, true, false).slice(13)}
                      </span>
                    </div>
                    <p className={styles['comment__body']}>
                      {renderTextWithLinks(comment.content.replace(regExp, ''))}
                    </p>
                    {comment.attachments.length !== 0 &&
                      comment.attachments.map(
                        (attachment: IssueAttachement) => (
                          <div
                            className={styles['comment__attachment']}
                            onClick={() => {
                              dispatch(
                                getIssueAttachment({
                                  issueId: issueId ? +issueId : -1,
                                  attachmentId: attachment.id,
                                })
                              ).then((data) =>
                                //@ts-ignore
                                openLinkInNewWindow(data.payload.attachment_url)
                              );
                            }}
                          >
                            <div className={styles['img-wrapper']}>
                              <img src={fileIcon} alt="file" />
                            </div>
                            <p className={styles['comment__filename']}>
                              {attachment.attachment_file_name}
                            </p>
                            <p className={styles['comment__filesize']}>
                              {getFileSize(attachment.attachment_file_size)}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </React.Fragment>
              ))}
          </div>

          <form
            className={styles['diagnostics__send']}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl isInvalid={Boolean(errors.content)}>
              <div className={styles['inputs-block']}>
                <label className={styles['diagnostics__file']}>
                  <input type="file" {...register('attachments')} />
                  <img src={clipIcon} alt="clip" />
                </label>

                <Input
                  placeholder="Введите комментарий..."
                  className={styles['diagnostics__input']}
                  autoComplete="off"
                  errorBorderColor="red.400"
                  {...register('content', {
                    required: 'Невозможно отправить пустое сообщение',
                  })}
                />

                <Button
                  className={styles['send-button']}
                  type="submit"
                  backgroundColor="#e3282a"
                  borderRadius="50%"
                  padding={0}
                >
                  <img
                    className={styles['send-button__img']}
                    src={arrowIcon}
                    alt="send"
                    style={{ width: '90%' }}
                  />
                </Button>
              </div>
              <FormErrorMessage>
                {/* @ts-ignore */}
                {errors.content && errors.content?.message}
              </FormErrorMessage>
            </FormControl>
            {watch('attachments') && watch('attachments')[0] && (
              <div className={styles['file-name']}>
                <p>{watch('attachments')[0].name}</p>
                <p>{getFileSize(watch('attachments')[0].size)}</p>
              </div>
            )}
          </form>
        </div>
      </LoadingWrapper>
    </div>
  );
}
