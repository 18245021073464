import * as yup from 'yup';

export const employeeSchema = yup.object().shape({
  last_name: yup
    .string()
    .required('Обязательное поле')
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  first_name: yup
    .string()
    .required('Обязательное поле')
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  patronymic: yup
    .string()
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
    email: yup
    .string()
    .email('Пожалуйста, введите корректный адрес электронной почты')
  ,
  mobile_phone: yup
    .string()
    .matches(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Пожалуйста, введите корректный номер телефона'
    ),
  telegram: yup
    .string()
    .test (
      'is-valid-telegram-or-empty',
      'Пожалуйста, введите корректный телеграм',
      (value) => {
        if (!value || value.length < 2) return true;
        return /^[a-zA-Zа-яА-Я0-9_.@-]+$/.test(value);
      }
    )
    .nullable()
    ,
  whatsapp: yup
    .string()
    .test (
      'is-valid-whatsapp-or-empty',
      'Пожалуйста, введите корректный whatsapp',
      (value) => {
        if (!value || value.length < 2) return true;
        return /^[a-zA-Zа-яА-Я0-9_.@-]+$/.test(value);
      }
    )
    .nullable()
    ,
});
