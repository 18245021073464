import * as yup from 'yup';

const addressSchema = yup.object().shape({
  value: yup.string().required('Адрес обязателен'),
  label: yup.string().required('Метка адреса обязательна'),
  fullAddress: yup.object().required('Полный адрес обязателен'),
});

export const cartConfirmSchema = yup.object().shape({
  isAddAddressHandler: yup.boolean(),
  self_delivery_type: yup
    .object()
    .shape({
      value: yup.string().required('Тип получателя обязателен'),
      label: yup.string().required('Метка типа получателя обязательна'),
    })
    .nullable(),
  comment: yup.string().max(2000, 'Максимум 2000 символов'),
  contact_full_name: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(200, 'Максимум 200 символов')
    .required('Пожалуйста введите полное имя'),
  contact_phone: yup
    .string()
    .required('Пожалуйста, введите номер телефона')
    .matches(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Пожалуйста, введите корректный номер телефона'
    ),
  contact_email: yup.string().email('Неверный формат электронной почты'),
  deliveryEnabled: yup.boolean(),
  deliveryMode: yup.string().when('deliveryEnabled', {
    is: true,
    then: () => yup.string().required('Укажите режим доставки'),
    otherwise: () => yup.string().notRequired(),
  }),
  delivery_type: yup
    .object()
    .shape({
      value: yup.string().required('Тип доставки обязателен'),
      label: yup.string().required('Метка типа доставки обязательна'),
    })
    .nullable(),
  payer_type: yup
    .object()
    .shape({
      value: yup.string().required('Тип плательщика обязателен'),
      label: yup.string().required('Метка типа плательщика обязательна'),
    })
    .nullable(),
  recipient_type: yup
    .object()
    .shape({
      value: yup.string().required('Тип получателя обязателен'),
      label: yup.string().required('Метка типа получателя обязательна'),
    })
    .nullable(),
  third_party_company_inn: yup
    .string()
    .when(['deliveryEnabled', 'deliveryMode', 'recipient_type'], {
      is: (deliveryEnabled, deliveryMode, recipient_type) =>
        deliveryEnabled &&
        deliveryMode !== 'self-delivery' &&
        recipient_type?.value === 'third party',
      then: () =>
        yup
          .string()
          .required('Укажите ИНН компании')
          .required('Пожалуйста, введите ИНН')
          .matches(/^\d+$/, 'ИНН может содержать только цифры')
          .min(10, 'ИНН должен содержать минимум 10 символов')
          .max(12, 'ИНН не может содержать более 12 символов'),
      otherwise: () => yup.string().notRequired(),
    }),
  third_party_company_name: yup
    .string()
    .when(['deliveryEnabled', 'deliveryMode', 'recipient_type'], {
      is: (deliveryEnabled, deliveryMode, recipient_type) =>
        deliveryEnabled &&
        deliveryMode !== 'self-delivery' &&
        recipient_type?.value === 'third party',
      then: () => yup.string().required('Укажите название компании'),
      otherwise: () => yup.string().notRequired(),
    }),
  shipmentAddress: yup
    .object()
    .when('deliveryEnabled', {
      is: true,
      then: () =>
        yup.object().when('deliveryMode', {
          is: (mode) => ['delivery', 'courier'].includes(mode),
          then: () => addressSchema,
          otherwise: () => yup.object().notRequired(),
        }),
    })
    .nullable(),
  legal_entity: yup
    .object()
    .shape({
      value: yup.string().required('Юридическое лицо обязательно'),
      label: yup.string().required('Метка юридического лица обязательна'),
    })
    .nullable(),
  add_pickup_details_later: yup.boolean(),
  courier_service: yup
    .string()
    .when(
      [
        'deliveryEnabled',
        'deliveryMode',
        'self_delivery_type',
        'add_pickup_details_later',
      ],
      {
        is: (
          deliveryEnabled,
          deliveryMode,
          self_delivery_type,
          add_pickup_details_later
        ) =>
          deliveryEnabled &&
          deliveryMode === 'self-delivery' &&
          !add_pickup_details_later &&
          self_delivery_type?.value === 'courier',
        then: () => yup.string().required('Укажите курьерский сервис'),
        otherwise: () => yup.string().notRequired(),
      }
    ),
  invoice_url: yup
    .string()
    .when(
      [
        'deliveryEnabled',
        'deliveryMode',
        'self_delivery_type',
        'add_pickup_details_later',
      ],
      {
        is: (
          deliveryEnabled,
          deliveryMode,
          self_delivery_type,
          add_pickup_details_later
        ) =>
          deliveryEnabled &&
          deliveryMode === 'self-delivery' &&
          !add_pickup_details_later &&
          self_delivery_type?.value === 'courier',
        then: () =>
          yup
            .string()
            .required('Укажите номер накладной или загрузите фото или скан'),
        otherwise: () => yup.string().notRequired(),
      }
    ),
  confidant_name: yup
    .string()
    .when(
      [
        'deliveryEnabled',
        'deliveryMode',
        'self_delivery_type',
        'add_pickup_details_later',
      ],
      {
        is: (
          deliveryEnabled,
          deliveryMode,
          self_delivery_type,
          add_pickup_details_later
        ) =>
          deliveryEnabled &&
          deliveryMode === 'self-delivery' &&
          !add_pickup_details_later &&
          self_delivery_type?.value === 'confidant',
        then: () => yup.string().required('Укажите данные доверенного лица'),
        otherwise: () => yup.string().notRequired(),
      }
    ),
  confidant_number: yup
    .string()
    .when(
      [
        'deliveryEnabled',
        'deliveryMode',
        'self_delivery_type',
        'add_pickup_details_later',
      ],
      {
        is: (
          deliveryEnabled,
          deliveryMode,
          self_delivery_type,
          add_pickup_details_later
        ) =>
          deliveryEnabled &&
          deliveryMode === 'self-delivery' &&
          !add_pickup_details_later &&
          self_delivery_type?.value === 'confidant',
        then: () =>
          yup
            .string()
            .required('Укажите номер доверенности или загрузите фото или скан'),
        otherwise: () => yup.string().notRequired(),
      }
    ),
});
