import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactPersonSchema } from '../../../utils/validators/registrationSchema';
import InputField from '../../../components/common/InputField/InputField';
import ReactInputMask from 'react-input-mask';
import Button from '../../../components/common/Button/Button';
import { RegisterHeader } from './RegistrationStepCompanyData';
import {
  addContactPerson,
  selectContacts,
  selectRegistrationStatus,
} from '../../../store/registration/reducer';
import { useAppDispatch, useAppSelector } from '../../../store/store';

export const RegistrationStepContactPerson = () => {
  const contactData = useAppSelector(selectContacts);
  const status = useAppSelector(selectRegistrationStatus);
  // const registrationData = useAppSelector(selectRegistrationData);

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(contactPersonSchema),
    shouldUnregister: false,
  });

  useEffect(() => {
    reset(contactData[0]);
  }, [contactData, reset]);

  const onSubmit = (data: any) => {
    dispatch(addContactPerson(data));
  };
  return (
    <>
      <RegisterHeader
        isBack={true}
        step={4}
        stepSize={3}
        title="Контактное лицо"
      />
      <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          id="name"
          inputName="name"
          label="Имя"
          errors={errors}
          register={register}
        />
        <InputField
          id="surname"
          inputName="surname"
          label="Фамилия"
          errors={errors}
          register={register}
        />
        <InputField
          id="patronymic"
          inputName="patronymic"
          label="Отчество"
          errors={errors}
          register={register}
        />
        <InputField
          id="position"
          inputName="position"
          label="Должность"
          errors={errors}
          register={register}
        />
        <InputField
          id="email"
          inputName="email"
          label="Email"
          errors={errors}
          register={register}
        />
        <Controller
          name={'personal_phone'}
          control={control}
          defaultValue={watch('personal_phone')}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <ReactInputMask
              mask="+7 (999) 999-99-99"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              id={'personal_phone'}
            >
              <InputField
                label="Телефон"
                inputName={'personal_phone'}
                ref={ref}
                errors={errors}
              />
            </ReactInputMask>
          )}
        />
        {/* <InputField
          as={ReactInputMask}
          mask={'+7 (***) ***-**-**'}
          id="personal_phone"
          inputName="personal_phone"
          label="Телефон"
          errors={errors}
          register={register}
        /> */}
        <Button type={'submit'} status={status}>
          Продолжить
        </Button>
      </form>
    </>
  );
};
