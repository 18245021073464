import { AxiosResponse } from 'axios';
import { ICreateOrder, Order } from '../../types/interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ordersApi } from '../../api/services/orders.service';
import { resetCart } from '../cart/reducer';
import { resetItems } from '../items/reducer';

export const fetchAllOrders = createAsyncThunk('orders/fetchAll', async () => {
  const response: AxiosResponse<Order[]> = await ordersApi.getOrders();
  return updateOrders(response.data);
});

export const fetchFirstPageOrders = createAsyncThunk(
  'orders/fetchAllWithPagination',
  async ({ limit }: { limit: number }) => {
    const response = await ordersApi.getOrdersWithPagination({ limit });
    return updateOrders(response.data.results);
  }
);

export const createOrder = createAsyncThunk(
  'orders/create',
  async (data: ICreateOrder, { dispatch }) => {
    const response = await ordersApi.createOrder(data);
    dispatch(resetCart());
    dispatch(resetItems())
    return updateOrders([response.data])[0];
  }
);

export const partialUpdateOrder = createAsyncThunk(
  'orders/partialUpdate',
  async ({ data, id }: { data: Partial<ICreateOrder>; id: number }) => {
    const response = await ordersApi.partialUpdateOrder(data, id);
    return response.data;
  }
);


export const cancelOrder = createAsyncThunk(
  'orders/delete',
  async ({ id }: { id: number }) => {
    await ordersApi.cancelOrder(id);
    return id;
  }
);

function updateOrders(orders: Order[]) {
  return orders.map((order) => {
    if (!order.print_form) {
      return {
        ...order,
        status: 'open',
        status_detail: 'no_print_form',
      };
    }
    if (order.items.every((item) => item.cancel)) {
      return {
        ...order,
        status: 'canceled',
        status_detail: 'canceled',
      };
    }
    if (order.paid && order.shipped) {
      return {
        ...order,
        status: 'closed',
        status_detail: 'paid_and_shipped',
      };
    } else if (order.paid) {
      return {
        ...order,
        status: 'open',
        status_detail: 'paid',
      };
    } else if (order.shipped) {
      return {
        ...order,
        status: 'open',
        status_detail: 'shipped',
      };
    }
    return {
      ...order,
      status: 'open',
      status_detail: 'no_paid_no_shipped',
    };
  });
}
