import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { User, Employee } from '../../types/interfaces';

export const employeesApi = {
  getAllEmployees: (): Promise<AxiosResponse<User[]>> => {
    return apiClient.get<User[]>('/employees/');
  },

  getEmployeeById: (id: string | undefined): Promise<AxiosResponse<User>> => {
    return apiClient.get<User>(`/employees/${id}/`);
  },

  createEmployee: (data: User): Promise<AxiosResponse<User>> => {
    return apiClient.post<User>('/employees/', data);
  },

  updateEmployee: (id: string, data: User): Promise<AxiosResponse<User>> => {
    return apiClient.put<User>(`/employees/${id}/`, data);
  },

  partialUpdateEmployee: (id: string, data: Partial<User>): Promise<AxiosResponse<User>> => {
    return apiClient.patch<User>(`/employees/${id}/`, data);
  },

  deleteEmployee: (id: string): Promise<AxiosResponse<void>> => {
    return apiClient.delete(`/employees/${id}/`);
  },

  registerEmployee: (data: Employee): Promise<AxiosResponse<Employee>> => {
    return apiClient.post<Employee>('/employees/register/', data);
  },
};
