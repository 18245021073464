export function renderTextWithLinks(text: string) {
  const regex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*|www\.[^\s/$.?#].[^\s]*)/gi;
  return text.split(regex).map((part, index) => {
    if (regex.test(part)) {
      return (
        <a key={index} href={part} target="_blank" rel="noreferrer">
          {part}
        </a>
      );
    } else {
      return part;
    }
  });
}
