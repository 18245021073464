interface DeviceOnDiagnostics {
  model: string;
  serialNumber: string;
  isWarrantyExpired: boolean;
}

export function parseHtmlTable(htmlString: string | undefined) {
  if (!htmlString)
    return [{ model: '', serialNumber: '', isWarrantyExpired: true }];
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString.trim(), 'text/html');
  console.log(htmlString);
  const rows = doc.querySelectorAll('table.in-ckeditor tbody tr');
  const resultArray: DeviceOnDiagnostics[] = [];

  rows.forEach((row) => {
    const cells = row.querySelectorAll('td');
    console.log(cells);
    if (cells.length >= 2) {
      // Убеждаемся, что есть как минимум две ячейки
      let model = cells[0].textContent ? cells[0].textContent.trim() : '';
      const serialNumber = cells[1].textContent
        ? cells[1].textContent.trim()
        : '';
      const isWarrantyExpired =
        cells.length > 3 &&
        //@ts-ignore
        cells[3].textContent.includes('срок гарантии');
      model = transliterateModel(model);
      resultArray.push({ model, serialNumber, isWarrantyExpired });
    }
  });
  return resultArray;
}

function transliterateModel(model: string) {
  const transliterations = {
    тд: 'TD',
    тдбле: 'TD-BLE',
  };

  return (
    model
      .split('-')
      //@ts-ignore
      .map((part) => transliterations[part] || part)
      .join('-')
      .toUpperCase()
  );
}
