import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '../../components/common/Button/Button';
import { authApi } from '../../api/services/auth.service';
import { useDispatch } from 'react-redux';
import {
  setErrorNotification,
  setNotification,
} from '../../store/notifications';
import AuthLayout from '../../components/layout/AccessLayout/AccessLayout';
import back from '../../assets/sprites/arrow_back.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordResetConfirmSchema, passwordResetSchema } from '../../utils/validators/registrationSchema';
import InputField from '../../components/common/InputField/InputField';

import './Login.scss';

const PasswordResetForm = () => {
  const [error, setError] = useState();
  const [status, setStatus] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(passwordResetSchema),
    shouldUnregister: false,
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = ({ email }) => {
    setStatus('loading');
    authApi
      .passwordResetRequest({ email })
      .then((res) => {
        setStatus('confirmed');
        setError('');
      })
      .catch((err) => {
        setError(err?.data?.detail ? err.data.detail : err.message);
        setStatus('error');
      });
  };

  return (
    <AuthLayout>
      <div className="auth__right-info">
        <div className="auth__decrement">
          <Link to={'/login'} className="header__greeting">
            <img src={back} alt="" />
            <span style={{ marginLeft: '10px' }}>Вход</span>
          </Link>
        </div>
      </div>
      {status === 'confirmed' ? (
        <>
          <h2 className="form__title">Сброс пароля</h2>
          <div className="form__description">
            Инструкция отправлена на вашу почту
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <h2 className="form__title">Восстановление пароля</h2>
          <div className="form__description">
            Введите свой email для сброса пароля
          </div>
          <div className="form__row">
            <InputField
              inputName="email"
              id="email"
              label="Email"
              placeholder="Введите Email"
              register={register}
              errors={errors}
            />
          </div>
          {error && (
            <p
              style={{
                color: '#E3282A',
                fontSize: '14px',
                marginTop: '10px',
              }}
            >
              {error}
            </p>
          )}
          <Button status={status} type="submit">
            Сбросить
          </Button>
        </form>
      )}
    </AuthLayout>
  );
};

const PasswordReset = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(passwordResetConfirmSchema),
    shouldUnregister: false,
    defaultValues: {
      new_password: '',
    },
  });

  const { key } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Сброс пароля';
  }, []);
  const [loading, setLoading] = useState('');
  const [error, setError] = useState();
  const dispatch = useDispatch();

  function onSubmit(data) {
    setLoading('loading');
    authApi
      .resetPassword({ code: key, new_password: data.new_password })
      .then(() => {
        setLoading('success');
        setError('');
        dispatch(setNotification('Новый пароль отправлен на почту'));
        navigate('/login');
      })
      .catch((err) => {
        setLoading('error');
        dispatch(setErrorNotification('Ошибка'));
        setError(
          err?.response?.data?.detail ? err.response.data.detail : 'Ошибка'
        );
      });
  }

  return (
    <>
      {key === 'reset' ? (
        <PasswordResetForm />
      ) : (
        <AuthLayout
          leftContent={
            <section className="auth__text-section">
              <h2 className="auth__text">Сброс пароля</h2>
              <div className="auth__divider"></div>
            </section>
          }
        >
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <h2 style={{ textAlign: 'center' }} className="form__title">
              {' '}
              Подтвердите сброс пароля
            </h2>
            <InputField
              inputName="new_password"
              id="new_password"
              label="Новый пароль"
              placeholder="Введите пароль"
              register={register}
              errors={errors}
            />
            <Button status={loading} type="submit">
              Подтвердить
            </Button>
          </form>
          <span style={{ color: 'red' }}> {error}</span>
        </AuthLayout>
      )}
    </>
  );
};

export default PasswordReset;
