import { useSelector } from 'react-redux';
import RegistrationTable from './RegistrationTable';
import { selectLegalEntities, selectRegistrationData, selectRegistrationStatus } from '../../../store/registration/reducer';
import Button from '../../../components/common/Button/Button';
import { useAppDispatch } from '../../../store/store';
import { registerCompany } from '../../../store/registration/actions';
import { RegisterHeader } from './RegistrationStepCompanyData';

export const RegistrationStepShowAllData = () => {
  const registrationData = useSelector(selectRegistrationData);
  const legalEntities = useSelector(selectLegalEntities);
  const status = useSelector(selectRegistrationStatus);
  const dispatch = useAppDispatch();
  const registerSubmit = () => {
    dispatch(registerCompany(registrationData));
  }

  return (
    <>
      <RegisterHeader step={5} isBack={true} stepSize={4} title="Подтверждение" />
      <RegistrationTable legalEntities={legalEntities}/>
      <Button type={'submit'} status={status} onClick={registerSubmit}>
        Подтвердить
      </Button>
    </>
  );
};
