import { createAsyncThunk } from '@reduxjs/toolkit';
import { usersApi } from '../../api/services/users.service';
import { setErrorNotification, setNotification } from '../notifications';
import {
  User,
  CreateUserRequestWithManagerPermissions,
} from '../../types/interfaces';

export const fetchAllUsers = createAsyncThunk(
  'users/fetchAll',
  async (_, { dispatch }) => {
    try {
      const response = await usersApi.getAllUsers();
      dispatch(setNotification('Список пользователей успешно получен.'));
      return response.data;
    } catch (error) {
      dispatch(
        setErrorNotification('Ошибка при получении списка пользователей.')
      );
      throw error;
    }
  }
);

export const fetchUserById = createAsyncThunk(
  'users/fetchById',
  async (id: number, { dispatch }) => {
    try {
      const response = await usersApi.getUserById(id);
      dispatch(setNotification('Информация о пользователе успешно получена.'));
      return response.data;
    } catch (error) {
      dispatch(
        setErrorNotification('Ошибка при получении информации о пользователе.')
      );
      throw error;
    }
  }
);

export const createUser = createAsyncThunk(
  'users/create',
  async (data: any, { dispatch }) => {
    try {
      const response = await usersApi.createUser(data);
      dispatch(setNotification('Пользователь успешно создан.'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при создании пользователя.'));
      throw error;
    }
  }
);

export const createUserWithManagerPermissions = createAsyncThunk(
  'users/createUserWithManagerPermissions',
  async (data: CreateUserRequestWithManagerPermissions, { dispatch }) => {
    try {
      const response = await usersApi.createUserWithManagerPermissions(data);
      dispatch(
        setNotification('Пользователь c правами менеджера успешно создан.')
      );
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при создании пользователя.'));
      throw error;
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/update',
  async ({ id, data }: { id: number; data: User }, { dispatch }) => {
    try {
      const response = await usersApi.updateUser(id, data);
      dispatch(setNotification('Пользователь успешно обновлен.'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении пользователя.'));
      throw error;
    }
  }
);

export const partialUpdateUser = createAsyncThunk(
  'users/partialUpdate',
  async ({ id, data }: { id: string; data: Partial<User> | FormData }, { dispatch }) => {
    try {
      const response = await usersApi.partialUpdateUser(id, data);
      dispatch(setNotification('Данные пользователя успешно изменены.'));
      return response.data;
    } catch (error) {
      dispatch(
        setErrorNotification('Ошибка при изменении данных пользователя.')
      );
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (id: number, { dispatch }) => {
    try {
      await usersApi.deleteUser(id);
      dispatch(setNotification('Пользователь успешно удален.'));
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении пользователя.'));
      throw error;
    }
  }
);
