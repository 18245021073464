import { createAsyncThunk } from '@reduxjs/toolkit';
import { pricesApi } from '../../api/services/prices.service';
import { setErrorNotification, setNotification } from '../notifications';

export const fetchAllPrices = createAsyncThunk(
  'prices/fetchAll',
  async (_, { dispatch }) => {
    try {
      const response = await pricesApi.fetchPrices();
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при получении списка цен.'));
      throw error;
    }
  }
);

export const fetchPriceById = createAsyncThunk(
  'prices/fetchById',
  async (id: number, { dispatch }) => {
    try {
      const response = await pricesApi.fetchPriceById(id);
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при получении информации о цене.'));
      throw error;
    }
  }
);

export const createPrice = createAsyncThunk(
  'prices/create',
  async (data: FormData, { dispatch }) => {
    try {
      const response = await pricesApi.createPrice(data);
      dispatch(setNotification('Ценник успешно создан.'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при создании ценника.'));
      throw error;
    }
  }
);

export const updatePrice = createAsyncThunk(
  'prices/update',
  async ({ id, data }: { id: number; data: FormData }, { dispatch }) => {
    try {
      const response = await pricesApi.updatePrice(id, data);
      dispatch(setNotification('Ценник успешно обновлен.'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении ценника.'));
      throw error;
    }
  }
);

export const patchPrice = createAsyncThunk(
  'prices/patch',
  async ({ id, data }: { id: number; data: Partial<FormData> }, { dispatch }) => {
    try {
      const response = await pricesApi.patchPrice(id, data);
      dispatch(setNotification('Ценник успешно изменен.'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при изменении ценника.'));
      throw error;
    }
  }
);

export const deletePrice = createAsyncThunk(
  'prices/delete',
  async (id: number, { dispatch }) => {
    try {
      await pricesApi.deletePrice(id);
      dispatch(setNotification('Ценник успешно удален.'));
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении ценника.'));
      throw error;
    }
  }
);
