import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './MyModal.module.scss';

interface IModal {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
}

export function MyModal({ isOpen, onClose, children, className='' }: IModal) {
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const closeModalOnOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (modalWrapperRef.current && !modalWrapperRef.current.contains(target) && isOpen) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeModalOnOutsideClick);
    return () => {
      document.removeEventListener('mousedown', closeModalOnOutsideClick);
    };
  }, [isOpen]);

  let modalWrapper = document.getElementById('modal-root');
  if (!modalWrapper) {
    return null;
  }

  return ReactDOM.createPortal(
    isOpen ? (
      <>
        <div className={styles.overlay} onClick={onClose}></div>
        <div ref={modalWrapperRef} className={[styles.modal__wrapper, className].join(' ')}>
          {children}
        </div>
      </>
    ) : null,
    modalWrapper  
  );
}