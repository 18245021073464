import React from 'react';
import style from './GridLayout.module.scss';

interface IProps {
  children: React.ReactNode;
  gap?: number;
  paddingTop?: string;
  minColumnWidth?: string;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed';
  length?: number;
}

const GridLayout = ({
  children,
  gap = 15,
  paddingTop = '0',
  minColumnWidth = '300px',
  status = 'idle',
}: IProps) => {
  const gridStyle = {
    gridGap: `${gap}px`,
    paddingTop,
    gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
  };

  const renderSkeletons = () =>
    Array.from({ length: 3 }).map((_, index) => (
      <div className={style.skeleton} key={index}></div>
    ));

  const contentToRender = () => {
    if (status === 'loading') {
      return renderSkeletons();
    } else {
      return children;
    }
  };

  return (
    <div className={style.grid_layout} style={gridStyle}>
      {contentToRender()}
    </div>
  );
};

export default GridLayout;
