/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import fallbackImage from '../../../assets/images/error_image.png';
import defaultImage from '../../../assets/images/no_image.png';

import styles from './ImageWithFallback.module.scss';

interface IImageWithFallback {
  src?: string;
  fallbackSrc?: string;
  defaultSrc?: string;
  className?: string;
  [key: string]: any;
}
const ImageWithFallback = ({
  src,
  fallbackSrc = fallbackImage,
  defaultSrc = defaultImage,
  ...props
}: IImageWithFallback) => {
  const [imgSrc, setImgSrc] = useState(src || defaultSrc);
  const [imgError, setImgError] = useState(false);

  const onError = () => {
    setImgSrc(fallbackSrc);
    setImgError(true);
  };

  return (
    <div className={[styles['image-container'], props.className].join(' ')}>
      {!imgError && <img {...props} src={imgSrc} onError={onError} />}
      {imgError && (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img
          className="image"
          {...props}
          src={fallbackSrc}
          alt="Fallback Image"
        />
      )}
    </div>
  );
};

export default ImageWithFallback;
