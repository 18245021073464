import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { addressSchema } from '../../../utils/validators/registrationSchema';
import InputField from '../../../components/common/InputField/InputField';
import Button from '../../../components/common/Button/Button';
import { RegisterHeader } from './RegistrationStepCompanyData';
import {
  addDeliveryAddress,
  selectLegalEntitiesPhysicalAddress,
  selectLegalEntityLegalAddress,
} from '../../../store/registration/reducer';
import { useAppDispatch, useAppSelector } from '../../../store/store';

export const RegistrationStepDeliveryAddress = () => {
  const physicalAddress = useAppSelector(selectLegalEntitiesPhysicalAddress);
  const legalAddress = useSelector(selectLegalEntityLegalAddress);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addressSchema),
    shouldUnregister: false,
  });

  const onSubmit = (data: any) => {
    dispatch(addDeliveryAddress(data));
  };

  useEffect(() => {
    reset(physicalAddress);
  }, [physicalAddress, reset]);

  const onDoesItMatch = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    for (let key in legalAddress) {
      setValue(
        key as
          | 'country'
          | 'region'
          | 'city'
          | 'street'
          | 'house'
          | 'apartment'
          | 'postcode',
        legalAddress[key]
      );
    }
  };
  return (
    <>
      <RegisterHeader
        isBack={true}
        stepSize={2}
        step={3}
        title="Адрес доставки"
      />
      <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          id="country"
          inputName="country"
          label="Страна доставки"
          errors={errors}
          register={register}
        />
        <InputField
          id="region"
          inputName="region"
          label="Регион доставки"
          errors={errors}
          register={register}
        />
        <InputField
          id="city"
          inputName="city"
          label="Город доставки"
          type="text"
          errors={errors}
          register={register}
        />

        <InputField
          id="street"
          inputName="street"
          label="Улица доставки"
          type="text"
          errors={errors}
          register={register}
        />
        <div className="auth__address-numbers">
          <InputField
            id="house"
            inputName="house"
            label="Дом доставки"
            type="text"
            errors={errors}
            register={register}
          />
          <InputField
            id="apartment"
            inputName="apartment"
            label="Офис доставки"
            type="text"
            errors={errors}
            register={register}
          />
        </div>
        <InputField
          id="postcode"
          inputName="postcode"
          label="Почтовый индекс доставки"
          type="text"
          errors={errors}
          register={register}
        />
        <div className="auth__button-container">
          <Button
            className="auth__button-it-matched"
            isWhite={true}
            onClick={onDoesItMatch}
          >
            Совпадает с юр. адресом
          </Button>
          <Button type={'submit'}>Продолжить</Button>
        </div>
      </form>
    </>
  );
};
