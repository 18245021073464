import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllContacts,
  fetchContactById,
  createContact,
  updateContact,
  partialUpdateContact,
  deleteContact,
} from './actions';
import { Contact, FetchStatusType } from '../../types/interfaces';
export interface ContactsState {
  contacts: Contact[];
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: string | null;
  currentContact: Contact;
}

const initialState: ContactsState = {
  contacts: [],
  status: 'idle',
  listFetchStatus: 'idle',
  error: null,
  currentContact: {
    id: '',
    name: '',
    position: '',
    surname: '',
    patronymic: '',
    personal_phone: '',
    integrator: '',
    main: false,
    cart: '',
    personality: '',
  },
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContacts: () => initialState,
    resetCurrentContact: (state) => {
      state.currentContact = {
        id: '',
        name: '',
        position: '',
        surname: '',
        patronymic: '',
        personal_phone: '',
        integrator: '',
        main: false,
        cart: '',
        personality: '',
      };
      state.status = 'idle';
    },
    deleteAppContact: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== action.payload
      );
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllContacts.pending, (state) => {
        state.listFetchStatus = 'loading';
      })
      .addCase(fetchAllContacts.fulfilled, (state, action) => {
        state.listFetchStatus = 'succeeded';
        state.contacts = action.payload;
      })
      .addCase(fetchAllContacts.rejected, (state, action) => {
        state.listFetchStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch contacts';
      })
      .addCase(fetchContactById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContactById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentContact = action.payload;
      })
      .addCase(fetchContactById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch contacts';
      })
      .addCase(updateContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contacts = state.contacts.map((contact) => {
          if (contact.id === action.payload.id) {
            return {
              ...contact,
              ...action.payload,
            };
          }
          return contact;
        });
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch contacts';
      })
      .addCase(partialUpdateContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(partialUpdateContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contacts = state.contacts.map((contact) => {
          if (contact.id === action.payload.id) {
            return {
              ...contact,
              ...action.payload,
            };
          }
          return contact;
        });
      })
      .addCase(partialUpdateContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch contacts';
      })
      .addCase(createContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contacts = [...state.contacts, action.payload];
      })
      .addCase(createContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch contacts';
      })
      .addCase(deleteContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contacts = state.contacts.filter(
          (contact) => contact.id !== action.payload
        );
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch contacts';
      });
  },
});

export const selectContacts = (state: any): Contact[] =>
  state.contacts.contacts;
export const selectCurrentContact = (state: any): Contact =>
  state.contacts.currentContact;
export const selectContactsStatus = (state: any): FetchStatusType =>
  state.contacts.listFetchStatus;
export const selectContactsError = (state: any): string => state.contacts.error;
export const { resetContacts, resetCurrentContact, deleteAppContact } =
  contactsSlice.actions;
export default contactsSlice.reducer;
