import React from 'react';
import {
  Box,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
} from '@chakra-ui/react';

const SpecificationsTable = ({ data={} }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box>
      {isMobile ? (
        <SimpleGrid columns={1} spacing={2}>
          {Object.values(data).map(([ label, value ], i) => (
            <Box key={i} p={3} bg="gray.100" borderRadius="md">
              <strong>{label}:</strong> {value}
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Table variant="simple" colorScheme="red">
          <Thead>
            <Tr>
              <Th color="white" bg="red.500">
                Название
              </Th>
              <Th color="white" bg="red.500">
                Значение
              </Th>
            </Tr>
          </Thead>
          <Tbody>
          {Object.entries(data).map(([ label, value ], i) => (
              <Tr key={i}>
                <Td color="black.700">{label}</Td>
                <Td color="black.700">{value}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default SpecificationsTable;