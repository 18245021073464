import React, { useEffect, useRef, useState } from 'react';
import copy from '../../../assets/sprites/copy_invites.svg';
import moment from 'moment';
import ClipboardJS from 'clipboard';
import { setNotification } from '../../../store/notifications';
import { Invite } from '../../../types/interfaces';
import { useAppDispatch } from '../../../store/store';
import CardLayout from '../../../components/layout/CardLayout';
import { Avatar, Button, Tag, TagLabel } from '@chakra-ui/react';
import styles from '../styles/InvitedCompanyCard.module.scss';

interface InvitedEmployeeCardProps {
  invite: Invite;
  showActions?: boolean;
  onDelete?: Function;
}

const InvitedEmployeeCard = ({
  invite,
  showActions = true,
  onDelete = () => {},
}: InvitedEmployeeCardProps) => {
  const clipboardRef = useRef(null);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (clipboardRef.current) {
      const clipboard = new ClipboardJS(clipboardRef.current);
      return () => {
        clipboard.destroy();
      };
    }
  }, [invite]);

  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1';
    return isLocalhost
      ? `${protocol}//${hostname}:${port}`
      : `${protocol}//${hostname}`;
  };
  const inviteLink = `${getBaseUrl()}/registration/${invite.id}`;

  return (
    <CardLayout>
      <CardLayout.Header>
        <Tag size="xl" colorScheme="white" p={0}>
          <Avatar src="" name={invite.name} ml={-1} mr={2} />
          <TagLabel>{invite.name}</TagLabel>
        </Tag>
      </CardLayout.Header>
      <CardLayout.Main>
        <p style={{ marginBottom: '20px' }}>
          Это приглашённый сотрудник. Скопируйте и предоставьте ему эту ссылку
          для дальнейшей его регистрациии в системе.
        </p>
        <p>
          Приглашение создано:{' '}
          <span style={{ fontWeight: '500' }}>
            {moment(invite.created_at).local().format('DD.MM.YYYY HH:mm')}
          </span>
        </p>
      </CardLayout.Main>
      <CardLayout.Footer>
        <div className={styles.footer}>
          <Button
            onClick={() => {
              dispatch(setNotification('Ссылка добавлена в буфер обмена'));
            }}
            ref={clipboardRef}
            className={styles.item_card__button}
            data-clipboard-text={inviteLink}
          >
            {/* <img src={copy} alt="" /> */}
            Скопировать
          </Button>
        </div>
      </CardLayout.Footer>
    </CardLayout>
  );
};

export default InvitedEmployeeCard;
