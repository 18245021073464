import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { catalogAddEditValidators } from '../../../utils/validators/catalogAddEditValidators';
import { currentItemSelector, selectCurrentItemWithCartInfo, selectItemsStatus } from '../../../store/items/reducer';
import { CATEGORY_SELECT_ITEMS } from '../../../utils/constants/selectOptions';
import { getItem, updateItem } from '../../../store/items/actions';
import { FormControl, FormLabel } from '@chakra-ui/react';
import Button from '../../common/Button/Button';
import LoadingWrapper from '../../common/Spinners/LoadingWrapper';
import InputField from '../../common/InputField/InputField';
import minusCircle from '../../../assets/sprites/minus_circle.svg';
import plusCircle from '../../../assets/sprites/plus_circle.svg';
// import redTrash from '../../../assets/sprites/trash.svg';
import redPlusCircle from '../../../assets/sprites/big_red_plus_circle.svg';
import TextareaField from '../../common/TextareaField/TextareaField';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import ReactSelect from 'react-select';
import styles from './CatalogAddEdit.module.scss';

const getCategoryLabel = (categoryValue: string) => {
  const category = CATEGORY_SELECT_ITEMS.find(
    (cat) => cat.value === categoryValue
  );
  return {
    label: category?.label || '',
    value: category?.value || '',
  }
};


const CatalogAddEdit = () => {
  const { id } = useParams();
  const product = useAppSelector(currentItemSelector);
  const dispatch = useAppDispatch();
  const status = useSelector(selectItemsStatus);
  const [photo, setPhoto] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(catalogAddEditValidators),
  });

  useEffect(() => {
    if (id) {
      dispatch(getItem({ id: Number(id) }))
    }
  }, [dispatch]);


  useEffect(() => {
    if (id) {
      reset({ ...product, tech_specs: product?.tech_specs.th || [], category: getCategoryLabel(product?.category) });
    }
  }, [dispatch, product]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tech_specs',
  });

  const { fields: additionalMaterialsFields, append: appendAdditionalMaterial, remove: removeAdditionalMaterial } = useFieldArray({
    control,
    name: 'additional_materials',
  });


  const onSubmit = async (data: any) => {
    const { tech_specs, category, short_description, description, name, additional_materials } = data;
    if (id) {
      let formData = new FormData();
      formData.append('photo', photo ? photo : '');
      formData.append('tech_specs', JSON.stringify({ th: tech_specs }));
      formData.append('category', category.value);
      formData.append('short_description', short_description);
      formData.append('description', description);
      formData.append('name', name);
      additional_materials.forEach((material: any, index: any) => {
        formData.append(`additional_materials`, JSON.stringify(material))
      });
      dispatch(
        updateItem({
          itemId: +id,
          body: formData
        })
      );
    }
  };

  const handlePhotoChange = (event: any) => {
    const newFile = event.target.files[0];
    if (newFile) {
      setPhoto(newFile)
      const newPreviewUrl = URL.createObjectURL(newFile)
      setValue('photo', newPreviewUrl)
    } else {
      setPhoto(null);
      setValue('photo', '')
    }
  };

  return (
    <LoadingWrapper type="main" status={status}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.form__left}>
          <InputField
            id="name"
            inputName="name"
            label="Название товара"
            errors={errors}
            register={register}
          />
          <TextareaField
            id="short_description"
            name="short_description"
            label="Краткое описание"
            errors={errors}
            register={register}
            maxLength={512}
            currentVal={watch('short_description')}
          />
          <TextareaField
            id="description"
            name="description"
            label="Описание"
            errors={errors}
            register={register}
            maxLength={2000}
            currentVal={watch('description')}
          />
          <div className={styles.form__field}>
            <label htmlFor={id} className={styles.form__label}>
              Технические характеристики
            </label>
            {fields?.map((spec, index) => (
              <div key={index} className={styles.techSpecRow}>
                <div className={styles.form__field}>
                  <div className={styles.form__field_header}>
                    <label
                      htmlFor={`tech_specs.${index}.label`}
                      className={styles.form__label}
                    >
                      Название
                    </label>
                    {errors?.tech_specs?.[index]?.label && (
                      <p className={styles.form__error}>
                        {errors?.tech_specs?.[index]?.label?.message}
                      </p>
                    )}
                  </div>
                  <input
                    defaultValue={spec.label}
                    {...register(`tech_specs.${index}.label`)}
                    className={`${styles.techSpecInput} ${errors?.tech_specs?.[index]?.label
                      ? styles.form__input_error
                      : ''
                      }`}
                  />
                </div>
                <div className={styles.form__field}>
                  <div className={styles.form__field_header}>
                    <label
                      htmlFor={`techSpecs[${index}].value`}
                      className={styles.form__label}
                    >
                      Значение
                    </label>
                    {errors?.tech_specs?.[index]?.value && (
                      <p className={styles.form__error}>
                        {errors?.tech_specs?.[index]?.value?.message}
                      </p>
                    )}
                  </div>
                  <input
                    defaultValue={spec.value}
                    {...register(`tech_specs.${index}.value`)}
                    className={`${styles.techSpecInput} ${errors?.tech_specs?.[index]?.value
                      ? styles.form__input_error
                      : ''
                      }`}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className={styles.techSpecRow__deleteButton}
                >
                  &#10005;
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => append({ label: '', value: '' })}
              className={styles.form__button}
            >
              <img
                src={plusCircle}
                alt="Добавить характеристику"
                className="catalog-add-form__action-field-button-icon"
              />
              <span>Добавить характеристику</span>
            </button>
          </div>
        </div>
        <div className={styles.form__right}>
          <div className={styles.form__field}>
            <div className={styles.form__field_header}>
              <label htmlFor="photo" className={styles.form__label}>
                Добавить фото *
              </label>
              {errors.photo && (
                <p className={styles.form__error}>{errors.photo.message}</p>
              )}
            </div>
            <div className={styles.form__image_preview}>
              <div className={styles.form__image_wrp}>
                {watch('photo') && (
                  <img
                    alt=""
                    src={watch('photo')}
                    className={styles.form__image}
                  />
                )}
                <div
                  className={[
                    styles.notImage,
                    !watch('photo') ? '' : styles.notImage__hovered,
                  ].join(' ')}
                >
                  <label htmlFor="photo" className={styles.form__label_button}>
                    Выбрать фото
                    <img
                      className={styles.form__input_image_plus}
                      src={redPlusCircle}
                      alt=""
                    />
                  </label>
                  {/* {watch('photo') && (
                    <img
                      onClick={() => {
                        setPhoto(null);
                        setValue('photo', '');
                      }}
                      className={styles.form__image_trash}
                      src={redTrash}
                      alt="remove image"
                    />
                  )} */}
                </div>
                <input
                  id="photo"
                  type="file"
                  name="photo"
                  className={styles.form__input_image}
                  onChange={handlePhotoChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.form__field}>
            <FormControl className={styles.form__field}>
              <FormLabel htmlFor="category" className={styles.form__label}>
                Категория *
              </FormLabel>
              {errors.category && (
                <p className={styles.form__error}>{errors.category.message}</p>
              )}
              <ReactSelect
                name="category"
                options={CATEGORY_SELECT_ITEMS}
                placeholder="Выберите категорию"
                value={watch('category')}
                onChange={(option: any) => {
                  setValue('category', option);
                }}
              />
            </FormControl>
          </div>
          {additionalMaterialsFields?.map((material, index) => (
            <div key={index}>
              <div className={styles.form__field}>
                <div className={styles.form__field_header}>
                  <label
                    htmlFor={`additional_materials[${index}].name`}
                    className={styles.form__label}
                  >
                    Доп. материал (название) *
                  </label>
                  {errors?.additional_materials?.[index]?.name && (
                    <p className={styles.form__error}>
                      {errors?.additional_materials?.[index]?.name?.message}
                    </p>
                  )}
                </div>
                <input
                  id={`additional_materials[${index}].name`}
                  defaultValue={material.name}
                  {...register(`additional_materials.${index}.name`, {
                    required: 'Обязательное поле',
                  })}
                  className={`${styles.form__input} ${errors?.additional_materials?.[index]?.name
                    ? styles.form__input_error
                    : ''
                    }`}
                />
              </div>
              <div className={styles.form__field}>
                <div className={styles.form__field_header}>
                  <label
                    htmlFor={`additional_materials[${index}].material_url`}
                    className={styles.form__label}
                  >
                    Доп. материал (ссылка) *
                  </label>

                  {errors?.additional_materials?.[index]?.material_url && (
                    <p className={styles.form__error}>
                      {errors?.additional_materials?.[index]?.material_url?.message}
                    </p>
                  )}
                </div>
                <input
                  id={`additional_materials[${index}].material_url`}
                  type="url"
                  defaultValue={material.material_url}
                  {...register(`additional_materials.${index}.material_url`, {
                    required: 'Обязательное поле',
                  })}
                  className={`${styles.form__input} ${errors?.additional_materials?.[index]?.material_url
                    ? styles.form__input_error
                    : ''
                    }`}
                />
              </div>
              <button
                type="button"
                onClick={() => removeAdditionalMaterial(index)}
                className={styles.form__button}
              >
                <img
                  src={minusCircle}
                  alt="Добавить материал"
                  className="catalog-add-form__action-field-button-icon"
                />
                <span>Удалить материал</span>
              </button>
            </div>
          ))}

          {additionalMaterialsFields?.length < 4 && (
            <button
              type="button"
              onClick={() => {
                appendAdditionalMaterial({ name: '', material_url: '' });
              }}
              className={styles.form__button}
            >
              <img
                src={plusCircle}
                alt="Добавить материал"
                className="catalog-add-form__action-field-button-icon"
              />
              <span>Добавить доп. материал</span>
            </button>
          )}
          <Button type="submit" className={styles.form__submitButton}>
            Сохранить
          </Button>
        </div>
      </form>
    </LoadingWrapper >
  );
};

export default CatalogAddEdit;
