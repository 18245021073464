import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  checkEmailAvailable,
  checkUsernameAvailable,
  login,
  logout,
  resetPassword,
  getMe,
  updateMe,
} from './actions';
import { Address, FetchStatusType, User } from '../../types/interfaces';
import { RootState } from '../store';
import { loadRememberMeData } from '../../utils/hooks/rememberMeData';

export interface AuthState {
  user: User;
  permissions: { [key: string]: boolean }[];
  isAuthenticated: boolean;
  status: FetchStatusType;
  error: string | null;
  isLoaded: FetchStatusType;
}

const userStorage = loadRememberMeData();

const userFromStorage: User =
  userStorage && typeof userStorage === 'object'
    ? userStorage?.user
    : {
        contactPersons: [],
        legalEntities: [],
        permissions: [],
        is_superuser: false,
        is_staff: false,
        username: '',
        first_name: '',
        last_name: '',
        mobile_phone: '',
        patronymic: '',
        email: '',
        telegram: '',
        whatsapp: '',
      };

const initialState: AuthState = {
  user: userFromStorage,
  permissions: [],
  isAuthenticated: false,
  status: 'idle',
  error: null,
  isLoaded: 'idle',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addCompanyLegalEntities: (state, action) => {
      state.user.company?.legal_entities.push(action.payload);
    },
    addCompanyContact: (state, action) => {
      state.user.company?.contacts.push(action.payload);
    },
    updateLegalEntities: (state, action) => {
      state.user.company?.legal_entities.push(action.payload);
    },

    updateCompanyContact: (state, action) => {
      state.user.company?.contacts.push(action.payload);
    },
    updateMyCompany: (state, action) => {
      state.user.company = action.payload;
    },
    addLegalEntityAddress: (
      state,
      action: PayloadAction<{ legalEntityId: number; address: Address }>
    ) => {
      const legalEntity = state.user.company?.legal_entities.find(
        (le) => le.id === action.payload.legalEntityId
      );
      if (legalEntity) {
        legalEntity.addresses.push(action.payload.address);
      }
    },
    editLegalEntityAddress: (
      state,
      action: PayloadAction<{ legalEntityId: number; address: Address }>
    ) => {
      const legalEntity = state.user.company?.legal_entities.find(
        (le) => le.id === action.payload.legalEntityId
      );
      if (legalEntity) {
        const index = legalEntity.addresses.findIndex(
          (addr) => addr.id === action.payload.address.id
        );
        if (index !== -1) {
          legalEntity.addresses[index] = action.payload.address;
        }
      }
    },
    deleteLegalEntityAddress: (
      state,
      action: PayloadAction<{ legalEntityId: number; addressId: number }>
    ) => {
      const legalEntity = state.user.company?.legal_entities.find(
        (le) => le.id === action.payload.legalEntityId
      );
      if (legalEntity) {
        legalEntity.addresses = legalEntity.addresses.filter(
          (addr) => addr.id !== action.payload.addressId
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.permissions = action.payload.user.permissions;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Login failed';
      })
      .addCase(logout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = initialState.user;
        state.isAuthenticated = false;
        state.status = 'idle';
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Logout failed';
      })
      .addCase(getMe.pending, (state) => {
        state.status = 'loading';
        state.isLoaded = 'loading';
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.permissions = action.payload.permissions;
        state.user = action.payload;
        state.isLoaded = 'succeeded';
      })
      .addCase(getMe.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch user';
        state.isLoaded = 'failed';
      })
      .addCase(updateMe.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMe.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        localStorage.setItem('user', JSON.stringify({ ...action.payload }));
      })
      .addCase(updateMe.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update user';
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to reset password';
      })
      .addCase(checkEmailAvailable.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkEmailAvailable.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(checkEmailAvailable.rejected, (state, action) => {
        state.status = 'failed';
        state.error =
          action.error.message || 'Failed to check email availability';
      })
      .addCase(checkUsernameAvailable.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkUsernameAvailable.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(checkUsernameAvailable.rejected, (state, action) => {
        state.status = 'failed';
        state.error =
          action.error.message || 'Failed to check username availability';
      });
  },
});

export const selectIsAuthenticated = (state: RootState): boolean =>
  state.auth.isAuthenticated;
export const selectUser = (state: RootState): User => state.auth.user;
export const selectUserPermissions = (
  state: RootState
): { [key: string]: boolean }[] => state.auth.permissions;
export const selectAuthStatus = (state: RootState): FetchStatusType =>
  state.auth.status;
export const selectAuthError = (state: RootState): string | null =>
  state.auth.error;
export const selectGetMeStatus = (state: RootState): FetchStatusType =>
  state.auth.status;

export const { addCompanyContact, addCompanyLegalEntities, updateLegalEntities, updateMyCompany, addLegalEntityAddress, editLegalEntityAddress, deleteLegalEntityAddress } =
  authSlice.actions;

export default authSlice.reducer;
