import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { clearAuth } from '../../../store/profile/reducer';
import logo from '../../../assets/logos/escort_logo.png';
import { NAV_ITEMS, NAV_ITEMS_USER } from '../../../utils/constants/navigation';
import isExpandedLogo from '../../../assets/logos/favicon.svg';
import {
  Box,
  VStack,
  Flex,
  useColorModeValue,
  Tooltip,
  Badge,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../../../store/cart/actions';
import { selectCartItemsLength } from '../../../store/cart/reducer';
import { Can } from '../../../utils/context/can';
import './Sidebar.scss';

const Sidebar = ({ hasCompany }) => {
  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem('isExpanded') === 'true' || false
  );
  const dispatch = useDispatch();

  const cartItemsLength = useSelector(selectCartItemsLength);

  useEffect(() => {
    localStorage.setItem('isExpanded', isExpanded);
    dispatch(getCart());
  }, [isExpanded, dispatch]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const colorRed = useColorModeValue('red.500', 'red.300');

  return (
    <Flex height="100vh">
      <Box
        width={isExpanded ? '300px' : '70px'}
        transition="0.2s width"
        color="white"
        alignItems={'flex-start'}
        position={'relative'}
      >
        <VStack
          overflow={'hidden'}
          gap={0}
          justifyContent={'flex-start'}
          height={'100%'}
          pt={'42px'}
        >
          <Box
            paddingLeft={'22px'}
            alignSelf={'flex-start'}
            color={colorRed}
            onClick={handleExpand}
            cursor="pointer"
            position="absolute"
            top="10px"
          >
            <HamburgerIcon boxSize={6} />
          </Box>
          <Link to="/" className="bar__header">
            <img
              src={!isExpanded ? isExpandedLogo : logo}
              alt=""
              className="bar__image"
            />
          </Link>
          {(hasCompany ? NAV_ITEMS_USER : NAV_ITEMS).map((item, index) => (
            <React.Fragment key={index}>
              <HasCan I={'view'} a={item.perm_name}>
                <Tab
                  key={index}
                  item={item}
                  isExpanded={isExpanded}
                  index={index}
                >
                  <div
                    style={{ paddingLeft: !isExpanded && '5px' }}
                    className="menu__info"
                  >
                    {item.alt === 'Корзина' && cartItemsLength > 0 && (
                      <Badge
                        display="flex"
                        justifyContent={'center'}
                        alignItems={'center'}
                        w={'20px'}
                        h={'20px'}
                        position="absolute"
                        top="-10px"
                        left={!isExpanded ? '20px' : 'auto'}
                        right={!isExpanded ? 'auto' : '-20px'}
                        borderRadius="full"
                        bg={'red'}
                        color="white"
                      >
                        {cartItemsLength}
                      </Badge>
                    )}
                    <img
                      src={item.icon}
                      alt={item.alt}
                      className="menu__option-icon"
                      style={{ marginLeft: item.id === 10 ? '-2.5px' : '' }}
                    />
                    <p
                      style={{
                        visibility: isExpanded ? 'visible' : 'hidden',
                      }}
                      className="menu__option-text"
                    >
                      {item.text}
                    </p>
                  </div>
                </Tab>
              </HasCan>
            </React.Fragment>
          ))}
        </VStack>
      </Box>
    </Flex>
  );
};

export default Sidebar;

function HasCan({ I = 'view', a, children }) {
  if (a === 'all') {
    return children;
  }
  return (
    <Can I={I} a={a}>
      {children}
    </Can>
  );
}
function Tab({ item, isExpanded, index, children }) {
  return (
    <Tooltip
      label={item.text}
      placement="right"
      display={isExpanded ? 'none' : 'flex'}
      key={index}
    >
      <NavLink
        state={item.name}
        to={item.link}
        className={`menu__option-wrapper`}
      >
        {children}
        <div className="menu__slider"></div>
      </NavLink>
    </Tooltip>
  );
}
