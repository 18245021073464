import React, { useContext } from 'react';
import { Navigate, useLocation, useOutletContext } from 'react-router-dom';
import { AbilityContext } from '../utils/context/can';
import { User } from '../types/interfaces';
interface ProtectedRouteProps {
  redirectTo: string;
  isOnlySuperManager?: boolean;
  children: React.ReactNode;
  action: string;
  subject: string;
}
const ProtectedRoute = ({
  redirectTo,
  isOnlySuperManager,
  children,
  action,
  subject,
}: ProtectedRouteProps): JSX.Element => {
  const { me }: { me: User } = useOutletContext();
  const location = useLocation();
  //const storageUserData = localStorage.getItem('user');
  //const userData = JSON.parse(storageUserData ?? '{}');
  //const userScope = userData?.scope;
  const ability = useContext(AbilityContext);

  if (isOnlySuperManager && !me.is_superuser) {
    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  }

  if (!ability.can(action, subject)) {
    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
