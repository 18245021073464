import { createSelector, createSlice } from '@reduxjs/toolkit';
import { FetchStatusType, Order } from '../../types/interfaces';
import {
  fetchAllOrders,
  createOrder,
  cancelOrder,
  fetchFirstPageOrders,
  partialUpdateOrder,
} from './actions';
import { RootState } from '../store';

export interface OrdersState {
  orders: Order[];
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: string | null;
  loadingOrderId: string;
  creatingStatus: FetchStatusType;
}

const initialState: OrdersState = {
  orders: [],
  listFetchStatus: 'idle',
  status: 'idle',
  error: null,
  loadingOrderId: '',
  creatingStatus: 'idle',
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrders: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrders.pending, (state) => {
        state.listFetchStatus = 'loading';
      })
      .addCase(fetchAllOrders.fulfilled, (state, action) => {
        state.listFetchStatus = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchAllOrders.rejected, (state, action) => {
        state.listFetchStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch orders';
      })
      .addCase(fetchFirstPageOrders.pending, (state) => {
        state.creatingStatus = 'loading';
      })
      .addCase(fetchFirstPageOrders.fulfilled, (state, action) => {
        state.creatingStatus = 'succeeded';
        const updatedOrders = state.orders.map((order) => {
          const updatedOrder = action.payload.find(
            (updated) => updated.id === order.id
          );
          return updatedOrder ? { ...order, ...updatedOrder } : order;
        });
        state.orders = updatedOrders;
        const currentOrderIds = new Set(state.orders.map((order) => order.id));
        const newOrders = action.payload.filter(
          (order) => !currentOrderIds.has(order.id)
        );
        state.orders = [...state.orders, ...newOrders];
      })
      .addCase(fetchFirstPageOrders.rejected, (state, action) => {
        state.creatingStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch orders';
      })
      .addCase(createOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = [action.payload, ...state.orders];
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create order';
      })
      .addCase(partialUpdateOrder.pending, (state) => {})
      .addCase(partialUpdateOrder.fulfilled, (state, action) => {
        state.orders = state.orders.map((order) => {
          if (order.id === action.payload.id) {
            return action.payload;
          }
          return order;
        });
      })

      .addCase(cancelOrder.pending, (state, action) => {
        state.status = 'loading';
        state.loadingOrderId = action.meta.arg.id.toString();
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders.map((order) => {
          if (order.id === action.meta.arg.id) {
            return {
              ...order,
              status: 'canceled',
              items: order.items.map((item) => {
                return {
                  ...item,
                  cancel: true,
                };
              }),
            };
          }
          return order;
        });
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to cancel order';
      });
  },
});

export const { resetOrders } = orderSlice.actions;

export const selectAllOrders = (state: RootState) => state.orders.orders;
export const selectOpenOrders = (state: RootState) =>
  state.orders.orders.filter((i: Order) => i.status === 'open');
export const selectClosedOrders = (state: RootState) =>
  state.orders.orders.filter((i: Order) => i.status === 'closed');
export const selectCanceledOrders = (state: RootState) =>
  state.orders.orders.filter((i: Order) => i.status === 'canceled');
export const selectOrdersStatus = (state: RootState): FetchStatusType =>
  state.orders.status;
export const selectOrdersFetchedStatus = (state: RootState): FetchStatusType =>
  state.orders.listFetchStatus;

export const selectOrdersListFetchStatus = (state: RootState) =>
  state.orders.listFetchStatus;
export const selectOrdersError = (state: RootState) => state.orders.error;
export const selectLoadingOrderId = (state: RootState) =>
  state.items.loadingOrderId;

export const selectAllOrdersTypes = createSelector(
  [selectOpenOrders, selectCanceledOrders, selectClosedOrders],
  (openOrders, canceledOrders, closedOrders) => {
    return [openOrders, canceledOrders, closedOrders];
  }
);

export default orderSlice.reducer;
