import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { CreatePriceListRequest, PriceList } from '../../types/interfaces';

export const pricesApi = {
  fetchPrices: (): Promise<AxiosResponse<PriceList[]>> => {
    return apiClient.get('/pricelists/');
  },
  fetchPriceById: (id: number): Promise<AxiosResponse<PriceList>> => {
    return apiClient.get(`/pricelists/${id}/`);
  },
  createPrice: (formData: FormData): Promise<AxiosResponse<PriceList>> => {
    return apiClient.post('/pricelists/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updatePrice: (id: number, data: FormData): Promise<AxiosResponse<PriceList>> => {
    return apiClient.put(`/pricelists/${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  patchPrice: (id: number, data: Partial<FormData>): Promise<AxiosResponse<PriceList>> => {
    return apiClient.patch(`/pricelists/${id}/`, data);
  },
  deletePrice: (id: number): Promise<AxiosResponse<void>> => {
    return apiClient.delete(`/pricelists/${id}/`);
  },
};
