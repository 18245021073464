import React, { useEffect, useState } from 'react';
import styles from './CartConfirm.module.scss';
import {
  deliveryTypes,
  payerTypes,
  recipientTypes,
  selfDeliveryTypes,
} from '../../../utils/constants/selectOptions';
import CartDeliveryTypeRadios from './components/CartDeliveryTypeRadios';
import { CartSelect } from './components/CartSelect';
import Button from '../../common/Button/Button';
import { CartFieldWrp } from './components/CartFieldWrp';
import { CartInput } from './components/CartInput';
import { CartSelfDeliveryData } from './components/CartSelfDeliveryData';
import { Address, FetchStatusType } from '../../../types/interfaces';
import { Switch } from '@chakra-ui/react';
import { apiClient } from '../../../api/instance';
import { FaPaperclip } from 'react-icons/fa';
import { useAppDispatch } from '../../../store/store';
import { setErrorNotification } from '../../../store/notifications';

export function formatAddress(address: Address): string | undefined {
  if (address) {
    const { country, region, city, street, house, apartment, postcode } =
      address;
    return `${country}, ${region}, ${city}, ${street}, дом ${house}, офис. ${apartment}, почтовый индекс ${postcode}`;
  }
}

interface SelectOption {
  label: string;
  value: string;
}

interface ICartStepDeliveryMethods {
  cartRegister: any;
  cartErrors: any;
  shipmentAddress: any;
  deliveryType: SelectOption | null;
  payerType: SelectOption | null;
  setValue: any;
  recipientType: SelectOption | null;
  deliveryMode: string;
  setStepOnContacts: () => void;
  watch: (field: string) => any;
  status: FetchStatusType;
  address: any;
  selfDeliveryType: any;
  trigger: () => void;
  itemsLength: any;
}

export const CartStepDeliveryMethods: React.FC<ICartStepDeliveryMethods> = ({
  cartRegister,
  cartErrors,
  shipmentAddress,
  deliveryType,
  payerType,
  trigger,
  setValue,
  recipientType,
  deliveryMode,
  setStepOnContacts,
  watch,
  status,
  address,
  selfDeliveryType,
  itemsLength,
}) => {
  const [files, setFiles] = useState<Record<string, string>>({
    confidant_number: '',
    invoice_url: '',
  });
  console.log(itemsLength);
  const setDeliveryMode = (value: string) => {
    setValue('deliveryMode', value);
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileUrl = await uploadFile(file);
      setValue(type, fileUrl);
      setFiles((prevFiles) => ({ ...prevFiles, [type]: fileUrl }));
      trigger();
    }
  };

  const dispatch = useAppDispatch();

  const uploadFile = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await apiClient.post('/files/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.file;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при загрузке файла'));
    }
  };

  useEffect(() => {
    trigger();
  }, [watch('add_pickup_details_later')]);

  const renderFileUpload = (type: string) => (
    <>
      <input
        type="file"
        id={`file_input_${type}`}
        style={{ display: 'none' }}
        onChange={(e) => handleFileChange(e, type)}
      />
      <label
        htmlFor={`file_input_${type}`}
        className={styles['file-upload-label']}
        style={{
          visibility: `${
            watch('add_pickup_details_later') ? 'hidden' : 'visible'
          }`,
        }}
      >
        <FaPaperclip className={styles['file-upload-icon']} />
      </label>
      {/* {files?.[type] && (
        <a href={files[type]} target="_blank" rel="noopener noreferrer">
          <FaPaperclip className={styles['file-upload-icon']} />
        </a>
      )} */}
    </>
  );

  const deliveryTypeContent = () => (
    <>
      <div className={styles['cart-confirm__column']}>
        <CartFieldWrp title={'Адрес доставки'}>
          <CartSelect
            options={address}
            onChange={(item: Address) => setValue('shipmentAddress', item)}
            value={shipmentAddress}
            placeholder={'Выберите адрес доставки'}
          />
        </CartFieldWrp>
        <CartFieldWrp title={'Кто оплачивает доставку?'}>
          <CartSelect
            options={payerTypes.map((i) => {
              if (itemsLength < 10 && i.value === 'sender') {
                return {
                  ...i,
                  isDisabled: true,
                };
              }
              return i;
            })}
            onChange={(item: SelectOption) => setValue('payer_type', item)}
            value={payerType}
            placeholder={'Кто оплачивает доставку?'}
          />
        </CartFieldWrp>
        <CartFieldWrp title={'На кого оформлен груз?'}>
          <CartSelect
            options={recipientTypes}
            onChange={(item: SelectOption) => setValue('recipient_type', item)}
            value={recipientType}
            placeholder={'На кого оформлен груз?'}
          />
        </CartFieldWrp>
        {recipientType?.value === 'third party' && (
          <>
            <CartFieldWrp title={'Данные компании'}>
              <CartInput
                label="Инн *"
                placeholder="Введите ИНН"
                inputName="third_party_company_inn"
                register={cartRegister}
                errors={cartErrors}
              />
            </CartFieldWrp>
            <CartFieldWrp>
              <CartInput
                label="Название компании *"
                placeholder="Название компании третьей стороны"
                inputName="third_party_company_name"
                register={cartRegister}
                errors={cartErrors}
              />
            </CartFieldWrp>
          </>
        )}
        <div id="cart_address_modal_for_portal"></div>
      </div>
      <div className={styles['cart-confirm__column']}>
        {payerType?.value === 'recipient' && (
          <CartFieldWrp title={'Кто доставит заказ?'}>
            <CartSelect
              options={deliveryTypes}
              onChange={(item: SelectOption) => setValue('delivery_type', item)}
              value={deliveryType}
              placeholder={'Кто доставит заказ?'}
            />
          </CartFieldWrp>
        )}
      </div>
    </>
  );

  const selfDeliveryTypeContent = () => (
    <>
      <div className={styles['cart-confirm__column']}>
        <CartFieldWrp title={'Кто заберет заказ?'}>
          <CartSelect
            options={selfDeliveryTypes}
            onChange={(item: SelectOption) =>
              setValue('self_delivery_type', item)
            }
            value={selfDeliveryType}
            placeholder={'Выберите компанию доставки'}
          />
        </CartFieldWrp>
        <div>
          <CartFieldWrp title="Дополнительные данные">
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                columnGap: '5px',
              }}
            >
              <Switch
                id="add_pickup_details_later"
                colorScheme="red"
                {...cartRegister('add_pickup_details_later')}
              />
              Добавить данные позже в разделе История заказов?
            </div>
          </CartFieldWrp>
          {selfDeliveryType?.value === 'confidant' && (
            <>
              <CartFieldWrp>
                <CartInput
                  label="ФИО"
                  placeholder="ФИО доверенного лица"
                  inputName="confidant_name"
                  register={cartRegister}
                  errors={cartErrors}
                  isDisabled={watch('add_pickup_details_later')}
                />
              </CartFieldWrp>
              <CartFieldWrp>
                <>
                  <CartInput
                    label="Номер доверенности или фото"
                    placeholder="Номер доверенности"
                    inputName="confidant_number"
                    register={cartRegister}
                    errors={cartErrors}
                    isDisabled={watch('add_pickup_details_later')}
                    icon={renderFileUpload('confidant_number')}
                  />
                </>
              </CartFieldWrp>
            </>
          )}
          {selfDeliveryType?.value === 'courier' && (
            <>
              <CartFieldWrp>
                <CartInput
                  label="Название сервиса *"
                  placeholder="Курьерская доставка"
                  inputName="courier_service"
                  register={cartRegister}
                  errors={cartErrors}
                  isDisabled={watch('add_pickup_details_later')}
                />
              </CartFieldWrp>
              <CartFieldWrp>
                <>
                  <CartInput
                    label="Номер накладной или фото"
                    placeholder="Номер накладной"
                    inputName="invoice_url"
                    register={cartRegister}
                    errors={cartErrors}
                    isDisabled={watch('add_pickup_details_later')}
                    icon={renderFileUpload('invoice_url')}
                  />
                </>
              </CartFieldWrp>
            </>
          )}
        </div>
      </div>
      <div
        style={{ padding: '24px 18px', display: 'flex' }}
        className={styles['cart-confirm__column']}
      >
        <CartSelfDeliveryData />
      </div>
    </>
  );

  return (
    <>
      <div className={styles['cart-confirm__delivery-mode']}>
        <CartDeliveryTypeRadios
          deliveryMode={deliveryMode}
          setDeliveryMode={setDeliveryMode}
        />
      </div>
      <div className={styles['cart-confirm__body']}>
        {deliveryMode === 'delivery' && deliveryTypeContent()}
        {deliveryMode === 'self-delivery' && selfDeliveryTypeContent()}
      </div>
      <div className={styles['cart-confirm__footer']}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setStepOnContacts();
          }}
          isWhite={true}
          type="button"
          className={styles['red-button']}
          status={status}
        >
          Назад
        </Button>
        <Button status={status} type="submit" className={styles['red-button']}>
          Оформить заказ
        </Button>
      </div>
    </>
  );
};
