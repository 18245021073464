import moment from 'moment';
import { Text } from '@chakra-ui/react';

export function DateDisplay({ order, canceled }: any) {
  const plannedDate = order.planned_shipment_date;
  const actualDate = order.real_shipment_date;

  let content;
  let color;

  if (canceled) {
    content = 'Заказ отменен';
    color = 'red';
    return <Text color={color}>{content}</Text>;
  }

  if (actualDate) {
    content = `Отправлено ${moment(plannedDate).local().format('DD.MM.YYYY')}`;
    color = 'green';
  } else if (plannedDate) {
    content = `Планируем отправить ${moment(plannedDate)
      .local()
      .format('DD.MM.YYYY')}`;
    color = '#FFA500';
  } else {
    content = 'Информация скоро будет';
    color = 'gray';
  }

  return <Text color={color}>{content}</Text>;
}