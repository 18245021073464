import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import {
  CreateUserRequestWithManagerPermissions,
  User,
} from '../../types/interfaces';

export const usersApi = {
  getAllUsers: (): Promise<AxiosResponse<User[]>> => {
    return apiClient.get<User[]>('/users/?groups__name=MANAGER');
  },

  getUserById: (id: number): Promise<AxiosResponse<User>> => {
    return apiClient.get<User>(`/users/${id}/`);
  },

  createUser: (data: any): Promise<AxiosResponse<User>> => {
    return apiClient.post<User>('/users/', data);
  },

  createUserWithManagerPermissions: (
    data: CreateUserRequestWithManagerPermissions
  ): Promise<AxiosResponse<User>> => {
    return apiClient.post<User>('/users/manager/', data);
  },

  updateUser: (id: number, data: User): Promise<AxiosResponse<User>> => {
    return apiClient.put<User>(`/users/${id}/`, data);
  },

  partialUpdateUser: (
    id: string,
    data: Partial<User> | FormData
  ): Promise<AxiosResponse<User>> => {
    return apiClient.patch<User>(`/users/${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateUserPhoto: (id: number, photoBinaryData: Blob) => {
    const formData = new FormData();
    formData.append('photo', photoBinaryData);

    return apiClient.patch(`/users/${id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteUser: (id: number): Promise<AxiosResponse<void>> => {
    return apiClient.delete(`/users/${id}/`);
  },

  registerUser: (data: any): Promise<AxiosResponse<void>> => {
    return apiClient.post(`/users/register/`, data);
  },
};
