import { cartApi } from '../../api/services/cart.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addItemToCartReducer,
  removeItemFromCartReducer,
} from '../items/reducer';
import { setNotification, setErrorNotification } from '../notifications';

export const getCart = createAsyncThunk(
  'cart/fetchCart',
  async (_, { dispatch }) => {
    try {
      const response = await cartApi.getCart();
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при получении корзины.'));
      throw error;
    }
  }
);

export const addItemToCart = createAsyncThunk(
  'cart/add',
  async (
    {
      id,
      quantity,
      inCart = false,
      verification_item = null,
      verification_quantity = 0,
    }: {
      id: number;
      quantity: number;
      verification_item: string | null;
      verification_quantity: number;
      inCart?: boolean;
    },
    { dispatch }
  ) => {
    try {
    console.log(verification_quantity, 'try {')

      const response = await cartApi.addItemToCart({
        id,
        quantity,
        verification_item,
        verification_quantity
      });

      const {item, ...rest} = response.data.items.find(i => i.item.id === id)
      console.log({
        ...item,
        ...rest,
        inCart: true,
      })
      dispatch(addItemToCartReducer({
        ...item,
        ...rest,
        inCart: true,
      }));
      dispatch(
        setNotification(
          inCart
            ? 'Количество товара в корзине обновлено!'
            : 'Товар добавлен в корзину!'
        )
      );
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении товара в корзину.'));
      throw error;
    }
  }
);

export const addItemsToCart = createAsyncThunk(
  'cart/addItems',
  async (
    {
      items,
      inCart = false,
    }: { items: { id: number; quantity: number }[]; inCart?: boolean },
    { dispatch }
  ) => {
    try {
      const response = await cartApi.addItemsToCart(items);
      dispatch(addItemToCartReducer(items));
      dispatch(
        setNotification(
          inCart
            ? 'Количество товара в корзине обновлено!'
            : 'Товар добавлен в корзину!'
        )
      );
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении товара в корзину.'));
      throw error;
    }
  }
);

export const deleteItemFromCart = createAsyncThunk(
  'cart/delete',
  async (id: number, { dispatch }) => {
    try {
      const response = await cartApi.deleteItemFromCart(id);
      dispatch(removeItemFromCartReducer({ id }));
      dispatch(setNotification('Товар успешно удален из корзины!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении товара из корзины.'));
      throw error;
    }
  }
);

export const deleteVerificationFromCart = createAsyncThunk(
  'cart/deleteVerify',
  async ( {
    id,
    quantity,
    verification_item
  }: {
    id: number;
    quantity: number;
    verification_item: string | null
  }, { dispatch }) => {
    try {
      const response = await cartApi.addItemToCart({
        id,
        quantity,
        verification_item,
        verification_quantity: 0
      });
      const {item, ...rest} = response.data.items.find(i => i.item.id === id)
      dispatch(addItemToCartReducer({
        ...item,
        ...rest,
        inCart: true,
      }));
      dispatch(
        setNotification('Количество товара в корзине обновлено!')
      );
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении товара из корзины.'));
      throw error;
    }
  }
);
