import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { AddContact, Contact } from '../../types/interfaces';

export const contactsApi = {
  getAllContacts: (params = {}): Promise<AxiosResponse<Contact[]>> => {
    return apiClient.get<Contact[]>('/contacts/', { params });
  },

  getContactById: (id: string | undefined): Promise<AxiosResponse<Contact>> => {
    return apiClient.get<Contact>(`/contacts/${id}/`);
  },

  createContact: (data: Contact): Promise<AxiosResponse<Contact>> => {
    return apiClient.post<Contact>('/contacts/', data);
  },

  updateContact: (
    id: string,
    data: Contact
  ): Promise<AxiosResponse<Contact>> => {
    return apiClient.put<Contact>(`/contacts/${id}/`, data);
  },

  partialUpdateContact: (
    id: string,
    data: Partial<Contact>
  ): Promise<AxiosResponse<Contact>> => {
    return apiClient.patch<Contact>(`/contacts/${id}/`, data);
  },

  deleteContact: (id: string): Promise<AxiosResponse<void>> => {
    return apiClient.delete(`/contacts/${id}/`);
  },
};
