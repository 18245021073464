import * as yup from 'yup';

export const companyAddEditValidators = yup.object().shape({
  name: yup
    .string()
    .required('Пожалуйста, введите имя')
    .min(2, 'Имя должно содержать минимум 2 символа')
    .max(100, 'Имя не может содержать более 100 символов'),
  inn: yup
    .string()
    .required('Пожалуйста, введите ИНН')
    .matches(/^\d+$/, 'ИНН может содержать только цифры')
    .min(10, 'ИНН должен содержать минимум 10 символов')
    .max(12, 'ИНН не может содержать более 12 символов'),
  email: yup
    .string()
    .required('Пожалуйста, введите адрес электронной почты')
    .email('Пожалуйста, введите корректный адрес электронной почты'),
  phoneNumber: yup
    .string()
    .required('Пожалуйста, введите номер телефона')
    .matches(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Пожалуйста, введите корректный номер телефона'
    ),
  deliveryAddress: yup.array().of(
    yup.object().shape({
      country: yup
        .string()
        .min(2, 'Минимум 2 символа')
        .max(100, 'Максимум 100 символов')
        .required('Введите страну'),
      region: yup
        .string()
        .min(2, 'Минимум 2 символа')
        .max(100, 'Максимум 100 символов')
        .required('Введите регион'),
      city: yup
        .string()
        .min(2, 'Минимум 2 символа')
        .max(100, 'Максимум 100 символов')
        .required('Введите город'),
      street: yup
        .string()
        .min(2, 'Минимум 2 символа')
        .max(150, 'Максимум 150 символов')
        .required('Введите улицу'),
      house: yup
        .string()
        .min(1, 'Минимум 1 символ')
        .max(50, 'Максимум 50 символов')
        .required('Введите номер дома'),
      apartment: yup
        .string()
        .min(1, 'Минимум 1 символ')
        .max(50, 'Максимум 50 символов')
        .required('Введите номер квартиры'),
      postcode: yup
        .string()
        .required('Введите индекс')
        .matches(/^\d+$/, 'Только цифры')
        .length(6, 'Ровно 6 символов'),
    })
  ),
  legalAddress: yup.object().shape({
    country: yup
      .string()
      .min(2, 'Минимум 2 символа')
      .max(100, 'Максимум 100 символов')
      .required('Введите страну'),
    region: yup
      .string()
      .min(2, 'Минимум 2 символа')
      .max(100, 'Максимум 100 символов')
      .required('Введите регион'),
    city: yup
      .string()
      .min(2, 'Минимум 2 символа')
      .max(100, 'Максимум 100 символов')
      .required('Введите город'),
    street: yup
      .string()
      .min(2, 'Минимум 2 символа')
      .max(150, 'Максимум 150 символов')
      .required('Введите улицу'),
    house: yup
      .string()
      .min(1, 'Минимум 1 символ')
      .max(50, 'Максимум 50 символов')
      .required('Введите номер дома'),
    apartment: yup
      .string()
      .min(1, 'Минимум 1 символ')
      .max(50, 'Максимум 50 символов')
      .required('Введите номер квартиры'),
    postcode: yup
      .string()
      .required('Введите индекс')
      .matches(/^\d+$/, 'Только цифры')
      .length(6, 'Ровно 6 символов'),
  }),
});

export const orderConfirmAddresSchema = yup.object().shape({
  country: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(100, 'Максимум 100 символов')
    .required('Введите страну'),
  region: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(100, 'Максимум 100 символов')
    .required('Введите регион'),
  city: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(100, 'Максимум 100 символов')
    .required('Введите город'),
  street: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(150, 'Максимум 150 символов')
    .required('Введите улицу'),
  house: yup
    .string()
    .min(1, 'Минимум 1 символ')
    .max(50, 'Максимум 50 символов')
    .required('Введите номер дома'),
  apartment: yup
    .string()
    .min(1, 'Минимум 1 символ')
    .max(50, 'Максимум 50 символов')
    .required('Введите номер квартиры'),
  postcode: yup
    .string()
    .required('Введите индекс')
    .matches(/^\d+$/, 'Только цифры')
    .length(6, 'Ровно 6 символов'),
});
