import { createAsyncThunk } from '@reduxjs/toolkit';
import { invitesApi } from '../../api/services/invites.service';
import { Invite } from '../../types/interfaces';
import { setErrorNotification, setNotification } from '../notifications';
import { addCompanyData } from '../registration/reducer';

export const fetchAllInvites = createAsyncThunk(
  'invites/fetchAll',
  async ({ id }: { id: number }, { dispatch }) => {
    try {
      const response = await invitesApi.getInvites({ id });
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при загрузке приглашений.'));
      throw error;
    }
  }
);

export const fetchInviteById = createAsyncThunk(
  'invites/fetchById',
  async (id: string, { dispatch }) => {
    try {
      const response = await invitesApi.getInvite(id);
      dispatch(addCompanyData(response.data));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при загрузке приглашения.'));
      throw error;
    }
  }
);

export const createInvite = createAsyncThunk(
  'invites/create',
  async (inviteData: Partial<Invite>, { dispatch }) => {
    try {
      const response = await invitesApi.createInvite(inviteData);
      dispatch(setNotification('Приглашение успешно сгенерировано!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при создании приглашения.'));
      throw error;
    }
  }
);

export const deleteInvite = createAsyncThunk(
  'invites/delete',
  async (inviteId: string, { dispatch }) => {
    try {
      await invitesApi.deleteInvite(inviteId);
      dispatch(setNotification('Приглашение удалено!'));
      return inviteId;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении приглашения.'));
      throw error;
    }
  }
);
