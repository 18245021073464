import styles from './Devices.module.scss';
import Select from '../../components/common/Select/Select';
import ButtonMy from '../../components/common/Button/Button';
import CustomDatePicker from './CustomDatepicker/CustomDatepicker';
import { IFilters } from './Devices';
import statuses from './utils/getOptions';
import InputFieldWithError from '../../components/common/InputField/InputFieldWithoutRH';
interface IssuesFiltersProps {
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  isFiltersApplied: boolean;
  onSearch: () => void;
  onReset: () => void;
}

export default function IssuesFilters({
  filters,
  setFilters,
  onSearch,
  onReset,
  isFiltersApplied,
}: IssuesFiltersProps) {
 // const isQueryNaN = Number.isNaN(+filters.query);
  return (
    <>
      {/* <InputFieldWithError
        label="Поиск"
        placeholder="Поиск по номеру заявки"
        className={`${styles['filters__input']} ${styles['search']}`}
        hasMarginAndPadding={false}
        value={filters.query}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFilters((filters) => ({
            ...filters,
            query: event.target.value,
          }))
        }
        error={isQueryNaN ? 'Номер заявки состоит только из цифр' : undefined}
      /> */}
      <Select
        placeholder="Все"
        options={statuses}
        label="Статус"
        optionSelected={filters.status}
        isClearable={false}
        onSelected={(option: any) =>
          setFilters((filters) => ({ ...filters, status: option }))
        }
        customStyles={{
          singleValue: () => ({
            fontSize: '14px',
          }),
          container: () => ({
            width: '250px',
            display: 'flex',
            alignItems: 'flex-end',
          }),
          control: () => ({
            height: '44px',
            fontSize: '10px',
          }),
        }}
      />
      <CustomDatePicker
        label="Дата поступления"
        filters={filters}
        setFilters={setFilters}
      />
      <ButtonMy
        className={`${styles['filters__submit']}`}
        onClick={() => {
          onSearch();
        }}
        disabled={!isFiltersApplied}
      >
        Найти
      </ButtonMy>
      {isFiltersApplied && (
        <ButtonMy
          className={`${styles['filters__clear']}`}
          onClick={() => {
            onReset();
          }}
        >
          x
        </ButtonMy>
      )}
    </>
  );
}
