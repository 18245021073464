import _ from 'lodash';
import { CartItem, Item, Price } from '../../types/interfaces';

export function calculateCartPrices(items: Item[], APO: number = 0): Item[] {
  const maxQuantity = items.reduce((max, item) => {
    if (!item.do_not_count) {
      return max + item.quantity;
    }
    return max;
  }, APO);

  return items.map((item) => {
    const priceInfo = item.prices.find(
      (price: Price) =>
        maxQuantity >= price.price_type.range_start &&
        maxQuantity <= price.price_type.range_end
    );
    let pricePerUnit;
    if (!priceInfo || priceInfo.price === 0) {
      const nonZeroPrices = item.prices.filter((p: Price) => p.price > 0);
      const minPrice = nonZeroPrices.reduce(
        (min, p) => (p.price < min ? p.price : min),
        nonZeroPrices[0]?.price
      );
      pricePerUnit = minPrice;
    } else {
      pricePerUnit = priceInfo.price;
    }

    let verificationPricePerUnit = 0;
    if (item.verification_item) {
      const verificationPriceInfo = item.verification_item.prices.find(
        (price: Price) =>
          item?.verification_quantity >= price.price_type.range_start &&
          item?.verification_quantity <= price.price_type.range_end
      );
      if (!verificationPriceInfo || verificationPriceInfo.price === 0) {
        const nonZeroVerificationPrices = item.verification_item.prices.filter(
          (p: Price) => p.price > 0
        );
        const minVerificationPrice = nonZeroVerificationPrices.reduce(
          (min, p) => (p.price < min ? p.price : min),
          nonZeroVerificationPrices[0]?.price
        );
        verificationPricePerUnit = minVerificationPrice;
      } else {
        verificationPricePerUnit = verificationPriceInfo.price;
      }
    }

    return {
      ...item,
      price: pricePerUnit,
      totalPrice:
        pricePerUnit * item.quantity +
        verificationPricePerUnit * item.verification_quantity,
      verificationPrice: verificationPricePerUnit,
      verificationTotalPrice:
        verificationPricePerUnit * item.verification_quantity,
    };
  });
}

// export function getPriceInRange(products: any, quantity = 1) {
//   let pricesInRange = [];
//   for (let i = 0; i < products.length; i++) {
//     let product = products[i];
//     let priceForQuantity = _.find(product.prices, (price: Price) => {
//       return (
//         quantity >= price.price_type.range_start &&
//         quantity <= price.price_type.range_end
//       );
//     });
//     if (!priceForQuantity || priceForQuantity.price === 0) {
//       let minPrice = _.minBy(product.prices, 'price');
//       if (minPrice) {
//         pricesInRange.push(minPrice);
//       }
//     } else if (priceForQuantity) {
//       pricesInRange.push(priceForQuantity);
//     }
//   }
//   return pricesInRange;
// }

export function getPriceInRange(product: any, quantity = 1) {
  if (!!product) {
    let pricesInRange;

    let priceForQuantity = _.find(product.prices, (price: Price) => {
      return (
        quantity >= price.price_type.range_start &&
        quantity <= price.price_type.range_end
      );
    });
    if (!priceForQuantity || priceForQuantity.price === 0) {
      let minPrice = _.minBy(product.prices, 'price');
      if (minPrice) {
        pricesInRange = minPrice;
      }
    } else if (priceForQuantity) {
      pricesInRange = priceForQuantity;
    }
    return pricesInRange;
  }
  return {
    price: null,
  };
}
