import { configureStore } from '@reduxjs/toolkit';
import items from './items/reducer';
import invites from './invites/reducer';
import notifications from './notifications';
import companies from './companies/reducer';
import contacts from './contacts/reducer';
import legalEntities from './legalEntities/reducer';
import auth from './auth/reducer';
import prices from './prices/reducer';
import cart from './cart/reducer';
import orders from './orders/reducer';
import registration from './registration/reducer';
import users from './users/reducer';
import employees from './employees/reducer';
import devices from './devices/reducer';
import issues from './issues/reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    items,
    invites,
    notifications,
    registration,
    companies,
    contacts,
    legalEntities,
    auth,
    prices,
    cart,
    orders,
    users,
    employees,
    devices,
    issues,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
