import ContactInfo from '../../../components/common/ContactInfo/ContactInfo';
import { Avatar, Tag, TagLabel } from '@chakra-ui/react';
import { Employee, User } from '../../../types/interfaces';
import CardLayout from '../../../components/layout/CardLayout';
import { CardButton } from '../../../components/common/CardButton/CardButton';
import { useContext, useState } from 'react';
import { MyModal } from '../../../components/common/MyModal/MyModal';
import EmployeeTable from './EmployeeTable';
import styles from '../styles/CompanyCard.module.scss';
import { AbilityContext, Can } from '../../../utils/context/can';
import { useAppSelector } from '../../../store/store';

interface EmployeeCardProps {
  employee: Employee;
}

function EmployeeCard({ employee }: EmployeeCardProps) {
  const [showModal, setShowModal] = useState(false);
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  );
  return (
    <CardLayout>
      <CardLayout.Header>
        <Tag size="xl" colorScheme="white" p={0}>
          <Avatar
            src={employee?.photo}
            name={employee?.first_name + ' ' + employee?.last_name}
            ml={-1}
            mr={2}
          />
          <TagLabel>
            {employee?.first_name + ' ' + employee?.last_name}
          </TagLabel>
        </Tag>
      </CardLayout.Header>
      <CardLayout.Main>
        <div className={styles.companyCard__data}>
          <ContactInfo company={employee} />
        </div>
      </CardLayout.Main>
      <CardLayout.Footer>
        <div style={{ display: 'flex', gap: '10px' }}>
          <CardButton type="eye" onClick={() => setShowModal(true)} />
          <Can I="editemployee" a="username">
            {' '}
            <CardButton link={`employee/${employee.id}`} title="Изменить" />
          </Can>
        </div>
        <MyModal isOpen={showModal} onClose={() => setShowModal(false)}>
          <EmployeeTable employee={employee} />
        </MyModal>
      </CardLayout.Footer>
    </CardLayout>
  );
}

export default EmployeeCard;
