export interface CheckAvailabilityResponse {
  available: boolean;
}

export type Nullable<T> = T | null;

export interface Time {
  created_at: string;
  updated_at: string;
}

export interface globalState {}

export interface User {
  assistant: any;
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  email: string;
  telegram?: string;
  whatsapp?: string;
  mobile_phone?: string;
  additional_phone?: string;
  photo?: string;
  cart?: any;
  company?: Company;
  permissions: { [key: string]: boolean }[];
  is_superuser: boolean;
  is_staff: boolean;
  groups?: string[];
}

export interface Employee {
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  email: string;
  telegram?: string;
  whatsapp?: string;
  mobile_phone?: string;
  additional_phone?: string;
  photo?: string;
  cart?: any;
  company?: Company;
  permissions: { [key: string]: boolean }[];
  is_superuser: boolean;
  is_staff: boolean;
}

export interface PatchEmployeeRequest {
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  telegram?: string;
  whatsapp?: string;
  mobile_phone?: string;
  additional_phone?: string;
  photo?: string;
  email?: string;
}

export interface CreateUserRequestWithManagerPermissions {
  username: string;
  email: string;
  password: string;
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  company?: number;
}

export interface TokenResponse {
  user: User;
  token: string;
}

export interface Contact {
  id: string;
  name: string;
  surname: string;
  patronymic?: string;
  position: string;
  personal_phone?: string;
  integrator?: string;
  main?: boolean;
  personality?: string;
  cart?: string;
  whatsapp?: string;
  telegram?: string;
  mail?: string;
  company?: number;
  photo?: string;
}
export interface AddContact extends Omit<Contact, 'id'> {}

export interface Address {
  id?: number;
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  apartment: string;
  postcode: string;
  type?: 'legal' | 'physical' | undefined;
}

export interface LegalEntity {
  id: number;
  name: string;
  email: string;
  inn: string;
  phones: string[];
  online_documents: boolean;
  okdesk_id: number;
  active: boolean;
  addresses: Address[];
}
export interface NewLegalEntity {
  name: string;
  email: string;
  inn: string;
  addresses: Address[];
  phones?: string[];
  online_documents?: boolean;
  okdesk_id?: number;
  active?: boolean;
}
export interface legalEntitySelect {
  value: number;
  label: string;
}

export interface Company {
  id: number;
  name: string;
  status: string;
  photo?: string;
  contacts: Contact[];
  legal_entities: LegalEntity[];
  notification_emails: string[];
  terms: boolean;
  telegram?: string;
  whatsapp?: string;
  mobile_phone?: string;
  email: string;
  manager?: number;
  personality?: string;
}

export interface InvitedCompany {
  guid: string;
  actualAddress: string;
  email: string;
  inn: string;
  kpp: string;
  legalAddress: string;
  name: string;
  nameFull: string;
  manager: InvitedCompanyManager;
}
export interface InvitedCompanyManager {
  guid: string;
  name: string;
  email: string;
}

export interface IntegratorStatuses {
  CREATED: 'created';
  MODERATION: 'moderation';
  ACTIVE: 'active';
  INACTIVE: 'inactive';
}

export interface Integrator {
  id: string;
  notification_emails: string[];
  terms: boolean;
  status: keyof IntegratorStatuses;
  invite: string;
  photo?: string;
  manager_email?: string;
  personality?: string;
}

export interface IntegratorStatuses {
  CREATED: 'created';
  MODERATION: 'moderation';
  ACTIVE: 'active';
  INACTIVE: 'inactive';
}

export interface InviteStatuses {
  UNUSED: 'unused';
  USED: 'used';
  BLOCKED: 'blocked';
}

export interface Invite extends Time {
  id: string;
  name: string;
  manager: string;
  status: keyof InviteStatuses;
  partial_data: any;
  owner: number;
  type: string;
}

export enum CardCategories {
  FUEL_LEVEL_SENSOR = 'fuel_level_sensor',
  MECHANISM_DEVIATION_SENSOR = 'mechanism_deviation_sensor',
  AGRICULTURAL_TECHNOLOGY_SENSOR = 'agricultural_technology_sensor',
  TEMPERATURE_AND_HUMIDITY_SENSOR = 'temperature_and_humidity_sensor',
  INDICATOR = 'indicator',
  AXLE_LOAD_SENSOR = 'axle_load_sensor',
  LIQUID_LEVEL_SENSOR = 'liquid_level_sensor',
  METEOROLOGICAL_EQUIPMENT = 'meteorological_equipment',
  OPTIONAL_EQUIPMENT = 'optional_equipment',
  ACCESSORIES = 'accessories',
  NO_CATEGORY = 'none',
}

export interface Attribute {
  name: string;
  card: Card;
}

export interface AttributeValue {
  attribute: Attribute; // Ссылка на объект Attribute
  value: string;
}

export interface Card {
  id: number;
  name: string;
  category: CardCategories;
  short_description: string;
  additional_materials?: [
    {
      name: string;
      material_url: string;
    }
  ];
  description: string;
  photo?: string;
  tech_specs: any;
  items: Item[];
  verification_item: VerificationItem | null;
}

export interface PriceType {
  range_start: number;
  range_end: number;
}

export interface Price {
  price_type: PriceType;
  price: number;
}

export interface ItemAttributes {
  id: number;
  attribute: string;
  value: string;
}

export interface VerificationItem {
  id: number;
  attributes: ItemAttributes[];
  prices: Price[];
  photo: string | null;
  created_at: string;
  updated_at: string;
  name: string;
  guid: string;
  do_not_count: boolean;
}

export interface Item {
  items: any;
  id: number;
  attributes: ItemAttributes[];
  prices: Price[];
  photo: string | null;
  created_at: string;
  updated_at: string;
  name: string;
  guid: string;
  do_not_count: boolean;
  quantity: number;
  verification_quantity: number;
  verification_item: VerificationItem | null;
  price?: number;
  totalPrice?: number;
  verificationPrice?: number;
  verificationTotalPrice?: number;
  inCart: any;
}
export interface PriceType {
  price_type: {
    range_start: number;
    range_end: number;
  };
  price: number;
}

export type FetchStatusType = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface FetchStatusesType {
  status: FetchStatusType;
  isLoaded: FetchStatusType;
  error: string | null;
}

export interface CartItem extends Item {
  item: Item;
  quantity: number;
  verification_item: any;
  verification_quantity: number;
}

export interface Cart {
  currency: string;
  amount_previous_order?: number;
  items: [] | CartItem[];
}

export interface OrderItem {
  quantity: number;
  price: string;
  cancel: boolean;
  item: number;
  item_name: string;
}

export interface IOrderResponse {
  id?: number;
  created_at: string;
  updated_at: string;
  currency: string;
  name: string;
  paid: PaidEnum;
  shipped: ShippedEnum;
  real_shipment_date: string;
  planned_shipment_date: string;
  closer?: number | null;
  created_by?: number | null;
  status?: string;
  delivery_track_number: string;
  delivery_state: string;
  delivery_date: Nullable<string>;
  self_delivery_type: 'confidant' | 'courier';
  invoice_url: string;
  confidant_number: string;
  confidant_name: string;
  third_party_company_name: string;
  third_party_company_inn: string;
}

export type Order = IOrderResponse &
  ICreateOrderCommonFields &
  ICreateOrderAdditionalFields &
  RenderOrder;
interface RenderOrder {
  legal_entity_data: LegalEntity;
  status_detail: string;
  delivery_track_number: string;
  delivery_state: string;
  delivery_date: Nullable<string>;
  order_status: any;
}
export interface ICreateOrderCommonFields {
  items: OrderItem[];
  comment?: string;
  delivery_type: DeliveryTypeEnum;
  contact_full_name: string;
  contact_phone: string;
  contact_email?: string;
  legal_entity?: number;
  company: number;
}

export interface ICreateOrderAdditionalFields {
  delivery_type?: DeliveryTypeEnum;
  recipient_type: string;
  payer_type: string;
  courier_service: string;
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  apartment: string;
  postcode: string;
  print_form?: string | null;
}

export interface ICreateOrder {
  comment: string;
  company: number;
  legal_entity: string;
  items: {
    quantity: number;
    price: string;
    item: number;
    item_name: string;
    cancel: boolean;
  }[];
  contact_full_name: string;
  contact_phone: string;
  contact_email: string;
  delivery_type: DeliveryTypeEnum | 'self-delivery' | 'unspecified';
  self_delivery_type?: 'courier' | 'confidant';
  courier_service?: string;
  invoice_url?: string;
  confidant_name?: string;
  confidant_number?: string;
  payer_type?: string;
  recipient_type?: string;
  third_party_company_inn?: string;
  third_party_company_name?: string;
}

export interface OrderUnspecified {
  items: OrderItem[];
  comment?: string | null;
  delivery_type: DeliveryTypeEnum;
  contact_full_name: string;
  contact_phone: string;
  contact_email?: string | null;
  legal_entity: number;
}

export interface OrderSelfDelivery extends OrderUnspecified {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  apartment: string;
  postcode: string;
  delivery_type: DeliveryTypeEnum;
}

export interface OrderOther extends OrderUnspecified {
  delivery_type: DeliveryTypeEnum;
}

export enum PaidEnum {
  False = 0,
  True = 1,
  Part = 2,
}

export enum ShippedEnum {
  False = 0,
  True = 1,
  Part = 2,
}

export enum DeliveryTypeEnum {
  Dellin = 'dellin',
  Major = 'major',
  Ems = 'ems',
  Flippost = 'flippost',
  Courier = 'courier',
  SelfDelivery = 'self-delivery',
  Other = 'other',
  Unspecified = 'unspecified',
}

export enum RecipientTypeEnum {
  ThirdParty = 'third party',
  Customer = 'customer',
}

export enum PayerTypeEnum {
  Sender = 'sender',
  Recipient = 'recipient',
  Customer = 'customer',
}

export interface PriceList {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  file: string;
  companies: number[];
}

export interface CreatePriceListRequest {
  name: string;
  file: string;
  companies: number[];
}
