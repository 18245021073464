import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../store/store';
import { Order } from '../../../types/interfaces';
import InputField from '../../../components/common/InputField/InputField';
import { partialUpdateOrder } from '../../../store/orders/actions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  order: Order;
};

export function UpdateSelfDeliveryInfo({ isOpen, onClose, order }: Props) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      confidant_name: order.confidant_name,
      confidant_number: order.confidant_number,
      courier_service: order.courier_service,
      invoice_url: order.invoice_url,
    },
  });
  const dispatch = useAppDispatch();
  const isConfidant = order.self_delivery_type === 'confidant';
  const onSubmit = (data: any) => {
    dispatch(partialUpdateOrder({ data, id: order.id }));
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактирование информации о самовывозе</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isConfidant ? (
              <>
                <InputField
                  label="ФИО доверенного лица"
                  register={register}
                  inputName="confidant_name"
                />
                <InputField
                  label="Номер доверенности"
                  register={register}
                  inputName="confidant_number"
                />
              </>
            ) : (
              <>
                <InputField
                  label="Курьерский сервис"
                  register={register}
                  inputName="courier_service"
                />
                <InputField
                  label="Номер или фото накладной"
                  register={register}
                  inputName="invoice_url"
                />
              </>
            )}
            <Box display="flex" gap={3} justifyContent="flex-end">
              <Button type="submit">Сохранить</Button>
              <Button colorScheme="red" onClick={() => onClose()}>
                Отмена
              </Button>
            </Box>
          </form>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
