import React from 'react';
import spinner from '../../../assets/sprites/spinner.svg';
import { FetchStatusType } from '../../../types/interfaces';

const MainSpinner = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <img src={spinner} alt="Загрузка" />
    </div>
  );
};

const Spinner = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <img src={spinner} alt="Загрузка" />
    </div>
  );
};

interface Props {
  type?: string;
  status?: FetchStatusType;
  children: React.ReactNode;
}

function LoadingWrapper({ type = '', status = 'idle', children }: Props) {
  return (
    <>
      {status === 'loading' ? (
        type === 'main' ? (
          <MainSpinner />
        ) : (
          <Spinner />
        )
      ) : (
        children
      )}
    </>
  );
}

export default LoadingWrapper;
