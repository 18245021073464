import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllCompanies,
  fetchCompanyById,
  createCompany,
  updateCompany,
  partialUpdateCompany,
  deleteCompany,
  partialUpdateMyCompany,
} from './actions';
import { Company, FetchStatusType, Nullable } from '../../types/interfaces';
import { RootState } from '../store';
export interface CompaniesState {
  companies: Company[];
  currentCompany: Nullable<Company>;
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: string | null;
}
// interface CompaniesFetchStatusesType extends FetchStatusesType {
//   listFetchStatus: FetchStatusType;
// }
const initialState: CompaniesState = {
  companies: [],
  currentCompany: null,
  status: 'idle',
  listFetchStatus: 'idle',
  error: null,
};
const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    resetCompanies: () => initialState,
    chooseCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanies.pending, (state) => {
        state.status = 'loading';
        state.listFetchStatus = 'loading';
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listFetchStatus = 'succeeded';
        state.companies = action.payload;
        state.error = null;
      })
      .addCase(fetchAllCompanies.rejected, (state, action) => {
        state.status = 'failed';
        state.listFetchStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch companies';
      })
      .addCase(fetchCompanyById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentCompany = action.payload;
        state.error = null;
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch company';
      })
      .addCase(createCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = [...state.companies, action.payload];
        state.error = null;
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create company';
      })
      .addCase(updateCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = state.companies.map((company) => {
          if (company.id === action.payload.id) {
            return action.payload;
          }
          return company;
        });
        state.error = null;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update company';
      })
      .addCase(partialUpdateCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(partialUpdateCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = state.companies.map((company) => {
          if (company.id === action.payload.id) {
            return action.payload;
          }
          return company;
        });
        state.error = null;
      })
      .addCase(partialUpdateCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update company';
      })
      .addCase(partialUpdateMyCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(partialUpdateMyCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = state.companies.map((company) => {
          if (company.id === action.payload.id) {
            return action.payload;
          }
          return company;
        });
        state.error = null;
      })
      .addCase(partialUpdateMyCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update company';
      })
      .addCase(deleteCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = state.companies.filter(
          (company) => company.id !== Number(action.payload)
        );
        state.error = null;
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete company';
      });
  },
});

export const selectCompanyList = (state: RootState): Company[] =>
  state.companies.companies;
export const selectCompanyById = (id: number) => (state: RootState) => {
  return (
    state.companies.companies.find((company) => company.id === id) || {
      name: '',
      inn: '',
      email: '',
      phoneNumber: '',
      legalAddress: {
        country: '',
        region: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        postcode: '',
      },
      deliveryAddress: [],
    }
  );
};
export const selectCompaniesStatus = (state: RootState): FetchStatusType =>
  state.companies.listFetchStatus;

export const selectCompanyStatus = (state: RootState): FetchStatusType =>
  state.companies.status;

export const { resetCompanies, chooseCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
