import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type NotificationType = 'success' | 'error';
interface Notification {
  type: NotificationType;
  message: string;
  id: number;
}
const initialState: { messages: Notification[] } = { messages: [] };
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (_state, action) => {
      _state.messages.push({
        type: 'success',
        message: action.payload,
        id: Date.now(),
      });
    },
    setErrorNotification: (_state, action) => {
      _state.messages.push({
        type: 'error',
        message: action.payload,
        id: Date.now(),
      });
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
    },
  },
});

export const selectAllMessages = (state: RootState) => state.notifications?.messages;

export const { setNotification, setErrorNotification, removeMessage } =
  notificationSlice.actions;

export default notificationSlice.reducer;
