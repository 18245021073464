import React from 'react';
import styles from './CartConfirmStepBlock.module.scss';
interface CartConfirmStepBlockProps {
  title: string;
  active?: boolean;
}
export const CartConfirmStepBlock = ({ title, active, ...props }: CartConfirmStepBlockProps) => {
  return (
    <div
      {...props}
      className={`${styles.cartConfirmBlock} ${active ? styles.active : ''}`}
    >
      {title}
    </div>
  );
};
