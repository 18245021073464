import telegramLogo from '../../assets/logos/telegram_logo.svg';
import whatsappLogo from '../../assets/logos/whatsapp_logo.svg';
import viberLogo from '../../assets/logos/viber_logo.svg';
import styles from './Contacts.module.scss';
import { useMe } from '../Area/Area';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { fetchAllUsers, fetchUserById } from '../../store/users/actions';
import { useEffect } from 'react';
import { selectUsersStatus } from '../../store/users/reducer';
import { Avatar } from '@chakra-ui/react';
import { User } from '../../types/interfaces';

const Contacts = () => {
  const { me } = useMe();
  const dispatch = useAppDispatch();
  const fetchManagersStatus = useAppSelector(selectUsersStatus);
  useEffect(() => {
    if (fetchManagersStatus !== 'succeeded') {
      dispatch(fetchAllUsers());
    }
  }, [dispatch]);
  const manager = useAppSelector((state) =>
    state.users.users.find((user: User) => user.id === me.company?.manager)
  );
  return (
    <div className={styles['contacts']}>
      <p className={styles['contacts__title']}>Контакты</p>
      <div className={styles['contacts__body']}>
        {manager && (
          <div className={styles['info']}>
            <p className={styles['info__title']}>Менеджер</p>
            <div className={styles['info__managers']}>
              <div className={styles.manager_block__container}>
                <Avatar
                  size={'xl'}
                  src={manager?.photo}
                  name={manager?.first_name + ' ' + manager?.last_name}
                  ml={-1}
                  mr={2}
                />
                <div className={styles['info__chunk']}>
                  <p
                    className={`${styles['info__text']} ${styles['info__text--bold']}`}
                  >
                    Контакты:
                  </p>
                  <p
                    className={`${styles['info__text']} ${styles['info__text--medium']}`}
                  >
                    {manager?.first_name} {manager?.last_name}
                  </p>
                  <a
                    href={`tel:${manager?.mobile_phone}`}
                    className={`${styles['info__text']} ${styles['info__text--medium']}`}
                  >
                    {manager?.mobile_phone}
                  </a>
                  <a
                    href={`mailto:${manager?.email}`}
                    className={`${styles['info__text']} ${styles['info__text--medium']}`}
                  >
                    {manager?.email}
                  </a>
                </div>
                {/* <div className={styles['contacts_avatar']} > */}

                {/* </div> */}
              </div>
              {manager?.assistant && (
                <div className={styles.manager_block__container}>
                  <Avatar
                    size={'xl'}
                    src={manager?.assistant?.photo}
                    name={
                      manager?.assistant?.first_name +
                      ' ' +
                      manager?.assistant?.last_name
                    }
                    ml={-1}
                    mr={2}
                  />
                  <div className={styles['info__chunk']}>
                    <p
                      className={`${styles['info__text']} ${styles['info__text--bold']}`}
                    >
                      Контакты офис менеджера:
                    </p>
                    <p
                      className={`${styles['info__text']} ${styles['info__text--medium']}`}
                    >
                      {manager?.assistant?.first_name}{' '}
                      {manager?.assistant?.last_name}
                    </p>
                    <a
                      href={`tel:${manager?.assistant?.mobile_phone}`}
                      className={`${styles['info__text']} ${styles['info__text--medium']}`}
                    >
                      {manager?.assistant?.mobile_phone}
                    </a>
                    <a
                      href={`mailto:${manager?.assistant?.email}`}
                      className={`${styles['info__text']} ${styles['info__text--medium']}`}
                    >
                      {manager?.assistant?.email}
                    </a>
                  </div>
                  {/* <div className={styles['contacts_avatar']} > */}

                  {/* </div> */}
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles['info']}>
          <p className={styles['info__title']}>Общая контактная информация</p>
          <div className={styles['info__chunk']}>
            <p
              className={`${styles['info__text']} ${styles['info__text--bold']}`}
            >
              Адрес:
            </p>
            <p
              className={`${styles['info__text']} ${styles['info__text--medium']}`}
            >
              Республика Татарстан, г. Казань, ул. Дементьева, 2Б корпус 4
            </p>
            <p
              className={`${styles['info__text']} ${styles['info__text--time']}`}
            >
              Пн-Пт: 08:00 – 17:00 Сб-Вс: Выходные
            </p>
          </div>
          <div className={styles['info__chunk']}>
            <p
              className={`${styles['info__text']} ${styles['info__text--bold']}`}
            >
              Контакты:
            </p>
            <a
              href="tel:88007771603"
              className={`${styles['info__text']} ${styles['info__text--medium']}`}
            >
              8 800 777 16 03
            </a>
            <p className={styles['info__text']}>
              Для звонков из России – бесплатно
            </p>
            <a
              href="tel:84951086833"
              className={`${styles['info__text']} ${styles['info__text--medium']}`}
            >
              8 495 108 68 33
            </a>
            <p className={styles['info__text']}>
              Для звонков из СНГ и других стран
            </p>
          </div>
          <div className={styles['info__chunk']}>
            <p
              className={`${styles['info__text']} ${styles['info__text--bold']}`}
            >
              Наш сайт:
            </p>
            <a
              href="https://www.fmeter.ru"
              className={`${styles['info__text']} ${styles['info__text--medium']}`}
            >
              www.fmeter.ru
            </a>
          </div>
        </div>
        <div className={styles['info']}>
          <p className={styles['info__title']}>Техническая поддержка</p>
          <div className={styles['info__chunk']}>
            <p
              className={`${styles['info__text']} ${styles['info__text--bold']}`}
            >
              Контакты:
            </p>
            <a
              href="tel:88007771603"
              className={`${styles['info__text']} ${styles['info__text--medium']}`}
            >
              8 800 777 16 03
            </a>
            <p className={styles['info__text']}>Добавочная 2 – круглосуточно</p>
          </div>
          <div className={styles['info__chunk']}>
            <p
              className={`${styles['info__text']} ${styles['info__text--bold']}`}
            >
              Автоматизированная система заявок:
            </p>
            <a
              href="mailto:support@fmeter.ru"
              className={`${styles['info__text']} ${styles['info__text--medium']}`}
            >
              support@fmeter.ru
            </a>
          </div>
          <div className={styles['info__chunk']}>
            <p
              className={`${styles['info__text']} ${styles['info__text--bold']}`}
            >
              Обратиться через мессенджер:
            </p>
            <div className={styles['info__icons']}>
              <a
                href="tg://resolve?domain=Escort_tech_Bot"
                target="_blank"
                rel="noreferrer"
              >
                <img src={telegramLogo} alt="Обратиться через Telegram" />
              </a>
              <a
                href="https://wa.me/79600464665"
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsappLogo} alt="Обратиться через WhatsApp" />
              </a>
              <a
                href="viber://pa?chatURI=EscortTechSupport/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={viberLogo} alt="Обратиться через Viber" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
