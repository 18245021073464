import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import './InfoTable.scss';
import { Order } from '../../../../types/interfaces';
import { formatAddress } from '../../CartConfirm/CartStepDeliveryMethods';
import {
  deliveryTypes,
  deliveryTypes2,
  payerTypes,
  recipientTypes,
  selfDeliveryTypes,
} from '../../../../utils/constants/selectOptions';
import { getLabel } from '../lib/getLabel';
export function DeliveryInfo({ order }: { order: Order }) {
  const isSelfDelivery = order.delivery_type === 'self-delivery';

  return <>{isSelfDelivery ? <SelfDelivery /> : <CommonDelivery />}</>;

  function CommonDelivery() {
    const isThirdParty = order.recipient_type === 'third party';
    const isPayerSender = order.payer_type === 'sender';
    return (
      <Table variant="underline">
        <Thead className="info-table__header">
          <Tr>
            <Th w="10%">Трек-номер</Th>
            <Th w="15%">Тип доставки</Th>
            <Th w="15%">Дата доставки </Th>
            <Th w="15%">Тип получателя</Th>
            {isThirdParty && <Th w="15%">Данные третьей стороны</Th>}
            <Th w="15%">Тип плательщика</Th>
            <Th w="15%">Адрес доставки</Th>
            <Th w="15%">Статус доставки</Th>
          </Tr>
        </Thead>
        <Tbody className="info-table__content">
          <Tr>
            <Td>{order.delivery_track_number || 'Информация скоро будет'}</Td>
            <Td>
              {isPayerSender
                ? getLabel(order.delivery_type, deliveryTypes2)
                : getLabel(order.delivery_type, deliveryTypes)}
            </Td>
            <Td>{order.planned_shipment_date || 'Информация скоро будет'}</Td>
            <Td>{getLabel(order.recipient_type, recipientTypes)}</Td>
            {isThirdParty && (
              <Td>
                Компания {order.third_party_company_name}, ИНН:{' '}
                <b>{order.third_party_company_inn}</b>
              </Td>
            )}
            <Td>{getLabel(order.payer_type, payerTypes)}</Td>
            <Td>{formatAddress(order)}</Td>
            <Td>{order.delivery_state || 'Информация скоро будет'}</Td>
          </Tr>
        </Tbody>
      </Table>
    );
  }

  function SelfDelivery() {
    const isConfidant = order.self_delivery_type === 'confidant';
    return (
      <Table variant="underline">
        <Thead className="info-table__header">
          <Tr>
            <Th w="15%">Тип доставки</Th>
            <Th w="15%">Заказ заберёт</Th>
            {isConfidant ? (
              <>
                <Th w="15%">ФИО доверенного лица</Th>
                <Th w="15%">Номер доверенности</Th>
              </>
            ) : (
              <>
                <Th w="15%">Курьерская служба</Th>
                <Th w="15%">Номер или фото накладной</Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody className="info-table__content">
          <Tr>
            <Td>Самовывоз</Td>
            <Td>{getLabel(order.self_delivery_type, selfDeliveryTypes)}</Td>
            {isConfidant ? (
              <>
                <Td w="15%">{order.confidant_name}</Td>
                <Td w="15%">{order.confidant_number}</Td>
              </>
            ) : (
              <>
                <Td w="15%">{order.courier_service}</Td>
                <Td w="15%">{order.invoice_url}</Td>
              </>
            )}
          </Tr>
        </Tbody>
      </Table>
    );
  }
}
