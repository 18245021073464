/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Input } from '@chakra-ui/react';
import MainLayout from '../../components/layout/MainLayout/MainLayout';

import SelectLegalEntities from './components/SelectLegalEntities';
import { renderSkeleton } from './renderSkeleton';
import './Orders.scss';
import {
  fetchAllOrders,
  fetchFirstPageOrders,
} from '../../store/orders/actions';
import { useMe } from '../Area/Area';
import {
  selectAllOrders,
  selectAllOrdersTypes,
  selectOrdersFetchedStatus,
  selectOrdersStatus,
} from '../../store/orders/reducer';
import { OrderList } from './OrderList';
import { useAppDispatch, useAppSelector } from '../../store/store';
import Tabs from '../../components/common/Tabs/Tabs';
function Orders() {
  const { me } = useMe();
  const dispatch = useAppDispatch();
  const orders = useAppSelector(selectAllOrders);
  const [openOrders, canceledOrders, closedOrders] =
    useAppSelector(selectAllOrdersTypes);
  const status = useAppSelector(selectOrdersStatus);
  const ordersFetchedStatus = useAppSelector(selectOrdersFetchedStatus);

  const [selectedLegalEntity, setSelectedLegalEntity] = useState<any>(null);
  const [search, setSearch] = useState('');
  useEffect(() => {
    if (ordersFetchedStatus === 'succeeded') return;
    dispatch(fetchAllOrders());
  }, [dispatch, ordersFetchedStatus]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (orders.some((order) => !order.print_form)) {
        dispatch(fetchFirstPageOrders({ limit: 10 }));
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [orders, dispatch]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  function filters() {
    return (
      <Box width={'100%'} display={'flex'} columnGap={'10px'}>
        <Input
          bg={'white'}
          borderRadius={'10px'}
          maxWidth={'500px'}
          value={search}
          onChange={handleSearchChange}
          placeholder="Поиск заказов"
          className="orders__search"
        />
        <SelectLegalEntities
          myLegalEntities={me.company?.legal_entities}
          selectedLegalEntity={selectedLegalEntity}
          setSelectedLegalEntity={setSelectedLegalEntity}
        />
      </Box>
    );
  }
  return (
    <MainLayout>
      <MainLayout.Title>История заказов</MainLayout.Title>

      <MainLayout.Main>
        {status === 'loading' ? (
          renderSkeleton(status)
        ) : (
          <>
            <Tabs variant="underline" contentAfterTabs={filters}>
              <div title="Все">
                <OrderList
                  orders={orders}
                  legalEntity={selectedLegalEntity}
                  search={search}
                />
              </div>
              <div title="В работе">
                <OrderList
                  orders={openOrders}
                  legalEntity={selectedLegalEntity}
                  search={search}
                />
              </div>
              <div title="Выполненные">
                <OrderList
                  orders={closedOrders}
                  legalEntity={selectedLegalEntity}
                  search={search}
                />
              </div>
              <div title="Отмененные">
                <OrderList
                  orders={canceledOrders}
                  legalEntity={selectedLegalEntity}
                  search={search}
                />
              </div>
            </Tabs>
          </>
        )}
      </MainLayout.Main>
    </MainLayout>
  );
}

export default Orders;
