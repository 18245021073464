import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import AddEntity from '../../common/AddEntity/AddEntity';
import '../../../pages/Area/Area.scss';

interface MainLayoutProps {
  children: ReactNode;
  className?: string;
}

const MainLayout: React.FC<MainLayoutProps> & {
  Title: typeof Title;
  AddButton: typeof AddButton;
  Header: typeof Header;
  Main: typeof Main;
} = ({ children, className = '', ...props }) => {
  return (
    <Box className={className} {...props}>
      {children}
    </Box>
  );
};

interface TitleProps {
  children: ReactNode;
}

const Title: React.FC<TitleProps> = ({ children }) => {
  return (
    <Box as="h1" fontWeight={'bold'} fontSize="2xl" mb={4}>
      {children}
    </Box>
  );
};

interface AddButtonProps {
  children?: ReactNode;
  visible?: boolean;
  text: string;
  path: string;
  state?: any;
  [key: string]: any;
}

const AddButton: React.FC<AddButtonProps> = ({
  children,
  visible = true,
  ...props
}) => {
  return (
    <Box visibility={visible ? 'visible' : 'hidden'} mb={4}>
      {children ? children : <AddEntity {...props} />}
    </Box>
  );
};

interface HeaderProps {
  children: ReactNode;
  isVisible?: boolean;
}

const Header: React.FC<HeaderProps> = ({ children, isVisible = true }) => {
  return (
    <Box
      as="header"
      alignItems={'center'}
      display={'flex'}
      justifyContent={'space-between'}
      mb={4}
      visibility={isVisible ? 'visible' : 'hidden'}
    >
      {children}
    </Box>
  );
};

interface MainProps {
  children: ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  return <Box as="main">{children}</Box>;
};

MainLayout.Title = Title;
MainLayout.AddButton = AddButton;
MainLayout.Header = Header;
MainLayout.Main = Main;

export default MainLayout;
