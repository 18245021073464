export const customStyles = {
  control: (provided) => ({
    ...provided,
    paddingLeft: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
    borderRadius: '10px',
    border: '1px solid #DCDEE0',
    color: '#BCBCBC',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.14px',
    minWidth: '15rem'
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? '#ff6a6aa3'
          : isFocused
            ? '#eee'
            : undefined,
      color: isDisabled ? '#ccc' : isSelected ? 'black' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? '#ff6a6aa3'
            : '#ff6a6aa3'
          : undefined,
      },
    };
  },
};