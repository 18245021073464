import React, { useState } from 'react';
import styles from './AvatarAddEditForm.module.scss';
import { setErrorNotification } from '../../../store/notifications';
import { useDispatch } from 'react-redux';
import { AvatarImage } from './AvatarImage';

function AvatarAddEditForm({ data, onSubmit, onDelete, status }) {
  const [avatar, setAvatar] = useState(data.photo);
  const dispatch = useDispatch();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 2 * 1024 * 1024) {
        dispatch(setErrorNotification('Файл не должен быть больше 2МБ'));
        event.target.value = '';
      } else {
        let reader = new FileReader();
        onSubmit(event);
        reader.onload = (e) => setAvatar(e.target.result);
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  };

  const deleteHandler = () => {
    onDelete();
    setAvatar(null);
  };
  return (
    <div className={styles.avatar__field}>
      <div className={styles.avatar__image_preview}>
        <div className={styles.personalImage}>
          <label className={styles.label}>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={onImageChange}
            />
            <AvatarImage
              status={status}
              avatar={avatar}
              deleteHandler={deleteHandler}
            />
          </label>
        </div>
        <div className={styles.avatar__info}>
          Для успешной загрузки фотография должна иметь формат jpg или png
        </div>
      </div>
    </div>
  );
}

export default AvatarAddEditForm;
