import React, { useEffect } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useLocation, useParams } from 'react-router-dom';
import packageJson from '../../../../package.json';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import styles from './PriceView.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectCurrentPrice } from '../../../store/prices/reducer';
import { fetchPriceById } from '../../../store/prices/actions';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'].replace(/\^/g, '');
function useSafeLocationState() {
  const location = useLocation();
  return location.state ? location.state : {};
}

const PriceView = () => {
  const { id } = useParams()
  const price = useAppSelector(selectCurrentPrice)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(id) {
      dispatch(fetchPriceById(Number(id)))
    }
  }, [id, dispatch])
  return (
    <div>
      <div className={styles['pdf-container']}>
        {price && price.file && (
          <Worker
            workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}
          >
            <Viewer fileUrl={price.file} />
          </Worker>
        )}
      </div>
    </div>
  );
};

export default PriceView;
