import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { Issue, IssueFetchedAttachment } from '../../types/issueInterfaces';
import { IFilters } from '../../pages/Devices/Devices';

const checkFiltersStatusCodes = (
  value: string | { value: string; label: string }
) => {
  if (typeof value !== 'string') {
    if (value) {
      return value.value;
    }
  }
  return null;
};

export const issuesApi = {
  getIssues: (): Promise<AxiosResponse<Issue[]>> => {
    return apiClient.get<Issue[]>('/issue/');
  },
  getFilteredIssues: (
    filters: IFilters,
    page: number
  ): Promise<AxiosResponse<Issue[]>>[] => {
    const response = apiClient.get<Issue[]>('/issue/', {
      params: {
        created_since: filters.startDate,
        created_until: filters.endDate,
        // issue_ids: Boolean(filters.query) ? filters.query : null,
        status_codes: checkFiltersStatusCodes(filters.status),
        page_number: page,
        page_size: 10,
        type_codes: 'Reclamation',
      },
    });
    const checkNextPage = apiClient.get<Issue[]>('/issue/', {
      params: {
        created_since: filters.startDate,
        created_until: filters.endDate,
        //issue_ids: Boolean(filters.query) ? filters.query : null,
        status_codes: checkFiltersStatusCodes(filters.status),
        page_number: page + 1,
        page_size: 10,
        type_codes: 'Reclamation',
      },
    });
    return [response, checkNextPage];
  },
  getIssueById: (id: number): Promise<AxiosResponse<Issue>> => {
    return apiClient.get<Issue>(`/issue/${id}`);
  },
  postCommentOnIssue: (id: number, data: any) => {
    return apiClient.post(`/issue/${id}/comment/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getIssueAttachment: (ids: {
    issueId: number;
    attachmentId: number;
  }): Promise<AxiosResponse<IssueFetchedAttachment>> => {
    return apiClient.get(
      `issue/${ids.issueId}/attachment/${ids.attachmentId}/`
    );
  },
};
