import React from 'react';
import styles from '../styles/LegalEntityTable.module.scss';
import { MyModal } from '../../../components/common/MyModal/MyModal';
import { LegalEntity } from '../../../types/interfaces';
import { translateType } from '../utils';
interface ILegalEntity {
  legalEntity: LegalEntity;
}

const LegalEntityTable = ({ legalEntity }: ILegalEntity) => {

const updatedAddresses = legalEntity?.addresses.map(address => ({
    ...address,
    type: translateType(address?.type || '')
}));
  
  return (
    <table className={styles.table}>
      <tbody>
        <tr >
          <td>Название</td>
          <td>{legalEntity.name}</td>
        </tr>
        <tr >
          <td>ИНН</td>
          <td>{legalEntity.inn}</td>
        </tr>
        <tr >
          <td>Почта</td>
          <td>{legalEntity.email}</td>
        </tr>
        <tr >
          <td>Телефоны</td>
          <td>{legalEntity.phones?.join(' ')}</td>
        </tr>
        <tr >
          <td>Адреса</td>
          <td>{updatedAddresses.map(
            (item) => Object.values(item).join(',')).join('; ') || 'Нет адресов'}
            </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LegalEntityTable;
