import * as yup from 'yup';

export const catalogAddEditValidators = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(512, 'Максимум 512 символов')
    .required('Пожалуйста, введите имя'),
  short_description: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(512, 'Максимум 512 символов')
    .required('Пожалуйста, введите краткое описание'),
  description: yup
    .string()
    .min(2, 'Минимум 2 символа')
    .max(2000, 'Максимум 2000 символов')
    .required('Пожалуйста, введите описание'),
  tech_specs: yup.array().of(
    yup.object().shape({
      label: yup
        .string()
        .required('Пожалуйста, введите название характеристики'),
      value: yup
        .string()
        .required('Пожалуйста, введите значение характеристики'),
    })
  ),
  category: yup.object().shape({
    label: yup
      .string(),
    value: yup
      .string()
  }).required('Пожалуйста, выберите категорию'),
  photo: yup.string()
  // .required('Пожалуйста, выберите фото')
  ,
  additional_materials: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Пожалуйста, введите название материала'),
      material_url: yup
        .string()
        .url('Пожалуйста, введите корректный URL материала')
        .required('Пожалуйста, введите URL материала'),
    })
  ),
});

export const priceAddEditSchema = yup.object().shape({
  name: yup.string().required('Введите имя'),
  // file: yup.string().required('Выберите файл'),
  companies: yup.array().min(1, 'Добавьте минимум одну компанию'),
});
