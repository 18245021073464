import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { step1Schema } from '../../../utils/validators/registrationSchema';
import { Controller, useForm } from 'react-hook-form';
import {
  selectCurrentStep,
  setStep,
} from '../../../store/registration/reducer';
import { useEffect } from 'react';
import { formatPhoneNumber } from '../../../utils/validators/phoneNormalize';
import { useFieldArray } from 'react-hook-form';
import Button from '../../../components/common/Button/Button';
import InputField from '../../../components/common/InputField/InputField';
import redPlusCircle from '../../../assets/sprites/red_plus_circle.svg';
import ReactInputMask from 'react-input-mask';
import logo from '../../../assets/images/register-logo.png';
import back from '../../../assets/sprites/arrow_back.png';
import {
  addCompanyPartialData,
  selectLegalEntities,
} from '../../../store/registration/reducer';
import { useAppDispatch, useAppSelector } from '../../../store/store';

export const RegisterLeftSide = () => {
  return (
    <div className="auth__left">
      <section className="auth__text-section">
        <img src={logo} alt="" />
      </section>
    </div>
  );
};
interface IRegisterHeader {
  title: string;
  description?: string;
  step: number;
  handler?: () => void;
  isBack?: boolean;
  stepSize?: number;
}

export const RegisterHeader = ({
  title,
  description,
  step,
  isBack,
  stepSize = 1,
  ...props
}: IRegisterHeader) => {
  const currentStep = useSelector(selectCurrentStep);
  const dispatch = useAppDispatch();
  const handleBack = () => {
    dispatch(setStep(stepSize));
  };
  return (
    <div className="auth__right-info">
      {currentStep !== 0 && isBack && (
        <div className="auth__decrement">
          <button onClick={handleBack} className="header__greeting">
            <img src={back} alt="" />
            <span style={{ marginLeft: '10px' }}>Назад</span>
          </button>
        </div>
      )}
      {step > 0 && <div className="auth__right-step">{step}/5</div>}
      <div className="auth__right-title">{title}</div>
      <p className="auth__right-description">{description}</p>
    </div>
  );
};

export const RegistrationStepCompanyData = () => {
  const newLegalEntities = useAppSelector(selectLegalEntities);

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(step1Schema),
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phones',
  });

  useEffect(() => {
    reset({
      name: newLegalEntities?.[0]?.name,
      inn: newLegalEntities?.[0]?.inn,
      email: newLegalEntities?.[0]?.email,
      online_documents: newLegalEntities?.[0]?.online_documents || false,
      phones:
        newLegalEntities?.[0]?.phones?.map((phone) => ({
          phone: formatPhoneNumber(phone),
        })).length > 0
          ? newLegalEntities?.[0]?.phones?.map((phone) => ({
              phone: formatPhoneNumber(phone),
            }))
          : [{ phone: '' }],
    });
  }, [newLegalEntities, reset]);

  const onSubmit = (data: any) => {
    let phones = data.phones.map((phone: any) => {
      return phone.phone;
    });
    if (data.phoneInput) {
      phones.push(data.phoneInput);
    }
    const { phoneInput, ...rest } = data;
    dispatch(addCompanyPartialData({ ...rest, phones }));
  };
  return (
    <>
      <RegisterHeader
        step={1}
        isBack={true}
        stepSize={0}
        title="Данные компании"
        description="Заполните регистрационные данные. Это займет пару минут."
      />
      <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          inputName="name"
          id="name"
          label="Имя"
          register={register}
          errors={errors}
        />
        <InputField
          inputName="inn"
          id="inn"
          label="ИНН"
          register={register}
          errors={errors}
        />
        <InputField
          inputName="email"
          id="email"
          label="Email"
          register={register}
          errors={errors}
        />
        <div className="auth__extra-phones">
          <div className="auth__phone-items">
            <div className="auth__phone-header">
              <p>Номера телефонов</p>
            </div>
            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <div className="auth__phone-header">
                    <p className="error-message">
                      {errors?.phones?.root?.message}
                    </p>
                    <p className="error-message">{errors?.phones?.message}</p>
                  </div>
                  <div className="auth__phone-item">
                    <Controller
                      name={`phones.${index}.phone`}
                      control={control}
                      defaultValue={item.phone} //
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactInputMask
                          mask="+7 (999) 999-99-99"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          id={`phone-${index}`}
                        >
                          <InputField
                            inputName={`phones[${index}].phone`}
                            ref={ref}
                            errors={errors?.phones?.[index]?.phone?.message}
                            deleteIconHandler={
                              index > 0
                                ? (e) => {
                                    e.preventDefault();
                                    remove(index);
                                  }
                                : undefined
                            }
                          />
                        </ReactInputMask>
                      )}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {fields.length >= 3 ? null : (
          <div
            className="auth__add-phone"
            onClick={(e) => {
              e.preventDefault();
              append({ phone: '' });
            }}
          >
            <img
              src={redPlusCircle}
              alt="Add phone"
              className="auth__add-phone-icon"
            />
            <span className="auth__add-phone-text">Добавить номер</span>
          </div>
        )}
        <div className="auth__online-doc-checkbox-wrapper">
          <input
            className="auth__online-doc-checkbox"
            type="checkbox"
            id="online_documents"
            {...register('online_documents')}
          />
          <p className="auth__online-doc-checkbox-text">
            Отправить документы онлайн
          </p>
        </div>
        <div className="auth__submit-button">
          <Button type={'submit'}>Продолжить</Button>
        </div>
      </form>
    </>
  );
};
