import { companiesApi } from '../../api/services/companies.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Company } from '../../types/interfaces';
import { setErrorNotification, setNotification } from '../notifications';
import { updateMyCompany } from '../auth/reducer';

export const fetchAllCompanies = createAsyncThunk(
  'companies/fetchAll',
  async (_, { dispatch }) => {
    try {
      const response = await companiesApi.getAllCompanies();
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при получении списка компаний.'));
      throw error;
    }
  }
);

export const fetchCompanyById = createAsyncThunk(
  'companies/fetchById',
  async (id: number) => {
    const response = await companiesApi.getCompanyById(id);
    return response.data;
  }
);

export const createCompany = createAsyncThunk(
  'companies/create',
  async (data: Company, { dispatch }) => {
    try {
      const response = await companiesApi.createCompany(data);
      dispatch(setNotification('Компания успешно добавлена!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении компании.'));
      throw error;
    }
  }
);

export const updateCompany = createAsyncThunk(
  'companies/update',
  async ({ id, data }: { id: number; data: Company }, { dispatch }) => {
    try {
      const response = await companiesApi.updateCompany(id, data);
      dispatch(setNotification('Компания успешно обновлена!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении компании.'));
      throw error;
    }
  }
);

export const partialUpdateCompany = createAsyncThunk(
  'companies/partialUpdate',
  async ({ id, data }: { id: number; data: Partial<Company> | FormData }) => {
    const response = await companiesApi.partialUpdateCompany(id, data);
    return response.data;
  }
);

export const partialUpdateMyCompany = createAsyncThunk(
  'companies/partialUpdateMyCompany',
  async (
    {
      id,
      data,
      type,
    }: { id: number; data: Partial<Company> | FormData; type?: string },
    { dispatch }: { dispatch: any }
  ): Promise<Company> => {
    try {
      const response = await companiesApi.partialUpdateCompany(id, data, type);
      dispatch(setNotification('Компания успешно обновлена!'));
      dispatch(updateMyCompany(response.data));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении компании.'));
      throw error;
    }
  }
);

export const deleteCompany = createAsyncThunk(
  'companies/delete',
  async (id: number) => {
    await companiesApi.deleteCompany(id);
    return id;
  }
);
