import { lazy } from 'react';

const Profile = lazy(() => import('../../pages/Profile/Profile'));
export const profile_routes = [
  {
    path: 'profile',
    element: (
      // <ProtectedRoute
      // redirectTo='/'
      // action='view'
      // subject={'me'}
      // children={
      <Profile />
    ),
    // }
    // />
    loader: () => {
      return (document.title = 'Профиль');
    },
  },
];
