/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from 'react-router-dom';
import Sidebar from '../../components/layout/Sidebar/Sidebar';
import { selectItemsStatus } from '../../store/items/reducer';
import Header from '../../components/layout/Header/Header';
import './Area.scss';
import { loadRememberMeData } from '../../utils/hooks/rememberMeData';
import { selectAuthStatus, selectUser } from '../../store/auth/reducer';
import { AppDispatch } from '../../store/store';
import { defineAbilitiesFor } from '../../utils/hooks/useAppAbility';
import { AbilityContext, Can } from '../../utils/context/can';
import { User } from '../../types/interfaces';
import { getMe } from '../../store/auth/actions';
import NotFound from '../NotFound/NotFound';
import { chooseCompany } from '../../store/companies/reducer';

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('ErrorBoundary :>> ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <NotFound isError={true} />;
    }

    return this.props.children;
  }
}

type ContextUser = {
  me: User;
};

const Area = () => {
  const user = useSelector(selectUser);
  let rememberMeData = loadRememberMeData();

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (user.company) dispatch(chooseCompany(user.company));
  }, [user, dispatch]);
  const status = useSelector(selectAuthStatus);
  const ability = defineAbilitiesFor(user);
  useEffect(() => {
    if (status !== 'succeeded') {
      dispatch(getMe());
    }
  }, [dispatch]);

  const itemsLoading = useSelector(selectItemsStatus);

  const location = useLocation();

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/catalog' && itemsLoading !== 'loading') {
        if (scrollableDivRef.current) {
          sessionStorage.setItem(
            'contentScrollPosition',
            (scrollableDivRef.current as HTMLDivElement).scrollTop.toString()
          );
        }
      }
    };

    if (itemsLoading !== 'loading' && location.pathname === '/catalog') {
      setTimeout(() => {
        if (scrollableDivRef.current) {
          (scrollableDivRef.current as HTMLDivElement).scrollTop = parseInt(
            sessionStorage.getItem('contentScrollPosition') || '0',
            10
          );
        }
      });
    }
    if (location.pathname !== '/catalog') {
      if (scrollableDivRef.current) {
        (scrollableDivRef.current as HTMLDivElement).scrollTop = 0;
      }
    }

    if (
      scrollableDivRef.current &&
      location.pathname === '/catalog' &&
      itemsLoading !== 'loading'
    ) {
      (scrollableDivRef.current as HTMLDivElement)!.addEventListener(
        'scroll',
        handleScroll
      );
    }

    return () => {
      if (scrollableDivRef.current) {
        (scrollableDivRef.current as HTMLDivElement)!.removeEventListener(
          'scroll',
          handleScroll
        );
      }
    };
  }, [itemsLoading, location.pathname]);

  return (
    <AbilityContext.Provider value={ability}>
      <ErrorBoundary>
        {!rememberMeData ? (
          <Navigate to="/login" />
        ) : (
          <div className="area">
            <Sidebar hasCompany={Boolean(user.company)} />
            <div ref={scrollableDivRef} className="content">
              <Header me={{ ...user }} />
              <Outlet context={{ me: user as User } satisfies ContextUser} />
            </div>
          </div>
        )}
      </ErrorBoundary>
    </AbilityContext.Provider>
  );
};
export function useMe() {
  return useOutletContext<ContextUser>();
}
export default Area;
