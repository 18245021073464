import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { addItemsToCart } from '../../../store/cart/actions';
import { useAppDispatch } from '../../../store/store';
import { OrderItem } from '../../../types/interfaces';

export function RepeatOrderModal({ isOpen, onClose, order }: any) {
  const dispatch = useAppDispatch();
  const [productQuantities, setProductQuantities] = useState(() =>
    order.items.reduce((acc: Record<string, number>, item: OrderItem) => {
      acc[item.item] = item.quantity;
      return acc;
    }, {})
  );

  const handleQuantityChange = (item: number, value: number) => {
    setProductQuantities((prev: Record<string, number>) => ({
      ...prev,
      [item]: value,
    }));
  };

  const handleAddToCart = () => {
    const products = order.items.map((item: OrderItem) => ({
      id: item.item,
      quantity: productQuantities[item.item],
      price: item.price,
    }));
    dispatch(addItemsToCart({ items: products }));
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Повтор заказа</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Наименование товара</Th>
                <Th>Количество</Th>
              </Tr>
            </Thead>
            <Tbody>
              {order.items.map((item: any) => (
                <Tr key={item.item}>
                  <Td color={item.cancel ? 'red.400' : ''}>{item.item_name}</Td>
                  <Td>
                    <Input
                      type="number"
                      value={productQuantities[item.item]}
                      onChange={(e) =>
                        handleQuantityChange(item.item, +e.target.value)
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={handleAddToCart}>
            В корзину
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
