import { yupResolver } from '@hookform/resolvers/yup';
import { step0Schema } from '../../../utils/validators/registrationSchema';
import { useForm } from 'react-hook-form';
import {
  selectCredentials,
  selectCurrentStep,
} from '../../../store/registration/reducer';
import { useEffect } from 'react';
import Button from '../../../components/common/Button/Button';
import InputField from '../../../components/common/InputField/InputField';
import { useParams } from 'react-router-dom';
import { RegisterHeader } from './RegistrationStepCompanyData';
import CustomModal from '../../../components/common/CustomModal/CustomModal';
import { fetchInviteById } from '../../../store/invites/actions';
import {
  checkUsernameAvailable,
  registerEmployee,
} from '../../../store/registration/actions';
import ReactInputMask from 'react-input-mask';
import {
  selectCurrentInvite,
  selectInviteStatus,
} from '../../../store/invites/reducer';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { authApi } from '../../../api/services/auth.service';
import {
  setErrorNotification,
  setNotification,
} from '../../../store/notifications';

export const RegistrationStepCredentials = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectInviteStatus);
  const step = useAppSelector(selectCurrentStep);
  const credentialData = useAppSelector(selectCredentials);
  const invite = useAppSelector(selectCurrentInvite);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(step0Schema),
    shouldUnregister: false,
    defaultValues: {
      email: '',
      username: '',
      password: '',
      terms: false,
      first_name: '',
      last_name: '',
      mobile_phone: '',
    },
  });

  useEffect(() => {
    reset(credentialData);
  }, [credentialData, reset]);

  useEffect(() => {
    if (status !== 'succeeded') {
      dispatch(fetchInviteById(id?.toString() || ''));
    }
  }, [dispatch, status, id]);

  const onSubmit = async (data: any) => {
    if (invite.type === 'employee') {
      dispatch(registerEmployee({ ...data, invite: invite.id }));
      return;
    }
    dispatch(checkUsernameAvailable({ ...data, ...invite }));
  };

  const handleUsernameBlur = async (event: any) => {
    const username = event.target.value;
    if (username) {
      authApi.checkUsernameAvailable(username).then((response) => {
        if (response.data) {
          dispatch(setNotification('Логин не занят!'));
        } else if (!response.data) {
          dispatch(setErrorNotification('Логин занят!'));
          setError('username', {
            message: 'Логин занят!',
          });
        }
      });
    }
  };

  return (
    <>
      <RegisterHeader step={step} title="Регистрационные данные" />
      <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          inputName="first_name"
          id="first_name"
          label="Имя"
          register={register}
          errors={errors}
        />
        <InputField
          inputName="last_name"
          id="last_name"
          label="Фамилия"
          register={register}
          errors={errors}
        />
        <InputField
          inputName="username"
          id="username"
          label="Придумайте логин"
          register={register}
          errors={errors}
          onBlur={handleUsernameBlur}
        />
        <InputField
          inputName="email"
          id="email"
          label="Email"
          register={register}
          errors={errors}
        />
        <InputField
          inputName="password"
          type="password"
          id="password"
          label="Придумайте пароль"
          register={register}
          errors={errors}
        />
        <InputField
          as={ReactInputMask}
          mask={'+7 (9**) ***-**-**'}
          id="mobile_phone"
          inputName="mobile_phone"
          label="Телефон"
          errors={errors}
          register={register}
        />
        {errors.terms && (
          <span className="auth__online-doc-checkbox-error">
            {errors.terms.message}
          </span>
        )}
        <div className="auth__online-doc-checkbox-wrapper">
          <input
            className="auth__online-doc-checkbox"
            type="checkbox"
            id="terms"
            {...register('terms')}
          />

          <p className="auth__online-doc-checkbox-text">
            Согласен с{' '}
            <CustomModal
              size="xxl"
              openButton={
                <span style={{ cursor: 'pointer', color: 'blue' }}>
                  политикой обработки персональных данных *
                </span>
              }
            >
              <div className="modal_terms">
                <div className="modal_terms_title">
                  Согласие на обработку персональных данных
                </div>
                <div className="modal_terms_content">
                  <div className="modal_terms_text">
                    Пользователь, оставляя заявку (под заявкой понимается любая
                    форма на интернет- сайте, поддерживающая ввод персональных
                    данных пользователя) на интернет-сайте www.lk.fmeter.ru,
                    принимает настоящее Согласие на обработку персональных
                    данных (далее – Согласие). Действуя свободно, своей волей и
                    в своем интересе, а также подтверждая свою дееспособность,
                    Пользователь дает свое согласие ООО «Ойл» (ИНН 1655164731),
                    которое расположено по адресу 420000, Татарстан Республика,
                    ул. Дементьева, д. 2Б, на обработку своих персональных
                    данных со следующими условиями:
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">1.</span>{' '}
                    Данное Согласие дается на обработку персональных данных, как
                    без использования средств автоматизации, так и с их
                    использованием.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">2.</span>{' '}
                    Согласие дается на обработку следующих моих персональных
                    данных: Персональные данные, не являющиеся специальными или
                    биометрическими: номера контактных телефонов; адреса
                    электронной почты; фамилия, имя и отчество пользователя;
                    адрес доставки оборудования; пользовательские данные
                    (сведения о местоположении; тип и версия ОС; тип и версия
                    Браузера; тип устройства и разрешение его экрана; источник,
                    откуда пришел на сайт пользователь; с какого сайта или по
                    какой рекламе; язык ОС и Браузера; какие страницы открывает
                    и на какие кнопки нажимает пользователь; ip-адрес.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">3.</span>{' '}
                    Персональные данные не является общедоступными.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">4.</span> Цель
                    обработки персональных данных: исполнение договора, а
                    именно: обработка входящих запросов физических лиц с целью
                    оказания консультирования и/или продажи оборудования.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">5.</span>{' '}
                    Основанием для обработки персональных данных является: ст.
                    24 Конституции Российской Федерации; ст. 6 Федерального
                    закона №152 – ФЗ «О персональных данных; Устав ООО «Ойл»;
                    настоящее согласие на обработку персональных данных.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">6.</span> В
                    ходе обработки с персональными данными будут совершены
                    следующие действия: сбор; запись; систематизация;
                    накопление; хранение; уточнение (обновление, изменение),
                    извлечение; использование; передача (распространение,
                    предоставление, доступ); блокирование; удаление;
                    уничтожение.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">7.</span>{' '}
                    Обработка персональных данных может быть прекращена по
                    запросу субъекта персональных данных. Хранение персональных
                    данных, зафиксированных на бумажных носителях осуществляется
                    согласно Федеральному закону №125 – ФЗ Об архивном деле в
                    Российской Федерации и иным нормативно правовым актам в
                    области архивного дела и архивного хранения.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">8.</span>{' '}
                    Согласие может быть отозвано субъектом персональных данных
                    или его представителем путём направления письменного
                    заявления ООО «Ойл» или его представителю по адресу,
                    указанному в начале данного Согласия.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">9.</span> В
                    случае отзыва субъектом персональных данных или его
                    представителем согласия на обработку персональных данных ООО
                    «Ойл» вправе продолжить обработку персональных данных без
                    согласия субъекта персональных данных при наличии оснований,
                    указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи
                    10 и части 2 статьи 11 Федерального закона №152 – ФЗ О
                    персональных данных от 27. 07. 2006 г.
                    <br />
                    <br />
                    <span className="modal_terms_text_paragraph">10.</span>{' '}
                    Настоящее Согласие действует все время до момента
                    прекращения обработки персональных данных, указанных в п.7 и
                    п. 8 данного Согласия.
                  </div>
                </div>
              </div>
            </CustomModal>
          </p>
        </div>
        <div className="auth__submit-button">
          <Button type={'submit'} status={status}>
            Продолжить
          </Button>
        </div>
      </form>
    </>
  );
};
