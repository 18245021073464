import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { Card } from '../../types/interfaces';

export const itemsApi = {
  getItems: (): Promise<AxiosResponse<Card[]>> => {
    return apiClient.get<Card[]>('cards/');
  },

  getItem: (id: number): Promise<AxiosResponse<Card>> => {
    return apiClient.get('cards/' + id);
  },

  getPrices: (body: any) => {
    return apiClient.post('services/get_prices', body);
  },
  getPriceList: () => {
    return apiClient.get(`/prices/`);
  },
  updatePrice: (id: number, body: any) => {
    return apiClient.post(!id ? '/prices/' : `/prices/?price_id=${id}`, body);
  },
  deletePrice: (id: number) => {
    return apiClient.delete(`/prices/?price_id=${id}`);
  },

  setIsVisibleItem: (id: number, isVisible: boolean) => {
    return apiClient.put(`items/${id}/visibility/customer`, {
      is_visible: isVisible,
    });
  },

  createItem: (body: any) => {
    return apiClient.post('items/', body);
  },

  updateItem: (itemId: number, body: any) => {
    return apiClient.patch(`cards/${itemId}/`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateItemImage(itemId: number, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return apiClient.post(`items/${itemId}/photo`, formData, config);
  },
  deleteItem: (itemId: number) => {
    return apiClient.delete(`items/${itemId}`);
  },
  deleteItemPhoto: (item_id: number) => {
    return apiClient.delete(`items/${item_id}/photo`);
  },
  getCart: () => {
    return apiClient.get(`/cart/`);
  },

  addItemToCart: (body: any) => {
    return apiClient.post(`/cart/`, body);
  },

  deleteItemFromCart: (itemId: number) => {
    return apiClient.delete(`/cart/${itemId}`);
  },
  createOrder: (body: any) => {
    return apiClient.post(`integrators/orders/`, body);
  },
  cancelOrder: (order_id: number, body: any) => {
    return apiClient.post(`integrators/orders/${order_id}/cancel`, body);
  },

  getDirectories: () => {
    return apiClient.get('directories/');
  },
  getDirectory: (name: string) => {
    return apiClient.get(`directories/?name=${name}&with_items=true`);
  },

  deleteDirectory: (name: string) => {
    return apiClient.delete(`directories/?name=${name}`);
  },

  addDirectory: (name: string, value: any) => {
    return apiClient.post(`directories/?name=${name}`, value);
  },

  uploadFile: (file: any, category: string) => {
    return apiClient.post(
      `files/upload?category=${category || 'price'}`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  removeFile: (file_url: string, category: string) => {
    return apiClient.post('files/remove', {
      file_url,
      category,
    });
  },
};
