import styles from './DevicesPagination.module.scss';
import prevIcon from '../../../assets/images/prev.svg';
import nextIcon from '../../../assets/images/next.svg';

interface DevicesPaginationProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isEmpty: boolean;
}

const DevicesPagination = ({
  currentPage,
  setCurrentPage,
  isEmpty,
}: DevicesPaginationProps) => {
  return (
    <div className={styles['pagination']}>
      {currentPage !== 1 && (
        <button
          onClick={() => {
            setCurrentPage((prevPage) => prevPage - 1);
          }}
        >
          <img src={prevIcon} alt="previous page" />
        </button>
      )}

      <button className={styles['pagination__number']}>{currentPage}</button>

      {!isEmpty && (
        <button
          onClick={() => {
            setCurrentPage((prevPage) => prevPage + 1);
          }}
        >
          <img src={nextIcon} alt="next page" />
        </button>
      )}
    </div>
  );
};

export default DevicesPagination;
