import React, { useState } from 'react';
import styles from './CartConfirm.module.scss';
import ButtonMy from '../../common/Button/Button';
import { Controller, UseFormResetField, UseFormTrigger } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { CartFieldWrp } from './components/CartFieldWrp';
import { CartSelect } from './components/CartSelect';
import { CartInput } from './components/CartInput';
import TextareaField from '../../common/TextareaField/TextareaField';
import { Contact, Employee, FetchStatusType } from '../../../types/interfaces';
import { useMe } from '../../../pages/Area/Area';
import { useEmployees } from '../../../utils/hooks/useEmployees';
interface ICartStepContactDetails {
  setValue: any;
  control: any;
  watch: any;
  cartErrors: any;
  register: any;
  setStepOnDelivery: any;
  setYourCartStep: any;
  isValid: boolean;
  status: FetchStatusType;
  reset: UseFormResetField<any>;
  trigger: UseFormTrigger<any>;
}

interface ISelect {
  label: string;
  value: string | number;
}
export function CartStepContactDetails({
  setValue,
  control,
  watch,
  cartErrors,
  register,
  setStepOnDelivery,
  setYourCartStep,
  isValid,
  status,
  trigger,
}: ICartStepContactDetails) {
  const { me } = useMe();
  const employees = useEmployees();
  const [contactChooseType, setContactChooseType] = useState<string>('select');
  function selectLegalEntityAndAddresses(item: ISelect) {
    setValue('legal_entity', {
      label: item.label,
      value: item.value,
    });
  }

  function getPersonInfo(person: Contact | Employee) {
    const isContact = 'name' in person;
    if (isContact) {
      const fullName = `${person?.surname} ${person?.name} ${person?.patronymic}`;
      const phone = person.personal_phone;
      const mail = person.mail;
      return { fullName, phone, mail };
    }

    const fullName = `${person?.last_name} ${person?.first_name} ${person?.patronymic}`;
    const phone = person.mobile_phone;
    const mail = person.email;
    return { fullName, phone, mail };
  }

  function isContact(person: any) {
    if ('value' in person) {
      return typeof person.value === 'string';
    }
    return typeof person.id === 'string';
  }
  function getOptionsForContact() {
    const persons = [...employees, ...(me.company?.contacts || [])] satisfies (
      | Contact
      | Employee
    )[];
    return persons.map((person) => {
      const { fullName } = getPersonInfo(person);
      return {
        label: fullName,
        value: person.id,
      };
    });
  }
  function selectContact(person: ISelect) {
    const fullPerson = isContact(person)
      ? me.company?.contacts.find((contact) => contact.id === person.value)
      : employees.find((employee) => employee.id === person.value);
    if (!fullPerson) {
      return;
    }
    const { fullName, mail, phone } = getPersonInfo(fullPerson);
    setValue('contact_full_name', fullName);
    setValue('contact_phone', phone);
    setValue('contact_email', mail);
    trigger();
  }
  
  const contactSelectError = 
  (cartErrors?.contact_full_name || cartErrors?.contact_phone) ?
   'Выберите контакт из списка или заполните данные вручную' : ''
  return (
    <>
      <div className={styles['cart-confirm__body']}>
        <div className={styles['cart-confirm__column']}>
          <CartFieldWrp title={'Юридическое лицо'}>
            <CartSelect
              onChange={selectLegalEntityAndAddresses}
              value={watch('legal_entity')}
              options={
                me?.company?.legal_entities?.map((i) => ({
                  label: i.name,
                  value: i.id,
                })) || []
              }
              placeholder="Выберите юридическое лицо*"
            />
          </CartFieldWrp>
          <CartFieldWrp title="Выбор контактного лица">
            <div className={styles['contact-choose']}>
              <div className={styles['contact-choose__block']}>
                <input
                  type="radio"
                  name="contactEnterType"
                  id="select"
                  value="select"
                  onChange={(e) => setContactChooseType(e.target.value)}
                  checked={contactChooseType === 'select'}
                />
                <label htmlFor="select">Выбрать из существующих</label>
              </div>
              <div className={styles['contact-choose__block']}>
                <input
                  type="radio"
                  name="contactEnterType"
                  id="enter"
                  value="enter"
                  onChange={(e) => {
                    setContactChooseType(e.target.value);
                  }}
                  checked={contactChooseType === 'enter'}
                />
                <label htmlFor="enter">Ввести вручную</label>
              </div>
            </div>
          </CartFieldWrp>

          {contactChooseType === 'select' && (
            <CartFieldWrp title="Контактное лицо*" error={contactSelectError}>
              <CartSelect
                defaultValue={getOptionsForContact()?.[0] }
                value={{ label: watch('contact_full_name'), value: '' }}
                onChange={selectContact}
                options={getOptionsForContact() || []}
                placeholder="Выберите контакт"
              />
            </CartFieldWrp>
          )}

          {contactChooseType === 'enter' && (
            <>
              <CartFieldWrp>
                <CartInput
                  label="ФИО*"
                  inputName="contact_full_name"
                  id="contact_full_name"
                  register={register}
                  placeholder="Иванов Иван Иванович"
                  errors={cartErrors}
                />
              </CartFieldWrp>

              <CartFieldWrp>
                <Controller
                  name="contact_phone"
                  control={control}
                  render={({ field }) => (
                    <CartInput
                      {...field}
                      as={ReactInputMask}
                      mask={'+7 (***) ***-**-**'}
                      id="contact_phone"
                      inputName="contact_phone"
                      label="Телефон*"
                      errors={cartErrors}
                      placeholder="8 (999) 999-99-99"
                      register={register}
                    />
                  )}
                />
              </CartFieldWrp>

              <CartFieldWrp>
                <CartInput
                  label="Электронная почта"
                  inputName="contact_email"
                  id="contact_email"
                  register={register}
                  errors={cartErrors}
                  placeholder="3nUeh@example.com"
                />
              </CartFieldWrp>
            </>
          )}
        </div>
        <div className={styles['cart-confirm__column']}>
          <CartFieldWrp>
            <TextareaField
              currentVal={watch('comment')}
              maxLength={2000}
              id="comment"
              name="comment"
              label="Комментарий к заказу"
              errors={cartErrors}
              register={register}
              placeholder="Ваш комментарий"
            />
          </CartFieldWrp>
        </div>
      </div>
      <div className={styles['cart-confirm__footer']}>
        <ButtonMy
          onClick={(e: React.MouseEvent): null | void => {
            e.preventDefault();
            e.stopPropagation();
            setYourCartStep();
          }}
          type="submit"
          className={styles['red-button']}
          status={status}
          isWhite={true}
        >
          Назад
        </ButtonMy>
        <ButtonMy
          status={status}
          isWhite={true}
          type="submit"
          className={styles['red-button']}
        >
          Оформить без доставки
        </ButtonMy>
        <ButtonMy
          onClick={(e: React.MouseEvent): null | void => {
            trigger();
            if (isValid) {
              e.preventDefault();
              e.stopPropagation();
              setStepOnDelivery();
            }
          }}
          className={styles['red-button']}
          status={status}
        >
          Продолжить
        </ButtonMy>
      </div>
    </>
  );
}
