import { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  selectIsAuthenticated,
  selectAuthStatus,
  selectAuthError,
} from '../../store/auth/reducer';
import { loginSchema } from '../../utils/validators/registrationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import LoadingWrapper from '../../components/common/Spinners/LoadingWrapper';
import InputField from '../../components/common/InputField/InputField';
import Button from '../../components/common/Button/Button';
import AuthLayout from '../../components/layout/AccessLayout/AccessLayout';
import { loadRememberMeData } from '../../utils/hooks/rememberMeData';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { login } from '../../store/auth/actions';

import './Login.scss';
import { Flex } from '@chakra-ui/react';

const Login = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(loginSchema),
    shouldUnregister: false,
    defaultValues: {
      password: '',
      username: '',
      rememberMe: false,
    },
  });

  const userStorage = loadRememberMeData();

  const authStatus = useAppSelector(selectAuthStatus);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const error = useAppSelector(selectAuthError);

  useEffect(() => {
    document.title = 'Вход';
  }, []);

  function onSubmit(data: any) {
    dispatch(login(data));
  }

  return (
    <LoadingWrapper status={authStatus}>
      <AuthLayout
        leftContent={
          <section className="auth__text-section">
            <h2 className="auth__text">Добро пожаловать!</h2>
            <div className="auth__divider"></div>
            <div className="auth__description">
              Заполните регистрационные данные, чтобы перейти в личный кабинет
            </div>
          </section>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <h2 className="form__title">Вход</h2>
          <div className="form__description">
            Пожалуйста, введите информацию в поля ниже, чтобы войти в личный
            кабинет
          </div>

          <div className="form__row">
            <InputField
              name="username"
              inputName="username"
              id="username"
              label="Email или Логин"
              placeholder="Введите Email или Логин"
              register={register}
              errors={errors}
            />
          </div>
          <div className="form__row">
            <InputField
              name="password"
              inputName="password"
              type="password"
              placeholder="Введите пароль"
              id="password"
              label="Пароль"
              register={register}
              errors={errors}
            />
          </div>
          <div className="form__options">
            <label className="form__remember">
              <input
                className="form__checkbox"
                type="checkbox"
                value="false"
                {...register('rememberMe')}
              />
              <span className="form__remember-text">Запомнить меня</span>
            </label>

            <Link
              to={'/password_reset/reset'}
              style={{ cursor: 'pointer', color: 'blue' }}
              className="form__forgot-text"
            >
              Забыли пароль?
            </Link>
          </div>
          {error && (
            <p
              style={{
                color: '#E3282A',
                fontSize: '14px',
                marginTop: '10px',
              }}
            >
              {error}
            </p>
          )}
          {userStorage && isAuth && <Navigate to="/" replace={true} />}
          <Flex justifyContent={'space-between'} columnGap={2}>
          <Button type="submit" disabled={!isValid}>
            Войти
          </Button>
          </Flex>
          <div className="form__description">
            Если вы хотите зарегистрироваться в Личном Кабинете, вы можете{' '}
            <span>
              <Link
                to={'/request_access'}
                style={{ cursor: 'pointer', color: 'blue' }}
                className="form__forgot-text"
              >
                получить доступ
              </Link>
            </span>
            , оставив заявку нашему менеджеру.
          </div>
        </form>
      </AuthLayout>
    </LoadingWrapper>
  );
};

export default Login;
