import { useNavigate } from 'react-router-dom';
import styles from './Devices.module.scss';
import DevicesPagination from './DevicesPagination/DevicesPaginataion';
import getTime from './utils/getTime';
import hexConverter from './utils/hexConverter';

const statusesTranslate = {
  'Work in progress': 'В работе',
  Postponed: 'Отложено',
  Resolved: 'Решено',
  Closed: 'Закрыто',
};
export default function IssuesTable({
  issues,
  currentPage,
  setCurrentPage,
  isEmpty,
}) {
  const navigate = useNavigate();
  return (
    <>
      <DevicesPagination
        totalPages={10}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isEmpty={isEmpty}
      />
      <div className={styles['issues']}>
        <div className={styles['issues__title']}>
          <p>Номер заявки</p>
          <p>Поступило</p>
          <p>Дедлайн</p>
          <p>Статус</p>
        </div>
        {issues?.map((issue) => (
          <div
            className={styles['issues__item']}
            key={issue.id}
            onClick={() => navigate(`/issues/${issue.id}`)}
          >
            <p>{issue.id}</p>
            <p>{getTime(issue.created_at, false, false)}</p>
            <p>{getTime(issue.deadline_at, false, false)}</p>
            <p
              style={{
                color: issue.status.color,
                backgroundColor: hexConverter(issue.status.color),
              }}
            >
              {statusesTranslate[issue.status.name]}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}
