import { useEffect, useState } from 'react';
import MainLayout from '../../components/layout/MainLayout/MainLayout';
import DevicesTable from './DevicesTable';
import DevicesFilters from './DevicesFilters';
import IssuesFilters from './IssuesFilters';
import IssuesTable from './IssuesTable';
import LoadingWrapper from '../../components/common/Spinners/LoadingWrapper';
import Tabs from '../../components/common/Tabs/Tabs';
import styles from './Devices.module.scss';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getIssues } from '../../store/issues/actions';
import NotFound from '../NotFound/NotFound';
import { resetCurrentIssue } from '../../store/issues/reducer';
import { Nullable } from '../../types/interfaces';

export interface IFilters {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  status: { value: string; label: string } | string;
  //query: string;
  pageNumber: number;
}
const initialFilters = {
  startDate: null,
  endDate: null,
  status: '',
  // query: '',
  pageNumber: 1,
};

export default function Devices() {
  const [filters, setFilters] = useState<IFilters>(initialFilters);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();
  const issues = useAppSelector((state) => state.issues.issues);
  const isNextPageEmpty = useAppSelector(
    (state) => state.issues.isNextPageEmpty
  );
  const filteredIssues = useAppSelector((state) => state.issues.filteredIssues);
  const issuesStatus = useAppSelector((state) => state.issues.listFetchStatus);
  const isFiltersApplied =
    JSON.stringify(filters) !== JSON.stringify(initialFilters);

  useEffect(() => {
    dispatch(getIssues({ filters, page: currentPage })); // pagination triggers this
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (issuesStatus === 'idle') {
      dispatch(getIssues({ filters, page: 1 }));
    }
    dispatch(resetCurrentIssue());
  }, [issuesStatus, dispatch]);

  const onSubmit = () => {
    setCurrentPage(1);
    dispatch(getIssues({ filters, page: 1 }));
  };

  const onFiltersReset = () => {
    setCurrentPage(1);
    setFilters(initialFilters);
    dispatch(getIssues({ filters: initialFilters, page: 1 }));
  };

  // const models = getOptions(devices, 'model');
  return (
    <MainLayout>
      <MainLayout.Main>
        <Tabs variant="underline" initialActiveTab={1}>
          <div title="Мои устройства">
            {/* <div className={styles['filters']}>
              <DevicesFilters />

               
            </div>
            <DevicesTable
              key={1}
              //devices={devices}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            /> */}
            <NotFound isAtWork={true} />
          </div>
          <div title="Устройства на диагностике">
            <div className={styles['filters']}>
              <IssuesFilters
                filters={filters}
                setFilters={setFilters}
                onSearch={onSubmit}
                onReset={onFiltersReset}
                isFiltersApplied={isFiltersApplied}
              />
            </div>

            <LoadingWrapper status={issuesStatus}>
              <IssuesTable
                key={2}
                issues={filteredIssues ?? issues}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isEmpty={isNextPageEmpty}
              />
            </LoadingWrapper>
          </div>
        </Tabs>
      </MainLayout.Main>
    </MainLayout>
  );
}
