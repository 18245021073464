import React, { useState, useMemo } from 'react';
import styles from './Tabs.module.scss';

type TabProps = {
  title: string;
  children: React.ReactNode;
};

type TabsProps = {
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
  initialActiveTab?: number;
  variant?: 'default' | 'underline' | 'booking';
  className?: string;
  contentAfterTabs?: () => React.ReactNode;
};

const Tabs: React.FC<TabsProps> = ({
  children,
  initialActiveTab = 0,
  variant = 'default',
  className = '',
  contentAfterTabs = null,
}) => {
  const [activeTab, setActiveTab] = useState<number>(initialActiveTab);

  const filteredChildren = useMemo(
    () =>
      React.Children.toArray(children).filter(
        (child: any) => child.props.title
      ),
    [children]
  );

  return (
    <div className={styles['tabs']}>
      <ul className={styles['tabsList']}>
        {filteredChildren.map((child: any, index) => (
          <li
            key={index}
            className={`${styles.tabItem} ${styles[variant]} ${
              activeTab === index ? styles.active : ''
            }`}
            onClick={() => setActiveTab(index)}
          >
            {child.props.title}
          </li>
        ))}
      </ul>
      {contentAfterTabs && contentAfterTabs()}
      <div className={[styles.tabsContent, className].join(' ')}>
        {filteredChildren.map((child: any, index) =>
          activeTab === index ? child.props.children : undefined
        )}
      </div>
    </div>
  );
};

export default Tabs;
