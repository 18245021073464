import { createAsyncThunk } from '@reduxjs/toolkit';
import { contactsApi } from '../../api/services/contacts.service';
import { Contact } from '../../types/interfaces';
import { setErrorNotification, setNotification } from '../notifications';
type PatchUserRequest = Partial<Contact> & Pick<Contact, 'id'>;

export const fetchAllContacts = createAsyncThunk(
  'contacts/fetchAll',
  async (params: object) => {
    const response = await contactsApi.getAllContacts(params);
    return response.data;
  }
);

export const fetchContactById = createAsyncThunk(
  'contacts/fetchById',
  async (id: string | undefined) => {
    const response = await contactsApi.getContactById(id);
    return response.data;
  }
);

export const createContact = createAsyncThunk(
  'contacts/create',
  async (data: Contact, { dispatch, rejectWithValue }) => {
    try {
      const response = await contactsApi.createContact(data);
      dispatch(setNotification('Контакт успешно создан!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении контакта'));
      return rejectWithValue(error);
    }
  }
);

export const updateContact = createAsyncThunk(
  'contacts/update',
  async (data: Contact, { dispatch, rejectWithValue }) => {
    try {
      const response = await contactsApi.updateContact(data.id, data);
      dispatch(setNotification('Контакт успешно обновлён!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении контакта'));
      return rejectWithValue(error);
    }
  }
);

export const partialUpdateContact = createAsyncThunk(
  'contacts/partialUpdate',
  async (data: PatchUserRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await contactsApi.partialUpdateContact(data.id, data);
      dispatch(setNotification('Контакт успешно обновлён!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при обновлении контакта'));
      return rejectWithValue(error);
    }
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/delete',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await contactsApi.deleteContact(id);
      dispatch(setNotification('Контакт успешно удалён!'));
      return id;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении контакта'));
      return rejectWithValue(error);
    }
  }
);
