import { useSelector } from 'react-redux';
import {
  selectAllItems,
  selectAnyRequestStatus,
} from '../../store/items/reducer';
import moment from 'moment';
import 'moment/locale/ru';
import MainLayout from '../../components/layout/MainLayout/MainLayout';
import { Box, Flex, Grid } from '@chakra-ui/react';
import styles from './Dashboard.module.scss';
import EmptyState from '../../components/common/EmptyState/EmptyState';
import {
  DashboardCatalogCard,
  DashboardUserItem,
  DashboardPriceItem,
  DashboardCartTable,
  DashboardOrderItem,
  DashboardCompanyItem,
} from './DashboardComponents';

import DashboardItem from './DashboardComponents';
import {
  selectAllOrders,
  selectOrdersStatus,
} from '../../store/orders/reducer';
import {
  selectCartWithCalculatedPrices,
  selectCartStatus,
} from '../../store/cart/reducer';
import { selectPrices } from '../../store/prices/reducer';
import { selectUsers, selectUsersStatus } from '../../store/users/reducer';
import {
  selectLegalEntities,
  selectLegalEntityStatus,
} from '../../store/legalEntities/reducer';
import useDashboardEffects from '../../utils/hooks/useDashboardEffects';
import { useAppSelector } from '../../store/store';
import { Can } from '../../utils/context/can';
moment.locale('ru');

const Dashboard = () => {
  const companies = useAppSelector(selectLegalEntities);
  const catalogItems = useAppSelector(selectAllItems);
  const orders = useAppSelector(selectAllOrders);
  const cart = useAppSelector(selectCartWithCalculatedPrices);
  const managers = useAppSelector(selectUsers);
  const prices = useAppSelector(selectPrices);
  const catalogStatus = useAppSelector((state) =>
    selectAnyRequestStatus(state, 'getItems')
  );
  const ordersStatus = useAppSelector(selectOrdersStatus);
  const getPriceListStatus = useAppSelector((state) =>
    selectAnyRequestStatus(state, 'getPriceList')
  );
  const fetchManagersStatus = useAppSelector(selectUsersStatus);
  const companiesStatus = useAppSelector(selectLegalEntityStatus);
  const cartStatus = useAppSelector(selectCartStatus);

  useDashboardEffects();

  const isExpanded =
    localStorage.getItem('isExpanded') &&
    localStorage.getItem('isExpanded') === 'true';

  return (
    <MainLayout>
      <Flex direction={{ base: 'column', md: 'row' }} wrap="wrap" gap={4}>
        <Can I="view" a="card">
          <DashboardItem
          minWidth='68%'
          flex={1}
            status={catalogStatus}
            title={'Каталог'}
            link={'/catalog'}
          >
            <Box display={'flex'} justifyContent={'center'} columnGap={4}>
              {catalogItems && catalogItems.length ? (
                catalogItems
                  .slice(0, 3)
                  .map((item) => (
                    <DashboardCatalogCard key={item.id} item={item} />
                  ))
              ) : (
                <EmptyState message="Товаров в каталоге нет" />
              )}
            </Box>
          </DashboardItem>
        </Can>
        <Can I="view" a="username">
          <DashboardItem
          minWidth='28%'
            className="dashboard_item_flex_end"
            status={fetchManagersStatus}
            title={'Менеджеры'}
            link={'/managers'}
          >
            {managers?.slice(0, 4).map((manager) => (
              <DashboardUserItem
                link={'/managers'}
                state={{ manager: manager, type: 'edit' }}
                item={manager}
              />
            ))}
          </DashboardItem>
        </Can>
        <Can I="view" a="pricelist">
          <DashboardItem
          minWidth='28%'
            status={getPriceListStatus}
            className={styles.dashboard_card__prices}
            title={'Прайсы'}
            link={'/prices'}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              columnGap={4}
              flexDirection={'column'}
            >
              {prices && prices.length ? (
                prices.slice(0, 3).map((item) => (
                  <DashboardPriceItem
                    key={item.id}
                    price={item}
                  />
                ))
              ) : (
                <EmptyState message="Прайсов не найдено" />
              )}
            </Box>
          </DashboardItem>
        </Can>
        <Can I='view' a='cart'>
          <DashboardItem status={cartStatus} title={'Корзина'} link={'/cart'}>
            <DashboardCartTable cart={cart} />
          </DashboardItem>
        </Can>
        <Can I="view" a="legalentity">
          <DashboardItem
            status={companiesStatus}
            title={'Компании'}
            link={'/companies'}
          >
            {companies?.slice(0, 5).map((legalEntity) => (
              <DashboardCompanyItem item={legalEntity} key={legalEntity.id} />
            ))}
          </DashboardItem>
        </Can>

        <Can I='view' a='order'>
          <DashboardItem status={ordersStatus} title={'Заказы'} link={'/orders'}>
            {orders && orders.length ? (
              orders
                .slice(0, 4)
                ?.map((order) => (
                  <DashboardOrderItem key={order.id} order={order} />
                ))
            ) : (
              <EmptyState message="Заказов не найдено" />
            )}
          </DashboardItem>
        </Can>
      </Flex>
    </MainLayout>
  );
};

export default Dashboard;
