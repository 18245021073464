import React from 'react';

interface NumberFormatterProps {
  className?: string;
  number: number;
}
const NumberFormatter = ({ className, number }: NumberFormatterProps) => {
  const formattedNumber = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return <span className={className}>{formattedNumber}</span>;
};

export default NumberFormatter;
