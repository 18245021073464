import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from '../../store/orders/actions';
import LoadingWrapper from '../../components/common/Spinners/LoadingWrapper';
import MainLayout from '../../components/layout/MainLayout/MainLayout';
import { CartConfirmStepBlock } from '../../components/common/CartConfirmStepBlock/CartConfirmStepBlock';
import styles from './Cart.module.scss';
import { CartStepContactDetails } from '../../components/modules/CartConfirm/CartStepContactDetails';
import {
  CartStepDeliveryMethods,
  formatAddress,
} from '../../components/modules/CartConfirm/CartStepDeliveryMethods';
import { CartStepYourCart } from '../../components/modules/CartConfirm/CartStepYourCart';
import {
  deliveryTypes,
  payerTypes,
  recipientTypes,
  selfDeliveryTypes,
} from '../../utils/constants/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import { cartConfirmSchema } from '../../utils/validators/cartConfirmSchema.js';
import { useForm } from 'react-hook-form';
import { CartAddAddressModal } from '../../components/modules/CartConfirm/components/CartAddAddressModal';
import EmptyState from '../../components/common/EmptyState/EmptyState';
import {
  selectCartStatus,
  selectCartWithCalculatedPrices,
} from '../../store/cart/reducer';
import { getCart } from '../../store/cart/actions';
import { AppDispatch } from '../../store/store';
import {
  Address,
  DeliveryTypeEnum,
  ICreateOrder,
} from '../../types/interfaces';
import { selectOrdersStatus } from '../../store/orders/reducer';
import { cartConfirmSteps } from './utils/constants';
import { useMe } from '../Area/Area';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

interface IContactFromStorage {
  contact_full_name: string;
  contact_phone: string;
  contact_email: string;
  courier_service?: string;
}

type CartConfirmSchema = yup.InferType<typeof cartConfirmSchema>;

function Cart() {
  const cartItems = useSelector(selectCartWithCalculatedPrices);
  const status = useSelector(selectCartStatus);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [itemsQuantity, setItemsQuantity] = useState(
    cartItems.reduce(
      (acc, item) => ({ ...acc, [item.guid]: item.quantity }),
      {}
    )
  );
  const contactStorage = localStorage.getItem('contact');
  const addressLocal = localStorage.getItem('addresses');
  const [addressFromStorage, setAddressFromStorage] = useState<
    { label: any; value: any; fullAddress: Address }[]
  >(addressLocal ? JSON.parse(addressLocal) : []);
  const [cartSteps, setCartSteps] = useState('0');

  const contactFromStorage: IContactFromStorage = contactStorage
    ? JSON.parse(contactStorage)
    : {
        contact_full_name: '',
        contact_phone: '',
        contact_email: '',
        courier_service: '',
      };

  const handleQuantityChange = (id: number, newQuantity: number) => {
    setItemsQuantity({ ...itemsQuantity, [id]: newQuantity });
  };

  const total = cartItems.reduce(
    (sum, item) => sum + (item.totalPrice || 0),
    0
  );
  const totalQuantity = cartItems.reduce((akk, i) => {
    return akk + i.quantity;
  }, 0);

  const totalQuantityThatCounts = cartItems.reduce((akk, i) => {
    if (!i.do_not_count) {
      return akk + i.quantity;
    }
    return akk;
  }, 0);

  const { me } = useMe();
  const {
    register: cartRegister,
    handleSubmit: cartHandleSubmit,
    setValue,
    watch,
    control,
    trigger,
    resetField,
    formState: { errors: cartErrors, isValid },
  } = useForm<CartConfirmSchema>({
    mode: 'onChange',
    resolver: yupResolver(cartConfirmSchema),
    defaultValues: {
      ...contactFromStorage,
      deliveryEnabled: false,
      comment: ' ',
      deliveryMode: 'delivery',
      payer_type: totalQuantityThatCounts >= 10 ? payerTypes[1] : payerTypes[0],
      delivery_type: deliveryTypes[0],
      recipient_type: recipientTypes[0],
      self_delivery_type: selfDeliveryTypes[0],
      add_pickup_details_later: true,
      third_party_company_inn: '',
      third_party_company_name: '',
      legal_entity: {
        label: me?.company?.legal_entities?.[0]?.name,
        value: me?.company?.legal_entities?.[0]?.id.toString(),
      },
      shipmentAddress: {
        label: formatAddress(
          me?.company?.legal_entities?.[0]?.addresses?.[0] ||
            addressFromStorage[0]?.fullAddress
        ),
        value:
          me?.company?.legal_entities?.[0]?.addresses?.[0]?.id ||
          addressFromStorage[0]?.value,
        fullAddress:
          me?.company?.legal_entities?.[0]?.addresses?.[0] ||
          addressFromStorage[0]?.fullAddress,
      },
    },
  });

  const recipientType = watch('recipient_type');
  const payerType = watch('payer_type');
  const deliveryType = watch('delivery_type');
  const deliveryMode = watch('deliveryMode') || '';
  const shipmentAddress = watch('shipmentAddress');
  const createOrderStatus = useSelector(selectOrdersStatus);
  const selfDeliveryType = watch('self_delivery_type') || {
    value: null,
    label: null,
  };

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);

  useEffect(() => {
    setValue(
      'payer_type',
      totalQuantityThatCounts >= 10 ? payerTypes[1] : payerTypes[0]
    );
  }, [totalQuantityThatCounts, setValue]);

  const handleAddressSubmit = (addr: Address) => {
    const exists = addressFromStorage.find(
      (a: any) => a.fullAddress.id === addr.id
    );
    const newAddr = exists
      ? exists
      : {
          label: formatAddress(addr),
          value: formatAddress(addr),
          fullAddress: addr,
        };
    setValue('shipmentAddress', newAddr);
    const updatedAddresses = [
      ...addressFromStorage.filter((a: any) => a.value !== newAddr.value),
      newAddr,
    ].slice(0, 5);
    setAddressFromStorage(updatedAddresses);
    localStorage.setItem('addresses', JSON.stringify(updatedAddresses));
  };

  function onSubmit(data: any) {
    const address = data?.shipmentAddress?.fullAddress || {};
    console.log(data);
    const constantValues: IContactFromStorage = {
      contact_full_name: data.contact_full_name,
      contact_phone: data.contact_phone,
      contact_email: data.contact_email,
    };

    const formattedItems = cartItems
      .flatMap((item) => {
        const mainItem = {
          quantity: item.quantity,
          price: item?.price?.toString() || '0',
          item: item.id,
          item_name: item.name,
          cancel: false,
        };

        if (item.verification_item) {
          const verificationItem = {
            quantity: item.verification_quantity,
            price: item?.verificationPrice?.toString() || '0',
            item: item.verification_item.id,
            item_name: item.verification_item.name,
            cancel: false,
          };

          return [mainItem, verificationItem];
        }

        return [mainItem];
      })
      .filter((item) => item.quantity > 0);

    localStorage.setItem(
      'contact',
      JSON.stringify({
        ...constantValues,
        courier_service: data.courier_service,
      })
    );

    const cartData = {
      comment: data.comment,
      company: me?.company?.id || 0,
      legal_entity: data.legal_entity.value,
      items: formattedItems,
      ...constantValues,
    };

    if (!data.deliveryEnabled) {
      const orderData: ICreateOrder = {
        delivery_type: DeliveryTypeEnum.Unspecified,
        ...cartData,
        ...address,
      };
      dispatch(createOrder(orderData)).then(() => {
        navigate('/orders');
      });
      return;
    }

    if (deliveryMode === 'self-delivery') {
      if (selfDeliveryType.value === 'courier') {
        const orderData: ICreateOrder = {
          ...cartData,
          delivery_type: 'self-delivery',
          self_delivery_type: 'courier',
          courier_service: data.courier_service,
          invoice_url: data.invoice_url,
        };
        dispatch(createOrder(orderData)).then(() => {
          navigate('/orders');
        });
        return;
      }
      if (selfDeliveryType.value === 'confidant') {
        const orderData: ICreateOrder = {
          ...cartData,
          self_delivery_type: 'confidant',
          delivery_type: 'self-delivery',
          confidant_name: data.confidant_name,
          confidant_number: data.confidant_number,
        };
        dispatch(createOrder(orderData)).then(() => {
          navigate('/orders');
        });
        return;
      }
    }

    if (deliveryMode === 'delivery') {
      let reqData: ICreateOrder = {
        ...cartData,
        ...address,
        delivery_type: 'other',
        payer_type: data.payer_type.value,
        recipient_type: data.recipient_type.value,
      };

      if (payerType?.value === 'recipient') {
        reqData.delivery_type = data.delivery_type.value;
      }

      if (recipientType?.value === 'third party') {
        reqData.third_party_company_inn = data.third_party_company_inn;
        reqData.third_party_company_name = data.third_party_company_name;
      }

      dispatch(createOrder(reqData)).then(() => {
        navigate('/orders');
      });
    }
  }

  const setStepOnDelivery = () => {
    setCartSteps('2');
    setValue('deliveryEnabled', true);
  };
  const setYourCartStep = () => {
    setCartSteps('0');
    setValue('deliveryEnabled', false);
  };
  const setStepOnContacts = () => {
    setCartSteps('1');
    setValue('deliveryEnabled', false);
  };

  const addresses = [
    {
      label: 'Юридического лица',
      options: me?.company?.legal_entities?.[0]?.addresses?.map((a) => ({
        label: formatAddress(a),
        value: formatAddress(a),
        fullAddress: a,
      })),
    },
    {
      label: 'Недавние',
      options: addressFromStorage.map((a) => ({
        label: formatAddress(a.fullAddress),
        value: formatAddress(a.fullAddress),
        fullAddress: a,
      })),
    },
  ];
  return (
    <LoadingWrapper type="main" status={status}>
      <MainLayout>
        <MainLayout.Title>Оформление заказа</MainLayout.Title>
        <MainLayout.Header isVisible={cartItems.length > 0}>
          <div className={styles.cart__steps} id="cart-steps">
            {Object.keys(cartConfirmSteps).map((key) => (
              <CartConfirmStepBlock
                key={key}
                title={cartConfirmSteps[key].title}
                active={key === cartSteps}
              />
            ))}
          </div>
        </MainLayout.Header>
        <MainLayout.Main>
          {cartItems.length ? (
            <>
              {cartSteps === '0' && (
                <CartStepYourCart
                  cartItems={cartItems}
                  handleQuantityChange={handleQuantityChange}
                  total={total}
                  setCartSteps={setCartSteps}
                />
              )}
              {cartSteps !== '0' && (
                <div className={styles['cart-confirm']}>
                  <form
                    className={styles['cart-confirm__form']}
                    onSubmit={cartHandleSubmit(onSubmit)}
                  >
                    {(() => {
                      switch (cartSteps) {
                        case '1':
                          return (
                            <CartStepContactDetails
                              setValue={setValue}
                              control={control}
                              watch={watch}
                              cartErrors={cartErrors}
                              register={cartRegister}
                              setStepOnDelivery={setStepOnDelivery}
                              setYourCartStep={setYourCartStep}
                              isValid={isValid}
                              status={createOrderStatus}
                              reset={resetField}
                              trigger={trigger}
                            />
                          );
                        case '2':
                          return (
                            <CartStepDeliveryMethods
                              setStepOnContacts={setStepOnContacts}
                              cartRegister={cartRegister}
                              cartErrors={cartErrors}
                              shipmentAddress={shipmentAddress}
                              deliveryType={deliveryType}
                              payerType={payerType}
                              setValue={setValue}
                              recipientType={recipientType}
                              deliveryMode={deliveryMode}
                              watch={watch}
                              status={createOrderStatus}
                              address={addresses}
                              selfDeliveryType={selfDeliveryType}
                              trigger={trigger}
                              itemsLength={cartItems.reduce((akk, i) => {
                                return akk + i.quantity;
                              }, 0)}
                            />
                          );
                        default:
                          break;
                      }
                    })()}
                  </form>
                  {deliveryMode !== 'self-delivery' && cartSteps === '2' && (
                    <>
                      <div className={styles['cart-confirm__column']}>
                        <CartAddAddressModal
                          setValue={setValue}
                          handleAddressSubmit={handleAddressSubmit}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <EmptyState
                title={'Корзина пуста'}
                message={'Добавьте в корзину что-нибудь'}
              />
            </>
          )}
        </MainLayout.Main>
      </MainLayout>
    </LoadingWrapper>
  );
}

export default Cart;


interface SelectOption {
  label: string;
  value: string;
}

type DeliveryData = {
  delivery_type: SelectOption;
  recipient_type?: SelectOption;
  payer_type?: SelectOption;
  courier_service?: string;
  shipmentAddress: {
    fullAddress: any;
  };
};

function getDeliveryData(deliveryMode: string | undefined, data: DeliveryData) {
  if (deliveryMode === 'self-delivery') {
    return {
      delivery_type: deliveryMode,
    };
  } else if (
    deliveryMode === 'delivery' &&
    ['dellin', 'major', 'ems', 'flippost'].includes(data.delivery_type.value)
  ) {
    return {
      recipient_type: data?.recipient_type?.value,
      payer_type: data?.payer_type?.value,
      delivery_type: data.delivery_type.value,
      ...data.shipmentAddress.fullAddress,
    };
  } else if (
    deliveryMode === 'delivery' &&
    data.delivery_type.value === 'other'
  ) {
    return {
      delivery_type: data.delivery_type.value,
      ...data.shipmentAddress.fullAddress,
    };
  } else if (deliveryMode === 'courier') {
    return {
      courier_service: data.courier_service,
      delivery_type: deliveryMode,
      ...data.shipmentAddress.fullAddress,
    };
  }
}
