import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusType, Nullable } from '../../types/interfaces';
import {
  getIssueAttachment,
  getIssueById,
  getIssues,
  postCommentOnIssue,
} from './actions';
import { Issue, IssueFetchedAttachment } from '../../types/issueInterfaces';

interface issuesState {
  issues: Issue[];
  filteredIssues: Nullable<Issue[]>;
  isNextPageEmpty: boolean;
  currentIssue: Nullable<Issue>;
  currentIssueAttachmentUrl: Nullable<IssueFetchedAttachment>;
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: Nullable<unknown>;
}

const initialState: issuesState = {
  issues: [],
  filteredIssues: null,
  isNextPageEmpty: false,
  currentIssue: null,
  currentIssueAttachmentUrl: null,
  status: 'idle',
  listFetchStatus: 'idle',
  error: null,
};

const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    resetCurrentIssue: (state) => {
      state.currentIssue = null;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIssues.pending, (state) => {
        state.listFetchStatus = 'loading';
      })
      .addCase(getIssues.fulfilled, (state, action) => {
        const [filtered, isNextEmpty] = action.payload;
        state.filteredIssues = filtered;
        state.isNextPageEmpty = isNextEmpty;
        state.listFetchStatus = 'succeeded';
      })
      .addCase(getIssues.rejected, (state, action) => {
        state.error = action.payload;
        state.listFetchStatus = 'failed';
      })
      .addCase(getIssueById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getIssueById.fulfilled, (state, action) => {
        state.currentIssue = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getIssueById.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      })
      .addCase(postCommentOnIssue.pending, (state) => {})
      .addCase(postCommentOnIssue.fulfilled, (state, action) => {
        state.currentIssue?.comments.push(action.payload);
      })
      .addCase(postCommentOnIssue.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getIssueAttachment.fulfilled, (state, action) => {
        state.currentIssueAttachmentUrl = action.payload;
      });
  },
});
export const { resetCurrentIssue } = issuesSlice.actions;
export default issuesSlice.reducer;
