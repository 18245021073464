import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GridItem } from '@chakra-ui/react';
import GridLayout from '../../components/layout/GridLayout';
import MainLayout from '../../components/layout/MainLayout/MainLayout';
import { fetchAllInvites } from '../../store/invites/actions';
import {
  selectAllInvites,
  selectInvitesStatus,
} from '../../store/invites/reducer';
import {
  resetContacts,
  resetCurrentContact,
} from '../../store/contacts/reducer';
import {
  resetCurrentLegalEntity,
  resetLegalEntities,
} from '../../store/legalEntities/reducer';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { User } from '../../types/interfaces';
import { AbilityContext, Can } from '../../utils/context/can';
import AddEntity from '../../components/common/AddEntity/AddEntity';
import LegalEntityCard from './components/LegalEntityCard';
import ContactCard from './components/ContactCard';
import Tabs from '../../components/common/Tabs/Tabs';
import EmployeeCard from './components/EmployeeCard';
import InvitedCompanyCard from './components/InvitedCompanyCard';

import './Companies.scss';
import Invites from './components/Invites/Invites';
import {
  resetCurrentEmployee,
  selectEmployees,
  selectEmployeesStatus,
} from '../../store/employees/reducer';
import { fetchAllEmployees } from '../../store/employees/actions';
import { useMe } from '../Area/Area';
import { fetchCompanyById } from '../../store/companies/actions';
import { fetchAllLegalEntities } from '../../store/legalEntities/actions';
import { fetchAllContacts } from '../../store/contacts/actions';
import InvitedEmployeeCard from './components/InvitedEmployeeCard';

const Company = () => {
  const { me }: { me: User } = useMe();
  const { companyId } = useParams();
  const invites = useAppSelector(selectAllInvites);
  const employees = useAppSelector(selectEmployees);
  const legalEntities = useAppSelector(
    (state) => state.legalEntities.legalEntities
  );
  const contacts = useAppSelector((state) => state.contacts.contacts);

  const filteredEmployees = employees.filter((e) => {
    return e.company?.id === Number(companyId);
  });
  const invitesStatus = useAppSelector(selectInvitesStatus);
  const employeesStatus = useAppSelector(selectEmployeesStatus);
  const legalEntitiesStatus = useAppSelector(
    (state) => state.legalEntities.listFetchStatus
  );
  const contactsStatus = useAppSelector(
    (state) => state.contacts.listFetchStatus
  );
  const dispatch = useAppDispatch();
  const ability = useContext(AbilityContext);
  const company = useAppSelector((state) => state.companies.currentCompany);
  const companyStatus = useAppSelector((state) => state.companies.status);
  useEffect(() => {
    if (employeesStatus !== 'succeeded') {
      dispatch(fetchAllEmployees());
    }
  }, [dispatch, employeesStatus]);

  useEffect(() => {
    if (legalEntitiesStatus !== 'succeeded') {
      dispatch(
        fetchAllLegalEntities({
          company: companyId,
        })
      );
    }
  }, [dispatch, legalEntitiesStatus, companyId]);

  useEffect(() => {
    if (contactsStatus !== 'succeeded') {
      dispatch(
        fetchAllContacts({
          company: companyId,
        })
      );
    }
  }, [dispatch, contactsStatus, companyId]);

  useEffect(() => {
    if (companyStatus !== 'succeeded') {
      if (companyId) {
        dispatch(fetchCompanyById(+companyId));
      }
    }
  }, [dispatch, companyId, companyStatus]);

  useEffect(() => {
    if (invitesStatus !== 'succeeded') {
      dispatch(fetchAllInvites({ id: me.id }));
    }
  }, [dispatch, companyId, invitesStatus]);

  console.log(invitesStatus);

  useEffect(() => {
    dispatch(resetCurrentEmployee());
  }, [dispatch]);

  const location = useLocation();
  const activeTab = Number(location.search.slice(12));
  return (
    <MainLayout>
      <MainLayout.Title>
        <div style={{ display: 'flex', gap: '20px' }}>
          <p>Компания "{company?.name}"</p>
        </div>
      </MainLayout.Title>
      <MainLayout.Main>
        <Tabs variant="underline" initialActiveTab={activeTab}>
          {ability.can('view', 'legalentity') ? (
            <div title="Юр. лица">
              <Can I="add" a="legalentity">
                <AddEntity text={'юр. лицо'} path={'legal-entities/'} />
              </Can>
              <GridLayout>
                {legalEntities.map((item) => (
                  <React.Fragment key={item.id}>
                    <LegalEntityCard key={item.id} legalEntity={item} />
                  </React.Fragment>
                ))}
              </GridLayout>
            </div>
          ) : (
            <></>
          )}
          {ability.can('viewemployee', 'username') ? (
            <div title="Сотрудники">
              <Can I="createemployee" a={'username'}>
                <Invites type="employee" />
              </Can>
              <GridLayout>
                {(companyId ? filteredEmployees : employees)?.map(
                  (employee) => (
                    <React.Fragment key={employee.id}>
                      <EmployeeCard employee={employee} />
                    </React.Fragment>
                  )
                )}
                {invites.map((invite) => (
                  <React.Fragment key={invite.id}>
                    <InvitedEmployeeCard invite={invite} />
                  </React.Fragment>
                ))}
              </GridLayout>
            </div>
          ) : (
            <></>
          )}

          {ability.can('view', 'contact') ? (
            <div title="Контакты">
              <Can I="add" a="contact">
                <AddEntity text={'контакт'} path={'contact-person/'} />
              </Can>
              <GridLayout>
                {contacts?.map((contact) => (
                  <React.Fragment key={contact.id}>
                    <ContactCard employee={contact} />
                  </React.Fragment>
                ))}
              </GridLayout>
            </div>
          ) : (
            <></>
          )}
        </Tabs>
      </MainLayout.Main>
    </MainLayout>
  );
};

export default Company;
