import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { fetchAllEmployees } from '../../store/employees/actions';

export const useEmployees = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAllEmployees());
  }, [dispatch]);
  const employees = useAppSelector((state) => state.employees.employees);
  return employees;
};
