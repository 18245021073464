import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputField from '../../components/common/InputField/InputField';
import Button from '../../components/common/Button/Button';
import AuthLayout from '../../components/layout/AccessLayout/AccessLayout';
import './Login.scss';
import ReactInputMask from 'react-input-mask';
import { useAppDispatch } from '../../store/store';
import {
  setErrorNotification,
  setNotification,
} from '../../store/notifications';
import { apiClient } from '../../api/instance';

const requestAccessSchema = yup.object().shape({
  first_name: yup.string().required('Имя обязательно'),
  last_name: yup.string().required('Фамилия обязательна'),
  patronymic: yup.string().required('Отчество обязательно'),
  company_name: yup.string().required('Название компании обязательно'),
  phonemy: yup
    .string()
    .required('Пожалуйста, введите номер телефона')
    .matches(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Пожалуйста, введите корректный номер телефона'
    ),
  email: yup.string().email('Некорректный email').required('Email обязателен'),
});

const RequestAccess = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(requestAccessSchema),
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = 'Получить доступ';
  }, []);

  const [isSubmit, setIsSubmit] = useState(false);

  const onSubmit = (data: any) => {
    apiClient
      .post('/companies/request_registration/', {...data, phone: data.phonemy})
      .then(() => {
        dispatch(setNotification('Заявка успешна оставлена!'));
        setIsSubmit(true);
      })
      .catch(() => {
        dispatch(
          setErrorNotification(
            'Произошла ошибка, попробуйте позже оставить заявку'
          )
        );
      });
  };

  return (
    <AuthLayout
      leftContent={
        <section className="auth__text-section">
          <h2 className="auth__text">Получить доступ</h2>
          <div className="auth__divider"></div>
          <div className="auth__description">
            Заполните форму ниже, чтобы оставить заявку на доступ
          </div>
        </section>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        {!isSubmit ? (
          <>
            <h2 className="form__title">Заявка на доступ</h2>
            <div className="form__description">
              Пожалуйста, введите информацию в поля ниже, чтобы оставить заявку
            </div>

            <div className="form__row">
              <InputField
                name="first_name"
                inputName="first_name"
                id="first_name"
                label="Имя"
                placeholder="Введите ваше имя"
                register={register}
                errors={errors}
              />
            </div>
            <div className="form__row">
              <InputField
                name="last_name"
                inputName="last_name"
                id="last_name"
                label="Фамилия"
                placeholder="Введите вашу фамилию"
                register={register}
                errors={errors}
              />
            </div>
            <div className="form__row">
              <InputField
                name="patronymic"
                inputName="patronymic"
                id="patronymic"
                label="Отчество"
                placeholder="Введите ваше отчество"
                register={register}
                errors={errors}
              />
            </div>
            <div className="form__row">
              <InputField
                name="company_name"
                inputName="company_name"
                id="company_name"
                label="Название компании"
                placeholder="Введите название компании"
                register={register}
                errors={errors}
              />
            </div>
            <div className="form__row">
              <Controller
                name={'phonemy'}
                control={control}
                render={({ field }) => {
                  console.log(field)
                return (
                  <InputField
                    {...field}
                    as={ReactInputMask}
                    mask={'+7 (***) ***-**-**'}
                    id="phonemy"
                    name="phonemy"
                    label="Телефон"
                    errors={errors}
                  />
                )}
              }
              />
            </div>
            <div className="form__row">
              <InputField
                name="email"
                inputName="email"
                id="email"
                label="Email адрес"
                placeholder="Введите ваш email"
                register={register}
                errors={errors}
              />
            </div>
            <Button type="submit">Отправить заявку</Button>
            <div className="form__description">
              Уже зарегистрированы?{' '}
              <Link to={'/login'} style={{ cursor: 'pointer', color: 'blue' }}>
                Войти
              </Link>
            </div>
          </>
        ) : (
          <div className="form__description">
            Ваш запрос принят. В ближайшее время менеджер с Вами свяжется.{' '}
            {'  '}
            <Link to={'/login'} style={{ cursor: 'pointer', color: 'blue' }}>
              Страница входа
            </Link>
          </div>
        )}
      </form>
    </AuthLayout>
  );
};

export default RequestAccess;
