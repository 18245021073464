import { useEffect } from 'react';
import page404 from '../../assets/images/404.png';
import './NotFound.scss';

const NotFound = ({isAtWork=false, isError=false}) => {
  useEffect(() => {
    document.title = 'Страница не найдена';
  }, []);

  return (
    <div className="not-found">
      <h1 className="not-found__title">
      {isAtWork && 'Страница находится в разработке'}
      {!isError && !isAtWork && 'Уппс...'}
      {isError && 'Уппс...'}
      </h1>
      <h2 className="not-found__text">
        {isAtWork ? '' : 'Страница не найдена'}
        {isError ? 'Возникла ошибка' : ''}
        </h2>
      <div className="not-found__description">
        {isAtWork && 'Вкладка находится в разработке, будет добавлена в ближайшее время'}
        {!isError && !isAtWork && 'Возможно, был введен неверный адрес, либо страница была перемещена'}
        {isError && 'Возникла ошибка при загрузке страницы'}
      </div>
      <img src={page404} alt="Не найдено" className="not-found__image" />
      <button
        className="not-found__button"
        onClick={() => (window.location.href = '/')}
      >
        Вернуться на главную
      </button>
    </div>
  );
};

export default NotFound;
