import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../common/Button/Button';
import {
  companyAddEditValidators,
  orderConfirmAddresSchema,
} from '../../../utils/validators/companyAddEditValidators.js';
import LoadingWrapper from '../../common/Spinners/LoadingWrapper';
import InputField from '../../common/InputField/InputField';
import { formatPhoneNumber } from '../../../utils/validators/phoneNormalize.js';
import { Box, Grid, GridItem, Input, Text } from '@chakra-ui/react';
import {
  companiesLegalEntityAddEditSections,
  orderConfirmFormSections,
} from '../../../utils/constants/formSections.js';

import styles from './CompaniesLegalEntityAddEdit.module.scss';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import CustomModal from '../../common/CustomModal/CustomModal';
import ConfirmModal from '../../common/ConfirmModal/ConfirmModal';
import { useAppDispatch, useAppSelector } from '../../../store/store.ts';
import { useMe } from '../../../pages/Area/Area';
import {
  createLegalEntity,
  deleteLegalEntity,
  fetchLegalEntityById,
  partialUpdateLegalEntity,
  updateLegalEntity,
} from '../../../store/legalEntities/actions.ts';
import { AbilityContext } from '../../../utils/context/can.ts';
import { addCompanyLegalEntities } from '../../../store/auth/reducer.ts';
import {
  deleteAppLegalEntity,
  findLegalEntity,
  resetCurrentLegalEntity,
  selectCurrentLegalEntity,
  updateAppLegalEntity,
} from '../../../store/legalEntities/reducer.ts';
import AvatarAddEditForm from '../../common/AvatarAddEditForm/AvatarAddEditForm';
export const CompaniesCompanyAddEdit = () => {
  const ability = useContext(AbilityContext);
  const status = useAppSelector((state) => state.legalEntities.status);
  const { legalEntityId } = useParams();
  const legalEntity = useAppSelector(selectCurrentLegalEntity);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(companyAddEditValidators),
    shouldUnregister: false,
    defaultValues: {
      ...legalEntity,
      phoneNumber: legalEntity.phones?.length ? legalEntity.phones[0] : '',
      additionalPhone:
        legalEntity.phones?.length >= 2 ? legalEntity.phones[1] : '',
      legalAddress: legalEntity.addresses?.length
        ? legalEntity.addresses[0]
        : {},
    },
  });
  useEffect(() => {
    if (status === 'idle' && legalEntityId) {
      dispatch(fetchLegalEntityById(legalEntityId));
    }
  }, [status, legalEntityId, dispatch]);
  useEffect(() => {
    reset({
      ...legalEntity,
      phoneNumber: legalEntity.phones?.length ? legalEntity.phones[0] : '',
      additionalPhone:
        legalEntity.phones?.length >= 2 ? legalEntity.phones[1] : '',
      legalAddress: legalEntity.addresses?.length
        ? legalEntity.addresses[0]
        : {},
    });
  }, [legalEntity, reset]);

  const onSubmit = async (body) => {
    if (body.additionalPhone) {
      body.phones = [
        formatPhoneNumber(body.phoneNumber),
        formatPhoneNumber(body.additionalPhone),
      ];
    } else {
      body.phones = [formatPhoneNumber(body.phoneNumber)];
    }

    if (ability.can('add', 'legalentity')) {
      if (!legalEntityId) {
        dispatch(
          createLegalEntity({
            ...body,
            id: legalEntityId,
            // company: me.company.id,
            addresses: [{ ...body.legalAddress, type: 'legal' }],
          })
        ).then((data) => {
          dispatch(addCompanyLegalEntities(data.payload));
        });
      }
    }
    if (ability.can('change', 'legalentity')) {
      if (legalEntityId) {
        const addressesForCheck = legalEntity.addresses.map((address) =>
          JSON.stringify(address)
        );
        const hasAddress = addressesForCheck.includes(
          JSON.stringify(body.legalAddress)
        );
        dispatch(
          updateLegalEntity({
            ...body,
            id: legalEntityId,
            addresses: hasAddress
              ? legalEntity.addresses
              : [
                  ...legalEntity.addresses,
                  { ...body.legalAddress, type: 'legal' },
                ],
          })
        ).then(() => {
          dispatch(resetCurrentLegalEntity());
        });
      }
    }
    navigate(-1);
  };

  const addDeliveryAddress = (address) => {
    const newAddresses = [
      ...legalEntity.addresses,
      {
        ...address,
        type: 'legal',
      },
    ];
    dispatch(
      partialUpdateLegalEntity({
        id: legalEntityId,
        addresses: newAddresses,
      })
    );
    dispatch(
      updateAppLegalEntity({
        ...legalEntity,
        addresses: newAddresses,
      })
    );
    setValue('legalAddress', address);
  };

  const changeAddress = (address) => {
    setValue('legalAddress', address);
  };

  const removeAddress = (deletingAddress) => {
    const newAddresses = legalEntity.addresses.filter(
      (address) => JSON.stringify(address) !== JSON.stringify(deletingAddress)
    );
    dispatch(
      partialUpdateLegalEntity({
        id: legalEntityId,
        addresses: newAddresses,
      })
    );
    dispatch(
      updateAppLegalEntity({
        ...legalEntity,
        addresses: newAddresses,
      })
    );
  };

  const onUpdateAvatar = (e) => {
    const formData = new FormData();
    formData.append('photo', e.target.files[0]);
    formData.append('id', legalEntityId);
    dispatch(
      partialUpdateLegalEntity({
        data: formData,
      })
    );
  };
  const onDeleteAvatar = () => {
    const formData = new FormData();
    formData.append('clear_photo', 'true');
    formData.append('id', legalEntityId);
    dispatch(partialUpdateLegalEntity({ data: formData }));
  };

  return (
    <LoadingWrapper type={'main'} status={status}>
      <div className={styles.form}>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={6}
          className={styles.container}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {companiesLegalEntityAddEditSections.map((section, index) => (
              <GridItem marginTop={'20px'} key={index}>
                <h2 className={styles.form__title}>
                  {section.title !== 'Адрес доставки' && section.title}
                </h2>

                {section.title !== 'Адрес доставки' &&
                  section.fields.map((data, i) => (
                    <>
                      {data.id === 'phoneNumber' ||
                      data.id === 'additionalPhone' ? (
                        <Controller
                          name={data.inputName}
                          control={control}
                          render={({ field }) => (
                            <InputField
                              {...field}
                              as={data.as}
                              mask={data.mask}
                              id={data.id}
                              inputName={data.inputName}
                              label={data.label}
                              errors={errors}
                              register={register}
                            />
                          )}
                        />
                      ) : (
                        <InputField
                          key={i}
                          id={data.id}
                          inputName={data.inputName}
                          label={data.label}
                          as={data.as}
                          mask={data.mask}
                          errors={errors}
                          register={register}
                        />
                      )}
                    </>
                  ))}
                {section.checkbox && (
                  <div className={styles.form__checkboxes}>
                    <input
                      className={styles.form__checkbox}
                      style={{ accentColor: '#E3282A' }}
                      title={section.checkbox.label}
                      type="checkbox"
                      name={section.checkbox.inputName}
                      {...register(section.checkbox.inputName)}
                      id={section.checkbox.id}
                    />
                    <label
                      htmlFor={section.checkbox.id}
                      className={styles.form__label}
                    >
                      {section.checkbox.label}
                    </label>
                  </div>
                )}
              </GridItem>
            ))}
            <Button type="submit" className={styles.form__submitButton}>
              {legalEntityId ? 'Сохранить' : 'Добавить'}
            </Button>
            {legalEntityId ? (
              <Button
                className={styles.form__deleteButton}
                onClick={() => {
                  dispatch(deleteLegalEntity(legalEntityId));
                  dispatch(deleteAppLegalEntity(legalEntityId));
                  navigate(-1);
                }}
              >
                Удалить юр. лицо
              </Button>
            ) : (
              ''
            )}
          </form>
          <GridItem marginTop={'20px'}>
            <h2 className={styles.form__title}>Адреса доставки</h2>
            <DeliveryAddressesSection
              addresses={legalEntity.addresses?.filter(
                (address) => address.type === 'legal'
              )}
              onRemove={removeAddress}
              onChange={changeAddress}
            />
            <AddDeliveryAddressModal handler={addDeliveryAddress} />
          </GridItem>
        </Grid>
      </div>
    </LoadingWrapper>
  );
};

export default CompaniesCompanyAddEdit;

export function DeliveryAddressesSection({ addresses, onRemove, onChange }) {
  const addressDetails = (address) => [
    { label: 'Страна', value: address.country },
    { label: 'Регион', value: address.region },
    { label: 'Город', value: address.city },
    { label: 'Улица', value: address.street },
    { label: 'Дом', value: address.house },
    { label: 'Индекс', value: address.postcode },
  ];
  return (
    <>
      {addresses &&
        addresses.length > 0 &&
        addresses?.map((data, i) => (
          <Box
            onClick={() => onChange(data)}
            cursor="pointer"
            p={2}
            border={'0.5px solid #80808075'}
            borderRadius={'8px'}
            key={i}
            marginBottom={4}
          >
            {addressDetails(data).map((item, j) => (
              <Box
                borderBottom={'1px solid #80808075'}
                display={'flex'}
                justifyContent={'space-between'}
                key={item.label}
              >
                <Text fontWeight="bold" color="gray.600">
                  {item.label}:
                </Text>
                <Text
                  textTransform={
                    item.postcode !== 'ИНН' ? 'capitalize' : 'none'
                  }
                >
                  {item.value}
                </Text>
              </Box>
            ))}
            <Box flex>
              <ConfirmModal
                openButton={
                  <DeleteIcon
                    w={'20px'}
                    _hover={{ color: 'red' }}
                    cursor={'pointer'}
                  />
                }
                confirm={() => onRemove(data)}
                texts={{
                  title: 'Удаление',
                  text: `Вы уверены, что хотите удалить адрес доставки?`,
                  cancel: 'Отмена',
                  confirm: 'Да',
                }}
              />
              <EditIcon
                w={'20px'}
                _hover={{ color: 'red' }}
                cursor={'pointer'}
              />
            </Box>
          </Box>
        ))}
    </>
  );
}

export function AddDeliveryAddressModal({ handler }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(orderConfirmAddresSchema),
    shouldUnregister: false,
    defaultValues: {
      country: '',
      region: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      postcode: '',
    },
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const addDeliveryAddress = (data) => {
    setModalOpen(true);
    handler(data);
    reset();
  };

  return (
    <CustomModal
      isClosed={isModalOpen}
      open={() => setModalOpen(false)}
      openButton={
        <Button isWhite={true}>
          Добавить адрес
          <AddIcon ml={2} />
        </Button>
      }
    >
      <GridItem>
        <form onSubmit={handleSubmit(addDeliveryAddress)}>
          <h2 className={styles.form__title}>
            {orderConfirmFormSections.title}
          </h2>
          {orderConfirmFormSections.fields.map((field) => (
            <InputField
              id={field.id}
              inputName={field.inputName}
              label={field.label}
              as={field.as}
              mask={field.mask}
              errors={errors}
              register={register}
            />
          ))}
          <Button type="submit">Добавить</Button>
        </form>
      </GridItem>
    </CustomModal>
  );
}
