import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addressSchema } from '../../../utils/validators/registrationSchema.js';
import { RegisterHeader } from './RegistrationStepCompanyData';
import Button from '../../../components/common/Button/Button';
import InputField from '../../../components/common/InputField/InputField';
import {
  addLegalAddress,
  selectLegalEntityLegalAddress,
} from '../../../store/registration/reducer';

export const RegistrationStepLegalAddress = () => {
  const legalAddress = useSelector(selectLegalEntityLegalAddress);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addressSchema),
    shouldUnregister: false,
  });

  const onSubmit = (data: any) => {
    dispatch(addLegalAddress(data));
  };

  useEffect(() => {
    reset(legalAddress);
  }, [legalAddress, reset]);

  return (
    <>
      <RegisterHeader
        isBack={true}
        stepSize={1}
        step={2}
        title="Юридический адрес"
      />
      <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          id="country"
          inputName="country"
          label="Страна"
          errors={errors}
          register={register}
        />
        <InputField
          id="region"
          inputName="region"
          label="Регион"
          errors={errors}
          register={register}
        />
        <InputField
          id="city"
          inputName="city"
          label="Город"
          type="text"
          errors={errors}
          register={register}
        />

        <InputField
          id="street"
          inputName="street"
          label="Улица"
          type="text"
          errors={errors}
          register={register}
        />

        <div className="auth__address-numbers">
          <InputField
            id="house"
            inputName="house"
            label="Дом"
            type="text"
            errors={errors}
            register={register}
          />
          <InputField
            id="apartment"
            inputName="apartment"
            label="Офис"
            type="text"
            errors={errors}
            register={register}
          />
        </div>

        <InputField
          id="postcode"
          inputName="postcode"
          label="Почтовый индекс"
          type="text"
          errors={errors}
          register={register}
        />
        <div className="auth__button-container">
          <Button type={'submit'}>Продолжить</Button>
        </div>
      </form>
    </>
  );
};
