import React from 'react';
import { Input, InputProps } from '@chakra-ui/react';
import styles from './styles/CartInput.module.scss';
import ReactInputMask from 'react-input-mask';

const getErrorMessage = (errors: any, inputName: string, index: number) => {
  const keys = inputName.split('.');
  let currentError = errors;

  for (const key of keys) {
    if (Array.isArray(currentError)) {
      currentError = currentError[index];
    }
    if (currentError && currentError[key]) {
      currentError = currentError[key];
    } else {
      return null;
    }
  }

  return currentError.message;
};

interface ICartInput extends InputProps {
  id?: string;
  inputName?: string;
  label?: string;
  type?: string;
  errors?: any;
  register?: any;
  validationSchema?: any;
  placeholder?: string;
  index?: number;
  defaultValue?: string;
  mask?: string | (string | RegExp)[];
  isTextArea?: boolean;
  as?: React.ElementType;
  icon?: React.ReactNode;
}

export const CartInput = React.forwardRef<HTMLInputElement, ICartInput>(
  (
    {
      id = '',
      inputName = '',
      label = '',
      type = 'text',
      errors = {},
      register = () => {},
      validationSchema = {},
      placeholder = '',
      index = 0,
      defaultValue = '',
      mask = '',
      isTextArea = false,
      as: InputComponent = Input,
      icon,
      ...props
    }: ICartInput,
    ref
  ) => {
    const errorMessage = getErrorMessage(errors, inputName, index);

    return (
      <div className={styles.input_field}>
        <div className={styles.input_field__header}>
          <label htmlFor={id} className={styles.input_field__label}>
            {label}
          </label>
          {errorMessage && (
            <label
              className={[
                styles.input_field__label,
                styles.input_field__error_message,
              ].join(' ')}
            >
              {errorMessage}
            </label>
          )}
        </div>
        <div className={styles.input_wrapper}>
          <InputComponent
            ref={ref}
            {...props}
            id={id}
            name={inputName}
            type={type}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className={`${styles.input_field__input} ${
              errorMessage ? styles.input_field__input_error : ''
            }`}
            {...register(inputName, validationSchema)}
            as={mask ? ReactInputMask : undefined}
            mask={mask}
          />
          {icon && <span className={styles.input_icon}>{icon}</span>}
        </div>
      </div>
    );
  }
);
