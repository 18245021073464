import React from 'react';
import { CartItem } from './components/CartItem';
import { CartTotal } from './components/CartTotal';
import { Item } from '../../../types/interfaces';

interface Props {
  cartItems: any[];
  handleQuantityChange: (id: number, newQuantity: number) => void;
  total: number;
  setCartSteps: (step: string) => void;
}
export function CartStepYourCart({
  cartItems,
  total,
  setCartSteps,
}: Props) {


  return (
    <>
      {cartItems.map((item) =>(
         <CartItem
          key={item.id}
          item={item}
        />
      )
      )}
      <CartTotal cartItems={cartItems} total={total} setCartSteps={setCartSteps} />
    </>
  );
}