import { Order } from '@/shared';
import axios from 'axios';
import moment from 'moment';

export const downloadBill = async (event: React.MouseEvent, order: Order) => {
  event.stopPropagation();
  try {
    const response = await axios.get(
      order?.print_form ? order.print_form : '',
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Заказ-${order.name}-от ${moment(order.created_at).format(
        'DD.MM.YYYY'
      )}.pdf`
    );
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  } catch (error) {
    console.error('Ошибка при загрузке файла: ', error);
  }
};
