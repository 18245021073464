import { useMemo } from 'react';
import { selectTotalItemsCount } from '../../store/cart/reducer';
import { Item } from '../../types/interfaces';
import { getPriceInRange } from './pricing';
import { useAppSelector } from '../../store/store';
interface IUsePriceInRange {
  product: Item;
  inputValue: number;
}
const usePriceInRange = ({ product, inputValue }: IUsePriceInRange) => {
  const totalItemsInCartCount = useAppSelector((state) =>
    selectTotalItemsCount(product?.id)(state)
  );
  
  const pricesInRange = useMemo(() => {
    return getPriceInRange(
      product,
      Number(inputValue) + totalItemsInCartCount
    )?.price;
  }, [product, inputValue, totalItemsInCartCount]);

  return pricesInRange;
};

export default usePriceInRange;
