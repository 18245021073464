import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusType, Nullable } from '../../types/interfaces';

interface devicesState {
  devices: [];
  currentDevice: Nullable<string[]>;
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: Nullable<unknown>;
}

const initialState: devicesState = {
  devices: [],
  currentDevice: null,
  status: 'idle',
  listFetchStatus: 'idle',
  error: null,
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});
export default devicesSlice.reducer;
