import { createAsyncThunk } from '@reduxjs/toolkit';
import { issuesApi } from '../../api/services/issues.service';
import { IFilters } from '../../pages/Devices/Devices';
import { Issue } from '../../types/issueInterfaces';

export const getIssues = createAsyncThunk(
  'issues/getIssues',
  async (
    { filters, page }: { filters: IFilters; page: number },
    { rejectWithValue }
  ) => {
    try {
      const [result, nextPage] = issuesApi.getFilteredIssues(filters, page);
      const response = await result;
      const checkNextPage = await nextPage;
      const checkResult = checkNextPage.data.length === 0;
      const returnTuple: [Issue[], boolean] = [response.data, checkResult];
      return returnTuple;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getIssueById = createAsyncThunk(
  'issues/getIssueById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await issuesApi.getIssueById(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const postCommentOnIssue = createAsyncThunk(
  'issues/addCommentOnIssue',
  async ({ id, ...data }: { id: number; data: any }, { rejectWithValue }) => {
    try {
      const response = await issuesApi.postCommentOnIssue(id, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getIssueAttachment = createAsyncThunk(
  'issues/getIssueAttachment',
  async (
    ids: {
      issueId: number;
      attachmentId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await issuesApi.getIssueAttachment(ids);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
