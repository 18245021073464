import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  addItemToCart as addItemToCartAction,
  deleteItemFromCart as deleteItemFromCartAction,
} from '../../../store/cart/actions';
import {
  currentItemSelector,
  selectItemsStatus,
} from '../../../store/items/reducer';
import { useAppDispatch } from '../../../store/store';
import { AbilityContext } from '../../../utils/context/can';
import { Link, List } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { getItem } from '../../../store/items/actions';
import { CatalogItemSelectionFilter } from './components/CatalogItemSelectionFilter';
import SpecificationsTable from '../../common/SpecificationsTable/SpecificationsTable';
import Tabs from '../../common/Tabs/Tabs';
import _ from 'lodash';
import './CatalogDetail.scss';

const CatalogDetail = () => {
  const { id } = useParams();
  const product = useSelector(currentItemSelector);
  const status = useSelector(selectItemsStatus);
  const dispatch = useAppDispatch();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    dispatch(getItem({ id: Number(id) }));
  }, [dispatch, id]);

  const addItemToCart = (
    id: number,
    quantity: number,
    verification_item: string | null,
    verification_quantity: number
  ) => {
    dispatch(
      addItemToCartAction({
        id,
        quantity,
        verification_item,
        verification_quantity,
      })
    );
  };

  const removeFromCart = (id: number) => {
    dispatch(deleteItemFromCartAction(id));
  };

  const addCartLogic = () => {
    if (ability.can('add', 'cartitem')) {
      return (
        <CatalogItemSelectionFilter
          status={status}
          addToCart={addItemToCart}
          product={product}
          removeFromCart={removeFromCart}
        />
      );
    }
    return (
      product?.items?.length > 1 && (
        <div className="detail__info">
          <div className="product">
            <label className="product__label">Товары в группе</label>
            <List spacing={3}>
              {product?.items?.map((product) => (
                <p key={product.id}>{product.name}</p>
              ))}
            </List>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="detail">
      <div className="detail__upper">
        <div className="detail__img-wrp">
          <img className="detail__img" src={product.photo} alt="detailImage" />
        </div>
        <div className="detail__info">
          <h3 className="detail__name">{product.name}</h3>
          <p className="detail__description">{product.short_description}</p>
          {addCartLogic()}
        </div>
      </div>
      <div className="detail__lower">
        <Tabs variant="booking">
          <div title="Описание">{product?.description}</div>
          <div title="Тех.характеристики">
            <SpecificationsTable data={product?.tech_specs} />
          </div>
          <div title="Доп.материалы">
            <ul className="detail__description-lower detail__additional-material">
              {product?.additional_materials?.map((material, index) => (
                <li className="detail__additional-material-item" key={index}>
                  <Link href={material.material_url}>
                    {material.name} <ExternalLinkIcon mx="2px" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default CatalogDetail;
