import { forwardRef } from 'react';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../../assets/images/calendarIcon.svg';
import styles from './CustomDatepicker.module.scss';
import { Input } from '@chakra-ui/react';
registerLocale('ru', ru);
const CustomDatePicker = ({ label, filters, setFilters }) => {
  const { startDate, endDate } = filters;
  const onChange = (dates) => {
    const [start, end] = dates;
    setFilters((filters) => ({
      ...filters,
      startDate: start && new Date(start.setHours(0, 0, 0, 0)),
      endDate: end && new Date(end.setHours(23, 59, 59, 999)),
    }));
  };
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      style={{
        position: 'relative',
        width: '270px',
        minHeight: '72px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <label className={styles['datepicker__label']}>{label}</label>
      <Input
        ref={ref}
        type="text"
        value={value}
        onChange={() => {}}
        onClick={onClick}
        className={styles['datepicker']}
      />
      <div className={styles['datepicker__icon']} onClick={onClick}>
        <img src={calendarIcon} alt="icon" />
      </div>
    </div>
  ));
  const plugDate = new Date();
  return (
    <DatePicker
      dateFormat="dd.MM.yyyy"
      selected={new Date()}
      selectsRange
      isClearable={true}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      customInput={<CustomInput />}
      portalId="root"
      locale={ru}
      wrapperClassName={styles['wrapper']}
      weekDayClassName={() => styles['weekday']}
      calendarClassName={styles['calendar']}
      dayClassName={(date) =>
        date.getDate() === plugDate.getDate() &&
        date.getMonth() === plugDate.getMonth()
          ? styles['current-day']
          : styles['day']
      }
    />
  );
};
export default CustomDatePicker;
