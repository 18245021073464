import React, { useEffect } from 'react';
import styles from './MessageToast.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { removeMessage, selectAllMessages } from '../../../store/notifications';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
const Toast = ({ message }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(removeMessage(message.id));
    }, 2000);
    return () => clearTimeout(timer);
  }, [dispatch, message]);

  return (
    <Alert status={message.type}>
      <AlertIcon />
      <AlertTitle>{message.message}</AlertTitle>
    </Alert>
  );
};

export const MessageToast = () => {
  const messages = useSelector(selectAllMessages);

  return (
    <div className={styles.container}>
      {messages.map((message) => (
        <Toast key={message.id} message={message} />
      ))}
    </div>
  );
};
