import React from 'react';
import camera from '../../../assets/images/camera-white.png';
import notImage from '../../../assets/images/no_avatar.svg';
import redTrash from '../../../assets/sprites/red_trash.svg';
import spinner from '../../../assets/sprites/spinner.svg';
import styles from './AvatarAddEditForm.module.scss';

export function AvatarImage({ status, avatar, deleteHandler }) {
  return (
    <figure className={styles.personalFigure}>
      <img
        src={status === 'loading' ? spinner : avatar ? avatar : notImage}
        className={styles.personalAvatar}
        alt="avatar"
      />
      <figcaption className={styles.personalFigcaption}>
        {status !== 'loading' && (
          <>
            <img
              alt="edit"
              className={styles.avatar__image_edit}
              src={camera}
            />
            {avatar && (
              <div
                className={styles.avatar__image_trash}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteHandler();
                }}
              >
                <img alt="trash" src={redTrash} />
              </div>
            )}
          </>
        )}
      </figcaption>
    </figure>
  );
}
