import { createAsyncThunk } from '@reduxjs/toolkit';
import { setErrorNotification } from '../notifications';
import { authApi } from '../../api/services/auth.service';
import { companiesApi } from '../../api/services/companies.service';
import { employeesApi } from '../../api/services/employees.service';
interface CompanyData {
  username: string;
  password: string;
  email: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
}

export const checkUsernameAvailable = createAsyncThunk(
  'registration/checkUsernameAvailable',
  async (body: CompanyData, { dispatch, rejectWithValue }) => {
    try {
      const response = await authApi.checkUsernameAvailable(body.username);
      if (!response.data) {
        dispatch(setErrorNotification('Логин уже занят.'));
        return rejectWithValue('Логин уже занят.');
      }
      return { hasError: false, ...body };
    } catch (error: any) {
      dispatch(setErrorNotification('Ошибка проверки логина.'));
      return rejectWithValue(
        error.response?.data || 'Ошибка при проверке логина.'
      );
    }
  }
);

export const registerCompany = createAsyncThunk(
  'registration/register',
  async (body: any, { dispatch }) => {
    try {
      const response = await companiesApi.registerCompany(body);
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('При регистрации произошла ошибка.'));
      throw error;
    }
  }
);

export const registerEmployee = createAsyncThunk(
  'registration/registerEmployee',
  async (body: any, { dispatch }) => {
    try {
      const response = await employeesApi.registerEmployee(body);
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('При регистрации произошла ошибка.'));
      throw error;
    }
  }
);
