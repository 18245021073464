import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '../../common/Button/Button';
import { companiesEmployeeEditSections } from '../../../utils/constants/formSections';
import InputField from '../../common/InputField/InputField';
import LoadingWrapper from '../../common/Spinners/LoadingWrapper';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { AbilityContext } from '../../../utils/context/can';
import styles from './CompaniesEmployeeEdit.module.scss';
import { selectCurrentEmployee } from '../../../store/employees/reducer';
import {
  fetchEmployeeById,
  partialUpdateEmployee,
} from '../../../store/employees/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { employeeSchema } from '../../../utils/validators/employeeSchema';

interface IDefaultValues {
  id: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  mobile_phone: string;
  whatsapp: string;
  telegram: string;
  email: string;
}
const CompaniesEmployeeEdit = () => {
  const { employeeId } = useParams();
  const employee = useAppSelector(selectCurrentEmployee);
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.employees.status);
  const ability = useContext(AbilityContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDefaultValues | any>({
    mode: 'onBlur',
    resolver: yupResolver(employeeSchema),
    shouldUnregister: false,
    defaultValues: {
      first_name: employee?.first_name,
      last_name: employee?.last_name,
      patronymic: employee?.patronymic,
      mobile_phone: employee?.mobile_phone,
      whatsapp: employee?.whatsapp,
      telegram: employee?.telegram,
      email: employee?.email,
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (status === 'idle' && employeeId) {
      dispatch(fetchEmployeeById(employeeId));
    }
  }, [dispatch, employeeId]);

  useEffect(() => {
    reset({
      first_name: employee?.first_name,
      last_name: employee?.last_name,
      patronymic: employee?.patronymic,
      mobile_phone: employee?.mobile_phone,
      whatsapp: employee?.whatsapp,
      telegram: employee?.telegram,
      email: employee?.email,
    });
  }, [reset, employee]);

  const onSubmit = async (data: IDefaultValues) => {
    if (ability.can('editemployee', 'username') && employeeId) {
      dispatch(partialUpdateEmployee({ id: employeeId, data }));
    }
    navigate(-1);
  };
  return (
    <LoadingWrapper type="main" status={status}>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {companiesEmployeeEditSections.map((section, index) => (
            <div key={index}>
              <h2 className={styles.form__title}>{section.title}</h2>
              {section.fields.map((data) => (
                <InputField
                  key={data.id}
                  id={data.id}
                  inputName={data.inputName}
                  label={data.label}
                  errors={errors}
                  register={register}
                  mask={data?.mask}
                  as={data?.as}
                />
              ))}
              <Button
                type="submit"
                className={styles.form__submitButton}
                // disabled={!isValid}
              >
                Сохранить
              </Button>
            </div>
          ))}
        </form>
      </div>
    </LoadingWrapper>
  );
};

export default CompaniesEmployeeEdit;
