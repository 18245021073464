import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { Order } from '../../types/interfaces';
import { downloadBill } from './components/download-bill';
import ConfirmModal from '../../components/common/ConfirmModal/ConfirmModal';
import { reverseOrder } from './components/cancel-order';
import { RepeatOrderModal } from './components/repeat-order-modal';
import { UpdateSelfDeliveryInfo } from './components/update-self-delivery-info';
import { Can } from '../../utils/context/can';

export function OrderActions({ order }: { order: Order }) {
  const [isRepeatOrderModalOpen, setIsRepeatOrderModalOpen] = useState(false);
  const [isUpdateSelfDeliveryModalOpen, setIsUpdateSelfDeliveryModalOpen] =
    useState(false);
  const dispatch = useAppDispatch();
  function onRepeatOrder(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation();
    setIsRepeatOrderModalOpen(true);
  }
  function onUpdateDeliveryInfo(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation();
    setIsUpdateSelfDeliveryModalOpen(true);
  }
  return (
    <>
      {' '}
      {order.order_status.status !== 'canceled' && (
        <Menu>
          <MenuButton
            onClick={(e) => e.stopPropagation()}
            as={Button}
            rightIcon={<HamburgerIcon />}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _active={{
              bg: 'transparent',
              transform: 'none',
              boxShadow: 'none',
            }}
          />
          <Portal>
            <MenuList fontFamily="Montserrat">
              {order?.print_form && (
                <MenuItem onClick={(event) => downloadBill(event, order)}>
                  Скачать счет
                </MenuItem>
              )}

              {order.delivery_type === 'self-delivery' && (
                <Can I="change" a="order">
                  <MenuItem onClick={(event) => onUpdateDeliveryInfo(event)}>
                    Дополнить информацию
                  </MenuItem>
                </Can>
              )}

              <Can I="add" a={'cartitem'}>
                <MenuItem onClick={(event) => onRepeatOrder(event)}>
                  Повторить заказ
                </MenuItem>
              </Can>
              {!(order.paid || order.shipped) && (
                <ConfirmModal
                  openButton={
                    <MenuItem color={'red.400'}>Отменить заказ</MenuItem>
                  }
                  confirm={() => reverseOrder(dispatch, order)}
                  texts={{
                    title: 'Отменить заказ',
                    text: 'Вы уверены, что хотите отменить этот заказ?',
                    confirm: 'Да',
                    cancel: 'Отмена',
                  }}
                />
              )}
            </MenuList>
          </Portal>
        </Menu>
      )}
      <RepeatOrderModal
        isOpen={isRepeatOrderModalOpen}
        onClose={() => setIsRepeatOrderModalOpen(false)}
        order={order}
      />
      <UpdateSelfDeliveryInfo
        isOpen={isUpdateSelfDeliveryModalOpen}
        onClose={() => setIsUpdateSelfDeliveryModalOpen(false)}
        order={order}
      />
    </>
  );
}
