import { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

export const useMessage = () => {
  return useContext(MessageContext);
};

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = (text, type, duration = 3000) => {
    const id = Date.now();
    setMessages((prevMessages) => [...prevMessages, { id, text, type }]);
    setTimeout(() => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== id)
      );
    }, duration);
  };

  const showMessage = (text, duration) => {
    addMessage(text, 'success', duration);
  };

  const showError = (text, duration) => {
    const getErrorMessageByStatusCode = (statusCode) => {
      switch (statusCode?.split(' ')) {
        case statusCode.includes('400'):
          return 'Ошибка 400: некорректный запрос';
        case statusCode.includes('401'):
          return 'Ошибка 401: неавторизован';
        case statusCode.includes('403'):
          return 'Ошибка 403: доступ запрещен';
        case statusCode.includes('404'):
          return 'Ошибка 404: ресурс не найден';
        case statusCode.includes('422'):
          return 'Ошибка 404: логическая ошибка';
        case statusCode.includes('500'):
          return 'Ошибка 500: ошибка сервера';
        default:
          return `Ошибка ${statusCode}: неизвестная ошибка`;
      }
    };
    addMessage(getErrorMessageByStatusCode(text), 'error', duration);
  };

  return (
    <MessageContext.Provider value={{ messages, showMessage, showError }}>
      {children}
    </MessageContext.Provider>
  );
};
