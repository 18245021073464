import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { FetchStatusType } from '../../types/interfaces';

export function renderSkeleton(status: FetchStatusType) {
  return Array.from({ length: 3 }).map((_, index) => (
    <Skeleton
      borderRadius={'15px'}
      endColor="gray.300"
      startColor="gray.200"
      marginBottom={'20px'}
      w={'100%'}
      key={index}
      minHeight={'200px'}
      isLoaded={status !== 'loading'}
      speed={2}
    />
  ));
}
