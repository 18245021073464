import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { Invite } from '../../types/interfaces';

export const invitesApi = {
  getInvites: ({id}: {id: number}): Promise<AxiosResponse<Invite[]>> => apiClient.get(`invites?status=unused&owner=${id}`),

  createInvite: (inviteData: Partial<Invite>): Promise<AxiosResponse<Invite>> =>
    apiClient.post<Invite>('invites/', inviteData),

  getInvite: (inviteId: string): Promise<AxiosResponse<Invite>> =>
    apiClient.get<Invite>(`invites/${inviteId}/`),

  deleteInvite: (inviteId: string): Promise<AxiosResponse<void>> =>
    apiClient.delete(`invites/${inviteId}/`),
};
