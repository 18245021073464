import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { Company } from '../../types/interfaces';

export const companiesApi = {
  getAllCompanies: (): Promise<AxiosResponse<Company[]>> => {
    return apiClient.get<Company[]>('/companies/');
  },

  getCompanyById: (id: number): Promise<AxiosResponse<Company>> => {
    return apiClient.get<Company>(`/companies/${id}/`);
  },

  createCompany: (data: Company): Promise<AxiosResponse<Company>> => {
    return apiClient.post<Company>('/companies/', data);
  },

  updateCompany: (
    id: number,
    data: Company
  ): Promise<AxiosResponse<Company>> => {
    return apiClient.put<Company>(`/companies/${id}/`, data);
  },

  partialUpdateCompany: (
    id: number,
    data: Partial<Company>| FormData,
    type?: string
  ): Promise<AxiosResponse<Company>> => {
    if(type === 'not-form-data') {
      return apiClient.patch<Company>(`/companies/${id}/`, data);
    }
    return apiClient.patch<Company>(`/companies/${id}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteCompany: (id: number): Promise<AxiosResponse<void>> => {
    return apiClient.delete(`/companies/${id}/`);
  },

  registerCompany: (data:any): Promise<AxiosResponse<void>> => {
    return apiClient.post(`/companies/register/`, data);
  },
};


