import React from 'react';
import styles from '../styles/LegalEntityCard.module.scss';
import { LegalEntity } from '../../../types/interfaces';
import { Link } from 'react-router-dom';
import ContactInfo from '../../../components/common/ContactInfo/ContactInfo';
import { Avatar } from '@chakra-ui/react';
import CardLayout from '../../../components/layout/CardLayout';
import { CardButton } from '../../../components/common/CardButton/CardButton';
import { useAppDispatch } from '../../../store/store';
import { deleteLegalEntity } from '../../../store/legalEntities/actions';
import { deleteAppLegalEntity } from '../../../store/legalEntities/reducer';
import LegalEntityTable from './LegalEntityTable';
import { MyModal } from '../../../components/common/MyModal/MyModal';
import { Can } from '../../../utils/context/can';
interface LegalEntityCardProps {
  legalEntity: LegalEntity;
}

const LegalEntityCard = ({ legalEntity }: LegalEntityCardProps) => {
  const [showLegalModal, setShowLegalModal] = React.useState(false);
  const dispatch = useAppDispatch();
  return (
    <CardLayout>
      <CardLayout.Header withLine={true}>
        <Avatar
          name={legalEntity.name}
          // src={legalEntity.logo}
          className={styles.dashboard_manager__card_image}
        />
        <h2 className={styles['legal-entity__title']}>{legalEntity.name}</h2>
      </CardLayout.Header>
      <CardLayout.Main>
        <ContactInfo company={legalEntity} />
      </CardLayout.Main>
      <CardLayout.Footer>
        <div className={styles['legal-entity__footer']}>
          <Can I="change" a="legalentity">
            <CardButton
              link={`legal-entities/${legalEntity.id}`}
              title="Изменить"
            />
            <CardButton
              type="delete"
              onClick={() => {
                dispatch(deleteLegalEntity(legalEntity.id));
                dispatch(deleteAppLegalEntity(legalEntity.id));
              }}
              title="Удалить юридическое лицо?"
            />
          </Can>
          <CardButton
            type="eye"
            onClick={() => setShowLegalModal(true)}
            title="Подробнее"
          />
          <MyModal
            isOpen={showLegalModal}
            onClose={() => setShowLegalModal(false)}
          >
            <LegalEntityTable legalEntity={legalEntity} />
          </MyModal>
        </div>
      </CardLayout.Footer>
    </CardLayout>
  );
};

export default LegalEntityCard;
