import { createAsyncThunk } from '@reduxjs/toolkit';
import { Employee, PatchEmployeeRequest } from '../../types/interfaces';
import { employeesApi } from '../../api/services/employees.service';

export const fetchAllEmployees = createAsyncThunk(
  'employees/fetchAll',
  async () => {
    const response = await employeesApi.getAllEmployees();
    return response.data;
  }
);

export const fetchEmployeeById = createAsyncThunk(
  'employees/fetchById',
  async (id: string | undefined) => {
    const response = await employeesApi.getEmployeeById(id);
    return response.data;
  }
);

export const createEmployee = createAsyncThunk(
  'employees/create',
  async (data: Employee) => {
    const response = await employeesApi.createEmployee(data);
    return response.data;
  }
);

export const updateEmployee = createAsyncThunk(
  'employees/update',
  async ({ id, data }: { id: string; data: Employee }) => {
    const response = await employeesApi.updateEmployee(id, data);
    return response.data;
  }
);

export const partialUpdateEmployee = createAsyncThunk(
  'employees/partialUpdate',
  async ({ id, data }: { id: string; data: PatchEmployeeRequest }) => {
    const response = await employeesApi.partialUpdateEmployee(id, data);
    return response.data;
  }
);

export const deleteEmployee = createAsyncThunk(
  'employees/delete',
  async (id: string) => {
    await employeesApi.deleteEmployee(id);
    return id;
  }
);
export const registerEmployee = createAsyncThunk(
  'employees/register',
  async (data: Employee) => {
    const response = await employeesApi.registerEmployee(data);
    return response.data;
  }
);
