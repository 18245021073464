import { Checkbox, HStack, Stack, Text } from '@chakra-ui/react';
import {
  Item,
  FetchStatusType,
  VerificationItem,
} from '../../../../types/interfaces';
import Button from '../../../common/Button/Button';

const SelectedProductDetail = ({
  product,
  pricesInRange,
  inputValue,
  setInputValue,
  handler,
  status,
  addingItemToCart,
  removeFromCart,
  isWithVerification,
  verificationQuantity,
  inCart,
  verificationItem,
  verificationHandler
}: {
  inputValue: string;
  setInputValue: any;
  handler: any;
  product: Item;
  pricesInRange: number;
  inCart: boolean;
  isWithVerification: boolean;
  addingItemToCart: (
    id: number,
    quantity: number,
    verification_item: string | null,
    verification_quantity: number
  ) => void;
  removeFromCart: (id: number) => void;
  status: FetchStatusType;
  verificationQuantity: number;
  verificationItem: VerificationItem | null;
  verificationHandler: (e: boolean) => void
}) => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-between"
      spacing={4}
      p={4}
      boxShadow="md"
      borderRadius="md"
      bg="white"
      w="full"
    >
      <Text fontSize="xl" fontWeight="bold" display="flex" columnGap="2">
        <Text
          textDecoration="line-through"
          color="gray"
          fontSize="xl"
          fontWeight="bold"
        >
          {pricesInRange < product?.prices?.[0]?.price && (
            <p>{product?.prices?.[0].price} ₽</p>
          )}
        </Text>
        <Text fontSize="xl" fontWeight="bold">
          {pricesInRange && `${pricesInRange} ₽`}
        </Text>
      </Text>
      <HStack align="center" spacing={2}>
        <Text fontWeight="medium">Кол-во</Text>
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handler}
          type="number"
          className="detail__input"
        />
      </HStack>
      {!!verificationItem && (
        <HStack align="center" spacing={2}>
          <Checkbox
            isChecked={isWithVerification}
            onChange={(e) => verificationHandler(e.target.checked)}
            size="lg"
            colorScheme="red"
          >
            С поверкой
          </Checkbox>
        </HStack>
      )}
      <Button
        status={status}
        onClick={() => {
          if (!inCart) {
            addingItemToCart(
              product?.id,
              +inputValue,
              verificationItem ? verificationItem.id.toString() : null,
              isWithVerification ? verificationQuantity : 0
            );
          } else {
            removeFromCart(product?.id);
          }
        }}
        className="detail__btn"
        type="button"
        isWhite={inCart}
      >
        {!inCart ? 'Добавить в корзину' : 'Удалить из корзины'}
      </Button>
    </Stack>
  );
};

export default SelectedProductDetail;
