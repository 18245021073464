import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import {
  Cart,
  CheckAvailabilityResponse,
  TokenResponse,
  User,
} from '../../types/interfaces';

export const cartApi = {
  getCart: (): Promise<AxiosResponse<Cart>> => apiClient.get<Cart>('cart/'),

  addItemToCart: ({
    id,
    quantity,
    verification_item,
    verification_quantity,
  }: {
    id: number;
    quantity: number;
    verification_item?: null | string;
    verification_quantity?: number;
  }): Promise<AxiosResponse<Cart>> =>
    apiClient.post<Cart>('cart/', [{ id, quantity, verification_item, verification_quantity }]),

  addItemsToCart: (
    data: {
      id: number;
      quantity: number;
    }[]
  ): Promise<AxiosResponse<Cart>> => apiClient.post<Cart>('cart/', data),

  deleteItemFromCart: (id: number): Promise<AxiosResponse<Cart>> =>
    apiClient.delete<Cart>(`cart/${id}`),
};
