import React from 'react';
import { Link } from 'react-router-dom';
import redPlusCircle from '../../../assets/sprites/big_red_plus_circle.svg';
import styles from './AddEntity.module.scss';
import { Button } from '@chakra-ui/react';
interface AddEntityProps {
  text: string;
  path?: string;
  state?: any;
  isLink?: boolean;
  onClick?: () => void;
}

const AddEntity = ({ text, path='/', state = {}, isLink=true, onClick=() => {} }: AddEntityProps): JSX.Element => {
  if(!isLink) {
    return (
      <Button
        leftIcon={<img src={redPlusCircle} alt={`Добавить ${text}`} />}
        className={styles['add-entity']}
        onClick={onClick}
      >
        Добавить {text}
      </Button>
    );
  }
  return (
    <Link state={state} to={path}>
      <Button
        leftIcon={<img src={redPlusCircle} alt={`Добавить ${text}`} />}
        className={styles['add-entity']}
      >
        Добавить {text}
      </Button>
    </Link>
  );
};

export default AddEntity;
