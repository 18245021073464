import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import catalogPencil from '../../../assets/sprites/catalog_pencil.svg';
import basket from '../../../assets/sprites/trash.svg';
import eye from '../../../assets/sprites/eye.svg';
import copy from '../../../assets/sprites/copy_invites.svg';

import styles from './CardButton.module.scss';
import Button from '../Button/Button';
import { MyModal } from '../MyModal/MyModal';


export function CardButton({ link = '/', title = 'Удалить', type = 'edit', onClick = () => { } , ...ButtonProps }: any) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()

  const navigateHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(link);
  };

  if (type === 'delete') {
    return (
      <>
        <MyModal isOpen={showModal} onClose={() => setShowModal(false)}>
          <div className={styles.modal__text}>{title}</div>
          <div className={styles.modal__confirm}>
            <Button onClick={onClick}>Да</Button>
            <Button isWhite={true} onClick={() => setShowModal(false)}>Нет</Button>
          </div>
        </MyModal >
        <button className={styles.item_card__image} onClick={() => setShowModal(true)}>
          <img className={styles.item_card__trash} src={basket} alt="Удалить" title={title} />
        </button>
      </>
    );
  }

  if (type === 'eye') {
    return (
      <button {...ButtonProps} onClick={onClick} className={styles.item_card__image}>
        <img className={styles.item_card__eye} src={eye} alt="Просмотреть" title={title} />
      </button>
    );
  }
  if (type === 'copy') {
    return (
      <button {...ButtonProps} onClick={onClick} className={styles.item_card__image}>
        <img className={styles.item_card__eye} src={copy} alt="Copy" title={title} />
      </button>
    );
  }

  return (
    <button {...ButtonProps} className={styles.item_card__image} onClick={navigateHandler}>
      <img className={styles.item_card__bar_pencil} src={catalogPencil} alt="Изменить" title={title} />
    </button>
  );
}
