import { lazy } from 'react';
import ProtectedRoute from '../ProtectedRoute';
import CompaniesCompanyAddEdit from '../../components/modules/CompaniesLegalEntityAddEdit/CompaniesLegalEntityAddEdit.jsx';
import CompaniesContactPersonAddEdit from '../../components/modules/CompaniesContactPersonAddEdit/CompaniesContactPersonAddEdit';
import CompaniesEmployeeEdit from '../../components/modules/CompaniesEmployeeEdit/CompaniesEmployeeEdit';
import Company from '../../pages/Companies/Company';

//   () =>
//     import(
//       '../../components/modules/CompaniesContactPersonAddEdit/CompaniesContactPersonAddEdit.jsx'
//     )
// );
// const CompanyView = lazy(
//   () => import('../../components/modules/CompanyView/CompanyView')
// );
const Companies = lazy(() => import('../../pages/Companies/Companies'));

export const companies_routes = [
  {
    path: 'companies',
    element: <Companies />,
    loader: () => {
      return (document.title = 'Компании');
    },
  },
  {
    path: 'company/:companyId?',
    element: <Company />,
    loader: () => {
      return (document.title = 'Компания');
    },
  },
  {
    path: 'companies?active_tab=:activeTab',
    element: <Companies />,
  },
  // {
  //   path: 'companies/:id',
  //   element: <CompanyView />,
  // },
  {
    path: 'company/:companyId?/legal-entities/:legalEntityId',
    element: (
      <ProtectedRoute
        action={'change'}
        subject={'legalentity'}
        redirectTo={'/companies'}
        children={<CompaniesCompanyAddEdit />}
      />
    ),
  },
  {
    path: 'company/:companyId?/contact-person/:contactPersonId',
    element: (
      <ProtectedRoute
        action={'change'}
        subject={'contact'}
        redirectTo={'/companies'}
        children={<CompaniesContactPersonAddEdit />}
      />
    ),
  },
  {
    path: 'company/:companyId?/employee/:employeeId',
    element: (
      <ProtectedRoute
        action={'editemployee'}
        subject={'username'}
        redirectTo={'/companies'}
        children={<CompaniesEmployeeEdit />}
      />
    ),
  },
  {
    path: 'company/:companyId?/legal-entities',
    element: (
      <ProtectedRoute
        action={'add'}
        subject={'legalentity'}
        redirectTo={'/companies'}
        children={<CompaniesCompanyAddEdit />}
      />
    ),
  },
  {
    path: 'company/:companyId?/contact-person',
    element: (
      <ProtectedRoute
        action={'add'}
        subject={'contact'}
        redirectTo={'/companies'}
        children={<CompaniesContactPersonAddEdit />}
      />
    ),
  },
];
