import * as Yup from 'yup';

export const step0Schema = Yup.object().shape({
  username: Yup.string()
    .required('Обязательное поле')
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов')
    .matches(
      /^[a-zA-Zа-яА-Я0-9_.@-]+$/,
      'Логин может содержать только буквы, цифры, подчеркивания, точки, @ и дефисы.'
    ),
  email: Yup.string()
    .email('Введите корректный email')
    .required('Введите email'),
  terms: Yup.bool().oneOf([true], 'Примите условия.'),
  password: Yup.string()
    .required('Введите пароль')
    .min(8, 'Минимум 8 символов')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[^\s]{8,}$/,
      'Нужна хотя бы 1 буква и 1 цифра без пробелов.'
    )
    .matches(
      /^[^\u0400-\u04FF]*$/,
      'Использование букв кириллицы запрещено.'
    ),


  first_name: Yup.string().required('Введите имя').min(2, 'Минимум 2 символа'),
  last_name: Yup.string().required('Введите фамилию').min(2, 'Минимум 2 символа'),
  mobile_phone: Yup.string()
    .matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Некорректный номер'),
});

export const step1Schema = Yup.object().shape({
  name: Yup.string().required('Введите имя'),
  email: Yup.string()
    .email('Введите корректный email')
    .required('Введите email'),
  inn: Yup.string()
    .required('Введите ИНН')
    .matches(/^\d+$/, 'Только цифры')
    .min(10, 'Минимум 10 символов')
    .max(12, 'Максимум 12 символов'),
  online_documents: Yup.bool(),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        phone: Yup.string().test('phone', 'Некорректный номер', (value) => {
          return (
            value &&
            value !== '+7 (___) ___-__-__' &&
            /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value)
          );
        }),
      })
    )
    .min(1, 'Необходимо ввести хотя бы один номер телефона')
    .required('Необходимо ввести хотя бы один номер телефона')
    .test(
      'filled-phones',
      'Необходимо ввести хотя бы один номер телефона',
      (value) => {
        if (!value) return false;
        return value.some((phone) => phone.phone && phone.phone.trim() !== '');
      }
    ),
});

export const contactPersonSchema = Yup.object().shape({
  name: Yup.string().required('Введите имя').min(2, 'Минимум 2 символа'),
  email: Yup.string().required('Введите email').email('Некорректный email'),
  patronymic: Yup.string(),
  surname: Yup.string().required('Введите фамилию').min(2, 'Минимум 2 символа'),
  position: Yup.string()
    .required('Введите должность')
    .min(2, 'Минимум 2 символа'),
  personal_phone: Yup.string()
    .required('Введите номер телефона')
    .matches(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Некорректный номер'),
});

export const addressSchema = Yup.object().shape({
  country: Yup.string()
    .min(2, 'Минимум 2 символа')
    .max(100, 'Максимум 100 символов')
    .required('Введите страну'),
  region: Yup.string()
    .min(2, 'Минимум 2 символа')
    .max(100, 'Максимум 100 символов')
    .required('Введите регион'),
  city: Yup.string()
    .min(2, 'Минимум 2 символа')
    .max(100, 'Максимум 100 символов')
    .required('Введите город'),
  street: Yup.string()
    .min(2, 'Минимум 2 символа')
    .max(150, 'Максимум 150 символов')
    .required('Введите улицу'),
  house: Yup.string()
    .min(1, 'Минимум 1 символ')
    .max(50, 'Максимум 50 символов')
    .required('Введите номер дома'),
  apartment: Yup.string()
    // .min(1, 'Минимум 1 символ')
    .max(50, 'Максимум 50 символов'),
  // .required('Введите номер квартиры'),
  postcode: Yup.string()
    .required('Введите индекс')
    .matches(/^\d+$/, 'Только цифры')
    .length(6, 'Ровно 6 символов'),
});

export const loginSchema = Yup.object().shape({
  username: Yup.string().required('Введите логин'),
  password: Yup.string().required('Введите пароль'),
  rememberMe: Yup.bool(),
});

export const passwordResetSchema = Yup.object().shape({
  email: Yup.string()
    .required('Обязательное поле')
    .email('Неверный формат электронной почты'),
});

export const passwordResetConfirmSchema = Yup.object().shape({
  new_password: Yup.string()
    .required('Введите пароль')
    .min(8, 'Минимум 8 символов')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[^\s]{8,}$/,
      'Нужна хотя бы 1 буква и 1 цифра без пробелов.'
    )
    .matches(
      /^[^\u0400-\u04FF]*$/,
      'Использование букв кириллицы запрещено.'
    ),
});
