import React, { useEffect, useState } from 'react';
import ButtonMy from '../../../common/Button/Button';
import styles from '../CartConfirm.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../../../common/InputField/InputField';
import { AddIcon } from '@chakra-ui/icons';
import { Button, GridItem } from '@chakra-ui/react';
import { orderConfirmAddresSchema } from '../../../../utils/validators/companyAddEditValidators.js';
import { orderConfirmFormSections } from '../../../../utils/constants/formSections.js';
import { Address } from '../../../../types/interfaces';
import { MyModal } from '../../../common/MyModal/MyModal';
interface CartAddAddressModalProps {
  handleAddressSubmit: (data: Address) => void;
  setValue: any;
}
export function CartAddAddressModal({ handleAddressSubmit, setValue }: CartAddAddressModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(orderConfirmAddresSchema),
    shouldUnregister: false,
    defaultValues: {
      country: '',
      region: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      postcode: '',
    },
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const submit = (data: Address) => {
    debugger
    handleAddressSubmit({ ...data, id: new Date().getTime() });
    reset();
    setModalOpen(false);
  };
  useEffect(() => {
    return () => {
      reset({
        country: '',
        region: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        postcode: '',
      })
    }
  }, [])
  return (
    <>
      <Button
        onClick={() => {
          setModalOpen(true);
          setValue('isAddAddressHandler', true);
        }}
        mt={'20px'}
        flex={'1'}
        bg={'transparent'}
        fontWeight={'bold'}
        rightIcon={<AddIcon className={styles.add_address_icon_from_btn} />}
      >
        Добавить адрес вручную
      </Button>
      <MyModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <form onSubmit={handleSubmit(submit)}>
          <h2 className={styles.form__title}>
            {orderConfirmFormSections.title}
          </h2>
          {orderConfirmFormSections.fields.map((field) => (
            <InputField
              key={field.id}
              inputName={field.inputName}
              label={field.label}
              errors={errors}
              register={register}
            />
          ))}
          <ButtonMy type="submit">Добавить</ButtonMy>
        </form>
      </MyModal>
    </>
  );
}
