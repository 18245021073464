import React, { InputHTMLAttributes, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import eye from '../../../assets/sprites/eye-fill.svg';
import eyeSlash from '../../../assets/sprites/eye-slash-fill.svg';
import deleteIcon from '../../../assets/sprites/Verctor.svg';

import styles from './InputField.module.scss';
import { Input } from '@chakra-ui/react';

const getErrorMessage = (errors: any, inputName: string, index: number) => {
  if (typeof errors === 'string') {
    return errors;
  }
  const keys = inputName.split('.');
  let currentError = errors;

  for (const key of keys) {
    if (Array.isArray(currentError)) {
      currentError = currentError[index];
    }
    if (currentError && currentError[key]) {
      currentError = currentError[key];
    } else {
      return null;
    }
  }

  return currentError.message;
};

interface IInputField extends Partial<InputHTMLAttributes<HTMLInputElement>> {
  id?: string;
  inputName?: string;
  label?: string;
  type?: string;
  errors?: any;
  as?: any;
  register?: any;
  validationSchema?: any;
  index?: number;
  defaultValue?: string;
  placeholder?: string;
  mask?: any;
  className?: string;
  onBlur?: any;
  hasMarginAndPadding?: boolean;
  deleteIconHandler?: (e: React.MouseEvent) => void;
}

const InputField = React.forwardRef(
  (
    {
      id = '',
      inputName = '',
      label = '',
      type = 'text',
      errors = {},
      register = () => {},
      validationSchema = {},
      index = 0,
      defaultValue = '',
      mask = null,
      as = 'input',
      deleteIconHandler,
      onBlur = () => {},
      className = '',
      placeholder = '',
      hasMarginAndPadding = true,
      ...props
    }: IInputField,
    ref
  ) => {
    const [show, setShow] = useState(false);
    const handleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setShow(!show);
    };

    const errorMessage = getErrorMessage(errors, inputName, index);
    const isRequired = validationSchema?.required;
    return (
      <div
        className={`${styles.field}`}
        style={
          hasMarginAndPadding
            ? {}
            : { marginTop: 0, marginBottom: 0, paddingBottom: 0 }
        }
      >
        <div
          className={styles.field_header}
          style={!label ? { display: 'block' } : {}}
        >
          <label htmlFor={id} className={styles.label}>
            {label}
            {isRequired && '*'}
          </label>
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        </div>
        <div className={styles.input_wrapper}>
          <Input
            ref={ref}
            {...props}
            id={id}
            name={inputName}
            type={show ? 'text' : type}
            defaultValue={defaultValue}
            className={`${styles.input} ${
              errorMessage ? styles.input_error : ''
            } ${className}`}
            {...register(inputName, validationSchema)}
            as={mask ? ReactInputMask : undefined}
            mask={mask}
            onBlur={onBlur}
            placeholder={placeholder}
          />
          {type === 'password' && (
            <>
              {show ? (
                <button
                  type="button"
                  onClick={handleClick}
                  className={styles.icon_button}
                >
                  <img src={eye} alt=" " />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleClick}
                  className={styles.icon_button}
                >
                  <img src={eyeSlash} alt=" " />
                </button>
              )}
            </>
          )}
          {deleteIconHandler && (
            <>
              <button
                type="button"
                onClick={deleteIconHandler}
                className={styles.icon_button}
              >
                <img src={deleteIcon} alt=" " />
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default InputField;