import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Autosuggest from 'react-autosuggest';
import {
  Box,
  Flex,
  Heading,
  Avatar,
  Text,
  useColorModeValue,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from '@chakra-ui/react';
import back from '../../../assets/sprites/arrow_back.png';
import ConfirmModal from '../../common/ConfirmModal/ConfirmModal';
import { useDispatch } from 'react-redux';
import { User } from '../../../types/interfaces';
import { AppDispatch } from '../../../store/store';
import { logout } from '../../../store/auth/actions';
import './Header.scss';

const Header = ({ me }: { me: User }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationsArray = location.pathname.split('/');
  const dispatch = useDispatch<AppDispatch>();

  const linkHandler = () => {
    navigate(-1);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleProfileClick = () => {
    navigate('/profile');
    setIsMenuOpen(false);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    setIsMenuOpen(false);
  };

  return (
    <Box as="header" className="header">
      <Flex
        className="header__container"
        justifyContent="space-between"
        alignItems="center"
        padding={'10px 0px'}
      >
        <Box as="nav" className="header__nav">
          {location.pathname === '/' ? (
            <Heading size="md" className="header__welcome">
              Добро пожаловать
            </Heading>
          ) : (
            locationsArray.length > 2 &&
            !location.pathname.startsWith('/issues') && (
              <button
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={linkHandler}
                className="header__back"
              >
                <img src={back} alt="" className="header__back-image" />
                <Box as="span" ml={2}>
                  Назад
                </Box>
              </button>
            )
          )}
        </Box>
        <Flex
          p={'5px 10px'}
          bg={'#fff'}
          alignItems={'center'}
          borderRadius={'37px'}
          className="header__right"
        >
          <Box
            as="nav"
            bg={useColorModeValue('white.100', 'white.800')}
            className="header__user"
          >
            <Menu isOpen={isMenuOpen}>
              <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <Flex
                  p={'5px 10px'}
                  bg={'#fff'}
                  alignItems={'center'}
                  borderRadius={'37px'}
                >
                  <Flex
                    p={'3px 3px'}
                    bg={'#fff'}
                    alignItems={'center'}
                    borderRadius={'37px'}
                  >
                    {me.username && (
                      <>
                        <Avatar
                          src={me.photo}
                          name={me.username}
                          color="white"
                          backgroundColor="red.200"
                          w={'35px'}
                          h={'35px'}
                        />

                        <Box ml="3">
                          <Text className="header__user-name" fontWeight="bold">
                            {me.first_name} {me.last_name}
                          </Text>
                          <Text fontSize="small">{me?.company?.name}</Text>
                        </Box>
                      </>
                    )}
                  </Flex>
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleProfileClick}>Профиль</MenuItem>
                <ConfirmModal
                  openButton={<MenuItem>Выйти</MenuItem>}
                  confirm={handleLogoutClick}
                  texts={{
                    title: 'Выход',
                    text: `Вы уверены, что хотите выйти?`,
                    cancel: 'Отмена',
                    confirm: 'Выйти',
                  }}
                />
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
