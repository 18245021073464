import React from 'react';
import styles from '../CartConfirm.module.scss';
interface ICartFieldWrp {
  title?: string;
  children: JSX.Element;
  error?: any
}
export function CartFieldWrp({ title, children, error }: ICartFieldWrp) {
  return (
    <div className={styles['cart-confirm__field-wrp']}>
      {title && (
        <div className={styles['cart-confirm__field-title']}>
          <span>{title}</span>{error && <span className={styles['cart-confirm__field-title-error']}>{error}</span>}
          </div>
      )}
      {children}
    </div>
  );
}
