import React from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import nextIcon from '../../assets/sprites/next.svg';
import prevIcon from '../../assets/sprites/prev.svg';

const PaginationButtons = ({ currentPage, setCurrentPage, ordersLength }: any) => {
  const activeStyle = {
    backgroundColor: '#E3282A',
    color: '#fff',
  };
  const inactiveStyle = { color: '#BCBCBC' };
  const totalPages = Math.ceil(ordersLength / 10);
  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const maxButtons = 3;
    const buttons = [];
    let startPage = 1;

    if (totalPages > maxButtons) {
      startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    }

    if (startPage > 1) {
      buttons.push(
        <Button
          border="none"
          key="first"
          size={'sm'}
          fontSize={'18px'}
          onClick={() => handlePageChange(1)}
        >
          1
        </Button>
      );
      if (startPage > 2) {
        buttons.push(
          <Button
            key="ellipsis-start"
            isDisabled
            border="none"
            size={'sm'}
            fontSize={'18px'}
          >
            ...
          </Button>
        );
      }
    }

    for (
      let page = startPage;
      page <= Math.min(startPage + maxButtons - 1, totalPages);
      page++
    ) {
      buttons.push(
        <Button
          key={page}
          onClick={() => handlePageChange(page)}
          style={page === currentPage ? activeStyle : inactiveStyle}
          _hover={{ bg: 'transparent', color: 'orange' }}
          _focus={{ boxShadow: 'none' }}
          border="none"
          size={'sm'}
          fontSize={'18px'}
        >
          {page}
        </Button>
      );
    }

    if (startPage + maxButtons - 1 < totalPages) {
      if (startPage + maxButtons < totalPages) {
        buttons.push(
          <Button
            key="ellipsis-end"
            isDisabled
            border="none"
            size={'sm'}
            fontSize={'18px'}
          >
            ...
          </Button>
        );
      }
      buttons.push(
        <Button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          border="none"
          size={'sm'}
          fontSize={'18px'}
        >
          {totalPages}
        </Button>
      );
    }

    return buttons;
  };

  return (
    <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
      {currentPage !== 1 && (
        <button onClick={() => setCurrentPage((prev: any) => prev - 1)}>
          <img src={prevIcon} alt="Назад" />
        </button>
      )}
      <ButtonGroup variant="outline" spacing="1">
        {renderPaginationButtons()}
      </ButtonGroup>
      {currentPage !== totalPages && (
        <button onClick={() => setCurrentPage((prev: any) => prev + 1)}>
          <img src={nextIcon} alt="Вперёд" />
        </button>
      )}
    </Box>
  );
};

export default PaginationButtons;