import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusType, Invite } from '../../types/interfaces';
import {
  fetchAllInvites,
  fetchInviteById,
  createInvite,
  deleteInvite,
} from './actions';
import { RootState } from '../store';
export interface InvitesState {
  invites: Invite[];
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: string | null;
  currentInvite: Invite | {};
}

const initialState: InvitesState = {
  invites: [],
  status: 'idle',
  listFetchStatus: 'idle',
  currentInvite: {},
  error: null,
};
const invitesSlice = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    resetInvites: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInvites.pending, (state) => {
        state.listFetchStatus = 'loading';
      })
      .addCase(fetchAllInvites.fulfilled, (state, action) => {
        state.listFetchStatus = 'succeeded';
        state.invites = action.payload;
      })
      .addCase(fetchAllInvites.rejected, (state, action) => {
        state.listFetchStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch invites';
      })
      .addCase(fetchInviteById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInviteById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentInvite = action.payload;
      })
      .addCase(fetchInviteById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch invite';
      })
      .addCase(createInvite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createInvite.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.invites = [...state.invites, action.payload];
      })
      .addCase(createInvite.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create invite';
      })
      .addCase(deleteInvite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteInvite.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.invites = state.invites.filter(
          (invite) => invite.id !== action.payload
        );
      })
      .addCase(deleteInvite.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete invite';
      });
  },
});

export const { resetInvites } = invitesSlice.actions;

export const selectAllInvites = (state: any): Invite[] => state.invites.invites;
export const selectCurrentInvite = (state: any): Invite =>
  state.invites.currentInvite;
export const selectInvitesStatus = (state: RootState): FetchStatusType =>
  state.invites.listFetchStatus;
export const selectInviteStatus = (state: any): FetchStatusType =>
  state.invites.status;
export const selectInvitesError = (state: any): string => state.invites.error;

export default invitesSlice.reducer;
