import { useState } from 'react';
import { selectInvitesStatus } from '../../../../store/invites/reducer';
import { createInvite } from '../../../../store/invites/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import Button from '../../../../components/common/Button/Button';
import InputField from '../../../../components/common/InputField/InputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MyModal } from '../../../../components/common/MyModal/MyModal';
import AddEntity from '../../../../components/common/AddEntity/AddEntity';
import './Invites.scss';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Обязательное поле')
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  surname: Yup.string()
    .required('Обязательное поле')
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
});

function Invites({ type = 'company' }): JSX.Element {
  const status = useAppSelector(selectInvitesStatus);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      surname: '',
    },
  });

  const onGenerateInvite = ({ name, surname }: any) => {
    const fullName = `${name} ${surname}`;
    dispatch(createInvite({ name: fullName }));
    reset();
    setShowModal(false);
  };
  return (
    <>
      <AddEntity
        text={type === 'employee' ? 'сотрудника' : 'инвайт'}
        isLink={false}
        onClick={() => setShowModal(true)}
      />
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <form
            onSubmit={handleSubmit(onGenerateInvite)}
            className="invites-wrp"
          >
            <p className="invites__title">
              Введите имя и фамилию приглашаемого сотрудника
            </p>
            <div className="my-4">
              <InputField
                label="Имя"
                inputName="name"
                register={register}
                errors={errors}
                autoComplete="off"
              />
              <InputField
                label="Фамилия"
                inputName="surname"
                register={register}
                errors={errors}
                autoComplete="off"
              />
              <Button type="submit" status={status}>
                Создать приглашение
              </Button>
            </div>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Invites;
