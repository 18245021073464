import ReactInputMask from 'react-input-mask';

export const companiesLegalEntityAddEditSections = [
  {
    title: 'Данные компании',
    fields: [
      { id: 'name', inputName: 'name', label: 'Название' },
      { id: 'inn', inputName: 'inn', label: 'ИНН' },
      { id: 'email', inputName: 'email', label: 'Email' },
      {
        id: 'phoneNumber',
        inputName: 'phoneNumber',
        label: 'Телефон',
        as: ReactInputMask,
        mask: '+7 (***) ***-**-**',
      },
      {
        id: 'additionalPhone',
        inputName: 'additionalPhone',
        label: 'Доп.номер телефона',
        as: ReactInputMask,
        mask: '+7 (***) ***-**-**',
      },
    ],
    checkbox: {
      id: 'onlineDocuments',
      inputName: 'onlineDocuments',
      label: 'Отправить документы онлайн',
    },
  },
  {
    title: 'Юридический адрес',
    fields: [
      {
        id: 'legalAddress.country',
        inputName: 'legalAddress.country',
        label: 'Страна юр. адреса',
      },
      {
        id: 'legalAddress.region',
        inputName: 'legalAddress.region',
        label: 'Регион юр. адреса',
      },
      {
        id: 'legalAddress.city',
        inputName: 'legalAddress.city',
        label: 'Город юр. адреса',
      },
      {
        id: 'legalAddress.street',
        inputName: 'legalAddress.street',
        label: 'Улица юр. адреса',
      },
      {
        id: 'legalAddress.house',
        inputName: 'legalAddress.house',
        label: 'Дом юр. адреса',
      },
      {
        id: 'legalAddress.apartment',
        inputName: 'legalAddress.apartment',
        label: 'Офис юр. адреса',
      },
      {
        id: 'legalAddress.postcode',
        inputName: 'legalAddress.postcode',
        label: 'Почтовый индекс юр. адреса',
      },
    ],
  },
  {
    title: 'Адрес доставки',
    fields: [
      {
        id: 'deliveryAddress.country',
        inputName: 'deliveryAddress.country',
        label: 'Страна доставки',
      },
      {
        id: 'deliveryAddress.region',
        inputName: 'deliveryAddress.region',
        label: 'Регион доставки',
      },
      {
        id: 'deliveryAddress.city',
        inputName: 'deliveryAddress.city',
        label: 'Город доставки',
      },
      {
        id: 'deliveryAddress.street',
        inputName: 'deliveryAddress.street',
        label: 'Улица доставки',
      },
      {
        id: 'deliveryAddress.house',
        inputName: 'deliveryAddress.house',
        label: 'Дом доставки',
      },
      {
        id: 'deliveryAddress.apartment',
        inputName: 'deliveryAddress.apartment',
        label: 'Офис доставки',
      },
      {
        id: 'deliveryAddress.postcode',
        inputName: 'deliveryAddress.postcode',
        label: 'Почтовый индекс доставки',
      },
    ],
  },
];

export const companiesContactPersonAddEditSections = [
  {
    title: 'Данные контакта',
    fields: [
      { id: 'name', inputName: 'name', label: 'Имя' },
      { id: 'surname', inputName: 'surname', label: 'Фамилия' },
      { id: 'patronymic', inputName: 'patronymic', label: 'Отчество' },
      { id: 'position', inputName: 'position', label: 'Должность' },
      // { id: 'email', inputName: 'email', label: 'Email' },
      {
        id: 'personal_phone',
        inputName: 'personal_phone',
        label: 'Телефон',
        as: ReactInputMask,
        mask: '+7 (***) ***-**-**',
      },
    ],
  },
];

export const companiesEmployeeEditSections = [
  {
    title: 'Данные сотрудника',
    fields: [
      { id: 'first_name', inputName: 'first_name', label: 'Имя' },
      { id: 'last_name', inputName: 'last_name', label: 'Фамилия' },
      { id: 'patronymic', inputName: 'patronymic', label: 'Отчество' },
      { id: 'email', inputName: 'email', label: 'Email' },
      {
        id: 'mobile_phone',
        inputName: 'mobile_phone',
        label: 'Телефон',
        as: ReactInputMask,
        mask: '+7 (***) ***-**-**',
      },
      { id: 'whatsapp', inputName: 'whatsapp', label: 'Whatsapp' },
      { id: 'telegram', inputName: 'telegram', label: 'Telegramm' },
    ],
  },
];

export const orderConfirmFormSections = {
  title: 'Адрес доставки',
  fields: [
    {
      id: 'country',
      inputName: 'country',
      label: 'Страна доставки',
    },
    {
      id: 'region',
      inputName: 'region',
      label: 'Регион доставки',
    },
    {
      id: 'city',
      inputName: 'city',
      label: 'Город доставки',
    },
    {
      id: 'street',
      inputName: 'street',
      label: 'Улица доставки',
    },
    {
      id: 'house',
      inputName: 'house',
      label: 'Дом доставки',
    },
    {
      id: 'apartment',
      inputName: 'apartment',
      label: 'Офис доставки',
    },
    {
      id: 'postcode',
      inputName: 'postcode',
      label: 'Почтовый индекс доставки',
    },
  ],
};
