import React from 'react';
import styles from './TextareaField.module.scss';
interface ITextareaField {
  id: string;
  name: string;
  label: string;
  errors: any;
  register: any;
  validationSchema?: any;
  maxLength?: number;
  currentVal?: string;
  placeholder?: string;
}
const TextareaField = ({
  id,
  name,
  label,
  errors,
  register,
  validationSchema,
  maxLength,
  currentVal,
  placeholder = '',
}: ITextareaField) => {
  const isRequired = validationSchema?.required;
  const hasLetters = currentVal?.length ?? 0 < Number(maxLength);
  return (
    <div className={styles.form__field}>
      <div className={styles.form__field_header}>
        <label htmlFor={id} className={styles.form__label}>
          {label}
        </label>
        {maxLength ? ( // has max length parameter
          errors[name] ? ( // if there is an error with field name
            <p className={styles.form__error}>{errors[name].message}</p> // print error messsage with certain css
          ) : (
            // if there is no error with field name
            <p
              className={[
                isRequired &&
                  (hasLetters
                    ? styles.form__length
                    : styles.form__error_length),
              ].join(' ')}
            >
              {currentVal?.length}/{maxLength}
            </p>
          )
        ) : (
          // no max length parameter
          errors[name] && ( // but if there is some other error with the field, print it
            <p className={styles.form__error}>{errors[name].message}</p>
          )
        )}
      </div>
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        className={`${styles.form__textarea} ${
          errors[name] ? styles.form__textarea_error : ''
        }`}
        {...register(name, validationSchema)}
      />
    </div>
  );
};

export default TextareaField;
