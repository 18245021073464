import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllEmployees,
  fetchEmployeeById,
  createEmployee,
  updateEmployee,
  partialUpdateEmployee,
  deleteEmployee,
  registerEmployee,
} from './actions';
import { Employee, FetchStatusType } from '../../types/interfaces';
import { RootState } from '../store';

export interface EmployeesState {
  employees: Employee[];
  status: FetchStatusType;
  listFetchStatus: FetchStatusType;
  error: string | null;
  currentEmployee: Employee | null;
}

const initialState: EmployeesState = {
  employees: [],
  status: 'idle',
  listFetchStatus: 'idle',
  error: null,
  currentEmployee: null,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetEmployees: () => initialState,
    resetCurrentEmployee: (state) => {
      state.currentEmployee = null;
      state.status = 'idle';
    },
    deleteAppEmployee: (state, action) => {
      state.employees = state.employees.filter(
        (employee) => employee.id !== action.payload
      );
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployees.pending, (state) => {
        state.listFetchStatus = 'loading';
      })
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.listFetchStatus = 'succeeded';
        state.employees = action.payload;
      })
      .addCase(fetchAllEmployees.rejected, (state, action) => {
        state.listFetchStatus = 'failed';
        state.error = action.error.message || 'Failed to fetch employees';
      })
      .addCase(fetchEmployeeById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEmployeeById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentEmployee = action.payload;
      })
      .addCase(fetchEmployeeById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch employee';
      })
      .addCase(createEmployee.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.employees.push(action.payload);
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create employee';
      })
      .addCase(updateEmployee.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.employees = state.employees.map((employee) => {
          if (employee.id === action.payload.id) {
            return action.payload;
          }
          return employee;
        });
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update employee';
      })
      .addCase(partialUpdateEmployee.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(partialUpdateEmployee.fulfilled, (state, action) => {
        //state.status = 'succeeded';
        state.employees = state.employees.map((employee) => {
          if (employee.id === action.payload.id) {
            return action.payload;
          }
          return employee;
        });
      })
      .addCase(partialUpdateEmployee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update employee';
      })
      .addCase(deleteEmployee.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.employees = state.employees.filter(
          (employee) => employee.id.toString() !== action.payload.toString()
        );
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete employee';
      })
      .addCase(registerEmployee.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerEmployee.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.employees.push(action.payload);
      })
      .addCase(registerEmployee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to register employee';
      });
  },
});

export const selectEmployees = (state: RootState): Employee[] =>
  state.employees.employees;
export const selectCurrentEmployee = (state: RootState): Employee | null =>
  state.employees.currentEmployee;
export const selectEmployeesStatus = (state: RootState): FetchStatusType =>
  state.employees.listFetchStatus;
export const selectEmployeesError = (state: RootState): string | null =>
  state.employees.error;

export const { resetEmployees, resetCurrentEmployee, deleteAppEmployee } =
  employeesSlice.actions;

export default employeesSlice.reducer;
