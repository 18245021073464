import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../common/Button/Button';
import { contactPersonSchema } from '../../../utils/validators/contactPersonSchema';
import { formatPhoneNumber } from '../../../utils/validators/phoneNormalize';
import { companiesContactPersonAddEditSections } from '../../../utils/constants/formSections';
import InputField from '../../common/InputField/InputField';
import LoadingWrapper from '../../common/Spinners/LoadingWrapper';
import styles from './CompaniesContactPersonAddEdit.module.scss';
import {
  createContact,
  fetchContactById,
  updateContact,
} from '../../../store/contacts/actions';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useMe } from '../../../pages/Area/Area';
import { AbilityContext } from '../../../utils/context/can';
import {
  resetCurrentContact,
  selectCurrentContact,
} from '../../../store/contacts/reducer';

interface IDefaultValues {
  id: string;
  name: string;
  surname: string;
  patronymic: string;
  position: string;
  personal_phone: string;
  integrator: string;
  main: boolean;
  cart: string;
  personality: string;
}
const CompaniesContactPersonAddEdit = () => {
  const { me } = useMe();
  const { contactPersonId } = useParams();
  const contact = useAppSelector(selectCurrentContact);
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.contacts.status);
  const ability = useContext(AbilityContext);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<IDefaultValues | any>({
    mode: 'onBlur',
    resolver: yupResolver(contactPersonSchema),
    shouldUnregister: false,
    defaultValues: {
      ...contact,
    },
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (status === 'idle' && contactPersonId) {
      dispatch(fetchContactById(contactPersonId));
    }
  }, [status, dispatch, contactPersonId]);

  useEffect(() => {
    reset({
      ...contact,
    });
  }, [reset, contact]);
  const onSubmit = async (data: IDefaultValues) => {
    if (ability.can('change', 'contact')) {
      if (contactPersonId) {
        dispatch(
          updateContact({
            ...data,
            company: me?.company?.id,
            id: contactPersonId,
          })
        ).then(() => {
          dispatch(resetCurrentContact());
        });
      }
    }
    if (ability.can('add', 'contact')) {
      if (!contactPersonId) {
        dispatch(createContact({ ...data, company: me.company?.id })).then(
          () => {
            dispatch(resetCurrentContact());
          }
        );
      }
    }
    navigate(-1);
  };
  return (
    <LoadingWrapper type="main" status={status}>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {companiesContactPersonAddEditSections.map((section, index) => (
            <div key={index}>
              <h2 className={styles.form__title}>{section.title}</h2>
              {section.fields.map((data, i) =>
                data.id === 'personal_phone' ? (
                  <Controller
                    key={data.id}
                    name={data.inputName}
                    control={control}
                    render={({ field }) => (
                      <InputField
                        {...field}
                        as={data.as}
                        mask={data.mask}
                        id={data.id}
                        inputName={data.inputName}
                        label={data.label}
                        errors={errors}
                      />
                    )}
                  />
                ) : (
                  <InputField
                    key={i}
                    id={data.id}
                    inputName={data.inputName}
                    label={data.label}
                    as={data.as}
                    mask={data.mask}
                    errors={errors}
                    register={register}
                  />
                )
              )}
              <Button
                type="submit"
                className={styles.form__submitButton}
                disabled={!isValid}
              >
                {contactPersonId ? 'Сохранить' : 'Добавить'}
              </Button>
            </div>
          ))}
        </form>
      </div>
    </LoadingWrapper>
  );
};

export default CompaniesContactPersonAddEdit;
