import React from 'react';
import { Link } from 'react-router-dom';
import GoToLink from '../../components/common/GoToLink/GoToLink';
import ImageWithFallback from '../../components/common/ImageWithFallback/ImageWithFallback';
import noAvatar from '../../assets/images/no_avatar.svg';
import moment from 'moment';
import ContactInfo from '../../components/common/ContactInfo/ContactInfo';
import styles from './Dashboard.module.scss';
import EmptyState from '../../components/common/EmptyState/EmptyState';
import { Skeleton } from '@chakra-ui/react';

export function DashboardUserItem({ item, link, state }) {
  return (
    <Link
      to={`${link}/${item.id}`}
      state={state}
      key={item.id}
      className={styles.dashboard_user__item}
    >
      <ImageWithFallback
        className={styles.dashboard_user__item_image}
        fallbackSrc={noAvatar}
        defaultSrc={noAvatar}
        src={item.photo}
      />
      <p className={styles.dashboard_user__item_name}>
        {item.first_name + ' ' + item.last_name  || item.name}
      </p>
    </Link>
  );
}

export function DashboardCompanyItem({ item }) {
  return (
    <Link
      to={`companies/`}
      key={item.id}
      className={styles.dashboard_user__item}
    >
      <ImageWithFallback
        className={styles.dashboard_user__item_image}
        fallbackSrc={noAvatar}
        defaultSrc={noAvatar}
        src={item.photo}
      />
      <p className={styles.dashboard_user__item_name}>
        {item.name}
      </p>
    </Link>
  );
}
export function DashboardOrderItem({ order }) {
  return (
    <div key={order.id} className={styles.dashboard_card__order_item}>
      <div className={styles.dashboard_card__order_item_date}>
        Заказ от {moment(order.createdAt).format('D MMMM')}
      </div>
      <div className={styles.dashboard_card__order_item_info}>
        <span className={styles.dashboard_card__order_item_id}>
          {order.name}
        </span>
        <span className={styles.dashboard_card__order_item_company}>
          {order?.legalEntity?.name}
        </span>
      </div>
    </div>
  );
}
 function DashboardItem({
  status,
  children,
  title,
  link,
  className = '',
  minWidth = '32%',
  ...props
}) {
  return (
    <Skeleton
      boxShadow={'sm'}
      borderRadius={'15px'}
      endColor="gray.300"
      startColor="gray.200"
      isLoaded={status !== 'loading'}
      speed={1}
      minWidth={minWidth}
      minHeight={'404px'}
      className={[styles.dashboard_item, styles[className]].join(' ')}
    >
        <p className={styles.dashboard_item__title}>{title}</p>
        <div className={styles.dashboard_item__content}>{children}</div>
        <GoToLink to={link} />
    </Skeleton>
  );
}
export function DashboardCatalogCard({ item }) {
  return (
    <Link
      to={`catalog/${item.id}`}
      key={item.id}
      className={styles.dashboard_catalog_card}
    >
      <img
        src={item.photo}
        className={styles.dashboard_catalog_card__image}
        alt="dashboard-catalog card"
      />
      <p className={styles.dashboard_catalog_card__name}>{item.name}</p>
    </Link>
  );
}
export function DashboardManagerCard({ manager }) {
  return (
    <Link
      to={`catalog/${manager.id}`}
      className={styles.dashboard_manager__card}
    >
      <div className={styles.dashboard_manager__card_header}>
        <img
          className={styles.dashboard_manager__card_image}
          src={manager?.photo ? manager?.photo : noAvatar}
          alt="manager"
        />
        <p className={styles.dashboard_manager__card_name}>
          {manager?.name} {manager?.surname}
        </p>
      </div>
      {manager && <ContactInfo contact={manager} />}
    </Link>
  );
}
export function DashboardPriceItem({ price }) {
  return (
    <Link
      to={`prices/${price.id}`}
      state={{ price }}
      key={price.id}
      className={styles.dashboard_card__prices_item}
    >
      {price.name}
    </Link>
  );
}
export function DashboardCartTable({ cart }) {
  return cart.length ? (
    <table className={styles.dashboard_card__cart_table}>
      <thead className={styles.dashboard_card__cart_table_head}>
        <tr className={styles.dashboard_card__cart_table_row}>
          <th className={styles.dashboard_card__cart_table_title}>
            Наименование товара
          </th>
          <th className={styles.dashboard_card__cart_table_title}>
            Количество
          </th>
        </tr>
      </thead>
      <tbody className={styles.dashboard_card__cart_table_body}>
        {cart.slice(0, 5).map((item) => (
          <tr className={styles.dashboard_card__cart_table_row} key={item.id}>
            <td className={styles.dashboard_card__cart_table_name}>
              <img
                src={item.photo}
                className={styles.dashboard_card__cart_table_image}
                alt=""
              />
              {item.name}
            </td>
            <td className={styles.dashboard_card__cart_table_quantity}>
              {item.quantity}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <EmptyState message="Корзина пуста" />
  );
}


export default React.memo(DashboardItem)