export const getLabel = (value: string | undefined, options: any[]) => {
  if (!value) {
    return 'Не указано';
  }
  if (value === 'unspecified') {
    return 'Заказ оформлен без доставки';
  }
  const option = options.find((option) => option.value === value);
  return option ? option.label : value;
};
