import React from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Text,
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import styles from './styles/CartItem.module.scss';
import QuantityInput from '../../../common/QuantityInput/QuantityInput';
import NumberFormatter from '../../../common/NumberFormatter/NumberFormatter';
import { Item } from '../../../../types/interfaces';

interface CartItemUIProps {
  item: Item;
  quantity: number;
  verificationQuantity: number;
  onQuantityChange: (value: number) => void;
  onVerificationQuantityChange: (value: number) => void;
  removeFromCart: () => void;
  removeVerifyHandler: (id: number) => void;
  addingItemToCart: ({
    quantity,
    verificationQuantity,
  }: {
    quantity: number;
    verificationQuantity: number;
  }) => void;
}

export const CartItemUI = ({
  item,
  quantity,
  verificationQuantity,
  onQuantityChange,
  onVerificationQuantityChange,
  removeFromCart,
  removeVerifyHandler,
  addingItemToCart,
}: CartItemUIProps): JSX.Element => {
  return (
    <Box
      className={styles.item}
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex direction={{ base: 'column', md: 'row' }} align="center">
        <Image
          className={styles['item__image']}
          src={item.photo ? item.photo : undefined}
          alt={item.photo ? item.photo : undefined}
          boxSize="150px"
          objectFit="cover"
          mr={4}
        />
        <Box flex="1" width="100%">
          <Table variant="unstyled" className={styles.table} width="100%">
            <Thead className={styles.thead}>
              <Tr className={styles.tr}>
                <Th className={styles.th}>Наименование</Th>
                <Th className={styles.th}>Количество</Th>
                <Th className={styles.th}>Сумма</Th>
                <Th className={styles.th}>
                  { !!item.verification_item && !!!item.verification_quantity && 'Добавить поверку'}
                </Th>
                <Th textAlign="end" className={styles.th}>
                  Удалить
                </Th>
              </Tr>
            </Thead>
            <Tbody className={styles.tbody}>
              <Tr className={styles.tr}>
                <Td className={styles.td}>
                  <Text isTruncated maxWidth="200px">
                    {item.name}
                  </Text>
                </Td>
                <Td className={styles.td}>
                  <QuantityInput value={quantity} onChange={onQuantityChange} />
                </Td>

                <Td className={styles.td}>
                  <NumberFormatter
                    number={item?.totalPrice ? +item.totalPrice : 0}
                  />{' '}
                  ₽ {!!item.verification_item && !!item.verification_quantity  && 'с учетом поверки'}
                </Td>

                <Td className={styles.td}>
                  {!!item.verification_item && !!!item.verification_quantity && (
                    <Button
                      onClick={() =>
                        addingItemToCart({
                          quantity,
                          verificationQuantity: quantity,
                        })
                      }
                    >
                      Добавить
                    </Button>
                  )}
                </Td>
                <Td textAlign="end" className={styles.td}>
                  <RemoveItemButton
                    removeFromCart={removeFromCart}
                    item={item}
                  />
                </Td>
              </Tr>
              {item.verification_quantity > 0 && (
                <Tr className={styles.tr} bg="gray.50">
                  <Td className={styles.td}>
                    <Text isTruncated maxWidth="200px">
                      {item?.verification_item?.name}
                    </Text>
                  </Td>
                  <Td className={styles.td}>
                    <QuantityInput
                      value={verificationQuantity}
                      onChange={onVerificationQuantityChange}
                    />
                  </Td>

                  <Td className={styles.td}>
                    <NumberFormatter
                      number={item?.verificationTotalPrice || 0}
                    />{' '}
                    ₽
                  </Td>
                  <Td className={styles.td}></Td>
                  <Td textAlign="end" className={styles.td}>
                    <RemoveItemButton
                      removeFromCart={removeVerifyHandler}
                      item={item}
                    />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </Box>
  );
};

interface RemoveItemButtonProps {
  removeFromCart: (id: number) => void;
  item: Item;
}

function RemoveItemButton({ removeFromCart, item }: RemoveItemButtonProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <div
          className={`${styles['item__column-body']} ${styles['item__column-trash-icon']}`}
        >
          <IconButton
            fontSize="20px"
            color="gray.500"
            variant="unstyled"
            _hover={{ color: 'red', bg: 'transparent' }}
            aria-label="Удалить"
            icon={<MdClose />}
          />
        </div>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>Удалить товар из корзины?</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Button onClick={() => removeFromCart(item.id)} colorScheme="red">
              Удалить
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
