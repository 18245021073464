import { default as ReactSelect } from 'react-select';
import styles from './Select.module.scss';

const mergeStyles = (defaultStyles, customStyles) => {
  const newStyles = { ...defaultStyles };
  Object.keys(defaultStyles).forEach((key) => {
    if (customStyles[key]) {
      newStyles[key] = (provided, state) => ({
        ...defaultStyles[key](provided, state),
        ...customStyles[key](provided, state),
      });
    }
  });
  return newStyles;
};
export const optionStyles = {
  option: (provided, { data, isDisabled, isFocused, isSelected }) => {
    const isCustomOption = data.isCustom;
    if (isCustomOption) {
      return {
        ...provided,
        color: 'red',
        ':hover': {
          color: 'red',
          backgroundColor: 'grey',
        },
      };
    }
    return {
      ...provided,

      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '13px',
      color: '#141414',
      backgroundColor: '#FFFFFF',

      textTransform: 'capitalize',
    };
  },

  singleValue: (provided) => ({
    ...provided,
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '143%',
    color: '#383838',
    paddingLeft: '10px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '143%',
    color: '#383838',
    paddingLeft: '10px',
  }),
  container: (provided) => ({
    position: 'relative',
    ...provided,
    minWidth: '150px',
    width: '100%',
    ':hover': {
      borderColor: '#FFFFFF',
      boxShadow: 'none',
    },
  }),
  control: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',

      borderRadius: '10px',
      minWidth: '150px',
      width: '100%',
      height: '40px',
      border: '1px solid #DCDEE0',
      borderColor: '#DCDEE0',
      boxShadow: 'none',
      ':hover': {
        border: '1px solid #DCDEE0',
        borderColor: '#DCDEE0',
        boxShadow: 'none',
      },
    };
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#E3282A',
    ':hover': {
      color: '#E3282A',
    },
  }),

  clearIndicator: (provided) => ({
    ...provided,
    color: '#E3282A',

    ':hover': {
      color: '#E3282A',
    },
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: '9999',
  }),
  valueContainer: (provided) => ({
    ...provided,
    textTransform: 'capitalize',
  }),
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
const Select = ({
  name = 'select',
  optionSelected,
  onSelected,
  options,
  placeholder = 'Тип продукта',
  customStyles = {},
  customOptions = [],
  isGrouped = false,
  isVisible = true,
  label = '',
  selectContainerStyle = {},
  isClearable = true,
  defaultValue = null,
}) => {
  const mergedStyles = mergeStyles(optionStyles, customStyles);
  const groupedOptions = [
    {
      label: 'Недавние',
      options: customOptions,
    },
    {
      label: 'Юридического лица',
      options: options,
    },
  ];
  return (
    <div className={styles['select_wrapper']} style={selectContainerStyle}>
      <label style={isVisible ? {} : { display: 'none' }}>{label}</label>
      <ReactSelect
        defaultValue={defaultValue}
        name={name}
        menuPortalTarget={document.body}
        styles={mergedStyles}
        options={isGrouped ? groupedOptions : options}
        isMulti={false}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        onChange={onSelected}
        allowSelectAll={false}
        value={optionSelected}
        placeholder={placeholder}
        isSearchable={false}
        controlShouldRenderValue={true}
        formatGroupLabel={formatGroupLabel}
        isClearable={isClearable}
      />
    </div>
  );
};

export default Select;
