import { legalEntitiesApi } from '../../api/services/legalEnitities.service';
import { LegalEntity, NewLegalEntity } from '../../types/interfaces';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setErrorNotification, setNotification } from '../notifications';
type PatchLegalEntityRequest = Partial<LegalEntity> & Pick<LegalEntity, 'id'>;
export const fetchAllLegalEntities = createAsyncThunk(
  'legalEntities/fetchAll',
  async (params: object = {}) => {
    const response = await legalEntitiesApi.getAllLegalEntities(params);
    return response.data;
  }
);

export const fetchLegalEntityById = createAsyncThunk(
  'legalEntities/fetchById',
  async (id: number) => {
    const response = await legalEntitiesApi.getLegalEntityById(id);
    return response.data;
  }
);

export const createLegalEntity = createAsyncThunk(
  'legalEntities/create',
  async (data: NewLegalEntity, { dispatch, rejectWithValue }) => {
    try {
      const response = await legalEntitiesApi.createLegalEntity(data);
      dispatch(setNotification('Юр.лицо успешно добавлено!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении юр.лица'));
      return rejectWithValue(error);
    }
  }
);

export const updateLegalEntity = createAsyncThunk(
  'legalEntities/update',
  async (data: LegalEntity, { dispatch, rejectWithValue }) => {
    try {
      const response = await legalEntitiesApi.updateLegalEntity(data.id, data);
      dispatch(setNotification('Юр.лицо успешно обновлено!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении юр.лица'));
      return rejectWithValue(error);
    }
  }
);

export const partialUpdateLegalEntity = createAsyncThunk(
  'legalEntities/partialUpdate',
  async (data: PatchLegalEntityRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await legalEntitiesApi.partialUpdateLegalEntity(
        data.id,
        data
      );
      dispatch(setNotification('Юр.лицо успешно обновлено!'));
      return response.data;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при добавлении юр.лица'));
      return rejectWithValue(error);
    }
  }
);

export const deleteLegalEntity = createAsyncThunk(
  'legalEntities/delete',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await legalEntitiesApi.deleteLegalEntity(id);
      dispatch(setNotification('Юр.лицо успешно удалено!'));
      return id;
    } catch (error) {
      dispatch(setErrorNotification('Ошибка при удалении юр.лица'));
      return rejectWithValue(error);
    }
  }
);
