// registrationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { checkUsernameAvailable, registerCompany, registerEmployee } from './actions';
import { FetchStatusType } from '../../types/interfaces';

interface UserData {
  email: string;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  mobile_phone: string;
  terms: boolean;
}
interface Address {
  [key: string]: string | 'legal' | 'physical';
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  apartment: string;
  postcode: string;
  type: 'legal' | 'physical';
}
interface LegalEntity {
  name: string;
  email: string;
  phones: string[];
  inn: string;
  online_documents: boolean;
  addresses: Address[];
  category_guid: string;
  partner_guid: string;
}
interface Contact {
  name: string;
  surname: string;
  patronymic: string;
  position: string;
  personal_phone: string;
}

interface RegistrationState {
  invite: string;
  legal_entities: LegalEntity[]
  contacts: Contact[]
  name: string
  user: UserData;
  terms: boolean;
  status: FetchStatusType;
  error: string;
  currentStep: number;
  manager: Manager;
  guid: string;
}

interface Manager {
  name: string;
  email: string;
  guid: string;
}

const initialState: RegistrationState = {
  status: 'idle',
  error: '',
  invite: '',
  guid: '',
  manager: {
    name: '',
    email: '',
    guid: '',
  },
  user: {
    username: '',
    password: '',
    email: '',
    first_name: '',
    last_name: '',
    mobile_phone: '',
    terms: false,
  },
  legal_entities: [{
    name: '',
    inn: '',
    email: '',
    phones: [],
    online_documents: true,
    addresses: [
      {
        country: '',
        region: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        postcode: '',
        type: 'legal',
      },
      {
        country: '',
        region: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        postcode: '',
        type: 'physical',
      },
    ],
    category_guid: '',
    partner_guid: '',
  }],
  contacts: [
    {
      name: '',
      surname: '',
      patronymic: '',
      position: '',
      personal_phone: '',
    }
  ],
  name: '',
  terms: true,
  currentStep: 0,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    resetRegistration: () => initialState,
    addCompanyPartialData(state, action: PayloadAction<{ name: string; inn: string; email: string; online_documents: boolean; phones: string[]; }>) {
      const { name, inn, email, phones, online_documents } = action.payload;
      state.name = name
      state.legal_entities[0].name = name
      state.legal_entities[0].inn = inn
      state.legal_entities[0].email = email
      state.legal_entities[0].phones = phones
      state.legal_entities[0].online_documents = online_documents
      state.currentStep = 2;
    },
    addCompanyData(state, action: PayloadAction<any>) {
      if (action.payload.type === 'employee') {
        return
      }
      const { inn, name, email, phone, manager, guid, legalAddressJSON, actualAddressJSON, categoryGuid } = action.payload.partial_data;

      const safeParseJSON = (jsonString: string) => {
        try {
          if (!jsonString) return null;
          let json = JSON.parse(jsonString);
          return json;
        } catch (err) {
          console.error("Ошибка при парсинге JSON:", err);
          return null;
        }
      };

      const parseAddress = (addressObj: any, type: 'legal' | 'physical'): Address | null => {
        if (!addressObj) return null;
        return {
          country: addressObj.country || '',
          region: addressObj.area || '',
          city: addressObj.city || '',
          street: addressObj.street || '',
          house: addressObj.houseNumber || '',
          apartment: addressObj.apartments?.[0]?.number || '',
          postcode: addressObj.ZIPcode || '',
          type: type,
        };
      };

      const legalAddressObj = safeParseJSON(legalAddressJSON);
      const actualAddressObj = safeParseJSON(actualAddressJSON);

      if (!legalAddressObj || !actualAddressObj) {
        console.log("Один из адресов некорректен и не может быть обработан.");
      }

      const defaultLegalAddress: Address = {
        country: '',
        region: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        postcode: '',
        type: 'legal',
      };
      
      const defaultPhysicalAddress: Address = {
        country: '',
        region: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        postcode: '',
        type: 'physical',
      };
      
      const legalAddressParsed = parseAddress(legalAddressObj, 'legal') || defaultLegalAddress;
      const actualAddressParsed = parseAddress(actualAddressObj, 'physical') || defaultPhysicalAddress;
      const legalEntity: LegalEntity = {
        name: name || '',
        email: email || '',
        phones: [phone].filter(Boolean),
        inn: inn || '',
        online_documents: true,
        addresses: [legalAddressParsed, actualAddressParsed],
        category_guid: categoryGuid || '',
        partner_guid: guid || '',
      };

      console.log('legalEntity.addresses',legalEntity.addresses[0].type);
      console.log('legalEntity.addresses',legalEntity.addresses[1].type);


      state.name = name || '';
      state.invite = action.payload.id || '';
      state.manager = manager || '';
      state.legal_entities[0] = (legalEntity);
    }

    ,
    addLegalAddress(state, action: PayloadAction<Address>) {
      const legalAddress = action.payload;
      state.legal_entities[0].addresses = state.legal_entities[0].addresses.map(address => {
        if (address.type === 'legal') {
          return {...legalAddress, type: 'legal'};
        }
        return address;
      })
      state.currentStep = 3
    },
    addDeliveryAddress(state, action: PayloadAction<Address>) {
      const deliveryAddress = action.payload;
      state.legal_entities[0].addresses = state.legal_entities[0].addresses.map(address => {
        if (address.type === 'physical') {
          return {...deliveryAddress, type: 'physical'};
        }
        return address;
      })
      state.currentStep = 4;
    },
    addContactPerson(state, action: PayloadAction<Contact>) {
      const contactPerson = action.payload;
      state.contacts[0] = contactPerson;
      state.currentStep = 5;
    },
    confirmRegistration(state) {

    },

    setStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    previousStep(state) {
      if (state.currentStep > 0) {
        state.currentStep--;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUsernameAvailable.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkUsernameAvailable.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { username, password, email, first_name, last_name, mobile_phone } = action.payload;
        state.user.username = username;
        state.user.password = password;
        state.user.email = email;
        state.user.first_name = first_name;
        state.user.last_name = last_name;
        state.currentStep = 1;
        state.user.mobile_phone = mobile_phone;
      })
      .addCase(checkUsernameAvailable.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch prices';
      })
      .addCase(registerCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentStep = 6;
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch prices';
      })
      .addCase(registerEmployee.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerEmployee.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentStep = 6;
      })
      .addCase(registerEmployee.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch prices';
      })
  },
});

export const {
  addCompanyPartialData,
  resetRegistration,
  addCompanyData,
  addLegalAddress,
  addDeliveryAddress,
  addContactPerson,
  confirmRegistration,
  previousStep,
  setStep
} = registrationSlice.actions;

export const selectCredentials = (state: RootState) => state.registration.user

export const selectLegalEntities = (state: RootState) => state.registration.legal_entities;

export const selectLegalEntityLegalAddress = (state: RootState) => {
  const legalEntity = state.registration.legal_entities[0];
  return legalEntity.addresses.find(address => address.type === 'legal');
};
export const selectLegalEntitiesPhysicalAddress = (state: RootState) => {
  const legalEntity = state.registration.legal_entities[0];
  return legalEntity.addresses.find(address => address.type === 'physical');
};


export const selectRegistrationData = (state: RootState) => {
  const contacts = state.registration.contacts.map((contact) => ({
    name: contact.name,
    surname: contact.surname,
    patronymic: contact.patronymic,
    position: contact.position,
    personal_phone: contact.personal_phone,
  }));

  const legal_entities = state.registration.legal_entities.map((entity) => ({
    name: entity.name,
    email: entity.email,
    inn: entity.inn,
    phones: entity.phones,
    category_guid: entity.category_guid,
    partner_guid: entity.partner_guid,
    online_documents: entity.online_documents,
    addresses: entity.addresses.map((address) => ({
      country: address.country,
      region: address.region,
      city: address.city,
      street: address.street,
      house: address.house,
      apartment: address.apartment,
      postcode: address.postcode,
      type: address.type,
    })),
  }));



  const user = {
    username: state.registration.user.username,
    password: state.registration.user.password,
    email: state.registration.user.email,
    first_name: state.registration.user.first_name,
    last_name: state.registration.user.last_name,
    mobile_phone: state.registration.user.mobile_phone,
    invite: state.registration.invite,
  }

  return {
    invite: state.registration.invite,
    name: state.registration.name,
    guid: state.registration.guid,
    terms: state.registration.terms,
    user,
    legal_entities,
    contacts,
  };
};
export const selectContacts = (state: RootState) => state.registration.contacts;
export const selectCurrentStep = (state: RootState) => state.registration.currentStep;
export const selectResponsibleManager = (state: RootState) => state.registration.manager;
export const selectRegistrationStatus = (state: RootState) => state.registration.status;

export default registrationSlice.reducer;
