import * as yup from 'yup';

export const contactPersonSchema = yup.object().shape({
  surname: yup
    .string()
    .required('Обязательное поле')
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  name: yup
    .string()
    .required('Обязательное поле')
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  patronymic: yup
    .string()
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  position: yup
    .string()
    .required('Обязательное поле')
    .trim()
    .min(2, 'Минимум 2 символа')
    .max(120, 'Максимум 120 символов'),
  personal_phone: yup
    .string()
    .required('Пожалуйста, введите номер телефона')
    .matches(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Пожалуйста, введите корректный номер телефона'
    ),
});

export const inviteSchema = yup.object().shape({
  name: yup
    .string()
    .required('Название инвайта обязательно')
    .min(2, 'Название инвайта должно содержать минимум 2 символа'),
});
