import React, { useState, useMemo } from 'react';

import styles from './TabsWithIcons.module.scss';

type TabProps = {
  title: string;
  children: React.ReactNode;
  icon: string;
};

type TabsProps = {
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
  initialActiveTab?: number;
};

export const TabsWithIcons: React.FC<TabsProps> = ({
  children,
  initialActiveTab = 0,
}) => {
  const [activeTab, setActiveTab] = useState<number>(initialActiveTab);

  const filteredChildren = useMemo(
    () =>
      React.Children.toArray(children).filter(
        (child: any) => child.props.title
      ),
    [children]
  );

  return (
    <div className={styles['tabs']}>
      <ul className={styles['tabs-list']}>
        {filteredChildren.map((child: any, index) => (
          <li
            key={index}
            className={`${styles['tab-item']} ${
              activeTab === index ? styles['tab-item--active'] : ''
            }`}
            onClick={() => setActiveTab(index)}
          >
            <img
              src={child.props.icon}
              alt="#"
              className={styles['tab-item__icon']}
            />
            {child.props.title}
          </li>
        ))}
      </ul>
      <div className={styles['tabs-content']}>
        {filteredChildren.map((child: any, index) =>
          activeTab === index ? child.props.children : undefined
        )}
      </div>
    </div>
  );
};