import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllUsers,
  fetchUserById,
  createUser,
  updateUser,
  partialUpdateUser,
  deleteUser,
  createUserWithManagerPermissions, 
} from './actions';
import { FetchStatusType, User } from '../../types/interfaces';
import { RootState } from '../store';

interface UsersState {
  users: User[];
  status: FetchStatusType;
  error: string | null;
  currentUser: User ;
}

const initialState: UsersState = {
  users: [],
  status: 'idle',
  error: null,
  currentUser: {
    id: 0,
    username: '',
    first_name: '',
    patronymic: '',
    last_name: '',
    email: '',
    mobile_phone: '',
    additional_phone: '',
    whatsapp: '',
    telegram: '',
    is_staff: false,
    is_superuser: false,
    permissions: [],
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch users';
      })
      .addCase(fetchUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload;
        } else {
          state.users.push(action.payload);
        }
        state.currentUser = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch user';
      })
      .addCase(createUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users.push(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create user';
      })
      .addCase(createUserWithManagerPermissions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUserWithManagerPermissions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users.push(action.payload);
      })
      .addCase(createUserWithManagerPermissions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create user with manager permissions';
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update user';
      })
      .addCase(partialUpdateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(partialUpdateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = { ...state.users[index], ...action.payload };
        }
      })
      .addCase(partialUpdateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to patch user';
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = state.users.filter(user => user.id !== action.meta.arg);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete user';
      });
  },
});

export const selectUsersStatus = (state: RootState): FetchStatusType => state.users.status;
export const selectUsersError = (state: RootState): string | null => state.users.error;
export const selectUsers = (state: RootState): User[] => state.users.users;
export const selectCurrentUser = (state: RootState): User | null => state.users.currentUser;

export const { resetUsers } = usersSlice.actions;

export default usersSlice.reducer;
