import { AxiosResponse } from 'axios';
import { apiClient } from '../instance';
import { LegalEntity, NewLegalEntity } from '../../types/interfaces';

export const legalEntitiesApi = {
  getAllLegalEntities: (
    params: object
  ): Promise<AxiosResponse<LegalEntity[]>> => {
    return apiClient.get<LegalEntity[]>('/legal_entities/', { params });
  },

  getLegalEntityById: (id: number): Promise<AxiosResponse<LegalEntity>> => {
    return apiClient.get<LegalEntity>(`/legal_entities/${id}/`);
  },

  createLegalEntity: (
    data: NewLegalEntity
  ): Promise<AxiosResponse<LegalEntity>> => {
    return apiClient.post<LegalEntity>('/legal_entities/', data);
  },

  updateLegalEntity: (
    id: number,
    data: LegalEntity
  ): Promise<AxiosResponse<LegalEntity>> => {
    return apiClient.put<LegalEntity>(`/legal_entities/${id}/`, data);
  },

  partialUpdateLegalEntity: (
    id: number,
    data: Partial<LegalEntity>
  ): Promise<AxiosResponse<LegalEntity>> => {
    return apiClient.patch<LegalEntity>(`/legal_entities/${id}/`, data);
  },

  deleteLegalEntity: (id: number): Promise<AxiosResponse<void>> => {
    return apiClient.delete(`/legal_entities/${id}/`);
  },
};
