export const cartConfirmSteps: Record<string, { title: string }> =  {
  0: {
    title: '1 – Ваша корзина',
  },
  1: {
    title: '2 – Контактные данные',
  },
  2: {
    title: '3 – Способы доставки',
  },
};