import React from 'react';
import errorImage from '../../../assets/images/404.png';
import Button from '../../../components/common/Button/Button';

const ErrorPage = () => {
  //const dispatch = useDispatch();
  const goBack = () => {
    // dispatch(setStep(6));
  };
  return (
    <div className="error-page-wrp">
      <h1 className="error-page-title">
        Произошла ошибка, пожалуйста обратитесь к вашему <br /> менеджеру или
        попробуйте снова пройти все этапы регистрации
      </h1>
      <Button onClick={goBack} className="error-page-btn">
        Вернуться к заполнению
      </Button>
      <img src={errorImage} alt="error" />
    </div>
  );
};

export default ErrorPage;
