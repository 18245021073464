import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getCart,
  addItemToCart,
  deleteItemFromCart,
  addItemsToCart,
  deleteVerificationFromCart,
} from './actions';
import { CartItem, FetchStatusType, Item } from '../../types/interfaces';
import { RootState } from '../store';
import { calculateCartPrices } from '../../utils/hooks/pricing';

export interface CartState {
  status: FetchStatusType;
  error: string | null;
  currency: string;
  items: Item[];
  amount_previous_order: number;
  loadingId: number | null;
}

const initialState: CartState = {
  status: 'idle',
  currency: '',
  error: null,
  amount_previous_order: 0,
  items: [],
  loadingId: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.items.map((item: CartItem) => ({
          ...item,
          ...item.item,
        }));
        if (action.payload.amount_previous_order) {
          state.amount_previous_order = action.payload.amount_previous_order;
        }
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch cart';
      })
      .addCase(addItemToCart.pending, (state, action) => {
        state.status = 'loading';
        state.loadingId = action.meta.arg.id;
        state.error = null;
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loadingId = null;
        state.items = action.payload.items.map((item: CartItem) => ({
          ...item,
          ...item.item,
        }));
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        state.status = 'failed';
        state.loadingId = null;
        state.error = action.error.message || 'Failed to add item to cart';
      })
      .addCase(addItemsToCart.pending, (state, action) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addItemsToCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loadingId = null;
        state.items = action.payload.items.map((item: CartItem) => ({
          ...item,
          ...item.item,
        }));
      })
      .addCase(addItemsToCart.rejected, (state, action) => {
        state.status = 'failed';
        state.loadingId = null;
        state.error = action.error.message || 'Failed to add items to cart';
      })
      .addCase(deleteItemFromCart.pending, (state, action) => {
        state.status = 'loading';
        state.loadingId = action.meta.arg;
        state.error = null;
      })
      .addCase(deleteItemFromCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loadingId = null;
        state.items = action.payload.items.map((item: CartItem) => ({
          ...item,
          ...item.item,
        }));
      })
      .addCase(deleteItemFromCart.rejected, (state, action) => {
        state.loadingId = null;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete item from cart';
      })
      .addCase(deleteVerificationFromCart.pending, (state, action) => {
        state.status = 'loading';
        state.loadingId = action.meta.arg.id;
        state.error = null;
      })
      .addCase(deleteVerificationFromCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loadingId = null;
        state.items = action.payload.items.map((item: CartItem) => ({
          ...item,
          ...item.item,
        }));
      })
      .addCase(deleteVerificationFromCart.rejected, (state, action) => {
        state.loadingId = null;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to delete item from cart';
      });
  },
});
export const selectItemCartAddDeleteStatus = (state: RootState) =>
  state.cart.loadingId;
export const selectCartStatus = (state: RootState): FetchStatusType =>
  state.cart.status;
export const selectCartError = (state: RootState): string | null =>
  state.cart.error;

const cartItemsSelector = (state: RootState) => state.cart.items;

export const getAmountPreviousOrder = (state: RootState) =>
  state.cart.amount_previous_order;

export const selectTotalItemsCount = (itemId: number) => createSelector(
  [cartItemsSelector, getAmountPreviousOrder],
  (cartItems, APO) => {
    return cartItems.reduce((total, item) => {
      if (!item.do_not_count && item.id !== itemId) {
        return total + item.quantity;
      }
      return total;
    }, APO);
  }
);
export const selectCartWithCalculatedPrices = createSelector(
  [cartItemsSelector, getAmountPreviousOrder],
  (cartItems, APO) => calculateCartPrices(cartItems, APO)
);

export const selectCartItemsLength = (state: RootState) =>
  state.cart.items.length;

export const { resetCart } = cartSlice.actions;

export default cartSlice.reducer;
