import React from 'react';
import Button from '../Button/Button';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import './ConfirmModal.scss';

interface IConfirmModal {
  confirm: () => void;
  texts: {
    title: string;
    text: string;
    cancel: string;
    confirm: string;
  };
  openButton: JSX.Element;
  children?: JSX.Element;
}
const ConfirmModal = ({ confirm=() => {}, texts={ title: '', text: '', cancel: '', confirm: '' }, openButton=<></>, children=<></> }: IConfirmModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    confirm();
    onClose();
  };

  const openModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onOpen();
  };

  return (
    <>
      {React.cloneElement(openButton, { onClick: openModal })}
      <Modal size={'xl'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent m="auto">
          <ModalHeader>{texts.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="modal__text">{texts.text}</div>
            {children}
          </ModalBody>
          <ModalFooter>
            <div className="modal__btns">
              <Button
                onClick={onClose}
                isWhite={true}
                className="modal__button modal__button--cancel"
              >
                {texts.cancel}
              </Button>
              <Button
                onClick={closeModal}
                className="modal__button modal__button--confirm"
              >
                {texts.confirm}
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModal;
