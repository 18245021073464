import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ReactSelect from 'react-select';
import { customStyles } from '../../../assets/styles/select-styles';
import { fetchAllLegalEntities } from '../../../store/legalEntities/actions';
import {
  selectLegalEntities,
  selectLegalEntityStatus,
} from '../../../store/legalEntities/reducer';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { LegalEntity, legalEntitySelect } from '../../../types/interfaces';
interface SelectLegalEntitiesProps {
  myLegalEntities: LegalEntity[] | undefined;
  selectedLegalEntity: legalEntitySelect | null;
  setSelectedLegalEntity: Dispatch<SetStateAction<legalEntitySelect | null>>;
  manager_id?: number;
  isClearable? : Boolean
}
function SelectLegalEntities({
  myLegalEntities,
  selectedLegalEntity,
  setSelectedLegalEntity,
  manager_id = 0,
  isClearable = true
}: SelectLegalEntitiesProps) {
  const legalEntities = useAppSelector(selectLegalEntities);
  const legalEntitiesFetchedStatus = useAppSelector(selectLegalEntityStatus);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!myLegalEntities) {
      if (legalEntitiesFetchedStatus !== 'succeeded') {
        dispatch(fetchAllLegalEntities());
      }
    }
  }, [dispatch]);

  const filteredIntegrators = myLegalEntities
    ? myLegalEntities.map((le) => ({ value: le.id, label: le.name }))
    : legalEntities.map((le) => ({ value: le.id, label: le.name }));

  return (
    <>
      <ReactSelect
        styles={customStyles}
        loadingMessage={() => 'Загрузка...'}
        isLoading={legalEntitiesFetchedStatus === 'loading'}
        options={filteredIntegrators}
        value={selectedLegalEntity}
        onChange={setSelectedLegalEntity}
        placeholder="Юридические лица"
        isMulti={false}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        //allowSelectAll={false}
        isSearchable={true}
        isClearable={isClearable ? true: false}
      />
    </>
  );
}

export default SelectLegalEntities;
