import { lazy } from 'react';
import ProtectedRoute from '../ProtectedRoute';
import CatalogDetail from '../../components/modules/CatalogDetail/CatalogDetail';
import CatalogAddEdit from '../../components/modules/CatalogAddEdit/CatalogAddEdit';
// const CatalogAddEdit = lazy(() => import('../../components/modules/CatalogAddEdit/CatalogAddEdit'));
// const CatalogDetail = lazy(() => import('../../components/modules/CatalogDetail/CatalogDetail'));
// const GroupAddEdit = lazy(() => import('../../components/modules/GroupAddEdit/GroupAddEdit'));
const Catalog = lazy(() => import('../../pages/Catalog/Catalog'));

export const catalog_routes = [
  {
    path: 'catalog',
    errorElement: <h1>404</h1>,
    element: <Catalog />,
  },
  {
    path: 'catalog/:id',
    element: <CatalogDetail />,
    loader: () => {
      return document.title = 'Товар';
    },
  },
  {
    path: 'catalog/edit/:id',
    element: (
      <ProtectedRoute
        isOnlySuperManager={true}
        action='change'
        subject={'card'}
        redirectTo={'/catalog'}
        children={
        <CatalogAddEdit />
      }
      />
    ),
    loader: () => {
      return document.title = 'Редактирование товара';
    },
  },
  {
    path: 'catalog/add',
    element: (
      <ProtectedRoute
        isOnlySuperManager={true}
        action='add'
        subject={'catalog'}
        redirectTo={'/catalog'}
        children={
        <CatalogAddEdit />
      }
      />
    ),
    loader: () => {
      return document.title = 'Редактирование товара';
    },
  },
];
